/* eslint-disable @typescript-eslint/ban-types */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import axios from 'axios';
import { MessagesAction, MessagesActionTypes } from './messages_types';
import { messagesURL, messagesCountUnseenURL } from '../services/messages';
import { disputesCountUnseenURL } from '../services/disputes';

export const messagesRequestActionCreator = (): MessagesActionTypes => {
    return {
        type: MessagesAction.MESSAGES_REQUEST,
    };
};

export const messagesSuccessActionActionCreator = (messages: object[]): MessagesActionTypes => {
    return {
        type: MessagesAction.MESSAGES_SUCCESS,
        payload: messages,
    };
};

export const messagesUnseenSuccessActionCreator = (payload: object): MessagesActionTypes => {
    return {
        type: MessagesAction.MESSAGES_UNREAD_SUCCESS,
        payload,
    };
};

export const messagesFailureActionActionCreator = (): MessagesActionTypes => {
    return {
        type: MessagesAction.MESSAGES_FAILURE,
    };
};

export const messagesResetActionActionCreator = (): MessagesActionTypes => {
    return {
        type: MessagesAction.MESSAGES_RESET,
    };
};

export const requestMessages = (onSuccess: Function) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(messagesRequestActionCreator());
        try {
            const { status, data } = await axios.get(messagesURL());

            if (status === 200) {
                dispatch(messagesSuccessActionActionCreator(data));
                onSuccess(data);
            }
        } catch (error) {
            dispatch(messagesFailureActionActionCreator());
        }
    };
};

export const requestUnreadMessagesAndDisputes = (getDisputes = true) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(messagesRequestActionCreator());
        try {
            let disputesData = {};
            const { data } = await axios.get(messagesCountUnseenURL());
            if (getDisputes) {
                disputesData = (await axios.get(disputesCountUnseenURL())).data;
            }

            dispatch(messagesUnseenSuccessActionCreator({
                unseenMessages: data,
                unseenDisputes: disputesData,
            }));
        } catch (error) {
            dispatch(messagesFailureActionActionCreator());
        }
    };
};
