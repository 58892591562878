/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';

interface OwnProps extends TranslationContext {
    opacityLevel: number;
    onOpacityValueChange(newOpacityValue: number): void;
}

const DamageOpacityInput: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const { t, opacityLevel, onOpacityValueChange } = props;

    return (
        <div className="damage-opacity-input">
            <div className="opacity-value">
                <label htmlFor="opacity">{t('preInspections.form.labels.opacity')}</label>
                <span>{opacityLevel}%</span>
            </div>
            <input
                id="opacity"
                name="opacity"
                type="range"
                value={opacityLevel}
                min="0"
                max="100"
                step="1"
                onChange={(e) => onOpacityValueChange(Number(e.currentTarget.value))}
                className="slider-opacity"
            />
        </div>
    );
};

export default withTranslationContext(DamageOpacityInput);
