/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { get } from 'lodash';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import logo from '../../assets/images/logo_horizontal.svg';
import FormTextField from './FormTextField';
import { getFormErrors, IFormError, VALIDATIONS } from '../../utils/validation';

interface OwnProps extends TranslationContext {
    onModalClose: Function;
    onClickSendReport: Function;
    agentName: string;
    agentEmail: string;
}

interface OwnState {
    insuranceCompany: string;
    email: string;
    formErrors: any;
}

const initialState: OwnState = {
    insuranceCompany: '',
    email: '',
    formErrors: {},
};

const modalOutsideId = 'modal-outside';

class SendMailModal extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { agentEmail, agentName } = this.props;

        this.setState({
            insuranceCompany: agentName,
            email: agentEmail,
        });

        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { state } = this;
        this.setState({
            ...state,
            [e.currentTarget.name]: e.currentTarget.value,
        });
    };

    onClose = () => {
        const { onModalClose } = this.props;
        this.setState({ ...initialState });
        onModalClose();
    };

    validateFields = (withReport: boolean): boolean => {
        const { state } = this;

        let errors: IFormError | null = getFormErrors(state, VALIDATIONS.SEND_MAIL_MODAL);

        if (errors && Object.keys(errors).length === 0) errors = null;

        if (!withReport) delete errors?.email;

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    sendWithoutReport = () => {
        const { insuranceCompany, email } = this.state;
        const { onClickSendReport } = this.props;
        if (this.validateFields(false)) {
            onClickSendReport(false, insuranceCompany, email);
            this.onClose();
        }
    };

    sendWithReport = () => {
        const { insuranceCompany, email } = this.state;
        const { onClickSendReport } = this.props;

        if (this.validateFields(true)) {
            onClickSendReport(true, insuranceCompany, email);
            this.onClose();
        }
    };

    handleOutsideClick = (e: any) => {
        const { target } = e;
        const { id } = target;

        if (id === modalOutsideId) {
            this.onClose();
        }
    };

    render() {
        const { t } = this.props;
        const { insuranceCompany, email, formErrors } = this.state;
        const strTitle = t('modalSendMail.title');

        return (
            <div>
                <div id={modalOutsideId} className="app-screen__modal" data-testid="modal-outside-test">
                    <div className="app-screen__modal__container send-message-modal">
                        <div className="border-header">
                            <img src={logo} alt="logo" />
                            <p>{strTitle}</p>
                        </div>
                        <div className="app-screen__modal__container__box send-message-box">
                            <div className="app-screen__modal__container__box__content send-report-content">
                                <FormTextField
                                    containerStyles="field-new-message"
                                    label={t('modalSendMail.name')}
                                    name="insuranceCompany"
                                    value={insuranceCompany}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.insuranceCompany', null)}
                                />
                                <FormTextField
                                    containerStyles="field-new-message"
                                    label={t('modalSendMail.email')}
                                    name="email"
                                    value={email}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.email', null)}
                                />
                                <div className="btn-container">
                                    <button
                                        type="button"
                                        className="btn btn--dark-purple"
                                        onClick={this.sendWithoutReport}
                                        data-testid="btn-send-without-report-test"
                                    >
                                        <span>{t('modalSendMail.withoutReport')} <br />{t('modalSendMail.report')}</span>
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn--purple"
                                        onClick={this.sendWithReport}
                                        data-testid="btn-send-with-report-test"
                                    >
                                        <span>{t('modalSendMail.withReport')} <br />{t('modalSendMail.report')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(SendMailModal);
