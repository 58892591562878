/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, ReactNode } from 'react';
import Select, { SingleValue } from 'react-select';

import ErrorMessage from './ErrorMessage';
import { SelectOption } from '../../constants/misc';

interface OwnProps {
    name: string;
    inputName: string;
    value: string | number;
    searchValue: string;
    onChange(name: string, optValue: string): void;
    onSearchInputChange(name: string, value: string): void;
    label?: string;
    containerStyles?: string;
    fieldStyles?: string;
    options: Array<SelectOption>;
    errors?: any;
    isDisabled?: boolean;
    labelTitle?: string;
    menuPlacement?: 'auto' | 'bottom' | 'top';
}

class FormSelectField extends Component<OwnProps> {
    onChange = (opt: SingleValue<SelectOption>): void => {
        const { onChange, name } = this.props;

        if (!opt) {
            onChange(name, '');
            return;
        }

        onChange(name, String(opt.value));
    };

    renderErrors = (): ReactNode => {
        const { name, errors } = this.props;

        return <ErrorMessage errors={errors} field={name} />;
    };

    render() {
        const {
            value,
            label,
            options,
            errors,
            searchValue,
            inputName,
            name,
            menuPlacement,
            labelTitle = '',
            fieldStyles = '',
            containerStyles = '',
            isDisabled = false,
            onSearchInputChange,
        } = this.props;
        const containerClass = containerStyles;
        const fieldClass = fieldStyles;

        return (
            <div className={`field-container ${containerClass}`}>
                {label && (
                    <div
                        className="field-container__label"
                        title={labelTitle || ''}
                    >
                        {label}
                    </div>
                )}
                <div className={`field-container__select-field ${fieldClass}`} data-testid={`select-wrapper-${name}`}>
                    <Select
                        onChange={this.onChange}
                        onInputChange={(newValue) => onSearchInputChange(inputName, newValue)}
                        value={options.find((opt) => String(opt.value) === String(value))}
                        inputValue={searchValue}
                        options={options}
                        name={name}
                        className="searchable-select"
                        classNamePrefix="searchable-select"
                        placeholder=""
                        isDisabled={isDisabled}
                        menuPlacement={menuPlacement}
                    />
                </div>
                <p>{errors && this.renderErrors()}</p>
            </div>
        );
    }
}

export default FormSelectField;
