/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { get } from 'lodash';
import logo from '../../assets/images/entry_logo.svg';
import EntryPageFormTextfield from '../elements/EntryPageFormTextfield';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import ButtonDeprecated from '../elements/ButtonDeprecated';
import { getFormErrors, IFormError, VALIDATIONS } from '../../utils/validation';
import { INDEX_ROUTE } from '../../constants/routes';
import { withRouter, WithRouterProps } from '../containers/withRouter';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';

interface OwnProps extends WithRouterProps, AuthenticationContext, TranslationContext {}

interface OwnState {
    email: string;
    formErrors: any;
}

const initialState = {
    email: '',
    formErrors: {},
};

class RecoverPasswordScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (this.validateFields()) {
            this.sendRecoverPasswordRequest();
        }
    };

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            email: e.currentTarget.value,
        });
    };

    onRecoverPasswordSuccess = () => {
        const { navigate } = this.props;
        navigate(INDEX_ROUTE);
    };

    onFailure = () => {
        const { t } = this.props;
        displayNotification(NOTIFICATION_TYPE.ERROR, t('errors.general'));
    };

    validateFields = () => {
        const { state } = this;
        let errors: IFormError | null = getFormErrors(state, VALIDATIONS.RECOVER_PASSWORD_FORM);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    sendRecoverPasswordRequest = () => {
        const { recoverPasswordRequest } = this.props;
        const { email } = this.state;

        recoverPasswordRequest(email.trim(), this.onRecoverPasswordSuccess, this.onFailure);
    };

    render() {
        const { t } = this.props;
        const { email, formErrors } = this.state;

        return (
            <div className="entry-page">
                <div className="entry-page__centered recover-password">
                    <img src={logo} alt="logo" />
                    <form onSubmit={this.onSubmit}>
                        <div className="entry-page__centered__fields">
                            <h2>{t('recoverPassword.helpText')}</h2>
                            <EntryPageFormTextfield
                                name="email"
                                value={email}
                                placeholder={t('login.labels.user')}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.email', null)}
                            />
                        </div>
                        <ButtonDeprecated
                            type="submit"
                            text={t('recoverPassword.sendBtn')}
                            styles="btn--purple"
                        />
                    </form>
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(withRouter(RecoverPasswordScreen)));
