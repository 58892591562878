/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { TranslationContextProvider } from './TranslationContext';

import { AppState } from '../../../reducers/types';
import { Language } from '../../../constants/preferences';
import { KeyedObject } from '../../../constants/misc';
import { getTranslation } from '../../../utils/translation';

import en from '../../../locales/en/translation.json';
import pt from '../../../locales/pt/translation.json';

interface StateProps {
    language: Language;
}

interface OwnProps extends StateProps {
    children: React.ReactNode;
}

type Props = StateProps & OwnProps;

export class TranslationController extends Component<Props> {
    t = (literal: string, params?: KeyedObject): string => {
        const { language } = this.props;
        return getTranslation({ en, pt }, language, literal, params);
    };

    render() {
        const {
            children,
            language,
        } = this.props;

        return (
            <TranslationContextProvider
                value={{
                    language,
                    t: this.t,
                }}
            >
                {children}
            </TranslationContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        language: state.preferences.language,
    };
};

export const ConnectedTranslationController = connect(mapStateToProps)(TranslationController);
