/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';
import { ConnectedFileDamageController, FileDamageController } from './FileDamageController';

export interface FileDamageContext extends Omit<InstanceType<typeof FileDamageController>, keyof React.Component> {}

const fileDamageContextInstance = createContext<FileDamageContext | null>(null);

// Export Provider and Consumer

export const FileDamageContextProvider = fileDamageContextInstance.Provider;
export const FileDamageContextConsumer = fileDamageContextInstance.Consumer;

export const withFileDamageContext = <P, >(Component: ComponentType<P>): FC<Omit<P, keyof FileDamageContext>> => (props) => (
    <ConnectedFileDamageController>
        <FileDamageContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </FileDamageContextConsumer>
    </ConnectedFileDamageController>
);
