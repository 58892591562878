/* eslint-disable @typescript-eslint/ban-types */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { AuthorizationContextProvider } from './AuthorizationContext';

import { AppState } from '../../../reducers/types';
import { RolePermission, PermissionKey } from '../../../constants/authorization';
import { PermissionOperators, checkPermission } from '../../../utils/authorization';

interface StateProps {
    permissions: RolePermission[];
}

interface OwnProps extends StateProps {
    children: React.ReactNode;
}

type Props = StateProps & OwnProps;

export class AuthorizationController extends Component<Props> {
    getPermissions = () => {
        const { permissions } = this.props;
        return permissions;
    };

    checkPermission = (actions: Array<PermissionKey>, operator: PermissionOperators = PermissionOperators.OR): boolean => {
        const {
            permissions,
        } = this.props;

        return checkPermission(permissions, actions, operator);
    };

    render() {
        const {
            children,
            permissions,
        } = this.props;

        return (
            <AuthorizationContextProvider
                value={{
                    permissions,
                    getPermissions: this.getPermissions,
                    checkPermission: this.checkPermission,
                }}
            >
                {children}
            </AuthorizationContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        permissions: state.authorization.permissions,
    };
};

export const ConnectedAuthorizationController = connect(mapStateToProps)(AuthorizationController);
