/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import { MessageContextProvider } from './MessageContext';
import { AppState } from '../../../reducers/types';
import { requestUnreadMessagesAndDisputes } from '../../../actions/messages';
import { AuthenticationContext, withAuthenticationContext } from '../authentication/AuthenticationContext';
import { UserRoles } from '../../../constants/authorization';
import { messagesURL, messageURL } from '../../../services/messages';
import { Message } from '../../../constants/types';
import { KeyedObject } from '../../../constants/misc';

interface StateProps {
    unseenMessages: number;
    unseenDisputes: number;
    isFetching: boolean;
}

interface DispatchProps {
    dispatchRequestUnreadMessagesAndDisputes: Function;
}

interface OwnProps {
    children: React.ReactNode;
}

type Props = StateProps & DispatchProps & AuthenticationContext & OwnProps;

export class MessageController extends Component<Props> {
    unreadMessageAndDisputes = () => {
        const {
            dispatchRequestUnreadMessagesAndDisputes,
            user,
        } = this.props;
        dispatchRequestUnreadMessagesAndDisputes(user?.role !== UserRoles.MECHANIC);
    };

    getMessages = async (fields: KeyedObject, onSuccess: (data: Message[], header: string) => void, onFailure: () => void): Promise<void> => {
        try {
            const { data, headers } = await axios.get(messagesURL(fields));
            onSuccess(data, headers['x-total-count']);
        } catch {
            onFailure();
        }
    };

    getMessage = async (id: string, onSuccess: () => void, onFailure: () => void): Promise<void> => {
        try {
            await axios.get(messageURL(id));
            onSuccess();
        } catch {
            onFailure();
        }
    };

    render() {
        const {
            children,
            unseenDisputes,
            unseenMessages,
            isFetching,
        } = this.props;

        return (
            <MessageContextProvider
                value={{
                    unseenDisputes,
                    unseenMessages,
                    isFetching,
                    unreadMessageAndDisputes: this.unreadMessageAndDisputes,
                    getMessages: this.getMessages,
                    getMessage: this.getMessage,
                }}
            >
                {children}
            </MessageContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        unseenDisputes: state.message.unseenDisputes,
        unseenMessages: state.message.unseenMessages,
        isFetching: state.message.isFetching,
    };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
    dispatchRequestUnreadMessagesAndDisputes: (getDisputes: boolean) => dispatch(requestUnreadMessagesAndDisputes(getDisputes)),
});

export const ConnectedMessageController = withAuthenticationContext(connect(mapStateToProps, mapDispatchToProps)(MessageController));
