
/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

export const fileDamagesUrl = (fileId: number): string => {
    return `${API_URL}/files/${fileId}/damage`;
};

export const fileDamageUrl = (fileId: number, damageId: number): string => {
    return `${API_URL}/files/${fileId}/damage/${damageId}`;
};

export const fileOutlineUrl = (fileId: number): string => {
    return `${API_URL}/files/${fileId}/parts`;
};
