/* eslint-disable @typescript-eslint/ban-types */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';

export interface WithPagingProps {
    pagingReset: boolean;
    currentPage: number;
    onPagingChange(page: number): void;
    onPagingReset: Function;
}

interface State {
    reset: boolean;
    page: number;
}

const withPaging = <P extends object>(
    Component: React.ComponentType<P>,
) => class WithPaging extends React.Component<Omit<P, keyof WithPagingProps>, State> {
        state: State = {
            reset: false,
            page: 1,
        };

        onPagingReset = () => {
            this.setState({
                reset: false,
                page: 1,
            });
        };

        onPagingChange = (start: number) => {
            this.setState({
                reset: false,
                page: start,
            });
        };

        render() {
            const { ...props } = this.props;
            const { reset, page } = this.state;

            return (
                <Component
                    {...props as P}
                    pagingReset={reset}
                    currentPage={page}
                    onPagingReset={this.onPagingReset}
                    onPagingChange={this.onPagingChange}
                />
            );
        }
    };

export default withPaging;
