/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { AuthorizationState } from './types';
import {
    AuthorizationAction,
} from '../actions/authorization_types';

export const authorizationInitialState: AuthorizationState = {
    permissions: [],
    isFetching: false,
    formErrors: null,
};

// eslint-disable-next-line func-names
export default function (state = authorizationInitialState, action: any): AuthorizationState {
    switch (action.type) {
        case AuthorizationAction.PERMISSIONS_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case AuthorizationAction.PERMISSIONS_SUCCESS:
            return {
                ...state,
                permissions: action.payload,
                isFetching: false,
                formErrors: null,
            };
        case AuthorizationAction.PERMISSIONS_FAILURE:
            return {
                ...state,
                isFetching: false,
                formErrors: action.payload,
            };
        case AuthorizationAction.PERMISSIONS_SET:
            return {
                ...state,
                permissions: action.payload,
            };
        case AuthorizationAction.PERMISSIONS_RESET:
            return authorizationInitialState;
        default:
            return state;
    }
}
