/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

export const disputesURL = (params: object = {}) => {
    const queryString = objectToParams(params);
    return `${API_URL}/disputes${queryString}`;
};

export const disputesCountUnseenURL = () => {
    return `${API_URL}/disputes/count-unseen`;
};

export const disputeURL = (id: string) => {
    return `${API_URL}/disputes/${id}`;
};
