/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';
import { RolePermission } from '../../../constants/authorization';

import { AuthorizationController, ConnectedAuthorizationController } from './AuthorizationController';

export interface AuthorizationContext extends Omit<InstanceType<typeof AuthorizationController>, keyof React.Component> {
    permissions: RolePermission[];
}

const AuthorizationContextInstance = createContext<AuthorizationContext | null>(null);

// Export Provider and Consumer

export const AuthorizationContextProvider = AuthorizationContextInstance.Provider;
export const AuthorizationContextConsumer = AuthorizationContextInstance.Consumer;

export const withAuthorizationContext = <P, >(
    Component: ComponentType<P>,
): FC<Omit<P, keyof AuthorizationContext>> => (props) => (
    <ConnectedAuthorizationController>
        <AuthorizationContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </AuthorizationContextConsumer>
    </ConnectedAuthorizationController>
    );
