/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import axios, { AxiosError } from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, ListData, FileDamage } from '../../../constants/types';
import { preInspectionFileComments } from '../../../services/preInspections';
import { AnnotationContextProvider } from './AnnotationContext';
import { fileDamagesUrl, fileDamageUrl } from '../../../services/files';
import { KeyedObject } from '../../../constants/misc';

interface OwnProps {
    children: React.ReactNode;
}

export class AnnotationController extends Component<OwnProps> {
    getAnnotations = async (fileId: number, isEditable: boolean): Promise<ListData<Box>> => {
        if (!isEditable) return { list: [], failed: false };

        try {
            const { data } = await axios.get(preInspectionFileComments(fileId));
            const boxes: Box[] = (data as Box[]).map((box) => ({
                ...box,
                isEditing: false,
            }));
            return { list: boxes, failed: false };
        } catch {
            return { list: [], failed: true };
        }
    };

    getGlassDamages = async (fileId: number): Promise<FileDamage[]> => {
        try {
            const { data } = await axios.get(fileDamagesUrl(fileId));
            return data;
        } catch {
            return [];
        }
    };

    addAnnotation = async (box: Box, fileId: number) => {
        try {
            const { data } = await axios.post(preInspectionFileComments(fileId), {
                ...box,
                comment: box.comment || '_',
            });
            return data;
        } catch {
            return null;
        }
    };

    editAnnotation = async (box: Box, fileId: number) => {
        try {
            const { data } = await axios.put(preInspectionFileComments(fileId, box.id), {
                ...box,
            });
            return data;
        } catch {
            return null;
        }
    };

    deleteAnnotation = async (box: Box, fileId: number) => {
        try {
            const { data } = await axios.delete(preInspectionFileComments(fileId, box.id));
            const boxes: Box[] = (data as Box[]).map((b) => ({
                ...b,
                isEditing: false,
            }));
            return boxes;
        } catch {
            return [];
        }
    };

    deleteGlassAIAnnotation = async (fileId: number, damageId: number): Promise<KeyedObject | null> => {
        try {
            await axios.delete(fileDamageUrl(fileId, damageId));
            return null;
        } catch (e) {
            const error = e as AxiosError;
            return error.response?.data || {};
        }
    };

    render() {
        const {
            children,
        } = this.props;

        return (
            <AnnotationContextProvider
                value={{
                    getAnnotations: this.getAnnotations,
                    getGlassDamages: this.getGlassDamages,
                    deleteAnnotation: this.deleteAnnotation,
                    editAnnotation: this.editAnnotation,
                    addAnnotation: this.addAnnotation,
                    deleteGlassAIAnnotation: this.deleteGlassAIAnnotation,
                }}
            >
                {children}
            </AnnotationContextProvider>
        );
    }
}

export const ConnectedAnnotationController = connect()(AnnotationController);
