/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { PermissionKey, UserRoles } from '../../../constants/authorization';
import { TabItem } from '../../../constants/misc';
import {
    BROKEN_GLASS_CONTROL_LIST_ROUTE, BROKEN_GLASS_CONTROL_ONGOING_LIST_ROUTE, NOT_FOUND_ROUTE, BROKEN_GLASS_CONTROL_DONE_LIST_ROUTE, BROKEN_GLASS_CONTROL_PENDING_LIST_ROUTE, BROKEN_GLASS_CONTROL_CREATE_ROUTE,
} from '../../../constants/routes';
import { BrokenGlassControlTabsEnum, BROKEN_GLASS_CONTROL_TABS_ITEM } from '../../../constants/tabs';
import { AuthenticationContext, withAuthenticationContext } from '../../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import Tabs from '../../elements/Tabs';
import BrokenGlassControlListScreen from './BrokenGlassControlListScreen';
import ControlInSeriesModal from '../../elements/ControlInSeriesModal';
import { ICON } from '../../elements/SvgIcon';
import { WithRouterProps, withRouter } from '../../containers/withRouter';

interface OwnProps extends WithRouterProps, TranslationContext, AuthenticationContext {}

interface OwnState {
    tabItemSelected?: string;
    tabItems: TabItem[];
    showControlInSeriesModal: boolean;
}

const initialState: OwnState = {
    tabItemSelected: BrokenGlassControlTabsEnum.ALL,
    tabItems: [],
    showControlInSeriesModal: false,
};

class BrokenGlassControlScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const {
            user, navigate,
        } = this.props;

        if (user && user.role === UserRoles.MECHANIC) {
            navigate(NOT_FOUND_ROUTE);
            return;
        }

        this.prepare();
    }

    onModalClose = () => {
        this.setState({
            showControlInSeriesModal: false,
        });
    };

    onTabChange = (newTab: string): void => {
        const { navigate } = this.props;

        switch (newTab) {
            case BrokenGlassControlTabsEnum.ONGOING:
                navigate(BROKEN_GLASS_CONTROL_ONGOING_LIST_ROUTE);
                break;
            case BrokenGlassControlTabsEnum.DONE:
                navigate(BROKEN_GLASS_CONTROL_DONE_LIST_ROUTE);
                break;
            case BrokenGlassControlTabsEnum.PENDING:
                navigate(BROKEN_GLASS_CONTROL_PENDING_LIST_ROUTE);
                break;
            case BrokenGlassControlTabsEnum.ALL:
            default:
                navigate(BROKEN_GLASS_CONTROL_LIST_ROUTE);
                break;
        }

        this.setState({ tabItemSelected: newTab });
    };

    prepare = (): void => {
        const { t, location, user } = this.props;
        const { pathname } = location;
        const tabItems: TabItem[] = [];

        let tabItemSelected = '';
        switch (pathname) {
            case BROKEN_GLASS_CONTROL_ONGOING_LIST_ROUTE:
                tabItemSelected = BrokenGlassControlTabsEnum.ONGOING;
                break;
            case BROKEN_GLASS_CONTROL_DONE_LIST_ROUTE:
                tabItemSelected = BrokenGlassControlTabsEnum.DONE;
                break;
            case BROKEN_GLASS_CONTROL_PENDING_LIST_ROUTE:
                tabItemSelected = BrokenGlassControlTabsEnum.PENDING;
                break;
            default:
                tabItemSelected = BrokenGlassControlTabsEnum.ALL;
                break;
        }

        Object.keys(BROKEN_GLASS_CONTROL_TABS_ITEM).forEach((k) => {
            const i = BROKEN_GLASS_CONTROL_TABS_ITEM[Number(k)];
            if (user && (user.role !== UserRoles.GLASS_PROVIDER || i !== BrokenGlassControlTabsEnum.DONE)) {
                const item: TabItem = {
                    value: i,
                    text: t(`brokenGlassControl.list.tabItems.${i}`),
                };

                tabItems.push(item);
            }
        });

        this.setState({
            tabItems,
            tabItemSelected,
        });
    };

    addBrokenGlassControlClick = () => {
        const { navigate } = this.props;
        navigate(BROKEN_GLASS_CONTROL_CREATE_ROUTE);
    };

    addControlInSeriesClick = () => {
        this.setState({
            showControlInSeriesModal: true,
        });
    };

    render(): React.ReactNode {
        const {
            t, user,
        } = this.props;
        const { tabItems, tabItemSelected, showControlInSeriesModal } = this.state;

        const isGlassProviderUser = (user && user.role === UserRoles.GLASS_PROVIDER);

        return (
            <div>
                <Tabs
                    tabsItems={tabItems}
                    onTabChange={this.onTabChange}
                    tabSelected={tabItemSelected}
                    withSearch
                    addButtonText={isGlassProviderUser ? undefined : t('brokenGlassControl.list.addBrokenGlassControlBtn')}
                    addButtonClickHandler={this.addBrokenGlassControlClick}
                    secondAddButtonText={t('brokenGlassControl.list.addBrokenGlassControlInSeriesBtn')}
                    secondButtonIcon={ICON.PLUS}
                    onSecondAddButtonClickHandler={this.addControlInSeriesClick}
                    secondAddButtonRole={[PermissionKey.ALL, PermissionKey.CREATE_BROKEN_GLASS_CONTROLS]}
                />
                <BrokenGlassControlListScreen
                    tabItem={tabItemSelected}
                />
                {showControlInSeriesModal && (
                    <ControlInSeriesModal onModalClose={this.onModalClose} />
                )}
            </div>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(withRouter(BrokenGlassControlScreen)));
