/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedTranslationController, TranslationController } from './TranslationController';

import { Language } from '../../../constants/preferences';

export interface TranslationContext extends Omit<InstanceType<typeof TranslationController>, keyof React.Component> {
    language: Language;
}

const TranslationContextInstance = createContext<TranslationContext | null>(null);

// Export Provider and Consumer

export const TranslationContextProvider = TranslationContextInstance.Provider;
export const TranslationContextConsumer = TranslationContextInstance.Consumer;

export const withTranslationContext = <P, >(
    Component: ComponentType<P>,
): FC<Omit<P, keyof TranslationContext>> => (props) => (
    <ConnectedTranslationController>
        <TranslationContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </TranslationContextConsumer>
    </ConnectedTranslationController>
    );
