/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, Fragment } from 'react';
import { ICON, SvgIcon } from './SvgIcon';
import {
    TranslationContext,
    withTranslationContext,
} from '../controllers/translation/TranslationContext';
import { ApiFile } from '../../constants/types';
import { CasualtyContext, withCasualtyContext } from '../controllers/casualites/CasualtyContext';

interface OwnProps extends TranslationContext, CasualtyContext {
    onModalClose: () => void;
    imgDownloadUrl?: string | null;
    imageFile?: ApiFile;
    imgLabel?: string | null;
}

interface OwnState {
    scale: string;
    imgObj: string | null;
    isFetching: boolean;
}

const initialState: OwnState = {
    scale: '',
    imgObj: null,
    isFetching: false,
};

const modalOutsideId = 'modal-outside';

class ImageShowModal extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { imgDownloadUrl, imageFile, getImageUrl } = this.props;
        document.addEventListener('mousedown', this.handleOutsideClick);

        if (imgDownloadUrl) {
            const { isFetching } = this.state;

            if (isFetching) return;

            this.setState({
                isFetching: true,
            });

            getImageUrl(imgDownloadUrl, this.onImageSuccess, this.onImageFailure);
        } else if (imageFile) {
            this.setState({
                imgObj: imageFile.fileString,
            });
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    onClose = () => {
        const { onModalClose } = this.props;
        onModalClose();
    };

    onClickChangeScale = () => {
        const { scale, imgObj, isFetching } = this.state;
        let newScale = '';

        if (imgObj === null && !isFetching) return;

        if (scale === newScale) {
            newScale = '1';
        }

        this.setState({
            scale: newScale,
        });
    };

    onImageSuccess = (data: Blob) => {
        const blob = new Blob([data], {
            type: 'image/jpeg',
        });

        const objectURL = URL.createObjectURL(blob);
        
        this.setState({
            imgObj: objectURL,
            isFetching: false,
        });
    };

    onImageFailure = () => {
        this.setState({
            isFetching: false,
        });
    };

    handleOutsideClick = (e: any) => {
        const { target } = e;
        const { id } = target;

        if (id === modalOutsideId) {
            this.onClose();
        }
    };

    render() {
        const { imgLabel, t } = this.props;
        const { scale, imgObj, isFetching } = this.state;

        return (
            <Fragment>
                { scale === '' && (
                    <div id={modalOutsideId} className="app-screen__modal" data-testid="image-show-modal">
                        <div className="app-screen__modal__container">
                            <div className="app-screen__modal__container__box">
                                <SvgIcon callback={this.onClose} icon={ICON.CROSS} />
                                <div className="app-screen__modal__container__box__content image-show">
                                    <div className="image-container" onClick={() => { this.onClickChangeScale(); }}>
                                        {imgObj === null && isFetching && (
                                            t('modalImage.loading')
                                        )}
                                        {imgObj === null && !isFetching ? (
                                            t('modalImage.imgNotFound')
                                        ) : (
                                            <img src={imgObj || ''} alt="" />
                                        )}
                                    </div>
                                    {imgLabel && (
                                        <p>{imgLabel}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {scale !== '' && (
                    <div className="app-screen__modal-zoom">
                        <div className="image-container">
                            <SvgIcon callback={this.onClose} icon={ICON.CROSS} />
                            <img className={`image-zoom${scale}`} src={imgObj || ''} alt="" onClick={() => { this.onClickChangeScale(); }} />
                        </div>
                    </div>
                )}
            </Fragment>

        );
    }
}

export default withTranslationContext(withCasualtyContext(ImageShowModal));
