/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export enum MessagesAction {
    MESSAGES_REQUEST = 'MESSAGES_REQUEST',
    MESSAGES_SUCCESS = 'MESSAGES_SUCCESS',
    MESSAGES_UNREAD_SUCCESS = 'MESSAGES_UNREAD_SUCCESS',
    MESSAGES_FAILURE = 'MESSAGES_FAILURE',
    MESSAGES_RESET = 'MESSAGES_RESET',
}

export interface MessagesRequestAction {
    type: typeof MessagesAction.MESSAGES_REQUEST;
}

export interface MessagesSuccessAction {
    type: typeof MessagesAction.MESSAGES_SUCCESS;
    payload: object;
}

export interface MessagesUnreadSuccessAction {
    type: typeof MessagesAction.MESSAGES_UNREAD_SUCCESS;
    payload: object;
}

export interface MessagesFailureAction {
    type: typeof MessagesAction.MESSAGES_FAILURE;
}

export interface MessagesResetAction {
    type: typeof MessagesAction.MESSAGES_RESET;
}

export type MessagesActionTypes = MessagesRequestAction | MessagesSuccessAction | MessagesFailureAction | MessagesResetAction | MessagesUnreadSuccessAction;
