/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { isEqual } from 'lodash';
import { ListOrder, RESULTS_PER_PAGE } from '../../../constants/misc';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import {
    MessageContext,
    withMessageContext,
} from '../../controllers/messages/MessageContext';
import { MessagesTabsEnum } from '../../../constants/tabs';
import TableHeader from '../../elements/TableHeader';
import TableCell, { TableCellActions, TableCellType } from '../../elements/TableCell';
import TablePaging from '../../elements/TablePaging';
import { Message } from '../../../constants/types';
import NewMessageModal from '../../elements/MessageModal';
import withQueryParams, { WithQueryParamsProps } from '../../hocs/withQueryParams';
import { WithRouterProps, withRouter } from '../../containers/withRouter';

interface OwnProps extends WithRouterProps, TranslationContext, MessageContext, WithQueryParamsProps {
    tabItem: string | undefined;
}

interface OwnState {
    isFetching: boolean;
    data: Message[];
    totalResults: number;
    actions: TableCellActions[];
    viewingMessage: boolean;
    messageToView: Message | null;
}

const initialState: OwnState = {
    isFetching: false,
    data: [],
    totalResults: 0,
    actions: [],
    viewingMessage: false,
    messageToView: null,
};

class MessagesListScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { location, onQueryParamsChange } = this.props;
        onQueryParamsChange(location.search);
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>) {
        const {
            listParams, tabItem: oldTab, location, onQueryParamsChange,
        } = this.props;
        const {
            listParams: oldListParams, tabItem, location: oldLocation,
        } = prevProps;

        const hasTabChanged: boolean = tabItem !== oldTab;

        if (location.search !== oldLocation.search) {
            onQueryParamsChange(location.search);
        }

        if (hasTabChanged || !isEqual(listParams, oldListParams)) {
            this.loadList();
        }
    }

    onMessageClick = (msg: Message) => {
        this.setState({ viewingMessage: true, messageToView: msg });
    };

    onGetMessageSuccess = () => {
        const { unreadMessageAndDisputes } = this.props;
        
        this.setState({
            isFetching: false,
        });
        this.loadList();
        unreadMessageAndDisputes();
    };

    onModalClose = (id: string | null) => {
        const { getMessage } = this.props;
        const { isFetching } = this.state;

        if (isFetching) return;

        this.setState({
            isFetching: true,
        });

        if (id) {
            getMessage(id, this.onGetMessageSuccess, this.onFailure);
        }

        this.setState({ viewingMessage: false, messageToView: null });
    };

    onGetMessagesSuccess = (data: Message[], header: string) => {
        const newTotalResults: number = parseInt(header);

        this.setState({
            data,
            totalResults: newTotalResults,
            isFetching: false,
        });
    };

    onFailure = () => {
        this.setState({
            isFetching: false,
        });
    };

    loadList = async () => {
        const {
            tabItem,
            listParams,
            getMessages,
        } = this.props;
        const { isFetching } = this.state;

        if (isFetching) return;

        let sent = false;
        if (tabItem === MessagesTabsEnum.SENT) {
            sent = true;
        }
        this.setState({
            isFetching: true,
        });

        await getMessages({
            _q: listParams?.search || '',
            _page: listParams ? listParams.page - 1 : 0,
            _limit: RESULTS_PER_PAGE,
            _sort: listParams?.sort || 'createdDate',
            _order: listParams?.order || ListOrder.Desc,
            sent,
        }, this.onGetMessagesSuccess, this.onFailure);
    };

    render() {
        const { t, listParams } = this.props;
        const {
            data, totalResults, actions, viewingMessage, messageToView,
        } = this.state;
        const hasData: boolean = data && data.length > 0;

        return (
            <div>
                <div className="app-screen__table">
                    <div className="app-screen__table__content">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <TableHeader
                                            text={t('messages.list.headers.process')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="createdDate"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('messages.list.headers.date')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="createdDate"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('messages.list.headers.from')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="from"
                                            sortable={false}
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('messages.list.headers.to')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="to"
                                            sortable={false}
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('messages.list.headers.subject')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="subject"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text=""
                                            sortable={false}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            { hasData ? (
                                <tbody>
                                    { data.map((message) => {
                                        const {
                                            id, from, to, subject, createdDate, casualtyUID, seen,
                                        } = message;

                                        return (
                                            <tr key={id} className={seen ? '' : 'not-seen'} onClick={() => this.onMessageClick(message)}>
                                                <TableCell
                                                    value={casualtyUID}
                                                />
                                                <TableCell
                                                    type={TableCellType.DATE}
                                                    value={createdDate}
                                                />
                                                <TableCell
                                                    value={from.name}
                                                />
                                                <TableCell
                                                    value={to.name}
                                                />
                                                <TableCell
                                                    value={subject}
                                                />
                                                <TableCell
                                                    type={TableCellType.ACTIONS}
                                                    actions={actions}
                                                    cellClass="actions-cell"
                                                    onEdit={() => {}}
                                                    onDelete={() => {}}
                                                />
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            ) : (
                                <tbody className="no-data">
                                    <tr>
                                        <td colSpan={5}>{t('global.noData')}</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                        {hasData && (
                            <TablePaging
                                currentPage={listParams?.page || 1}
                                limit={RESULTS_PER_PAGE}
                                totalResults={totalResults}
                            />
                        )}
                    </div>
                </div>
                {(viewingMessage && messageToView) && <NewMessageModal onModalClose={(id: string | null) => this.onModalClose(id)} messageToView={messageToView} />}
            </div>
        );
    }
}

export default withMessageContext(withRouter(withTranslationContext(withQueryParams(MessagesListScreen))));
