/* eslint-disable @typescript-eslint/ban-types */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedAuthenticationController, AuthenticationController } from './AuthenticationController';
import { AuthenticationUser } from '../../../constants/authentication';

export interface AuthenticationContext extends Omit<InstanceType<typeof AuthenticationController>, keyof React.Component> {
    isAuthenticated: boolean;
    isFetching: boolean;
    user: AuthenticationUser | null;
    turnCredential: string;
    turnUsername: string;
    token: string | null;
    formErrors: object | null;
}

const authenticationContextInstance = createContext<AuthenticationContext | null>(null);

export const AuthenticationContextProvider = authenticationContextInstance.Provider;
export const AuthenticationContextConsumer = authenticationContextInstance.Consumer;

export const withAuthenticationContext = <P, >(Component: ComponentType<P>): FC<Omit<P, keyof AuthenticationContext>> => (props) => (
    <ConnectedAuthenticationController>
        <AuthenticationContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </AuthenticationContextConsumer>
    </ConnectedAuthenticationController>
);
