/* eslint-disable @typescript-eslint/ban-types */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import axios, { AxiosError } from 'axios';

import {
    AuthenticationActionTypes,
    AuthenticationAction,
} from './authentication_types';
import { LoginFormFields, AuthenticationUser } from '../constants/authentication';
import { authenticationLoginURL, authenticationLogoutURL } from '../services/authentication';
import { permissionsResetActionCreator, permissionsSetActionCreator } from './authorization';
import {
    adminPermissions,
    collaboratorPermissions,
    insuranceUserPermissions,
    mechanicPermissions,
    externalAdminPermissions,
    UserRoles,
    glassProviderPermissions,
    pilotTesterPermissions,
} from '../constants/authorization';
import { resetVideoState } from './video';

export const loginRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGIN_REQUEST,
    };
};

export const loginSuccessActionCreator = (auth: object): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGIN_SUCCESS,
        payload: auth,
    };
};

export const loginFailureActionCreator = (formErrors: object): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGIN_FAILURE,
        payload: formErrors,
    };
};

export const logoutRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGOUT_REQUEST,
    };
};

export const setAuthenticationActionCreator = (auth: object): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.SET_AUTHENTICATION,
        payload: auth,
    };
};

export const resetAuthenticationActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.RESET_AUTHENTICATION,
    };
};

export const turnCredentialsSetActionCreator = (credentials: object): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.SET_TURN_CREDENTIALS,
        payload: credentials,
    };
};

export const tokenSetActionCreator = (token: object): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.SET_TOKEN,
        payload: token,
    };
};

export const userSetActionCreator = (user: object): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.SET_USER,
        payload: user,
    };
};

export const requestLogin = (formData: LoginFormFields, onSuccess: Function) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(loginRequestActionCreator());
        try {
            const { status, data, headers } = await axios.post(authenticationLoginURL(), formData);
            if (status === 200) {
                dispatch(loginSuccessActionCreator({
                    token: headers.authorization,
                    user: data,
                }));

                switch (data.role) {
                    case UserRoles.ADMIN:
                        dispatch(permissionsSetActionCreator(adminPermissions));
                        break;
                    case UserRoles.MECHANIC:
                        dispatch(permissionsSetActionCreator(mechanicPermissions));
                        break;
                    case UserRoles.INSURANCE_USER:
                        dispatch(permissionsSetActionCreator(insuranceUserPermissions));
                        break;
                    case UserRoles.COLLABORATOR:
                        dispatch(permissionsSetActionCreator(collaboratorPermissions));
                        break;
                    case UserRoles.EXTERNAL_ADMIN:
                        dispatch(permissionsSetActionCreator(externalAdminPermissions));
                        break;
                    case UserRoles.GLASS_PROVIDER:
                        dispatch(permissionsSetActionCreator(glassProviderPermissions));
                        break;
                    case UserRoles.PILOT_TESTER:
                        dispatch(permissionsSetActionCreator(pilotTesterPermissions));
                        break;
                    default:
                }

                onSuccess();
            }
        } catch (err) {
            const error = err as AxiosError;
            if (error.response) {
                dispatch(loginFailureActionCreator(error.response.data));
            } else {
                dispatch(loginFailureActionCreator({}));
            }
        }
    };
};

export const requestLogout = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            await axios.get(authenticationLogoutURL());
            dispatch(resetAuthenticationActionCreator());
            dispatch(permissionsResetActionCreator());
            dispatch(resetVideoState());
        } catch { /* empty */ }
    };
};

export const requestUpdateTurnCredentials = (turnUsername: string, turnCredential: string) => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(turnCredentialsSetActionCreator({ turnUsername, turnCredential }));
    };
};

export const requestUpdateToken = (token: string) => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(tokenSetActionCreator({ token }));
    };
};

export const requestUpdateUser = (user: AuthenticationUser) => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(userSetActionCreator({ user }));
    };
};
