/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { TabItem } from '../../../constants/misc';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import Tabs from '../../elements/Tabs';
import { UsersTabsEnum, USERS_TABS_ITEM } from '../../../constants/tabs';
import UsersList from './UsersListScreen';
import NewUserModal from '../../elements/NewUserModal';
import { AuthorizationContext, withAuthorizationContext } from '../../controllers/authorization/AuthorizationContext';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import { UsersContext, withUsersContext } from '../../controllers/users/UsersContext';
import { withRouter, WithRouterProps } from '../../containers/withRouter';
import {
    USERS_ADMIN_LIST_ROUTE,
    USERS_COLLABORATOR_LIST_ROUTE,
    USERS_INSURANCE_LIST_ROUTE,
    USERS_LIST_ROUTE,
    USERS_MECHANIC_LIST_ROUTE,
    USERS_GLASS_PROVIDER_LIST_ROUTE,
} from '../../../constants/routes';

interface OwnProps extends WithRouterProps, TranslationContext, AuthorizationContext, UsersContext {}

interface OwnState {
    tabItemSelected?: string;
    tabItems: TabItem[];
    addUserModalOpen: boolean;
}

const initialState: OwnState = {
    tabItemSelected: UsersTabsEnum.ALL,
    tabItems: [],
    addUserModalOpen: false,
};

class UsersScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        this.prepare();
    }

    onSuccessResendInvites = () => {
        const { t } = this.props;
        displayNotification(NOTIFICATION_TYPE.SUCCESS, t('users.successResendInvitesNotif'));
    };

    onFailureResendInvites = (errorCode: number) => {
        const { t } = this.props;
        if (errorCode === 55) return displayNotification(NOTIFICATION_TYPE.ERROR, t('users.error55ResendInvitesNotif'));
        return displayNotification(NOTIFICATION_TYPE.ERROR, t('users.errorResendInvitesNotif'));
    };

    onTabChange = (newTab: string) => {
        const { navigate } = this.props;
        switch (newTab) {
            case UsersTabsEnum.ALL:
                navigate(USERS_LIST_ROUTE);
                break;
            case UsersTabsEnum.MECHANIC:
                navigate(USERS_MECHANIC_LIST_ROUTE);
                break;
            case UsersTabsEnum.INSURANCE_USER:
                navigate(USERS_INSURANCE_LIST_ROUTE);
                break;
            case UsersTabsEnum.ADMIN:
                navigate(USERS_ADMIN_LIST_ROUTE);
                break;
            case UsersTabsEnum.COLLABORATOR:
                navigate(USERS_COLLABORATOR_LIST_ROUTE);
                break;
            case UsersTabsEnum.GLASS_PROVIDER:
                navigate(USERS_GLASS_PROVIDER_LIST_ROUTE);
                break;
            default:
        }
        this.setState({
            tabItemSelected: newTab,
        });
    };

    onCreateUserSuccess = () => {
        const { t } = this.props;

        this.setState({
            addUserModalOpen: false,
        }, () => {
            displayNotification(NOTIFICATION_TYPE.SUCCESS, t('createUser.successNotif'));
        });
    };

    onModalClose = () => {
        this.setState({ addUserModalOpen: false });
    };

    prepare = () => {
        const { t, location } = this.props;
        const { pathname } = location;
        const tabItems: TabItem[] = [];

        let tabItemSelected = UsersTabsEnum.ALL;
        switch (pathname) {
            case USERS_MECHANIC_LIST_ROUTE:
                tabItemSelected = UsersTabsEnum.MECHANIC;
                break;
            case USERS_INSURANCE_LIST_ROUTE:
                tabItemSelected = UsersTabsEnum.INSURANCE_USER;
                break;
            case USERS_ADMIN_LIST_ROUTE:
                tabItemSelected = UsersTabsEnum.ADMIN;
                break;
            case USERS_COLLABORATOR_LIST_ROUTE:
                tabItemSelected = UsersTabsEnum.COLLABORATOR;
                break;
            case USERS_GLASS_PROVIDER_LIST_ROUTE:
                tabItemSelected = UsersTabsEnum.GLASS_PROVIDER;
                break;
            default:
        }

        Object.keys(USERS_TABS_ITEM).forEach((k) => {
            const i = USERS_TABS_ITEM[Number(k)];

            const item: TabItem = {
                value: i,
                text: t(`users.list.tabItems.${i}`),
            };

            tabItems.push(item);
        });

        this.setState({
            tabItems,
            tabItemSelected,
        });
    };

    addUserClick = () => {
        this.setState({ addUserModalOpen: true });
    };

    resendInvitesButtonClick = () => {
        const { resendInvitesEmails } = this.props;
        resendInvitesEmails(this.onSuccessResendInvites, this.onFailureResendInvites);
    };

    render() {
        const { t } = this.props;
        const { tabItems, tabItemSelected, addUserModalOpen } = this.state;

        return (
            <>
                <Tabs
                    tabsItems={tabItems}
                    onTabChange={this.onTabChange}
                    withSearch
                    addButtonText={t('users.addUserBtn')}
                    addButtonClickHandler={this.addUserClick}
                    tabSelected={tabItemSelected}
                    btnResendInvitesClickHandler={this.resendInvitesButtonClick}
                />
                <UsersList
                    tabItem={tabItemSelected}
                />
                {addUserModalOpen && (<NewUserModal onModalClose={this.onModalClose} onCreateSuccess={this.onCreateUserSuccess} />)}
            </>
        );
    }
}

export default withAuthorizationContext(withTranslationContext(withUsersContext(withRouter(UsersScreen))));
