/* eslint-disable @typescript-eslint/ban-types */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { FunctionComponent } from 'react';

import {
    AuthorizationContext,
    withAuthorizationContext,
} from '../controllers/authorization/AuthorizationContext';
import { checkPermission } from '../../utils/authorization';

interface OwnProps extends AuthorizationContext {
    actions: Array<string>;
    operator?: string;
    yes?: Function;
    no?: Function;
}

type Props = OwnProps;

export const yesDefault = () => null;
export const noDefault = () => null;

const Can: FunctionComponent<Props> = (
    {
        permissions,
        actions,
        operator,
        yes = yesDefault,
        no = noDefault,
    }: Props,
) => {
    return checkPermission(permissions, actions, operator) ? yes() : no();
};

export default withAuthorizationContext(Can);
