/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthorizationContext, withAuthorizationContext } from '../controllers/authorization/AuthorizationContext';
import TopBar from '../elements/TopBar';
import {
    CASUALTIES_CANCELED_LIST_ROUTE,
    CASUALTIES_CREATE_ROUTE,
    CASUALTIES_DETAILS_ROUTE, CASUALTIES_DONE_LIST_ROUTE,
    CASUALTIES_EDIT_ROUTE,
    CASUALTIES_LIST_ROUTE, CASUALTIES_ONGOING_LIST_ROUTE,
    INDEX_ROUTE,
    MESSAGES_RECEIVED_LIST_ROUTE, MESSAGES_SENT_LIST_ROUTE,
    PRE_INSPECTIONS_COMPLETE_LIST_ROUTE,
    PRE_INSPECTIONS_DETAILS_ROUTE,
    PRE_INSPECTIONS_EDIT_ROUTE,
    PRE_INSPECTIONS_GLASS_LIST_ROUTE,
    PRE_INSPECTIONS_LIST_ROUTE,
    PRE_INSPECTIONS_CREATE_ROUTE,
    STATISTICS_ROUTE, USER_INSURANCE_DETAILS_ROUTE,
    USER_INSURANCE_EDIT_ROUTE, USER_MECHANIC_DETAILS_ROUTE,
    USER_MECHANIC_EDIT_ROUTE, USERS_ADMIN_LIST_ROUTE, USERS_COLLABORATOR_LIST_ROUTE,
    USERS_EDIT_ROUTE, USERS_INSURANCE_LIST_ROUTE,
    USERS_LIST_ROUTE, USERS_MECHANIC_LIST_ROUTE, DISPUTES_LIST_ROUTE,
    USERS_GLASS_PROVIDER_LIST_ROUTE,
    USER_GLASS_PROVIDER_EDIT_ROUTE,
    BROKEN_GLASS_CONTROL_LIST_ROUTE,
    BROKEN_GLASS_CONTROL_ONGOING_LIST_ROUTE,
    BROKEN_GLASS_CONTROL_PENDING_LIST_ROUTE,
    BROKEN_GLASS_CONTROL_DONE_LIST_ROUTE,
    BROKEN_GLASS_CONTROL_CREATE_ROUTE,
    BROKEN_GLASS_CONTROL_EDIT_ROUTE,
    BROKEN_GLASS_CONTROL_DETAILS_ROUTE, DAMAGE_TOOL_ROUTE,
} from '../../constants/routes';
import { PermissionKey, UserRoles } from '../../constants/authorization';
import UsersScreen from './users/UsersScreen';
import CasualtiesScreen from './casualties/CasualtiesScreen';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import MechanicUserEditScreen from './users/MechanicUserEditScreen';
import InsuranceUserEditScreen from './users/InsuranceUserEditScreen';
import AdminCollabUserEditScreen from './users/AdminCollabUserEditScreen';
import CasualtiesFormScreen from './casualties/CasualtyFormScreen';
import MessagesScreen from './messages/MessagesScreen';
import PreInspectionsScreen from './preInspections/PreInspectionsScreen';
import StatisticsScreen from './statistics/StatisticsScreen';
import PreInspectionsFormScreen from './preInspections/PreInspectionsFormScreen';
import { VideoContextInterface, withVideoContext } from '../controllers/video/VideoContext';
import { WEB_SOCKET_URL, WEB_URL } from '../../settings';
import { SocketReceivedMessage, SocketReceivedMessageType, SocketSendMessageType } from '../../constants/socket_types';
import Loader from '../elements/Loader';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';
import PreInspectionsCreateFormScreen from './preInspections/PreInspectionsCreateFormScreen';
import DisputesListScreen from './disputes/DisputesListScreen';
import GlassProviderEditScreen from './users/GlassProviderEditScreen';
import BrokenGlassControlScreen from './brokenGlassControl/BrokenGlassControlScreen';
import BrokenGlassControlCreateFormScreen from './brokenGlassControl/BrokenGlassControlCreateFormScreen';
import BrokenGlassControlFormScreen from './brokenGlassControl/BrokenGlassControlFormScreen';
import { withRouter, WithRouterProps } from '../containers/withRouter';
import ProtectedRoute from '../containers/ProtectedRoute';
import DamageToolScreen from './DamageToolScreen';
import VideocallModal from '../elements/VideocallModal';

interface OwnProps extends WithRouterProps, TranslationContext, AuthorizationContext, AuthenticationContext, VideoContextInterface {}

interface OwnState {
    showAnswerCall: boolean;
    socket: WebSocket | null;
    isSocketConnecting: boolean;
    casualtyUid: string | null;
    casualtyId: number | null;
    mechanicName: string | null;
    preInspectionId: number | null;
    preInspectionUid: string | null;
}

class IndexScreen extends Component<OwnProps, OwnState> {
    state = {
        showAnswerCall: false,
        socket: null,
        isSocketConnecting: false,
        casualtyUid: null,
        casualtyId: null,
        mechanicName: null,
        preInspectionId: null,
        preInspectionUid: null,
    };

    candidates: Array<SocketReceivedMessage> = [];

    componentDidMount(): void {
        this.configSocket();

        if (window.addEventListener) {
            window.addEventListener('online', this.onOnlineStatus, true);
            window.addEventListener('offline', this.onOfflineStatus, true);
        }
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>): void {
        const {
            incomingCall: oldIncomingCall,
            messageToSend: oldMessageToSend,
        } = prevProps;
        const {
            incomingCall,
            messageToSend,
            declineCall,
            user,
            location,
            navigate,
        } = this.props;

        if (incomingCall !== oldIncomingCall && incomingCall) {
            this.play();
            this.showIncomingCall();
            return;
        }

        if (messageToSend && (messageToSend !== oldMessageToSend)) {
            this.sendSocketMessage();
            return;
        }

        if (declineCall) {
            this.stop();
            this.declineCall();
        }

        if (location.pathname === INDEX_ROUTE) {
            if (user) {
                switch (user.role) {
                    case UserRoles.ADMIN:
                        navigate(USERS_LIST_ROUTE);
                        break;
                    case UserRoles.PILOT_TESTER:
                        navigate(PRE_INSPECTIONS_COMPLETE_LIST_ROUTE);
                        break;
                    case UserRoles.MECHANIC:
                    case UserRoles.INSURANCE_USER:
                    case UserRoles.COLLABORATOR:
                        navigate(CASUALTIES_LIST_ROUTE);
                        break;
                    case UserRoles.GLASS_PROVIDER:
                        navigate(BROKEN_GLASS_CONTROL_LIST_ROUTE);
                        break;
                    default:
                        navigate(PRE_INSPECTIONS_LIST_ROUTE);
                }
            }
        }
    }

    componentWillUnmount(): void {
        const { resetVideoState } = this.props;
        const { socket } = this.state;

        if (socket) {
            const signal = {
                messageType: SocketSendMessageType.TERMINATE_OFFER,
            };

            // @ts-expect-error updating packages
            socket.send(JSON.stringify(signal));

            // @ts-expect-error updating packages
            socket.close();
        }

        if (window.removeEventListener) {
            window.removeEventListener('online', this.onOnlineStatus, true);
            window.removeEventListener('offline', this.onOfflineStatus, true);
        }

        resetVideoState();
    }

    onOnlineStatus = () => {
        this.configSocket();
    };

    onOfflineStatus = () => {
        const { socket } = this.state;

        if (socket) {
            // @ts-expect-error updating packages
            socket.close();
        }
    };

    onVideoCallModalClose = () => {
        this.setState({
            showAnswerCall: false,
        });
        this.declineCall();
        this.stop();
    };

    configSocket = () => {
        if (!('WebSocket' in window)) {
            return;
        }
        this.setState({ isSocketConnecting: true });

        const { token, user } = this.props;
        const socket = new WebSocket(`${String(WEB_SOCKET_URL)}/video/${token}`);
        const socketImportFile = new WebSocket(`${String(WEB_SOCKET_URL)}/${token}`);

        let messageType = SocketSendMessageType.GET_ALL_ONLINE_USERS;

        socket.onmessage = this.handleSocketMessage;
        socketImportFile.onmessage = this.handleSocketMessage;

        if (user && user.role && (user.role === UserRoles.MECHANIC || user.role === UserRoles.INSURANCE_USER)) {
            messageType = SocketSendMessageType.GET_ONLINE_USERS;
        }

        socket.onopen = () => {
            const signal = {
                messageType,
            };
            socket.send(JSON.stringify(signal));

            this.setState({
                socket,
            });
        };

        this.setState({
            isSocketConnecting: false,
        });
    };

    handleSocketMessage = (ev: MessageEvent) => {
        const {
            t,
            logout,
            handleSocketMessage,
            updateTurnCredentials,
        } = this.props;
        const { data } = ev;
        const msg: SocketReceivedMessage = JSON.parse(data);

        if (msg.messageType === SocketReceivedMessageType.ICE_CANDIDATE_FOUND) {
            this.candidates.push(msg);
        }

        if (!msg.messageType) {
            if (msg.successful) {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('messages.importFile.successWebSocket').replace('{file}', msg.filename), false);
            } else {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('messages.importFile.errorWebSocket').replace('{file}', msg.filename), false);
            }
        } else {
            switch (msg.messageType) {
                case SocketReceivedMessageType.TURN_CREDENTIALS:
                    updateTurnCredentials(msg.username, msg.credential);
                    break;
                case SocketReceivedMessageType.USER_OFFLINE:
                case SocketReceivedMessageType.USER_BUSY:
                    displayNotification(NOTIFICATION_TYPE.ERROR, t('errors.couldNotAnswerCall'));
                    break;
                case SocketReceivedMessageType.UNAUTHORIZED:
                case SocketReceivedMessageType.INVALID_SESSION:
                case SocketReceivedMessageType.INVALID_TOKEN:
                    logout();
                    break;
                case SocketReceivedMessageType.INCOMING_CALL_CANCELED:
                    this.setState({ showAnswerCall: false });
                    this.stop();
                    break;
                default:
            }
        }

        handleSocketMessage(msg);
    };

    sendSocketMessage = () => {
        const { messageToSend } = this.props;
        const { socket } = this.state;

        if (socket && messageToSend) {
            // @ts-expect-error updating packages
            socket.send(messageToSend);
        }
    };

    answerCall = () => {
        const {
            notifyAnswerCall, navigate, user, updatePendingCandidates,
        } = this.props;
        const { casualtyId, preInspectionId } = this.state;

        const isAdmin = user && (user.role === UserRoles.ADMIN || user.role === UserRoles.EXTERNAL_ADMIN);
        this.stop();

        if (casualtyId) {
            const path = isAdmin ? CASUALTIES_EDIT_ROUTE : CASUALTIES_DETAILS_ROUTE;

            notifyAnswerCall();
            updatePendingCandidates(true, this.candidates);
            navigate(`${path}/${casualtyId}`);

            this.setState({
                showAnswerCall: false,
            });

            this.candidates = [];
            return;
        }

        if (preInspectionId) {
            notifyAnswerCall();
            const path = isAdmin ? PRE_INSPECTIONS_EDIT_ROUTE : PRE_INSPECTIONS_DETAILS_ROUTE;
            updatePendingCandidates(true, this.candidates);
            navigate(`${path}/${preInspectionId}`);

            this.setState({
                showAnswerCall: false,
            });

            this.candidates = [];
        }
    };

    declineCall = () => {
        const { socket } = this.state;

        if (socket) {
            const signal = {
                messageType: SocketSendMessageType.DECLINE_OFFER,
            };

            // @ts-expect-error updating packages
            socket.send(JSON.stringify(signal));
        }
    };

    showIncomingCall = () => {
        const { incomingCallMessage, user, location } = this.props;
        const { pathname } = location;

        if (incomingCallMessage) {
            const { messageMap } = incomingCallMessage;

            const isAdminOrCollab = user && (user.role === UserRoles.ADMIN || user.role === UserRoles.EXTERNAL_ADMIN || user.role === UserRoles.COLLABORATOR);

            const isCasualtyRouteOpen = pathname.startsWith(CASUALTIES_DETAILS_ROUTE) || pathname.startsWith(CASUALTIES_EDIT_ROUTE);
            const isPreInspectionRouteOpen = pathname.startsWith(PRE_INSPECTIONS_EDIT_ROUTE) || pathname.startsWith(PRE_INSPECTIONS_DETAILS_ROUTE);
            const split = pathname.split('/');
            const id = split[split.length - 1];
            if ((!isCasualtyRouteOpen && messageMap.casualtyId) || (isCasualtyRouteOpen && messageMap.casualtyId && Number(id) !== messageMap.casualtyId)) {
                this.setState({
                    showAnswerCall: true,
                    casualtyId: messageMap.casualtyId,
                    casualtyUid: messageMap.casualtyUid,
                    mechanicName: messageMap.mechanicName && isAdminOrCollab ? messageMap.mechanicName : null,
                });
                return;
            }
            if ((!isPreInspectionRouteOpen && messageMap.preInspectionId) || (isPreInspectionRouteOpen && messageMap.preInspectionId && Number(id) !== messageMap.preInspectionId)) {
                this.setState({
                    showAnswerCall: true,
                    preInspectionId: messageMap.preInspectionId,
                    preInspectionUid: messageMap.preInspectionUid,
                });
            }
        }
    };

    getComponent = () => {
        const { user } = this.props;

        if (user) {
            switch (user.role) {
                case UserRoles.ADMIN:
                    return <UsersScreen />;
                case UserRoles.EXTERNAL_ADMIN:
                case UserRoles.PILOT_TESTER:
                    return <PreInspectionsScreen />;
                case UserRoles.GLASS_PROVIDER:
                    return <BrokenGlassControlScreen />;
                case UserRoles.MECHANIC:
                case UserRoles.INSURANCE_USER:
                    return <CasualtiesScreen />;
                default:
                    return <CasualtiesScreen />;
            }
        }
    };

    play = () => {
        const audioControlElement = document.getElementById('audio-control');
        if (audioControlElement) {
            (audioControlElement as HTMLAudioElement).muted = false;
            const playPromisse = (audioControlElement as HTMLAudioElement).play();

            if (playPromisse) {
                playPromisse.catch(() => {
                    (audioControlElement as HTMLAudioElement).play();
                });
            }
        }
    };

    stop = () => {
        const audioControlElement = document.getElementById('audio-control');

        if (audioControlElement) {
            (audioControlElement as HTMLAudioElement).pause();
            (audioControlElement as HTMLAudioElement).muted = true;
            (audioControlElement as HTMLAudioElement).currentTime = 0;
        }
    };

    render() {
        const {
            showAnswerCall,
            isSocketConnecting,
            mechanicName,
            casualtyUid,
            preInspectionUid,
        } = this.state;

        return (
            <div className="index-screen">
                {isSocketConnecting && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <TopBar showTabs />
                <Routes>
                    <Route path={INDEX_ROUTE} element={this.getComponent()} />
                    <Route
                        path={USERS_LIST_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_USERS]}>
                                <UsersScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={USERS_ADMIN_LIST_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_USERS]}>
                                <UsersScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={USERS_COLLABORATOR_LIST_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_USERS]}>
                                <UsersScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={USERS_INSURANCE_LIST_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_USERS]}>
                                <UsersScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={USERS_MECHANIC_LIST_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_USERS]}>
                                <UsersScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={USERS_GLASS_PROVIDER_LIST_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_USERS]}>
                                <UsersScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={MESSAGES_RECEIVED_LIST_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_MESSAGES]}>
                                <MessagesScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={MESSAGES_SENT_LIST_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_MESSAGES]}>
                                <MessagesScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={CASUALTIES_LIST_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_CASUALTIES]}>
                                <CasualtiesScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={CASUALTIES_DONE_LIST_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_CASUALTIES]}>
                                <CasualtiesScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={CASUALTIES_CANCELED_LIST_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_CASUALTIES]}>
                                <CasualtiesScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={CASUALTIES_ONGOING_LIST_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_CASUALTIES]}>
                                <CasualtiesScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={CASUALTIES_CREATE_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.CREATE_CASUALTIES]}>
                                <CasualtiesFormScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={`${CASUALTIES_EDIT_ROUTE}/:id`}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.EDIT_CASUALTIES]}>
                                <CasualtiesFormScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={`${CASUALTIES_DETAILS_ROUTE}/:id`}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_CASUALTIES_DETAILS]}>
                                <CasualtiesFormScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={`${USER_MECHANIC_EDIT_ROUTE}/:id`}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.EDIT_USER]}>
                                <MechanicUserEditScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={`${USER_MECHANIC_DETAILS_ROUTE}/:id`}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_MECHANIC_DETAILS]}>
                                <MechanicUserEditScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={`${USER_INSURANCE_EDIT_ROUTE}/:id`}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.EDIT_USER]}>
                                <InsuranceUserEditScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={`${USER_INSURANCE_DETAILS_ROUTE}/:id`}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_INSURANCE_DETAILS]}>
                                <InsuranceUserEditScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={`${USER_GLASS_PROVIDER_EDIT_ROUTE}/:id`}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.EDIT_USER]}>
                                <GlassProviderEditScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={`${USERS_EDIT_ROUTE}/:role/:id`}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.EDIT_USER]}>
                                <AdminCollabUserEditScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={PRE_INSPECTIONS_LIST_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_PRE_INSPECTIONS]}>
                                <PreInspectionsScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={PRE_INSPECTIONS_GLASS_LIST_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_PRE_INSPECTIONS]}>
                                <PreInspectionsScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={PRE_INSPECTIONS_COMPLETE_LIST_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_PRE_INSPECTIONS]}>
                                <PreInspectionsScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={`${PRE_INSPECTIONS_EDIT_ROUTE}/:id`}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.EDIT_PRE_INSPECTIONS]}>
                                <PreInspectionsFormScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={`${PRE_INSPECTIONS_CREATE_ROUTE}/:glass`}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.CREATE_PRE_INSPECTIONS]}>
                                <PreInspectionsCreateFormScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={`${PRE_INSPECTIONS_DETAILS_ROUTE}/:id`}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_PRE_INSPECTIONS_DETAILS]}>
                                <PreInspectionsFormScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={STATISTICS_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_STATISTICS]}>
                                <StatisticsScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={DISPUTES_LIST_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_DISPUTES]}>
                                <DisputesListScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={BROKEN_GLASS_CONTROL_LIST_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_BROKEN_GLASS_CONTROLS]}>
                                <BrokenGlassControlScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={BROKEN_GLASS_CONTROL_ONGOING_LIST_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_BROKEN_GLASS_CONTROLS]}>
                                <BrokenGlassControlScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={BROKEN_GLASS_CONTROL_DONE_LIST_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_BROKEN_GLASS_CONTROLS]}>
                                <BrokenGlassControlScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={BROKEN_GLASS_CONTROL_PENDING_LIST_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_BROKEN_GLASS_CONTROLS]}>
                                <BrokenGlassControlScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={BROKEN_GLASS_CONTROL_CREATE_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.CREATE_BROKEN_GLASS_CONTROLS]}>
                                <BrokenGlassControlCreateFormScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={`${BROKEN_GLASS_CONTROL_EDIT_ROUTE}/:id`}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.EDIT_BROKEN_GLASS_CONTROLS]}>
                                <BrokenGlassControlFormScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={`${BROKEN_GLASS_CONTROL_DETAILS_ROUTE}/:id`}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL, PermissionKey.VIEW_BROKEN_GLASS_CONTROLS_DETAILS]}>
                                <BrokenGlassControlFormScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path={DAMAGE_TOOL_ROUTE}
                        element={(
                            <ProtectedRoute actions={[PermissionKey.ALL]}>
                                <DamageToolScreen />
                            </ProtectedRoute>
                        )}
                    />
                    <Route
                        path="*"
                        element={<div>not found</div>}
                    />
                </Routes>
                {showAnswerCall && (
                    <VideocallModal
                        onModalClose={this.onVideoCallModalClose}
                        onAnswerCall={this.answerCall}
                        mechanicName={mechanicName ? String(mechanicName) : ''}
                        casualtyUid={casualtyUid ? String(casualtyUid) : ''}
                        preInspectionUid={preInspectionUid ? String(preInspectionUid) : ''}
                    />
                )}

                <audio id="audio-control" loop muted>
                    <source src={`${WEB_URL}/audio/lovingly.mp3`} type="audio/mp3" />
                    <source src={`${WEB_URL}/audio/lovingly.ogg`} type="audio/ogg" />
                </audio>
            </div>
        );
    }
}

export default withTranslationContext(withVideoContext(withAuthenticationContext(withAuthorizationContext(withRouter(IndexScreen)))));
