/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent, useState } from 'react';
import { FileResponse } from '../../constants/types';
import iconCarCrash from '../../assets/images/icon-car_crash.svg';
import iconCrackedGlass from '../../assets/images/icon-cracked_glass.svg';
import ButtonDeprecated from './ButtonDeprecated';
import { ICON } from './SvgIcon';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';

interface OwnProps extends TranslationContext {
    image: FileResponse;
    preInspectionThumbnailURL: Record<number, string>;
    onImageClick: () => void;
}

const ImageCard: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        image,
        preInspectionThumbnailURL,
        onImageClick,
    } = props;

    const { id, originalFileName } = image;

    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const canExpandSection = (image.glassDamage || image.bodyworkDamage) && image.damageInfo.length > 2;

    const toggleDescription = () => {
        setIsExpanded(!isExpanded);
    };

    const formatValue = (value: number) => {
        return Number.isInteger(value) ? value : value.toFixed(2);
    };

    return (
        <div className="card">
            <div className="img-container">
                <img
                    src={preInspectionThumbnailURL[id]}
                    alt={originalFileName}
                    title={originalFileName}
                    onClick={onImageClick}
                    className="image"
                />
            </div>
            <div className={`content${isExpanded ? ' content--expanded' : ''}`}>
                <div className="info-card">
                    <h2 className="title">{originalFileName}</h2>
                    <div className="icons">
                        {image.bodyworkDamage && <img src={iconCarCrash} alt={t('preInspections.icons.bodyworkDamage')} title={t('preInspections.form.labels.bodyworkDamage')} />}
                        {image.glassDamage && <img src={iconCrackedGlass} alt={t('preInspections.icons.glassDamage')} title={t('preInspections.form.labels.glassDamage')} />}
                    </div>
                </div>
                <div className={`description${isExpanded ? ' description--expanded' : ''}`}>
                    {!image.damageInfo.length && (
                        <h3 className="no-damage">{t('preInspections.form.labels.noDamageData')}</h3>
                    )}
                    {image.damageInfo.map((damage) => (
                        <div className="details" key={damage.carPartType}>
                            <span aria-label={damage.carPartType}>{t(`carPartType.${damage.carPartType}`)}</span>
                            <span>{damage.damageArea ? <>{`${formatValue(damage.damageArea)}cm`}<sup>2</sup></> : t('preInspections.form.labels.noDamageValue')}</span>
                            <span>{damage.damagePercentage ? `${formatValue(damage.damagePercentage)}%` : t('preInspections.form.labels.noDamageValue')}</span>
                        </div>
                    ))}
                </div>
                {canExpandSection && (
                    <ButtonDeprecated
                        icon={isExpanded ? ICON.CHEVRON_UP : ICON.CHEVRON_DOWN}
                        iconPosition="center"
                        callback={toggleDescription}
                        styles="toggle-button"
                    />
                )}
            </div>
        </div>
    );
};

export default withTranslationContext(ImageCard);
