/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { ComponentType } from 'react';
import {
    useLocation,
    useNavigate,
    useParams,
    Location,
    NavigateFunction,
    Params,
} from 'react-router-dom';

export interface WithRouterProps {
    location: Location;
    navigate: NavigateFunction;
    params: Params;
}

export function withRouter<P extends object>(Component: ComponentType<P>) {
    function ComponentWithRouterProp(props: Omit<P, keyof WithRouterProps>) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();

        return (
            <Component
                {...props as P}
                location={location}
                navigate={navigate}
                params={params}
            />
        );
    }

    return ComponentWithRouterProp;
}
