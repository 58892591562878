/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { isEqual } from 'lodash';
import {
    ListOrder, RESULTS_PER_PAGE,
} from '../../../constants/misc';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import TableCell, { TableCellActions, TableCellType } from '../../elements/TableCell';
import { CasualtiesTabsEnum } from '../../../constants/tabs';
import Loader from '../../elements/Loader';
import TableHeader from '../../elements/TableHeader';
import TablePaging from '../../elements/TablePaging';
import FilterModal from '../../elements/FilterModal';
import {
    CASUALTIES_DETAILS_ROUTE,
    CASUALTIES_EDIT_ROUTE,
} from '../../../constants/routes';
import { AuthorizationContext, withAuthorizationContext } from '../../controllers/authorization/AuthorizationContext';
import { checkPermission } from '../../../utils/authorization';
import { PermissionKey, UserRoles } from '../../../constants/authorization';
import withQueryParams, { WithQueryParamsProps } from '../../hocs/withQueryParams';
import { WithRouterProps, withRouter } from '../../containers/withRouter';
import { CasualtyContext, withCasualtyContext } from '../../controllers/casualites/CasualtyContext';

interface OwnProps extends WithRouterProps, AuthorizationContext, TranslationContext, CasualtyContext, WithQueryParamsProps {
    tabItem: string;
    role: string;
}

interface OwnState {
    isFetching: boolean;
    data: object[];
    totalResults: number;
    actions: TableCellActions[];
    isInsurance: boolean;
    showFilterModal: boolean;
}

const initialState: OwnState = {
    data: [],
    isFetching: false,
    totalResults: 0,
    actions: [],
    isInsurance: false,
    showFilterModal: false,
};

class CasualtiesListScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { role, location, onQueryParamsChange } = this.props;
        this.setState({
            isInsurance: role === UserRoles.INSURANCE_USER,
        }, () => onQueryParamsChange(location.search));
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>) {
        const {
            listParams, tabItem: oldTab, onQueryParamsChange, location,
        } = this.props;
        const {
            listParams: oldListParams, location: oldLocation, tabItem,
        } = prevProps;

        const hasTabChanged: boolean = tabItem !== oldTab;

        if (location.search !== oldLocation.search) {
            onQueryParamsChange(location.search);
        }

        if (hasTabChanged || !isEqual(listParams, oldListParams)) {
            this.loadList();
        }
    }

    onCasualtyClick = (id: number) => {
        const { navigate, permissions } = this.props;

        if (checkPermission(permissions, [PermissionKey.ALL, PermissionKey.EDIT_CASUALTIES])) {
            navigate(`${CASUALTIES_EDIT_ROUTE}/${id}`);
        } else if (checkPermission(permissions, [PermissionKey.VIEW_CASUALTIES_DETAILS])) {
            navigate(`${CASUALTIES_DETAILS_ROUTE}/${id}`);
        }
    };

    onFilterClick = () => {
        this.setState({
            showFilterModal: true,
        });
    };

    onFilterSubmit = (startDate: number, endDate: number) => {
        this.loadList(startDate, endDate);
    };

    loadList = async (startDate?: number, endDate?: number) => {
        const {
            tabItem,
            listParams,
            getCasualties,
        } = this.props;
        const { isFetching, isInsurance } = this.state;

        if (isFetching) return;

        try {
            let status = '';
            if (tabItem !== CasualtiesTabsEnum.ALL) {
                status = tabItem;
            }

            if (isInsurance) status = 'DONE';

            const queryParams = {
                _q: listParams?.search || '',
                _page: listParams ? listParams.page - 1 : 0,
                _limit: RESULTS_PER_PAGE,
                _sort: listParams?.sort || 'createdDate',
                _order: listParams?.order || ListOrder.Desc,
                status,
                startDate: startDate || null,
                endDate: endDate || null,
            };

            const result = await getCasualties(queryParams);

            this.setState({
                data: result.data,
                totalResults: result.total,
                isFetching: false,
                isInsurance: false,
            });
        } catch (error) {
            this.setState({
                isFetching: false,
            });
        }
    };

    render() {
        const {
            t, listParams,
        } = this.props;
        const {
            data, totalResults, actions, isFetching, showFilterModal,
        } = this.state;
        const hasData: boolean = data && data.length > 0;

        return (
            <div>
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="app-screen__table">
                    <div className="app-screen__table__content bigger">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <TableHeader
                                            text={t('casualties.list.headers.state')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="status"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('casualties.list.headers.process')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="casualtyId"
                                            sortable={false}
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('casualties.list.headers.insuranceProcessNumber')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="insuranceProcessNumber"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('casualties.list.headers.insurance')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="insuranceCompany.name"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('casualties.list.headers.date')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="createdDate"
                                            sortable
                                            filterable
                                            onFilterClick={this.onFilterClick}
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('casualties.list.headers.conclusionDate')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="doneDate"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('casualties.list.headers.licensePlate')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="vehicleLicensePlate"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('casualties.list.headers.brand')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="vehicleBrand"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('casualties.list.headers.mechanic')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="mechanic"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('casualties.list.headers.district')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="mechanic.district"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text=""
                                            sortable={false}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            { hasData ? (
                                <tbody>
                                    { data.map((casualty: any) => {
                                        const {
                                            id,
                                            uid,
                                            insuranceCompany,
                                            createdDate,
                                            vehicleLicensePlate,
                                            vehicleBrand,
                                            mechanic,
                                            insuranceProcessNumber,
                                            status,
                                            doneDate,
                                        } = casualty;

                                        let insuranceCompanyName = '';
                                        let mechanicName = '';
                                        let mechanicDistrict = '';

                                        if (insuranceCompany) {
                                            insuranceCompanyName = insuranceCompany.name;
                                        }

                                        if (mechanic) {
                                            mechanicName = mechanic.name;
                                            mechanicDistrict = mechanic.district;
                                        }

                                        return (
                                            <tr key={id} onClick={() => this.onCasualtyClick(Number(id))}>
                                                <TableCell
                                                    type={TableCellType.PRE_INSPECTION_STATUS}
                                                    value={status}
                                                    title={t(`enums.preInspectionStatus.${status}`)}
                                                />
                                                <TableCell
                                                    value={uid}
                                                />
                                                <TableCell
                                                    value={insuranceProcessNumber || '--'}
                                                />
                                                <TableCell
                                                    value={insuranceCompanyName}
                                                />
                                                <TableCell
                                                    type={TableCellType.DATE}
                                                    value={createdDate}
                                                />
                                                {doneDate ? (
                                                    <TableCell
                                                        type={TableCellType.DATE}
                                                        value={doneDate}
                                                    />
                                                ) : (
                                                    <TableCell
                                                        value="--"
                                                    />
                                                )}
                                                <TableCell
                                                    value={vehicleLicensePlate}
                                                />
                                                <TableCell
                                                    value={vehicleBrand}
                                                />
                                                <TableCell
                                                    value={mechanicName}
                                                />
                                                <TableCell
                                                    value={mechanicDistrict}
                                                />
                                                <TableCell
                                                    type={TableCellType.ACTIONS}
                                                    actions={actions}
                                                    value={createdDate}
                                                    onEdit={() => {}}
                                                    onDelete={() => {}}
                                                    onMessage={() => {}}
                                                />
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            ) : (
                                <tbody className="no-data">
                                    <tr>
                                        <td colSpan={10}>{t('global.noData')}</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                        {hasData && (
                            <TablePaging
                                currentPage={listParams?.page || 1}
                                limit={RESULTS_PER_PAGE}
                                totalResults={totalResults}
                            />
                        )}
                    </div>
                </div>
                {showFilterModal && (
                    <FilterModal onModalClose={() => this.setState({ showFilterModal: false })} onSubmit={this.onFilterSubmit} />
                )}
            </div>
        );
    }
}

export default withAuthorizationContext(withRouter(withTranslationContext(withCasualtyContext(withQueryParams(CasualtiesListScreen)))));
