/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

export const sendArtificialIntelligenceURL = () => {
    return `${API_URL}/ai/send`;
};
