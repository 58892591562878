/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import axios, { AxiosError } from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import {
    casualtiesURL, casualtyBudgetURL, casualtyBudgetUserURL, casualtyDocumentsURL, casualtyDocumentURL, casualtyInformativeNoteURL,
    casualtyURL,
} from '../../../services/casualties';
import { CasualtyContextProvider } from './CasualtyContext';
import { CasualtyRequest, CasualtyResponse, EuroTaxBudget } from '../../../constants/types';
import { KeyedObject } from '../../../constants/misc';
import { messagesURL } from '../../../services/messages';
import { getFilenameFromContentDisposition } from '../../../utils/misc';

interface OwnProps {
    children: React.ReactNode;
}

export class CasualtyController extends React.Component<OwnProps> {
    createCasualtyBudget = async (id: string, onSuccess: (data: EuroTaxBudget) => void, onFailure: () => void): Promise<void> => {
        try {
            const { data } = await axios.post(casualtyBudgetURL(id));
            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    getCasualtyBudget = async (id: string, onSuccess: (data: EuroTaxBudget) => void, onFailure: () => void): Promise<void> => {
        try {
            const { data } = await axios.get(casualtyBudgetURL(id));
            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    createCasualtyBudgetUser = async (id: string, budgetId: string, fields: object, onSuccess: () => void, onFailure: () => void): Promise<void> => {
        try {
            await axios.post(casualtyBudgetUserURL(id, budgetId), fields);
            onSuccess();
        } catch {
            onFailure();
        }
    };

    getCasualties = async (queryParams: KeyedObject) => {
        const { data, headers } = await axios.get(casualtiesURL(queryParams));
        return { data, total: parseInt(headers['x-total-count']) };
    };

    getImageUrl = async (imageURL: string, onSuccess: (image: Blob) => void, onFailure: () => void): Promise<void> => {
        try {
            const { data } = await axios.get(imageURL, { responseType: 'arraybuffer' });
            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    sendInformativeNote = async (id: number, fields: KeyedObject, onSuccess: () => void, onFailure: (error: AxiosError) => void) => {
        try {
            await axios.post(casualtyInformativeNoteURL(id), fields);
            onSuccess();
        } catch (error) {
            onFailure(error as AxiosError);
        }
    };

    sendMessage = async (fields: KeyedObject, onSuccess: () => void, onFailure: (error: AxiosError) => void) => {
        try {
            await axios.post(messagesURL(), fields);
            onSuccess();
        } catch (error) {
            onFailure(error as AxiosError);
        }
    };

    generatePDF = async (url: string, onSuccess: (file: File, filename: string, mimeType: string) => void, onFailure: () => void) => {
        try {
            const { data, headers } = await axios.post(url, null, { responseType: 'blob' });
            const fileName: string = getFilenameFromContentDisposition(headers['content-disposition']);
            const mimeType = headers['content-type'];
            onSuccess(data, fileName, mimeType);
        } catch {
            onFailure();
        }
    };

    getCasualtyDocuments = async (id: string, params: KeyedObject) => {
        const { data, headers } = await axios.get(casualtyDocumentsURL(String(id), params));
        const total: number = parseInt(headers['x-total-count']);

        return {
            data,
            total,
        };
    };

    uploadCasualtyDocument = async (id: string, file: FormData, onSuccess: (data: number) => void, onFailure: () => void) => {
        try {
            const { data } = await axios.post(casualtyDocumentsURL(id), file, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            });
            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    getCasualtyDocument = async (id: string, documentId: string) => {
        try {
            await axios.get(casualtyDocumentURL(id, documentId));
        } catch { /* empty */ }
    };

    editDocumentFilename = async (id: string, idDocument: string, newFileName: string, onSuccess: (data: KeyedObject) => void, onFailure: () => void) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            const { data } = await axios.put(casualtyDocumentURL(id, idDocument), newFileName, config);
            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    downloadDocument = async (id: string, fileId: string, onSuccess: (data: Blob) => void, onFailure: () => void) => {
        try {
            const { data } = await axios.get(casualtyDocumentURL(id, fileId), {
                responseType: 'blob',
                headers: {
                    Accept: 'application/octet-stream',
                },
            });
            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    deleteDocument = async (id: string, idDocument: string, onSuccess: () => void, onFailure: () => void) => {
        try {
            await axios.delete(casualtyDocumentURL(String(id), String(idDocument)));
            onSuccess();
        } catch {
            onFailure();
        }
    };

    requestHelper = async (url: string, onSuccess: (data: KeyedObject) => void, onFailure: () => void) => {
        try {
            const { data } = await axios.post(url);
            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    getCasualty = async (id: string, onSuccess: (data: CasualtyResponse) => void, onFailure: () => void) => {
        try {
            const { data } = await axios.get(casualtyURL(id));
            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    createCasualty = async (fields: CasualtyRequest, onSuccess: (data: KeyedObject) => void, onFailure: (error: AxiosError) => void) => {
        try {
            const { data } = await axios.post(casualtiesURL(), fields);
            onSuccess(data);
        } catch (error) {
            onFailure(error as AxiosError);
        }
    };

    editCasualty = async (id: string, fields: CasualtyRequest, onSuccess: () => void, onFailure: (error: AxiosError) => void) => {
        try {
            await axios.put(casualtyURL(id), fields);
            onSuccess();
        } catch (error) {
            onFailure(error as AxiosError);
        }
    };

    render(): React.ReactNode {
        const {
            children,
        } = this.props;
        return (
            <CasualtyContextProvider
                value={{
                    createCasualtyBudget: this.createCasualtyBudget,
                    getCasualtyBudget: this.getCasualtyBudget,
                    createCasualtyBudgetUser: this.createCasualtyBudgetUser,
                    getCasualties: this.getCasualties,
                    getImageUrl: this.getImageUrl,
                    sendInformativeNote: this.sendInformativeNote,
                    sendMessage: this.sendMessage,
                    generatePDF: this.generatePDF,
                    editDocumentFilename: this.editDocumentFilename,
                    deleteDocument: this.deleteDocument,
                    getCasualtyDocuments: this.getCasualtyDocuments,
                    getCasualtyDocument: this.getCasualtyDocument,
                    downloadDocument: this.downloadDocument,
                    requestHelper: this.requestHelper,
                    getCasualty: this.getCasualty,
                    createCasualty: this.createCasualty,
                    editCasualty: this.editCasualty,
                    uploadCasualtyDocument: this.uploadCasualtyDocument,
                }}
            >
                {children}
            </CasualtyContextProvider>
        );
    }
}

export const ConnectedCasualtyController = connect()(CasualtyController);
