/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { debounce, get } from 'lodash';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { DEBOUNCED_REQUEST_DELAY_MS, SelectOption } from '../../constants/misc';
import FormSelectField from './FormSelectField';
import FormTextField from './FormTextField';
import FormDatePicker from './FormDatePicker';
import ButtonDeprecated from './ButtonDeprecated';
import {
    CasualtyRequest, Mechanic, CasualtyStatus, User, UserRoleParam,
} from '../../constants/types';
import { EXPERTISE_COMPENSATION_TYPE } from '../../constants/expertise';
import Loader from './Loader';
import { PermissionKey, UserRoles } from '../../constants/authorization';
import { AuthorizationContext, withAuthorizationContext } from '../controllers/authorization/AuthorizationContext';
import { checkPermission } from '../../utils/authorization';
import { USER_MECHANIC_DETAILS_ROUTE, USER_MECHANIC_EDIT_ROUTE } from '../../constants/routes';
import { ICON } from './SvgIcon';
import { UsersContext, withUsersContext } from '../controllers/users/UsersContext';
import FormSearchableSelectField from './FormSearchableSelectField';
import { CasualtyContext, withCasualtyContext } from '../controllers/casualites/CasualtyContext';
import { casualtyDocumentResumeURL } from '../../services/casualties';

interface OwnProps extends TranslationContext, AuthorizationContext, UsersContext, CasualtyContext {
    casualty: CasualtyRequest | null;
    onCasualtyChange: Function;
    formErrors: any;
    readOnly: boolean;
    role: string;
}

interface OwnState {
    preparing: boolean;
    insuranceCompaniesOptions: Array<SelectOption>;
    casualtyObj: CasualtyRequest;
    compensationTypeOptions: Array<SelectOption>;
    situationTypeOptions: Array<SelectOption>;
    mechanicOptions: Array<SelectOption>;
    mechanics: Array<Mechanic>;
    isFetching: boolean;
    insurances: Array<User>;
    mechanicSearchValue: string;
    insuranceCompanySearchValue: string;
}

const initialState: OwnState = {
    preparing: false,
    mechanicSearchValue: '',
    insuranceCompanySearchValue: '',
    insuranceCompaniesOptions: [],
    casualtyObj: {
        id: null,
        casualtyDate: '',
        uid: '',
        compensationType: '',
        deductibleValue: null,
        expertiseDate: '',
        expertiseNumber: '',
        expertiseObservations: '',
        expertiseSituation: null,
        insuranceCompanyId: null,
        insurancePolicyNumber: '',
        insuranceCompanyName: null,
        insuredValue: null,
        mechanicName: null,
        mechanicId: null,
        vehicleBrand: '',
        vehicleInfo: null,
        vehicleLicensePlate: '',
        vehicleModel: '',
        vehicleVersion: '',
        vehicleOwner: '',
        vehicleOwnerContact: null,
        insuranceProcessNumber: '',
        status: '',
        creatorId: null,
        vin: null,
        apiKey: '',
    },
    compensationTypeOptions: [],
    situationTypeOptions: [],
    mechanicOptions: [],
    mechanics: [],
    insurances: [],
    isFetching: false,
};

class CasualtyRequestTab extends Component<OwnProps, OwnState> {
    state = {
        ...initialState,
    };

    private readonly casualtiesNameLabel: string = 'casualties.form.labels.name';

    private readonly debouncedRequestInsuranceCompanies: Function;

    private readonly debouncedRequestMechanics: Function;

    private readonly debouncedOnChange: Function;

    constructor(props: OwnProps) {
        super(props);

        this.debouncedRequestInsuranceCompanies = debounce(this.requestInsuranceOptions, DEBOUNCED_REQUEST_DELAY_MS);
        this.debouncedRequestMechanics = debounce(this.requestMechanicsOptions, DEBOUNCED_REQUEST_DELAY_MS);
        this.debouncedOnChange = debounce(this.triggerChange, DEBOUNCED_REQUEST_DELAY_MS);
    }

    componentDidMount(): void {
        const { casualty } = this.props;

        if (casualty) {
            this.setState({
                casualtyObj: {
                    ...casualty,
                    apiKey: casualty.insuranceCompany ? casualty.insuranceCompany.apiKey : '',
                },
            }, this.prepare);
        }
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>): void {
        const { casualtyObj } = this.state;
        const { casualty } = this.props;
        if (casualty && prevProps.casualty?.status !== casualty.status) {
            casualtyObj.status = casualty.status;
            this.setState({
                casualtyObj,
            });
        }
    }

    onGeneratePdfResumeSuccess = (data: File, fileName: string, mimeType: string) => {
        this.setState({
            isFetching: false,
        });
        const blob = new Blob([data], { type: mimeType });
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
    };

    onGeneratePdfResumeFailure = () => {
        this.setState({
            isFetching: false,
        });
    };

    onGeneratePdfClick = async () => {
        const { generatePDF } = this.props;
        const { casualtyObj, isFetching, preparing } = this.state;
        if (preparing || isFetching) return;

        this.setState({ isFetching: true });

        await generatePDF(casualtyDocumentResumeURL(String(casualtyObj.id)), this.onGeneratePdfResumeSuccess, this.onGeneratePdfResumeFailure);
    };

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const {
            casualtyObj,
        } = this.state;

        this.setState({
            casualtyObj: {
                ...casualtyObj,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        }, () => this.debouncedOnChange());
    };

    onMechanicSelectChange = (optValue: string): void => {
        const {
            casualtyObj, mechanics,
        } = this.state;

        const mechanic = mechanics.find((k) => k.id === Number(optValue));

        const mechanicName = mechanic?.name || null;

        this.setState({
            casualtyObj: {
                ...casualtyObj,
                mechanicName,
                mechanicId: mechanic?.id || null,
            },
        }, () => this.debouncedOnChange());
    };

    onInsuranceSelectChange = (optValue: string): void => {
        const {
            casualtyObj, insurances,
        } = this.state;

        const insuranceCompany = insurances.find((i) => i.id === Number(optValue));
        const insuranceName: string | null = insuranceCompany?.name || null;

        if (insuranceCompany) {
            this.setState({
                casualtyObj: {
                    ...casualtyObj,
                    insuranceCompanyName: insuranceName,
                    insuranceCompanyId: insuranceCompany.id,
                    apiKey: insuranceCompany.apiKey || '',
                },
            }, () => this.debouncedOnChange());
        }
    };

    onDateChange = (name: string, date: any) => {
        const { casualtyObj } = this.state;

        this.setState({
            casualtyObj: {
                ...casualtyObj,
                [name]: date,
            },
        }, () => this.debouncedOnChange());
    };

    onSelectSearchInputChange = (name: string, newValue: string): void => {
        if (name === 'insuranceCompanySearchValue') {
            this.debouncedRequestInsuranceCompanies(newValue);
        }

        if (name === 'mechanicSearchValue') {
            this.debouncedRequestMechanics(newValue);
        }

        this.setState((prevState) => ({
            ...prevState,
            [name]: newValue,
        }));
    };

    requestInsuranceOptions = async (searchValue: string): Promise<void> => {
        const { getUsersOptions } = this.props;

        const { options, users } = await getUsersOptions({
            userRole: UserRoleParam.InsuranceUser, _limit: 10, _q: searchValue, active: true,
        });

        this.setState({
            insuranceCompaniesOptions: options,
            insurances: users,
        });
    };

    requestMechanicsOptions = async (searchValue: string): Promise<void> => {
        const { mechanicOptionsRequest } = this.props;
        const { casualtyObj } = this.state;

        const data = await mechanicOptionsRequest({
            userRole: UserRoleParam.Mechanic,
            _limit: 10,
            _q: searchValue || casualtyObj.mechanicName,
            active: true,
        });
        
        const mechanicOptions: Array<SelectOption> = [];

        if (data) {
            mechanicOptions.push({
                value: '',
                label: '',
            });

            Object.values(data).forEach((mechanic: Mechanic) => {
                mechanicOptions.push({
                    value: mechanic.id,
                    label: mechanic.name,
                });
            });
        }

        this.setState({
            mechanics: data,
            mechanicOptions,
        });
    };

    triggerChange = () => {
        const { onCasualtyChange } = this.props;
        const {
            casualtyObj,
        } = this.state;

        onCasualtyChange({
            ...casualtyObj,
        });
    };

    prepare = async () => {
        const { t, readOnly } = this.props;
        const { preparing, isFetching } = this.state;

        if (preparing || isFetching || readOnly) return;

        this.requestInsuranceOptions('');
        this.requestMechanicsOptions('');

        const compensationOptions: Array<SelectOption> = [];

        compensationOptions.push({
            value: '',
            label: '',
        });

        Object.values(EXPERTISE_COMPENSATION_TYPE).forEach((type) => {
            compensationOptions.push({
                value: type,
                label: t(`enums.expertiseCompensationType.${type}`),
            });
        });

        this.setState({
            preparing: false,
            compensationTypeOptions: [...compensationOptions],
        });
    };

    render() {
        const {
            t, formErrors, readOnly, permissions, role, casualty,
        } = this.props;
        const {
            preparing,
            isFetching,
            insuranceCompaniesOptions,
            casualtyObj,
            compensationTypeOptions,
            situationTypeOptions,
            mechanicOptions,
            insuranceCompanySearchValue,
            mechanicSearchValue,
        } = this.state;

        let mechanicToRoute: any = null;
        if (casualtyObj.mechanicId && checkPermission(permissions, [PermissionKey.ALL, PermissionKey.EDIT_USER])) {
            mechanicToRoute = `${USER_MECHANIC_EDIT_ROUTE}/${casualtyObj.mechanicId}`;
        } else if (casualtyObj.mechanicId && checkPermission(permissions, [PermissionKey.VIEW_MECHANIC_DETAILS])) {
            mechanicToRoute = `${USER_MECHANIC_DETAILS_ROUTE}/${casualtyObj.mechanicId}`;
        }

        let isEdit = false;
        if (casualty && casualty.id) isEdit = true;

        return (
            <div>
                {(preparing || isFetching) && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <h3>{t('casualties.form.subtitles.client')}</h3>
                <div className="row">
                    <div className="col-sm-4">
                        {readOnly || isEdit ? (
                            <FormTextField
                                label={t(this.casualtiesNameLabel)}
                                name="insuranceCompanyId"
                                value={casualtyObj.insuranceCompanyName}
                                onChange={() => {}}
                                disabled
                            />
                        ) : (
                            <FormSearchableSelectField
                                name="insuranceCompanyId"
                                inputName="insuranceCompanySearchValue"
                                value={casualtyObj.insuranceCompanyId || ''}
                                searchValue={insuranceCompanySearchValue}
                                onChange={(_, optValue): void => this.onInsuranceSelectChange(optValue)}
                                onSearchInputChange={this.onSelectSearchInputChange}
                                options={insuranceCompaniesOptions}
                                label={t(this.casualtiesNameLabel)}
                                errors={get(formErrors, 'fields.insuranceCompanyId', null)}
                            />
                        )}
                    </div>
                    <div className="col-sm-4">
                        <FormTextField
                            label={t('casualties.form.labels.apiKey')}
                            name="apiKey"
                            value={casualtyObj.apiKey}
                            disabled
                        />
                    </div>
                    <div className="col-sm-4">
                        <FormTextField
                            label={t('casualties.form.labels.policyNumber')}
                            name="insurancePolicyNumber"
                            value={casualtyObj.insurancePolicyNumber}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.insurancePolicyNumber', null)}
                            disabled={readOnly}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <FormTextField
                            label={t('casualties.form.labels.insuranceProcessNumber')}
                            name="insuranceProcessNumber"
                            value={casualtyObj.insuranceProcessNumber}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.insuranceProcessNumber', null)}
                            disabled={readOnly}
                        />
                    </div>
                </div>
                {/* VEHICLE */}
                <h3>{t('casualties.form.subtitles.vehicle')}</h3>
                <div className="row">
                    <div className="col-sm-3" style={{ paddingLeft: 0 }}>
                        <FormTextField
                            label={t('casualties.form.labels.vehicleLicensePlate')}
                            name="vehicleLicensePlate"
                            value={casualtyObj.vehicleLicensePlate}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.vehicleLicensePlate', null)}
                            disabled={readOnly}
                        />
                    </div>
                    <div className="col-sm-3">
                        <FormTextField
                            label={t('casualties.form.labels.vehicleBrand')}
                            name="vehicleBrand"
                            value={casualtyObj.vehicleBrand}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.vehicleBrand', null)}
                            disabled={readOnly}
                        />
                    </div>
                    <div className="col-sm-3">
                        <FormTextField
                            label={t('casualties.form.labels.vehicleModel')}
                            name="vehicleModel"
                            value={casualtyObj.vehicleModel}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.vehicleModel', null)}
                            disabled={readOnly}
                        />
                    </div>
                    <div className="col-sm-3" style={{ paddingRight: 0 }}>
                        <FormTextField
                            label={t('casualties.form.labels.vehicleVersion')}
                            name="vehicleVersion"
                            value={casualtyObj.vehicleVersion}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.vehicleVersion', null)}
                            disabled={readOnly}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-8">
                        <FormTextField
                            label={t('casualties.form.labels.vehicleOwner')}
                            name="vehicleOwner"
                            value={casualtyObj.vehicleOwner}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.vehicleOwner', null)}
                            disabled={readOnly}
                        />
                    </div>
                    <div className="col-sm-4">
                        <FormTextField
                            label={t('casualties.form.labels.contact')}
                            name="vehicleOwnerContact"
                            value={casualtyObj.vehicleOwnerContact}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.vehicleOwnerContact', null)}
                            disabled={readOnly}
                        />
                    </div>
                </div>
                {/* CASUALTY */}
                <h3>{t('casualties.form.subtitles.casualty')}</h3>
                <div className="row">
                    <div className="col-sm-4">
                        <FormTextField
                            label={t('casualties.form.labels.number')}
                            name="expertiseNumber"
                            value={casualtyObj.expertiseNumber}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.expertiseNumber', null)}
                            disabled={readOnly}
                        />
                    </div>
                    <div className="col-sm-4">
                        <FormDatePicker
                            name="expertiseDate"
                            label={t('casualties.form.labels.expertiseDate')}
                            value={casualtyObj.expertiseDate ? new Date(casualtyObj.expertiseDate) : null}
                            onChange={this.onDateChange}
                            errors={get(formErrors, 'fields.expertiseDate', null)}
                            disabled={readOnly}
                        />
                    </div>
                    <div className="col-sm-4">
                        <FormDatePicker
                            name="casualtyDate"
                            label={t('casualties.form.labels.casualtyDate')}
                            value={casualtyObj.casualtyDate ? new Date(casualtyObj.casualtyDate) : null}
                            onChange={this.onDateChange}
                            errors={get(formErrors, 'fields.casualtyDate', null)}
                            disabled={readOnly}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <FormTextField
                            label={t('casualties.form.labels.insuranceCapital')}
                            name="insuredValue"
                            value={casualtyObj.insuredValue}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.insuredValue', null)}
                            disabled={readOnly}
                        />
                    </div>
                    <div className="col-sm-4">
                        <FormTextField
                            label={t('casualties.form.labels.deductibleValue')}
                            name="deductibleValue"
                            value={casualtyObj.deductibleValue}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.deductibleValue', null)}
                            disabled={readOnly}
                        />
                    </div>
                    <div className="col-sm-4">
                        {readOnly ? (
                            <FormTextField
                                label={t('casualties.form.labels.compensationType')}
                                name="compensationType"
                                value={casualtyObj.compensationType
                                    ? t(`enums.expertiseCompensationType.${casualtyObj.compensationType}`)
                                    : t('casualties.form.labels.notDefined')}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.compensationType', null)}
                                disabled
                            />
                        ) : (
                            <FormSelectField
                                options={compensationTypeOptions}
                                name="compensationType"
                                value={casualtyObj.compensationType}
                                onChange={this.onInputChange}
                                label={t('casualties.form.labels.compensationType')}
                                errors={get(formErrors, 'fields.compensationType', null)}
                            />
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        {readOnly ? (
                            <FormTextField
                                label={t('casualties.form.labels.situation')}
                                name="insurancePolicyNumber"
                                value={casualtyObj.expertiseSituation
                                    ? t(`enums.expertiseSituationType.${casualtyObj.expertiseSituation}`)
                                    : t('casualties.form.labels.notDefined')}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.insurancePolicyNumber', null)}
                                disabled
                            />
                        ) : (
                            <FormSelectField
                                options={situationTypeOptions}
                                name="expertiseSituation"
                                value={casualtyObj.expertiseSituation}
                                onChange={this.onInputChange}
                                label={t('casualties.form.labels.situation')}
                                errors={get(formErrors, 'fields.expertiseSituation', null)}
                            />
                        )}
                    </div>
                    <div className="col-sm-8">
                        <FormTextField
                            label={t('casualties.form.labels.observations')}
                            name="expertiseObservations"
                            value={casualtyObj.expertiseObservations}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.expertiseObservations', null)}
                            disabled={readOnly}
                        />
                    </div>
                </div>
                {/* MECHANIC */}
                <h3>{t('casualties.form.subtitles.mechanic')}</h3>
                <div className="row">
                    <div className="col-sm-4">
                        {readOnly ? (
                            <FormTextField
                                label={t(this.casualtiesNameLabel)}
                                name="mechanicName"
                                value={casualtyObj.mechanicName}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.mechanicId', null)}
                                disabled
                            />
                        ) : (
                            <FormSearchableSelectField
                                name="mechanicId"
                                inputName="mechanicSearchValue"
                                value={casualtyObj.mechanicId || ''}
                                searchValue={mechanicSearchValue}
                                onChange={(_, optValue): void => this.onMechanicSelectChange(optValue)}
                                onSearchInputChange={this.onSelectSearchInputChange}
                                options={mechanicOptions}
                                label={t(this.casualtiesNameLabel)}
                                errors={get(formErrors, 'fields.mechanicId', null)}
                                menuPlacement="top"
                            />
                        )}
                    </div>
                    {mechanicToRoute && (
                        <div className="col-sm-4">
                            <div className="link-container">
                                <Link to={mechanicToRoute}>
                                    <p>{t('casualties.form.mechanicDetails')}</p>
                                </Link>
                            </div>
                        </div>
                    )}
                    {role !== UserRoles.MECHANIC && casualtyObj.status !== CasualtyStatus.CANCELED && (
                        <div className="col-sm-4">
                            <div className="savePdf-container">
                                <ButtonDeprecated
                                    text={t('global.buttons.request')}
                                    type="button"
                                    styles="btn--savePdf btn--icon-center"
                                    icon={ICON.PDF}
                                    callback={this.onGeneratePdfClick}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withTranslationContext(withAuthorizationContext(withUsersContext(withCasualtyContext(CasualtyRequestTab))));
