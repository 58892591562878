/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import axios from 'axios';
import { AuthenticationContextProvider } from './AuthenticationContext';

import { AppState } from '../../../reducers/types';
import { AuthenticationUser, LoginFormFields, ResetPasswordFields } from '../../../constants/authentication';
import {
    requestLogin, requestLogout, requestUpdateTurnCredentials, requestUpdateToken, requestUpdateUser,
} from '../../../actions/authentication';
import { recoverPasswordURL, resetPasswordURL } from '../../../services/authentication';
import { casualtyBudgetDetailsURL } from '../../../services/casualties';

interface StateProps {
    isAuthenticated: boolean;
    isFetching: boolean;
    token: string | null;
    user: AuthenticationUser | null;
    formErrors: object | null;
    turnCredential: string;
    turnUsername: string;
}

interface DispatchProps {
    dispatchRequestLogin: Function;
    dispatchRequestLogout: Function;
    dispatchRequestUpdateTurnCredentials: Function;
    dispatchRequestUpdateToken: Function;
    dispatchRequestUpdateUser: Function;
}

interface OwnProps {
    children: React.ReactNode;
}

type Props = StateProps & DispatchProps & OwnProps;

export class AuthenticationController extends Component<Props> {
    login = (fields: LoginFormFields, callback: Function) => {
        const {
            dispatchRequestLogin,
        } = this.props;
        dispatchRequestLogin(fields, callback);
    };

    logout = () => {
        const { dispatchRequestLogout } = this.props;
        dispatchRequestLogout();
    };

    updateTurnCredentials = (username: string, credential: string) => {
        const { dispatchRequestUpdateTurnCredentials } = this.props;
        dispatchRequestUpdateTurnCredentials(username, credential);
    };

    updateToken = (token: string) => {
        const { dispatchRequestUpdateToken } = this.props;
        dispatchRequestUpdateToken(token);
    };

    updateUser = (user: AuthenticationUser) => {
        const { dispatchRequestUpdateUser } = this.props;
        dispatchRequestUpdateUser(user);
    };

    recoverPasswordRequest = async (email: string, onSuccess: () => void, onFailure: () => void) => {
        try {
            await axios.post(recoverPasswordURL(), { email });
            onSuccess();
        } catch {
            onFailure();
        }
    };

    resetPasswordRequest = async (payload: ResetPasswordFields, onSuccess: () => void, onFailure: () => void) => {
        try {
            await axios.post(resetPasswordURL(), payload);
            onSuccess();
        } catch {
            onFailure();
        }
    };

    createBudgetRequest = async (casualtyId: string, budgetId: string) => {
        await axios.post(casualtyBudgetDetailsURL(casualtyId, budgetId));
    };

    render() {
        const {
            children,
            isAuthenticated,
            isFetching,
            token,
            user,
            turnCredential,
            turnUsername,
            formErrors,
        } = this.props;

        return (
            <AuthenticationContextProvider
                value={{
                    isAuthenticated,
                    isFetching,
                    login: this.login,
                    logout: this.logout,
                    token,
                    updateToken: this.updateToken,
                    updateUser: this.updateUser,
                    user,
                    formErrors,
                    turnCredential,
                    turnUsername,
                    updateTurnCredentials: this.updateTurnCredentials,
                    recoverPasswordRequest: this.recoverPasswordRequest,
                    resetPasswordRequest: this.resetPasswordRequest,
                    createBudgetRequest: this.createBudgetRequest,
                }}
            >
                {children}
            </AuthenticationContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        isAuthenticated: state.authentication.isAuthenticated,
        isFetching: state.authentication.isFetching,
        token: state.authentication.token,
        user: state.authentication.user,
        formErrors: state.authentication.formErrors,
        turnCredential: state.authentication.turnCredential,
        turnUsername: state.authentication.turnUsername,
    };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
    dispatchRequestLogin: (formData: LoginFormFields, onSuccess: Function) => dispatch(requestLogin(formData, onSuccess)),
    dispatchRequestLogout: () => dispatch(requestLogout()),
    dispatchRequestUpdateTurnCredentials: (username: string, credential: string) => dispatch(requestUpdateTurnCredentials(username, credential)),
    dispatchRequestUpdateToken: (token: string) => dispatch(requestUpdateToken(token)),
    dispatchRequestUpdateUser: (user: AuthenticationUser) => dispatch(requestUpdateUser(user)),
});

export const ConnectedAuthenticationController = connect(mapStateToProps, mapDispatchToProps)(AuthenticationController);
