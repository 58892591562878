/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ButtonDeprecated from './ButtonDeprecated';
import { SelectOption } from '../../constants/misc';

interface OwnProps {
    title?: string;
    message?: string;
    onOptionSelected: (opt: SelectOption) => void;
    options: SelectOption[];
}

const displaySelectOptionsDialog = (props: OwnProps) => {
    const {
        title,
        message,
        options,
        onOptionSelected,
    } = props;

    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="confirm">
                    <div className="confirm__inner options-select">
                        {title && (
                            <h6>{title}</h6>
                        )}
                        {message && (
                            <p>{message}</p>
                        )}
                        <div className="confirm__inner__buttons full-width">
                            {options.map((opt) => {
                                return (
                                    <ButtonDeprecated
                                        key={opt.value}
                                        type="button"
                                        text={opt.label}
                                        styles="btn--purple btn--full-width"
                                        callback={() => {
                                            onClose();
                                            onOptionSelected(opt);
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            );
        },
    });
};

export default displaySelectOptionsDialog;
