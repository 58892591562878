/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export enum Language {
    EN = 'en',
    PT = 'pt',
}

export const languageNameToEnum = (language: string): Language => {
    if (language.toLowerCase() === 'en') return Language.EN;
    return Language.PT;
};

export const languageEnumToName = (language: Language): string => {
    if (language === Language.EN) return 'en';
    return 'pt';
};

export interface Preferences {
    language: Language;
}
