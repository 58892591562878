/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { debounce, get } from 'lodash';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import logo from '../../assets/images/logo_horizontal.svg';
import FormTextField from './FormTextField';
import FormNumberField from './FormNumberField';
import ButtonDeprecated from './ButtonDeprecated';
import {
    ControlInSeries, User, UserRoleParam,
} from '../../constants/types';
import {
    AuthenticationContext,
    withAuthenticationContext,
} from '../controllers/authentication/AuthenticationContext';
import { DEBOUNCED_REQUEST_DELAY_MS, SelectOption } from '../../constants/misc';
import { getFormErrors, IFormError, VALIDATIONS } from '../../utils/validation';
import FormSearchableSelectField from './FormSearchableSelectField';
import DocumentUpload from './DocumentUpload';
import { UsersContext, withUsersContext } from '../controllers/users/UsersContext';
import { ICON } from './SvgIcon';
import {
    BrokenGlassControlContext,
    withBrokenGlassControlContext,
} from '../controllers/brokenGlass/BrokenGlassControlContext';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';
import { ProviderTypes } from '../../constants/provider';

interface NumberToProcess {
    [ProviderTypes.CAETANOGLASS]: string;
    [ProviderTypes.EXPRESSGLASS]: string;
    [ProviderTypes.CARGLASS]: string;
    [ProviderTypes.NEWCAR]: string;
    [ProviderTypes.GLASSDRIVE]: string;
}

interface OwnProps extends TranslationContext, AuthenticationContext, UsersContext, BrokenGlassControlContext {
    onModalClose: Function;
}

interface OwnState {
    formErrors: any;
    isFetching: boolean;
    insuranceCompanyId: number | null;
    insurances: Array<User>;
    insuranceCompaniesOptions: Array<SelectOption>;
    insuranceSearchValue: string;
    fileName: string;
    file: File[];
    numberToProcess: NumberToProcess;
    maxNumberField: string;
}

const initialState: OwnState = {
    formErrors: {},
    isFetching: false,
    insuranceCompanyId: null,
    insurances: [],
    insuranceCompaniesOptions: [],
    insuranceSearchValue: '',
    fileName: '',
    file: [],
    numberToProcess: {
        [ProviderTypes.CAETANOGLASS]: '0',
        [ProviderTypes.EXPRESSGLASS]: '0',
        [ProviderTypes.CARGLASS]: '0',
        [ProviderTypes.NEWCAR]: '0',
        [ProviderTypes.GLASSDRIVE]: '0',
    },
    maxNumberField: '2147483647',
};

const modalOutsideId = 'modal-outside';

class ControlInSeriesModal extends Component<OwnProps, OwnState> {
    state = initialState;

    private readonly debouncedRequestInsuranceCompanies: Function;

    constructor(props: OwnProps) {
        super(props);

        this.debouncedRequestInsuranceCompanies = debounce(this.requestInsuranceCompanies, DEBOUNCED_REQUEST_DELAY_MS);
    }

    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleOutsideClick);
        this.setState({ isFetching: true });
        this.prepare();
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    onSubmit = () => {
        const { t } = this.props;

        if (this.validateFields() && this.validateIfAllNumberFieldsAreEmpty()) {
            this.setState({ isFetching: true });
            this.uploadFile();
        } else if (!this.validateIfAllNumberFieldsAreEmpty()) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('messages.importFile.errorNumberFieldsNotif'));
        }
    };

    onClose = () => {
        const { onModalClose } = this.props;
        this.setState({ ...initialState });
        onModalClose();
    };

    onSearchInputChange = (name: string, newValue: string): void => {
        this.debouncedRequestInsuranceCompanies(newValue);

        this.setState((prevState) => ({
            ...prevState,
            [name]: newValue,
        }));
    };

    onFileSelected = (file: File[]) => {
        if (file) this.setState({ fileName: file[0].name, file });
    };

    onInsuranceCompanySelect = (name: string, optValue: string): void => {
        const { insurances } = this.state;

        const insuranceCompany = insurances.find((k) => k.id === Number(optValue));

        this.setState({
            insuranceCompanyId: insuranceCompany ? insuranceCompany.id : null,
        });
    };

    onSuccess = () => {
        const { t } = this.props;

        displayNotification(NOTIFICATION_TYPE.SUCCESS, t('messages.importFile.successNotif'));

        this.setState({
            insuranceCompanyId: null,
            fileName: '',
            file: [],
            isFetching: false,
        });

        this.onClose();
    };

    onFailure = (errorCode: number) => {
        const { t } = this.props;

        if (errorCode === 56) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('messages.importFile.error56Notif'));
        } else {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('messages.importFile.errorNotif'));
        }

        this.setState({ isFetching: false });
    };

    onChangeNumberField = (e: React.FormEvent<HTMLInputElement>) => {
        const { name, value } = e.currentTarget;
        const { maxNumberField } = this.state;
        const treatedValue = parseInt(value.replace('', '0'), 10) > parseInt(maxNumberField) ? maxNumberField : String(parseInt(value.replace('', '0'), 10)).replace('-', '');

        switch (name) {
            case ProviderTypes.CAETANOGLASS:
                this.setState((prevState) => ({
                    numberToProcess: {
                        ...prevState.numberToProcess,
                        [ProviderTypes.CAETANOGLASS]: treatedValue,
                    },
                }));
                break;
            case ProviderTypes.EXPRESSGLASS:
                this.setState((prevState) => ({
                    numberToProcess: {
                        ...prevState.numberToProcess,
                        [ProviderTypes.EXPRESSGLASS]: treatedValue,
                    },
                }));
                break;
            case ProviderTypes.CARGLASS:
                this.setState((prevState) => ({
                    numberToProcess: {
                        ...prevState.numberToProcess,
                        [ProviderTypes.CARGLASS]: treatedValue,
                    },
                }));
                break;
            case ProviderTypes.NEWCAR:
                this.setState((prevState) => ({
                    numberToProcess: {
                        ...prevState.numberToProcess,
                        [ProviderTypes.NEWCAR]: treatedValue,
                    },
                }));
                break;
            case ProviderTypes.GLASSDRIVE:
                this.setState((prevState) => ({
                    numberToProcess: {
                        ...prevState.numberToProcess,
                        [ProviderTypes.GLASSDRIVE]: treatedValue,
                    },
                }));
                break;
            default:
                break;
        }
    };

    validateFields = () => {
        const { fileName, insuranceCompanyId } = this.state;
        let errors: IFormError | null = getFormErrors({ fileName, insuranceCompanyId }, VALIDATIONS.IMPORT_FILE_FORM);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    handleOutsideClick = (e: any) => {
        const { target } = e;
        const { id } = target;

        if (id === modalOutsideId) {
            this.onClose();
        }
    };

    requestInsuranceCompanies = async (insuranceSearch: string): Promise<void> => {
        const { getUsersOptions } = this.props;

        try {
            const { options, users } = await getUsersOptions({ userRole: UserRoleParam.InsuranceUser, _limit: 10, _q: insuranceSearch });

            this.setState({
                insurances: users,
                insuranceCompaniesOptions: options,
            });
        } catch {
            this.setState({
                insurances: [],
                insuranceCompaniesOptions: [],
            });
        }
    };

    prepare = async () => {
        await this.requestInsuranceCompanies('');

        this.setState({ isFetching: false });
    };

    uploadFile = async () => {
        const { importFile } = this.props;
        const { file, insuranceCompanyId } = this.state;
        const entriesToProcessByGlassProvider = this.getEntriesToProcessByProvider();

        const formData = new FormData();
        formData.append('file', file[0]);
        formData.append('fileName', file[0].name);
        formData.append('insuranceCompanyId', String(insuranceCompanyId));
        formData.append('entriesToProcessByGlassProvider', new Blob([JSON.stringify(entriesToProcessByGlassProvider)], {
            type: 'application/json',
        }));

        await importFile(formData, this.onSuccess, this.onFailure);
    };

    getEntriesToProcessByProvider = (): ControlInSeries[] => {
        const {
            numberToProcess,
        } = this.state;
        const entriesNumberToProcessArray: ControlInSeries[] = [];

        Object.values(ProviderTypes).forEach((providerType: string) => {
            switch (providerType) {
                case ProviderTypes.GLASSDRIVE:
                    entriesNumberToProcessArray.push({
                        providerType,
                        entriesNumberToProcess: parseInt(numberToProcess[ProviderTypes.GLASSDRIVE]),
                    });
                    break;
                case ProviderTypes.EXPRESSGLASS:
                    entriesNumberToProcessArray.push({
                        providerType,
                        entriesNumberToProcess: parseInt(numberToProcess[ProviderTypes.EXPRESSGLASS]),
                    });
                    break;
                case ProviderTypes.CARGLASS:
                    entriesNumberToProcessArray.push({
                        providerType,
                        entriesNumberToProcess: parseInt(numberToProcess[ProviderTypes.CARGLASS]),
                    });
                    break;
                case ProviderTypes.NEWCAR:
                    entriesNumberToProcessArray.push({
                        providerType,
                        entriesNumberToProcess: parseInt(numberToProcess[ProviderTypes.NEWCAR]),
                    });
                    break;
                case ProviderTypes.CAETANOGLASS:
                    entriesNumberToProcessArray.push({
                        providerType,
                        entriesNumberToProcess: parseInt(numberToProcess[ProviderTypes.CAETANOGLASS]),
                    });
                    break;
                default:
            }
        });

        return entriesNumberToProcessArray;
    };

    validateIfAllNumberFieldsAreEmpty = () => {
        const { numberToProcess } = this.state;
        let validate = false;

        Object.values(numberToProcess).forEach((eachNumberToProcess) => {
            if (eachNumberToProcess !== '0') validate = true;
        });

        return validate;
    };

    render() {
        const { t } = this.props;
        const {
            formErrors,
            insuranceCompanyId,
            insuranceCompaniesOptions,
            insuranceSearchValue,
            fileName,
            isFetching,
            numberToProcess,
            maxNumberField,
        } = this.state;

        return (
            <div>
                <div id={modalOutsideId} className="app-screen__modal" data-testid="modal-outside-test">
                    <div className="app-screen__modal__container control-in-series-modal">
                        <div className="border-header">
                            <img src={logo} alt="logo" />
                            <p>{t('modalControlInSeries.title')}</p>
                        </div>
                        <div className="app-screen__modal__container__box control-in-series-box">
                            <div className="app-screen__modal__container__box__content control-in-series-content">
                                <div className="linear">
                                    <FormSearchableSelectField
                                        name="insuranceCompanyId"
                                        inputName="insuranceSearchValue"
                                        value={insuranceCompanyId || ''}
                                        searchValue={insuranceSearchValue}
                                        onChange={this.onInsuranceCompanySelect}
                                        onSearchInputChange={this.onSearchInputChange}
                                        options={insuranceCompaniesOptions}
                                        label={t('modalControlInSeries.insurance')}
                                        errors={get(formErrors, 'fields.insuranceCompanyId', null)}
                                        containerStyles="field-new-message"
                                    />
                                    <div className="field-new-message linear__align-btn-right">
                                        <FormTextField
                                            label={t('modalControlInSeries.file')}
                                            name="fileName"
                                            value={fileName}
                                            errors={get(formErrors, 'fields.fileName', null)}
                                            disabled
                                        />
                                        <DocumentUpload
                                            onSelected={this.onFileSelected}
                                            buttonIcon={ICON.ATTACH_HORIZONTAL}
                                            toolTipText={t('casualties.form.attachDocuments')}
                                            styles="btn--green second-button"
                                            multiple={false}
                                        />
                                    </div>
                                </div>
                                <h3>{t('modalControlInSeries.rowsQantity')}</h3>
                                <div className="linear">
                                    <div className="field-new-message">
                                        <FormNumberField
                                            label={ProviderTypes.CAETANOGLASS}
                                            name={ProviderTypes.CAETANOGLASS}
                                            value={numberToProcess[ProviderTypes.CAETANOGLASS]}
                                            min="0"
                                            max={maxNumberField}
                                            onChange={this.onChangeNumberField}
                                        />
                                    </div>
                                    <div className="field-new-message">
                                        <FormNumberField
                                            label={ProviderTypes.EXPRESSGLASS}
                                            name={ProviderTypes.EXPRESSGLASS}
                                            value={numberToProcess[ProviderTypes.EXPRESSGLASS]}
                                            min="0"
                                            max={maxNumberField}
                                            onChange={this.onChangeNumberField}
                                        />
                                    </div>
                                </div>
                                <div className="linear">
                                    <div className="field-new-message">
                                        <FormNumberField
                                            label={ProviderTypes.CARGLASS}
                                            name={ProviderTypes.CARGLASS}
                                            value={numberToProcess[ProviderTypes.CARGLASS]}
                                            min="0"
                                            max={maxNumberField}
                                            onChange={this.onChangeNumberField}
                                        />
                                    </div>
                                    <div className="field-new-message">
                                        <FormNumberField
                                            label={ProviderTypes.NEWCAR}
                                            name={ProviderTypes.NEWCAR}
                                            value={numberToProcess[ProviderTypes.NEWCAR]}
                                            min="0"
                                            max={maxNumberField}
                                            onChange={this.onChangeNumberField}
                                        />
                                    </div>
                                </div>
                                <div className="linear">
                                    <div className="field-new-message">
                                        <FormNumberField
                                            label={ProviderTypes.GLASSDRIVE}
                                            name={ProviderTypes.GLASSDRIVE}
                                            value={numberToProcess[ProviderTypes.GLASSDRIVE]}
                                            min="0"
                                            max={maxNumberField}
                                            onChange={this.onChangeNumberField}
                                        />
                                    </div>
                                    <div className="field-new-message linear__align-btn-right">
                                        <ButtonDeprecated
                                            text={t('global.buttons.send')}
                                            styles="btn--purple"
                                            callback={this.onSubmit}
                                            disabled={isFetching}
                                            icon={isFetching ? ICON.LOADER : ''}
                                            iconPosition="left"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(withUsersContext(withBrokenGlassControlContext(ControlInSeriesModal))));
