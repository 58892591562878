/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import ErrorMessage from './ErrorMessage';

interface OwnProps {
    name: string;
    value: string | number | null;
    onChange?: Function;
    label: string;
    containerStyles?: string;
    fieldStyles?: string;
    errors?: any;
    disabled?: boolean;
    rows?: number;
    smallArea?: boolean;
}

class FormTextAreaField extends Component<OwnProps> {
    renderErrors = () => {
        const { name, errors } = this.props;

        return (
            <ErrorMessage errors={errors} field={name} />
        );
    };

    render() {
        const {
            name, value, onChange, label, containerStyles, fieldStyles, disabled, rows, smallArea, errors,
        } = this.props;
        const containerClass = containerStyles || '';
        let fieldClass = fieldStyles || '';

        if (disabled) {
            fieldClass += ' disabled';
        }

        if (smallArea) {
            fieldClass += ' small';
        }

        return (
            <div className={`field-container ${containerClass}`}>
                <div className="field-container__label">
                    {label}
                </div>
                <div className={`field-container__text-area ${fieldClass}`}>
                    <textarea
                        name={name}
                        value={value || ''}
                        rows={rows || 5}
                        onChange={onChange ? (e) => onChange(e) : () => {}}
                        disabled={disabled || false}
                    />
                </div>
                <p>{errors && this.renderErrors()}</p>
            </div>
        );
    }
}

export default FormTextAreaField;
