/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { AnnotationController, ConnectedAnnotationController } from './AnnotationController';

export interface AnnotationContext extends Omit<InstanceType<typeof AnnotationController>, keyof React.Component> {}

const annotationContextInstance = createContext<AnnotationContext | null>(null);

// Export Provider and Consumer

export const AnnotationContextProvider = annotationContextInstance.Provider;
export const AnnotationContextConsumer = annotationContextInstance.Consumer;

export const withAnnotationContext = <P, >(Component: ComponentType<P>): FC<Omit<P, keyof AnnotationContext>> => (props) => (
    <ConnectedAnnotationController>
        <AnnotationContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </AnnotationContextConsumer>
    </ConnectedAnnotationController>
);
