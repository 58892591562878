/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, ReactNode } from 'react';
import ErrorMessage from './ErrorMessage';
import { IError } from '../../utils/validation';

interface OwnProps {
    name: string;
    value: string | number | null;
    onChange?: Function;
    label?: string;
    containerStyles?: string;
    labelStyles?: string;
    fieldStyles?: string;
    errors?: IError[];
    disabled?: boolean;
    id?: string;
    rightAdornment?: string;
}

class FormTextField extends Component<OwnProps> {
    renderErrors = (): ReactNode => {
        const { name, errors } = this.props;

        if (!errors) return null;

        return <ErrorMessage errors={errors} field={name} />;
    };

    render() {
        const {
            name,
            value = '',
            onChange,
            label,
            containerStyles = '',
            labelStyles = '',
            fieldStyles = '',
            disabled = false,
            id,
            rightAdornment,
        } = this.props;

        let fieldClass = fieldStyles;

        if (disabled) fieldClass += ' disabled';

        return (
            <div className={`field-container ${containerStyles}`}>
                {label && (
                    <div className={`field-container__label ${labelStyles}`}>
                        {label}
                    </div>
                )}
                <div className={`field-container__text-field ${fieldClass}`}>
                    <input
                        name={name}
                        aria-label={label}
                        type="text"
                        value={value || ''}
                        onChange={onChange ? (e) => onChange(e) : () => {}}
                        maxLength={80}
                        disabled={disabled}
                        id={id}
                        data-testid={`input-${name}`}
                    />
                    {rightAdornment}
                </div>
                <p>{this.renderErrors()}</p>
            </div>
        );
    }
}

export default FormTextField;
