/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PreInspectionsContextProvider } from './PreInspectionsContext';
import {
    preInspectionCompleteCloseURL,
    preInspectionCompleteUpdateURL, preInspectionGlassCloseURL, preInspectionGlassReEvaluateURL, preInspectionGlassRestartURL, preInspectionGlassUpdateURL,
    preInspectionResetTokenURL,
    preInspectionsURL,
    preInspectionURL,
    preInspectionVideoTokenURL,
} from '../../../services/preInspections';
import {
    PreInspection,
    RequestClose,
    RequestShare,
} from '../../../constants/types';
import { KeyedObject } from '../../../constants/misc';
import { disputesURL, disputeURL } from '../../../services/disputes';
import { getFilenameFromContentDisposition } from '../../../utils/misc';

interface OwnProps {
    children: React.ReactNode;
}

export class PreInspectionsController extends Component<OwnProps> {
    getPreInspection = async (id: string, onSuccess: (data: PreInspection) => void, onFailure: () => void) => {
        try {
            const { data } = await axios.get(preInspectionURL(id));
            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    getImage = async (image: string): Promise<string | null> => {
        try {
            const { data } = await axios.get(image, { responseType: 'arraybuffer' });

            const blob = new Blob([data], { type: 'image/jpeg' });

            const objectURL = URL.createObjectURL(blob);

            return objectURL;
        } catch {
            return null;
        }
    };

    reEvaluateGlassPreInspection = async (id: string, onSuccess: (data: PreInspection) => void, onFailure: () => void) => {
        try {
            const { data } = await axios.put(preInspectionGlassReEvaluateURL(id));
            onSuccess(data);
            return data;
        } catch {
            onFailure();
        }
    };

    glassUpdate = async (id: number, fields: object, onSuccess: () => void, onFailure: () => void) => {
        try {
            await axios.put(preInspectionGlassUpdateURL(id), fields);
            onSuccess();
        } catch {
            onFailure();
        }
    };

    preInspectionUpdate = async (id: number, fields: object, onSuccess: () => void, onFailure: () => void) => {
        try {
            await axios.put(preInspectionCompleteUpdateURL(id), fields);
            onSuccess();
        } catch {
            onFailure();
        }
    };

    concludeGlassPreInspection = async (id: string, onSuccess: (data: PreInspection) => void, onFailure: () => void) => {
        try {
            const { data } = await axios.put(preInspectionGlassCloseURL(id));
            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    requestCancellation = async (url: string, onSuccess: (data: PreInspection) => void, onFailure: () => void) => {
        try {
            const { data } = await axios.put(url);
            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    requestNewToken = async (id: string, onSuccess: (data: string) => void, onFailure: () => void) => {
        try {
            const { data } = await axios.post(preInspectionResetTokenURL(id));
            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    requestNewVideoToken = async (id: string, onSuccess: (data: string) => void, onFailure: () => void) => {
        try {
            const { data } = await axios.post(preInspectionVideoTokenURL(id));
            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    requestReopen = async (url: string, onSuccess: (data: PreInspection) => void, onFailure: () => void) => {
        try {
            const { data } = await axios.put(url);
            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    requestRestart = async (id: string, onSuccess: (data: PreInspection) => void, onFailure: () => void) => {
        try {
            const { data } = await axios.put(preInspectionGlassRestartURL(id));
            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    requestClose = async (id: string, fields: RequestClose, onSuccess: (data: PreInspection) => void, onFailure: () => void) => {
        try {
            const { data } = await axios.put(preInspectionCompleteCloseURL(id), fields);
            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    shareToken = async (url: string, fields: RequestShare, onSuccess: () => void, onFailure: (error: AxiosError) => void) => {
        try {
            await axios.put(url, fields);
            onSuccess();
        } catch (error) {
            onFailure(error as AxiosError);
        }
    };

    createPreInspection = async (url: string, fields: KeyedObject, onSuccess: () => void, onFailure: (error: AxiosError) => void) => {
        try {
            await axios.post(url, fields);
            onSuccess();
        } catch (error) {
            onFailure(error as AxiosError);
        }
    };

    getPreInspections = async (queryParams: KeyedObject) => {
        const { data, headers } = await axios.get(preInspectionsURL(queryParams));
        return { data, total: parseInt(headers['x-total-count']) };
    };

    getDisputes = async (queryParams: KeyedObject) => {
        const { data, headers } = await axios.get(disputesURL(queryParams));
        return { data, total: parseInt(headers['x-total-count']) };
    };

    getDispute = async (id: string, onSuccess: () => void, onFailure: () => void) => {
        try {
            await axios.get(disputeURL(id));
            onSuccess();
        } catch {
            onFailure();
        }
    };

    generatePDF = async (url: string, onSuccess: (file: File, filename: string, mimeType: string) => void, onFailure: () => void) => {
        try {
            const { data, headers } = await axios.post(url, null, { responseType: 'blob' });
            const fileName: string = getFilenameFromContentDisposition(headers['content-disposition']);
            const mimeType = headers['content-type'];
            onSuccess(data, fileName, mimeType);
        } catch {
            onFailure();
        }
    };

    getImageThumbnail = async (url: string, onSuccess: (file: AxiosResponse) => void, onFailure: () => void) => {
        try {
            const data = await axios.get(url, { responseType: 'arraybuffer' });
            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    render() {
        const {
            children,
        } = this.props;

        return (
            <PreInspectionsContextProvider
                value={{
                    getPreInspection: this.getPreInspection,
                    getImage: this.getImage,
                    reEvaluateGlassPreInspection: this.reEvaluateGlassPreInspection,
                    glassUpdate: this.glassUpdate,
                    preInspectionUpdate: this.preInspectionUpdate,
                    concludeGlassPreInspection: this.concludeGlassPreInspection,
                    requestCancellation: this.requestCancellation,
                    requestNewToken: this.requestNewToken,
                    requestNewVideoToken: this.requestNewVideoToken,
                    requestReopen: this.requestReopen,
                    requestRestart: this.requestRestart,
                    requestClose: this.requestClose,
                    shareToken: this.shareToken,
                    createPreInspection: this.createPreInspection,
                    getPreInspections: this.getPreInspections,
                    getDisputes: this.getDisputes,
                    getDispute: this.getDispute,
                    generatePDF: this.generatePDF,
                    getImageThumbnail: this.getImageThumbnail,
                }}
            >
                {children}
            </PreInspectionsContextProvider>
        );
    }
}

export const ConnectedPreInspectionsController = connect()(PreInspectionsController);
