/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { VideoState } from './types';
import { VideoAction } from '../actions/video_types';

export const videoInitialState: VideoState = {
    onlineFluxeUsers: [],
    onlineMechanicUsers: [],
    onlineInsuranceUsers: [],
    onlineGlassProviderUsers: [],
    incomingCall: false,
    signalMessage: null,
    incomingCallMessage: null,
    wasIceCandidateFound: false,
    callAnswered: false,
    answerCall: false,
    messageToSend: null,
    declineCall: false,
    callDeclined: false,
    callWasTerminated: false,
    tokenIsInvalid: false,
    hasCandidates: false,
    candidates: [],
};

// eslint-disable-next-line func-names
export default function (state = videoInitialState, action: any): VideoState {
    switch (action.type) {
        case VideoAction.SET_ONLINE_FLUXE_USERS:
            return {
                ...state,
                onlineFluxeUsers: [
                    ...action.payload,
                ],
            };
        case VideoAction.SET_ONLINE_MECHANIC_USERS:
            return {
                ...state,
                onlineMechanicUsers: [
                    ...action.payload,
                ],
            };
        case VideoAction.SET_ONLINE_INSURANCE_USERS:
            return {
                ...state,
                onlineInsuranceUsers: [
                    ...action.payload,
                ],
            };
        case VideoAction.INCOMING_CALL:
            return {
                ...state,
                incomingCallMessage: action.payload,
                incomingCall: true,
            };
        case VideoAction.CALL_ANSWERED:
            return {
                ...state,
                signalMessage: action.payload,
                callAnswered: true,
                incomingCall: false,
            };
        case VideoAction.DECLINE_CALL:
            return {
                ...state,
                callAnswered: false,
                incomingCall: false,
                declineCall: true,
            };
        case VideoAction.ICE_CANDIDATE_FOUND:
            return {
                ...state,
                signalMessage: action.payload,
                wasIceCandidateFound: true,
            };
        case VideoAction.ANSWER_CALL:
            return {
                ...state,
                answerCall: true,
                incomingCall: false,
            };
        case VideoAction.SEND_MESSAGE:
            return {
                ...state,
                messageToSend: action.payload,
            };
        case VideoAction.CALL_DECLINED:
            return {
                ...state,
                callDeclined: true,
                incomingCall: false,
                callAnswered: false,
                declineCall: false,
                answerCall: false,
            };
        case VideoAction.RESET_FLAGS:
            return {
                ...state,
                incomingCallMessage: null,
                wasIceCandidateFound: false,
                callDeclined: false,
                incomingCall: false,
                callAnswered: false,
                declineCall: false,
                answerCall: false,
                tokenIsInvalid: false,
            };
        case VideoAction.VIDEO_RESET:
            return {
                ...videoInitialState,
            };
        case VideoAction.RESET_HAS_CANDIDATES:
            return {
                ...state,
                hasCandidates: action.payload,
            };
        case VideoAction.RESET_PENDING_CANDIDATES:
            return {
                ...state,
                candidates: action.payload,
            };
        default:
            return state;
    }
}
