/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import {
    BUDGET_DETAIL_ROUTE,
    LOGIN_ROUTE,
    NOT_FOUND_ROUTE,
    RECOVER_PASSWORD_ROUTE,
    REGISTER_ROUTE,
    RESET_PASSWORD_ROUTE,
} from '../constants/routes';
import LoginScreen from './screens/LoginScreen';
import RecoverPasswordScreen from './screens/RecoverPasswordScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import IndexScreen from './screens/IndexScreen';
import AuthenticatedRoute from './containers/AuthenticatedRoute';
import ErrorScreen from './screens/ErrorScreen';

const Router: React.FunctionComponent = () => (
    <div className="app-router">
        <ErrorBoundary FallbackComponent={ErrorScreen}>
            <Routes>
                <Route
                    path={NOT_FOUND_ROUTE}
                    element={<div>not found</div>}
                />
                <Route
                    path={LOGIN_ROUTE}
                    element={<LoginScreen />}
                />
                <Route
                    path={RECOVER_PASSWORD_ROUTE}
                    element={<RecoverPasswordScreen />}
                />
                <Route
                    path={`${RESET_PASSWORD_ROUTE}/:token`}
                    element={<ResetPasswordScreen />}
                />
                <Route
                    path={`${REGISTER_ROUTE}/:token`}
                    element={<ResetPasswordScreen />}
                />
                <Route
                    path={BUDGET_DETAIL_ROUTE}
                    element={<LoginScreen />}
                />
                <Route
                    path="*"
                    element={(
                        <AuthenticatedRoute>
                            <IndexScreen />
                        </AuthenticatedRoute>
                    )}
                />
            </Routes>
        </ErrorBoundary>
        <ToastContainer hideProgressBar className="toast-container" />
    </div>
);

export default Router;
