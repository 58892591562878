/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import axios, { AxiosError } from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CarPartOutline, FileDamage } from '../../../constants/types';
import { FileDamageContextProvider } from './FileDamageContext';
import { fileDamagesUrl, fileOutlineUrl } from '../../../services/files';
import { sendArtificialIntelligenceURL } from '../../../services/misc';

interface OwnProps {
    children: React.ReactNode;
}

export class FileDamageController extends Component<OwnProps> {
    updateFileDamages = async (fileId: number, damages: FileDamage[], onSuccess: (updatedDamages: FileDamage[]) => void, onFailure: () => void) => {
        try {
            const { data } = await axios.put(fileDamagesUrl(fileId), damages);

            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    getFilePartOutlines = async (fileId: number): Promise<CarPartOutline[]> => {
        try {
            const { data } = await axios.get<CarPartOutline[]>(fileOutlineUrl(fileId));
            return data;
        } catch {
            return [];
        }
    };

    sendDamageImageToTrainAI = async (file: File, damagesList: FileDamage[], onSuccess: () => void, onFailure: (error: AxiosError) => void): Promise<void> => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('damageLocations', new Blob([JSON.stringify(damagesList)], {
                type: 'application/json',
            }));

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };

            await axios.post(sendArtificialIntelligenceURL(), formData, config);

            onSuccess();
        } catch (err) {
            onFailure(err as AxiosError);
        }
    };

    render() {
        const {
            children,
        } = this.props;

        return (
            <FileDamageContextProvider
                value={{
                    updateFileDamages: this.updateFileDamages,
                    getFilePartOutlines: this.getFilePartOutlines,
                    sendDamageImageToTrainAI: this.sendDamageImageToTrainAI,
                }}
            >
                {children}
            </FileDamageContextProvider>
        );
    }
}

export const ConnectedFileDamageController = connect()(FileDamageController);
