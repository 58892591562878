/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { ComponentType, createContext, FC } from 'react';
import { ConnectedUsersController, UsersController } from './UsersController';

export interface UsersContext extends Omit<InstanceType<typeof UsersController>, keyof React.Component> {}

const UsersContextInstance = createContext<UsersContext | null>(null);

export const UsersContextProvider = UsersContextInstance.Provider;
export const UsersContextConsumer = UsersContextInstance.Consumer;

export const withUsersContext = <P, >(
    Component: ComponentType<P>,
): FC<Omit<P, keyof UsersContext>> => (props) => (
    <ConnectedUsersController>
        <UsersContextConsumer>
            { (ctx) => <Component {...props as P} {...ctx} />}
        </UsersContextConsumer>
    </ConnectedUsersController>
    );
