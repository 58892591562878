/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { ListParams, ListQueryParamKey } from '../../constants/misc';

export interface WithQueryParamsProps {
    listParams: ListParams | null;
    onQueryParamsChange(query: string): void;
}

interface State {
    listParams: ListParams | null;
}

const withQueryParams = <P extends object>(
    Component: React.ComponentType<P>,
) => class WithQueryParams extends React.Component<Omit<P, keyof WithQueryParamsProps>, State> {
        state: State = {
            listParams: null,
        };

        onQueryParamsChange = (query: string): void => {
            const urlSearchParams = new URLSearchParams(query);
            const page = urlSearchParams.get(ListQueryParamKey.Page);
            const sort = urlSearchParams.get(ListQueryParamKey.Sort) || '';
            const order = urlSearchParams.get(ListQueryParamKey.Order) || '';
            const search = urlSearchParams.get(ListQueryParamKey.Search) || '';

            let pageNumber = page ? Number(page) : 1;
            if (pageNumber < 1) pageNumber = 1;

            this.setState({
                listParams: {
                    page: pageNumber,
                    sort,
                    order,
                    search,
                },
            });
        };

        render() {
            const { ...props } = this.props;
            const { listParams } = this.state;

            return (
                <Component
                    {...props as P}
                    listParams={listParams}
                    onQueryParamsChange={this.onQueryParamsChange}
                />
            );
        }
    };

export default withQueryParams;
