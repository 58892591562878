/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { AxiosError } from 'axios';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import {
    PolygonPoint,
    FileDamage,
    FileDamageSeverityType,
    FileDamageType,
} from '../../constants/types';
import Button from '../elements/Button';
import {
    drawDamagesPolygonsInCanvas,
    drawNewLineInCanvas,
    getDamageIdIfClicked,
    getTwoPointsDistance,
} from '../../utils/damagesHelper';
import { LAST_POINT_THRESHOLD } from '../../constants/damages';
import displaySelectOptionsDialog from '../elements/displaySelectOptionsDialog';
import { SelectOption } from '../../constants/misc';
import { generateInt } from '../../utils/misc';
import DamageOpacityInput from '../elements/DamageOpacityInput';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';
import { FileDamageContext, withFileDamageContext } from '../controllers/fileDamage/FileDamageContext';
import Loader from '../elements/Loader';

interface OwnProps extends TranslationContext, FileDamageContext {}

interface OwnState {
    imgFile: File | null;
    imgObjectUrl: string;
    damagesList: FileDamage[];
    damageSelectedId: number | null;
    currentDrawingPolygonPoints: Array<PolygonPoint>;
    widthRatio: number;
    heightRatio: number;
    opacityValue: number;
    showLoader: boolean;
}

const initialState: OwnState = {
    imgFile: null,
    imgObjectUrl: '',
    damagesList: [],
    damageSelectedId: null,
    currentDrawingPolygonPoints: [],
    widthRatio: 0,
    heightRatio: 0,
    opacityValue: 40,
    showLoader: false,
};

class DamageToolScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    canvasRef = React.createRef<HTMLCanvasElement>();

    imageRef = React.createRef<HTMLImageElement>();

    canvasCtx: CanvasRenderingContext2D | null = null;

    componentWillUnmount() {
        window.removeEventListener('resize', this.drawCanvas);
        window.removeEventListener('keydown', this.onWindowKeydown);
    }

    onFileSelected = (acceptedFiles: File[]) => {
        if (acceptedFiles.length === 0) return;

        this.setState({
            imgFile: acceptedFiles[0],
            imgObjectUrl: URL.createObjectURL(acceptedFiles[0]),
        });
    };

    onSaveDamagesClick = () => {
        const { sendDamageImageToTrainAI } = this.props;
        const { damagesList, imgFile } = this.state;

        if (!imgFile) return;

        this.setState({
            showLoader: true,
        });

        sendDamageImageToTrainAI(imgFile, damagesList, this.handleSendDataSuccess, this.handleSendDataFailure);
    };

    onCancelClick = () => {
        const { imgObjectUrl } = this.state;

        URL.revokeObjectURL(imgObjectUrl);

        this.setState({
            damagesList: [],
            imgFile: null,
            imgObjectUrl: '',
            currentDrawingPolygonPoints: [],
        });
    };

    onRemoveDamageClick = () => {
        const { damageSelectedId, damagesList } = this.state;

        this.setState({
            damagesList: [...damagesList].filter((damage) => damage.id !== damageSelectedId),
            damageSelectedId: null,
        }, this.redrawCanvas);
    };

    onImageLoad = () => {
        window.addEventListener('resize', this.drawCanvas);
        window.addEventListener('keydown', this.onWindowKeydown);
        this.drawCanvas();
    };

    onWindowKeydown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            this.cleanCurrentPolygonPoints();
        } else if (e.key === 'Backspace') {
            this.onRemoveDamageClick();
        }
    };

    onCanvasContextMenu = (e: React.MouseEvent) => {
        e.preventDefault();
        this.cleanCurrentPolygonPoints();
    };

    onCanvasClick = (e: React.MouseEvent) => {
        const { t } = this.props;
        const {
            widthRatio,
            heightRatio,
            damagesList,
            currentDrawingPolygonPoints,
        } = this.state;

        if (damagesList.length === 25) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('errors.damagesCreationMaxLimit'));
            return;
        }

        if (!this.canvasRef.current) return;

        const rect = this.canvasRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const imgClickedX = Math.round(x / widthRatio);
        const imgClickedY = Math.round(y / heightRatio);

        const clickedDamageId = getDamageIdIfClicked(damagesList, imgClickedX, imgClickedY);

        if (!clickedDamageId || currentDrawingPolygonPoints.length > 0) {
            this.setState({ damageSelectedId: null });
            this.handleClickWhenDrawingPolygon(x, y);
        }

        if (clickedDamageId) {
            this.setState({
                damageSelectedId: clickedDamageId,
            }, this.redrawCanvas);
        }
    };

    onCanvasMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
        const {
            currentDrawingPolygonPoints,
        } = this.state;

        e.preventDefault();
        e.stopPropagation();

        if (!this.canvasRef.current || !this.canvasCtx || currentDrawingPolygonPoints.length === 0) return;

        const { left, top } = this.canvasRef.current.getBoundingClientRect();

        const x = e.clientX - left;
        const y = e.clientY - top;

        const lastPoint = currentDrawingPolygonPoints[currentDrawingPolygonPoints.length - 1];
        this.redrawCanvas();
        drawNewLineInCanvas(this.canvasCtx, lastPoint.x, lastPoint.y, x, y);
    };

    onDamageTypeOptSelected = (opt: SelectOption) => {
        const {
            damagesList,
            currentDrawingPolygonPoints,
            widthRatio,
            heightRatio,
        } = this.state;

        this.setState({
            currentDrawingPolygonPoints: [],
            damagesList: [
                ...damagesList, {
                    id: generateInt(-50000, -1),
                    damageSeverityType: FileDamageSeverityType.LOW,
                    damageType: opt.value,
                    polygonPoints: currentDrawingPolygonPoints.map((point) => ({
                        x: Math.round(point.x / widthRatio),
                        y: Math.round(point.y / heightRatio),
                    })),
                },
            ],
        }, this.redrawCanvas);
    };

    onOpacityValueChange = (newOpacity: number) => {
        this.setState({
            opacityValue: newOpacity,
        }, this.redrawCanvas);
    };

    handleClickWhenDrawingPolygon = (clickedX: number, clickedY: number) => {
        const {
            currentDrawingPolygonPoints,
        } = this.state;

        if (currentDrawingPolygonPoints.length === 0) {
            this.setState({ currentDrawingPolygonPoints: [{ x: clickedX, y: clickedY }] });
            return;
        }

        const distance = getTwoPointsDistance(currentDrawingPolygonPoints[0], { x: clickedX, y: clickedY });

        if (distance < LAST_POINT_THRESHOLD) {
            this.handleFinishDrawingCurrentPolygon();
            return;
        }

        this.setState({
            currentDrawingPolygonPoints: [...currentDrawingPolygonPoints, { x: clickedX, y: clickedY }],
        }, this.redrawCanvas);
    };

    handleFinishDrawingCurrentPolygon = () => {
        const { t } = this.props;

        displaySelectOptionsDialog({
            message: t('preInspections.form.selectDamageTypeMsg'),
            options: [{
                value: FileDamageType.GLASS,
                label: t('preInspections.form.glassDamageBtn'),
            }, {
                value: FileDamageType.BODYWORK,
                label: t('preInspections.form.bodyworkDamageBtn'),
            }],
            onOptionSelected: this.onDamageTypeOptSelected,
        });
    };

    handleSendDataSuccess = () => {
        const { t } = this.props;

        displayNotification(NOTIFICATION_TYPE.SUCCESS, t('damageTool.sendAISuccessMsg'));

        this.setState({
            showLoader: false,
        });

        this.onCancelClick();
    };

    handleSendDataFailure = (error: AxiosError) => {
        const { t } = this.props;

        const { fields } = error.response?.data;
        
        if (fields?.damageLocationDtos?.[0]?.typeOfViolation === 'Size') {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('damageTool.sendAIFailureSizeMsg'));
        } else {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('damageTool.sendAIFailureMsg'));
        }

        this.setState({
            showLoader: false,
        });
    };

    redrawCanvas = () => {
        const {
            currentDrawingPolygonPoints,
            damagesList,
            widthRatio,
            heightRatio,
            damageSelectedId,
            opacityValue,
        } = this.state;

        if (!this.canvasRef.current || !this.canvasCtx) return;

        this.canvasCtx.clearRect(0, 0, this.canvasRef.current.width, this.canvasRef.current.height);

        drawDamagesPolygonsInCanvas({
            canvasCtx: this.canvasCtx,
            isDamageTool: true,
            opacity: opacityValue,
            damagesList,
            widthRatio,
            heightRatio,
            damageSelectedId,
        });

        for (let i = 0; i < currentDrawingPolygonPoints.length - 1; i++) {
            const currentPoint = currentDrawingPolygonPoints[i];
            const nextPoint = currentDrawingPolygonPoints[i + 1];

            drawNewLineInCanvas(this.canvasCtx, currentPoint.x, currentPoint.y, nextPoint.x, nextPoint.y);
        }
    };

    drawCanvas = () => {
        if (!this.canvasRef.current || !this.imageRef.current) return;

        this.canvasRef.current.width = this.imageRef.current.clientWidth;
        this.canvasRef.current.height = this.imageRef.current.clientHeight;
        this.canvasCtx = this.canvasRef.current.getContext('2d');

        this.setState({
            widthRatio: this.canvasRef.current.width / this.imageRef.current.naturalWidth,
            heightRatio: this.canvasRef.current.height / this.imageRef.current.naturalHeight,
        }, this.redrawCanvas);
    };

    cleanCurrentPolygonPoints = () => {
        this.setState({
            currentDrawingPolygonPoints: [],
        }, this.redrawCanvas);
    };

    renderImgToDrawDamages = () => {
        const { t } = this.props;
        const { imgObjectUrl, damageSelectedId, opacityValue } = this.state;

        return (
            <React.Fragment>
                <div className="damage-tool-screen__actions">
                    <DamageOpacityInput
                        opacityLevel={opacityValue}
                        onOpacityValueChange={this.onOpacityValueChange}
                    />
                    <Button
                        extraClasses="btn--outlined"
                        onClick={this.onCancelClick}
                    >
                        {t('global.buttons.reject')}
                    </Button>
                    <Button
                        extraClasses="btn--danger"
                        onClick={() => this.onRemoveDamageClick()}
                        isDisabled={!damageSelectedId}
                    >
                        {t('preInspections.form.removeDamageBtn')}
                    </Button>
                    <Button
                        extraClasses="btn--purple draw-image__details__inner__actions__bigger-btn"
                        onClick={this.onSaveDamagesClick}
                    >
                        {t('preInspections.form.saveNewDamagesBtn')}
                    </Button>
                </div>
                <div className="damage-tool-screen__img-wrapper">
                    <img
                        ref={this.imageRef}
                        src={imgObjectUrl}
                        alt={t('errors.invalidImage')}
                        onLoad={this.onImageLoad}
                        data-testid="damage-file-img"
                        width={this.imageRef.current?.clientWidth}
                        height={this.imageRef.current?.clientHeight}
                    />
                    <canvas
                        ref={this.canvasRef}
                        data-testid="damage-tool-canvas"
                        onClick={this.onCanvasClick}
                        onMouseMove={(e) => this.onCanvasMouseMove(e)}
                        onContextMenu={this.onCanvasContextMenu}
                        width={this.canvasRef.current?.clientWidth}
                        height={this.canvasRef.current?.clientHeight}
                    />
                </div>
            </React.Fragment>
        );
    };

    render() {
        const { t } = this.props;
        const { imgFile, showLoader } = this.state;

        return (
            <div className="damage-tool-screen">
                {showLoader && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                {imgFile ? (
                    this.renderImgToDrawDamages()
                ) : (
                    <Dropzone onDrop={this.onFileSelected}>
                        {({ getRootProps, getInputProps, isDragActive }) => (
                            <section className="dropzone-wrapper">
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {
                                        isDragActive
                                            ? <p>{t('damageTool.dropMsg')}</p>
                                            : <p>{t('damageTool.dragAndDropMsg')}</p>
                                    }
                                </div>
                            </section>
                        )}
                    </Dropzone>
                )}
            </div>
        );
    }
}

export default withTranslationContext(withFileDamageContext(DamageToolScreen));
