/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { get } from 'lodash';
import { AxiosError } from 'axios';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { AuthenticationContext, withAuthenticationContext } from '../../controllers/authentication/AuthenticationContext';
import { insuranceUserURL } from '../../../services/users';
import { NOT_FOUND_ROUTE, USER_INSURANCE_DETAILS_ROUTE } from '../../../constants/routes';
import Loader from '../../elements/Loader';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import ButtonDeprecated from '../../elements/ButtonDeprecated';
import FormTextField from '../../elements/FormTextField';
import { getFormErrors, IFormError, VALIDATIONS } from '../../../utils/validation';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import displayConfirm from '../../elements/displayConfirm';
import { VideoContextInterface, withVideoContext } from '../../controllers/video/VideoContext';
import { UserRoles } from '../../../constants/authorization';
import GeneralModal from '../../elements/GeneralModal';
import { WEB_URL } from '../../../settings';
import { WithRouterProps, withRouter } from '../../containers/withRouter';
import { validateNIF } from '../../../utils/misc';
import { UsersContext, withUsersContext } from '../../controllers/users/UsersContext';
import { UserResponse } from '../../../constants/types';
import { KeyedObject } from '../../../constants/misc';
import CheckBox from '../../elements/CheckBox';

interface OwnProps extends WithRouterProps, TranslationContext, VideoContextInterface, AuthenticationContext, UsersContext {}

interface OwnState {
    id: string;
    name: string;
    email: string;
    nif: string;
    contact: string;
    role: string;
    pilotInsuranceCompany: boolean;
    callbackURL: string;
    isFetching: boolean;
    preparing: boolean;
    inactive: boolean;
    formErrors: any;
    hasModifications: boolean;
    viewingDetails: boolean;
    registered: boolean;
    isEditable: boolean;
    apiKey: string;
    showResetKeyModal: boolean;
}

const initialState: OwnState = {
    id: '',
    name: '',
    email: '',
    nif: '',
    contact: '',
    pilotInsuranceCompany: false,
    isFetching: false,
    preparing: false,
    inactive: false,
    callbackURL: '',
    role: '',
    formErrors: {},
    hasModifications: false,
    viewingDetails: false,
    registered: false,
    isEditable: false,
    apiKey: '',
    showResetKeyModal: false,
};

class InsuranceUserEditScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { params, user, location } = this.props;
        const { pathname } = location;
        const { id } = params;

        let isEditable = false;

        const role = user?.role || '';

        if (role === UserRoles.ADMIN || role === UserRoles.COLLABORATOR) {
            isEditable = true;
        }

        if (id) {
            let viewingDetails = false;
            if (pathname.startsWith(USER_INSURANCE_DETAILS_ROUTE)) {
                viewingDetails = true;
            }
            this.setState({
                id,
                viewingDetails,
                isEditable,
            }, this.prepare);
        }
    }

    onToggleClick = () => {
        const { t } = this.props;
        const { id, inactive, name } = this.state;

        let msg: string = t('editUser.deactivateMessage', { name });
        if (inactive) msg = t('editUser.activateMessage', { name });

        displayConfirm({
            acceptButtonText: t('global.buttons.accept'),
            onAccept: () => this.toggleRequest(id),
            rejectButtonText: t('global.buttons.reject'),
            containerStyle: 'remove-document',
            title: msg,
        });
    };

    onBackClick = () => {
        const { navigate } = this.props;
        navigate(-1);
    };

    onSave = () => {
        const {
            name,
            email,
            contact,
            nif,
            pilotInsuranceCompany,
            callbackURL,
        } = this.state;

        const validNif = validateNIF(nif.trim());

        if (this.validateForm() && validNif) {
            const fields = {
                name: String(name).trim(),
                email: String(email).trim(),
                contact: String(contact).trim(),
                nif: String(nif).trim(),
                pilotInsuranceCompany,
                callback: String(callbackURL).trim(),
            };

            const formData = new FormData();

            formData.append('userRequest', new Blob([JSON.stringify(fields)], { type: 'application/json' }));

            this.editUserRequest(formData);
        }

        if (!validNif) {
            this.setState({
                formErrors: {
                    fields: {
                        nif: [
                            {
                                object: 'InsuranceCompanyRequest',
                                typeOfViolation: 'FiscalCode',
                                message: 'Invalid fiscal code!',
                            },
                        ],
                    },
                },
            });
        }
    };

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { state } = this;
        this.setState({
            ...state,
            [e.currentTarget.name]: e.currentTarget.value,
            hasModifications: true,
        });
    };

    onPilotInsuranceCompanyClick = () => {
        this.setState((prevState: OwnState) => ({
            ...prevState,
            pilotInsuranceCompany: !prevState.pilotInsuranceCompany,
            hasModifications: true,
        }));
    };

    onClickDownloadCallbackJson = () => {
        const { t } = this.props;
        const { callbackURL } = this.state;

        if (!callbackURL) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('editUser.errorCallbackNotDefined'));
            return;
        }
        this.downloadPVVJson();
        this.downloadPVCJson();
    };

    onResetApiKeySuccess = () => {
        this.setState({
            isFetching: false,
            showResetKeyModal: false,
        }, () => this.prepare());
    };

    onResetApiKeyFailure = () => {
        const { t } = this.props;

        displayNotification(NOTIFICATION_TYPE.ERROR, t('editUser.errorApiKey'));
        this.setState({
            isFetching: false,
            showResetKeyModal: false,
        });
    };

    onToggleUserSuccess = (data: UserResponse) => {
        const { t } = this.props;

        if (data.inactive) displayNotification(NOTIFICATION_TYPE.SUCCESS, t('editUser.deactivateSuccess'));
        else displayNotification(NOTIFICATION_TYPE.SUCCESS, t('editUser.activateSuccess'));

        this.setState({
            isFetching: false,
            inactive: data.inactive,
        });
    };

    onToggleUserFailure = () => {
        const { t } = this.props;
        const { inactive: oldInactive } = this.state;

        if (oldInactive) displayNotification(NOTIFICATION_TYPE.ERROR, t('editUser.deactivateError'));
        else displayNotification(NOTIFICATION_TYPE.ERROR, t('editUser.activateError'));

        this.setState({ isFetching: false });
    };

    onResetApiKeyClick = () => {
        const { resetApiKey } = this.props;
        const { id, isFetching } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        resetApiKey(id, this.onResetApiKeySuccess, this.onResetApiKeyFailure);
    };

    onGetInsuranceUserSuccess = (data: KeyedObject) => {
        const {
            name,
            email,
            pilotInsuranceCompany,
            contact,
            inactive,
            nif,
            role,
            registered,
            apiKey,
            callbackURL,
        } = data;

        this.setState({
            preparing: false,
            name,
            email,
            pilotInsuranceCompany,
            contact: contact || '',
            inactive,
            nif: nif || '',
            role,
            registered,
            apiKey,
            callbackURL: callbackURL || '',
        });
    };

    onGetInsuranceUserFailure = (error: AxiosError) => {
        const { navigate } = this.props;

        if (error.response) {
            const { status } = error.response;

            if (status === 403) navigate(NOT_FOUND_ROUTE);
        }

        this.setState({ preparing: false });
    };

    onEditInsuranceUserSuccess = () => {
        const { t, user } = this.props;

        displayNotification(NOTIFICATION_TYPE.SUCCESS, t('editUser.successNotif'));

        this.setState({ isFetching: false });

        if (user && user.role === UserRoles.INSURANCE_USER) return;

        this.onBackClick();
    };

    onEditInsuranceUserFailure = (error: AxiosError) => {
        this.setState({ isFetching: false });

        if (error.response) this.handleResponse(error.response.data);
    };

    downloadPVVJson = () => {
        const link = document.createElement('a');
        link.href = `${WEB_URL}/files/fluxe-pvv-callback-example.json`;
        link.setAttribute('download', 'fluxe-pvv-callback-example.json');
        link.click();
    };

    downloadPVCJson = () => {
        const link = document.createElement('a');
        link.href = `${WEB_URL}/files/fluxe-pvc-callback-example.json`;
        link.setAttribute('download', 'fluxe-pvc-callback-example.json');
        link.click();
    };

    toggleRequest = async (id: number | string) => {
        const { toggleUsers } = this.props;

        this.setState({ isFetching: true });

        toggleUsers(Number(id), this.onToggleUserSuccess, this.onToggleUserFailure);
    };

    editUserRequest = async (fields: FormData) => {
        const { editUser } = this.props;
        const { id } = this.state;

        this.setState({ isFetching: true });

        editUser(insuranceUserURL(id), fields, this.onEditInsuranceUserSuccess, this.onEditInsuranceUserFailure);
    };

    handleResponse = (formErrors: any = null) => {
        const { t } = this.props;

        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors,
                isFetching: false,
            });
        } else {
            this.setState({ isFetching: false });
        }

        if (formErrors.errors && formErrors.errors[0].errorCode === 25) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('errors.invalidURLFormat'));
        } else {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('editUser.errorNotif'));
        }
    };

    validateForm = () => {
        const { state } = this;

        let errors: IFormError | null = state.callbackURL !== '' ? getFormErrors(state, VALIDATIONS.USER_CALLBACK_EDIT_FORM) : getFormErrors(state, VALIDATIONS.USER_EDIT_FORM);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    prepare = async () => {
        const { getUser } = this.props;
        const { id, preparing } = this.state;

        if (preparing) return;

        this.setState({ preparing: true });

        getUser(insuranceUserURL(id), this.onGetInsuranceUserSuccess, this.onGetInsuranceUserFailure);
    };

    render() {
        const { t, onlineInsuranceUsers } = this.props;
        const {
            id,
            preparing,
            isFetching,
            name,
            email,
            contact,
            nif,
            pilotInsuranceCompany,
            inactive,
            formErrors,
            role,
            hasModifications,
            viewingDetails,
            registered,
            isEditable,
            apiKey,
            callbackURL,
            showResetKeyModal,
        } = this.state;

        const showLoader: boolean = isFetching || preparing;

        const isOnline: boolean = onlineInsuranceUsers.includes(Number(id));

        return (
            <div>
                {showLoader && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="app-screen__form-edit-user">
                    <div className="app-screen__form-edit-user__top-container">
                        <div className="info-container">
                            <SvgIcon icon={ICON.LEFT_ARROW} callback={this.onBackClick} />
                            <div className="info">
                                <p className="title">{t('editUser.smallTitle')}</p>
                                <p className="user-name">{name}</p>
                                {registered ? (
                                    <div className={inactive ? 'status-dot red-circle' : `${isOnline ? ' status-dot green-circle' : 'status-dot yellow-circle'}`} />
                                ) : (
                                    <div className="status-dot orange-circle" />
                                )}
                            </div>
                        </div>
                        {!viewingDetails && (
                            <div className="buttons-container">
                                <ButtonDeprecated
                                    text={t('global.buttons.saveModifications')}
                                    type="submit"
                                    styles="btn--purple"
                                    callback={this.onSave}
                                    disabled={!hasModifications}
                                />
                                {isEditable && (
                                    <ButtonDeprecated
                                        text={inactive ? t('global.buttons.activate') : t('global.buttons.deactivate')}
                                        callback={this.onToggleClick}
                                        styles="btn--green"
                                        iconPosition="right"
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    <div className="app-screen__form-edit-user__container">
                        <div className="app-screen__form-edit-user__container__content">
                            <h3>{t('editUser.subtitles.personalData')}</h3>
                            <div className="row">
                                <div className="col-sm-8">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.name')}
                                        name="name"
                                        value={name}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.name', null)}
                                        disabled={viewingDetails || !isEditable}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.type')}
                                        name="role"
                                        value={t(`enums.userRoles.${role}`)}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.nif')}
                                        name="nif"
                                        value={nif}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.nif', null)}
                                        disabled={viewingDetails || !isEditable}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <CheckBox
                                        callback={this.onPilotInsuranceCompanyClick}
                                        checked={pilotInsuranceCompany}
                                        label={t('editUser.labels.pilotInsuranceCompany')}
                                    >
                                        {t('editUser.labels.pilotInsuranceCompany')}
                                    </CheckBox>
                                </div>
                            </div>
                            <h3 className="subtitle-with-margin">{t('editUser.subtitles.contactData')}</h3>
                            <div className="row">
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.contact')}
                                        name="contact"
                                        value={contact}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.contact', null)}
                                        disabled={viewingDetails || !isEditable}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.email')}
                                        name="email"
                                        value={email}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.apiKey')}
                                        name="apiKey"
                                        value={apiKey}
                                        disabled
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <ButtonDeprecated
                                        text={t('editUser.labels.reset')}
                                        callback={() => {
                                            this.setState({
                                                showResetKeyModal: true,
                                            });
                                        }}
                                        styles="btn--dark-purple btn--full-width"
                                        iconPosition="right"
                                    />
                                </div>
                                <div className="col-sm-8">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.callbackURL')}
                                        name="callbackURL"
                                        value={callbackURL}
                                        errors={get(formErrors, 'fields.callbackURL', null)}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-8" />
                                <div className="col-sm-8">
                                    <div className="dispute-with-link">
                                        {t('editUser.labels.callbackDescription')}
                                        <span onClick={this.onClickDownloadCallbackJson}>{t('editUser.labels.callbackClickHere')}</span>
                                        {t('editUser.labels.callbackDescription2')}
                                        <span onClick={this.downloadPVVJson}>{t('editUser.labels.callbackGlass')}</span>
                                        {t('editUser.labels.callbackOr')}
                                        <span onClick={this.downloadPVCJson}>{t('editUser.labels.callbackComplete')}</span>.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showResetKeyModal
                    && (
                        <GeneralModal
                            onModalClose={() => {
                                this.setState({
                                    showResetKeyModal: false,
                                });
                            }}
                            containerStyle="document-edit-container"
                            boxStyle="document-edit-box"
                            boxContentStyle="document-edit-box-content"
                        >
                            <div className="mt-10 text-bold">
                                {t('editUser.modal.description')}
                            </div>
                            <div className="text-small">
                                {t('editUser.modal.description1')}
                            </div>
                            <ButtonDeprecated
                                text={t('global.buttons.ok')}
                                callback={this.onResetApiKeyClick}
                                styles="btn--purple"
                            />
                        </GeneralModal>
                    )}
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withVideoContext(withUsersContext(withTranslationContext(withRouter(InsuranceUserEditScreen)))));
