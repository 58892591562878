/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { SvgIcon } from './SvgIcon';

interface OwnProps {
    children?: React.ReactNode;
    label?: string;
    callback: (event: React.MouseEvent<HTMLDivElement>) => void;
    checked: boolean;
}

class CheckBox extends Component<OwnProps> {
    icon() {
        const { checked } = this.props;

        if (checked) {
            return <SvgIcon icon="CHECKED" />;
        }
        return <span />;
    }

    render() {
        const { callback, children, label } = this.props;
        return (
            <div className="checkbox" onClick={callback}>
                <button type="button" className="button" aria-label={label}>
                    <div className="check">
                        {this.icon()}
                    </div>

                    <div className="content">
                        {children}
                    </div>
                </button>
            </div>
        );
    }
}

export default CheckBox;
