/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/logo_horizontal.svg';
import { ReactComponent as IconPolyline } from '../../assets/images/icon-polyline.svg';
import { ICON, SvgIcon } from './SvgIcon';
import {
    AuthenticationContext,
    withAuthenticationContext,
} from '../controllers/authentication/AuthenticationContext';
import {
    MessageContext,
    withMessageContext,
} from '../controllers/messages/MessageContext';
import {
    TranslationContext,
    withTranslationContext,
} from '../controllers/translation/TranslationContext';
import ButtonDeprecated from './ButtonDeprecated';
import {
    MESSAGES_RECEIVED_LIST_ROUTE,
    CASUALTIES_LIST_ROUTE,
    USERS_LIST_ROUTE,
    STATISTICS_ROUTE,
    PRE_INSPECTIONS_LIST_ROUTE, INDEX_ROUTE,
    USER_MECHANIC_EDIT_ROUTE,
    USERS_EDIT_ROUTE,
    USER_INSURANCE_EDIT_ROUTE,
    DISPUTES_LIST_ROUTE,
    USER_GLASS_PROVIDER_EDIT_ROUTE,
    BROKEN_GLASS_CONTROL_LIST_ROUTE, DAMAGE_TOOL_ROUTE,
} from '../../constants/routes';
import Can from '../containers/Can';
import { PermissionKey, UserRoles } from '../../constants/authorization';
import { WithRouterProps, withRouter } from '../containers/withRouter';

interface OwnProps extends WithRouterProps, AuthenticationContext, TranslationContext, MessageContext {
    showTabs: boolean;
}

class TopBar extends Component<OwnProps> {
    componentDidMount() {
        const { user } = this.props;

        if (user && user.role !== UserRoles.INSURANCE_USER) {
            const { unreadMessageAndDisputes } = this.props;
            unreadMessageAndDisputes();
        }
    }

    onLogoClick = () => {
        const { navigate } = this.props;
        navigate(INDEX_ROUTE);
    };

    onLogoutClick = () => {
        this.logoutRequest();
    };

    onProfileClick = () => {
        const { user, navigate } = this.props;
        if (user) {
            const { id, role } = user;

            switch (role) {
                case UserRoles.MECHANIC:
                    navigate(`${USER_MECHANIC_EDIT_ROUTE}/${id}`);
                    break;
                case UserRoles.INSURANCE_USER:
                    navigate(`${USER_INSURANCE_EDIT_ROUTE}/${id}`);
                    break;
                case UserRoles.GLASS_PROVIDER:
                    navigate(`${USER_GLASS_PROVIDER_EDIT_ROUTE}/${id}`);
                    break;
                case UserRoles.ADMIN:
                case UserRoles.EXTERNAL_ADMIN:
                case UserRoles.COLLABORATOR:
                    navigate(`${USERS_EDIT_ROUTE}/${role.toLowerCase()}/${id}`);
                    break;
                default:
            }
        }
    };

    logoutRequest = () => {
        const { logout } = this.props;

        logout();
    };

    belongsToRoute = (route: string) => {
        const { location } = this.props;
        const {
            pathname,
        } = location;

        const parts = route.split('/');

        if (parts.length > 1) {
            return pathname.includes(parts[1]);
        }

        return false;
    };

    render() {
        const {
            t,
            user,
            unseenDisputes,
            unseenMessages,
            showTabs,
        } = this.props;
        let role = '';

        if (user) ({ role } = user);

        const width = window.innerWidth;

        return (
            <div className="app-screen__topbar" data-testid="app-topbar">
                <div className="app-screen__topbar__container">
                    <img src={logo} alt="" onClick={this.onLogoClick} />
                    {showTabs && (
                        <React.Fragment>
                            <div className="app-screen__topbar__container__menu">
                                <div className="app-screen__topbar__container__menu__menu-item">
                                    <Can
                                        actions={[PermissionKey.ALL, PermissionKey.VIEW_PRE_INSPECTIONS]}
                                        yes={() => (
                                            <NavLink
                                                to={PRE_INSPECTIONS_LIST_ROUTE}
                                                className={({ isActive }) => (isActive || this.belongsToRoute(PRE_INSPECTIONS_LIST_ROUTE)
                                                    ? 'active'
                                                    : undefined)
                                        }
                                            >
                                                <SvgIcon icon={ICON.CAR} />
                                                {width >= 768 && (<p>{t('topbar.menu.preSurveys')}</p>)}
                                            </NavLink>
                                        )}
                                    />
                                </div>
                                <div className="app-screen__topbar__container__menu__menu-item">
                                    <Can
                                        actions={[PermissionKey.ALL, PermissionKey.VIEW_CASUALTIES]}
                                        yes={() => (
                                            <NavLink
                                                to={CASUALTIES_LIST_ROUTE}
                                                className={({ isActive }) => (isActive || this.belongsToRoute(CASUALTIES_LIST_ROUTE)
                                                    ? 'active'
                                                    : undefined)
                                        }
                                            >
                                                <SvgIcon icon={ICON.PROCESSES} />
                                                {width >= 768 && (<p>{t('topbar.menu.casulties')}</p>)}
                                            </NavLink>
                                        )}
                                    />
                                </div>
                                <div className="app-screen__topbar__container__menu__menu-item">
                                    <Can
                                        actions={[PermissionKey.ALL, PermissionKey.VIEW_BROKEN_GLASS_CONTROLS]}
                                        yes={() => (
                                            <NavLink
                                                to={BROKEN_GLASS_CONTROL_LIST_ROUTE}
                                                className={({ isActive }) => (isActive || this.belongsToRoute(BROKEN_GLASS_CONTROL_LIST_ROUTE)
                                                    ? 'active'
                                                    : undefined)
                                        }
                                                data-testid="broken-glass-menu-item"
                                            >
                                                <SvgIcon icon={ICON.BROKEN_GLASS_CONTROL} />
                                                {width >= 768 && (<p>{t('topbar.menu.brokenGlassControl')}</p>)}
                                            </NavLink>
                                        )}
                                    />
                                </div>
                                <div className="app-screen__topbar__container__menu__menu-item message">
                                    {role !== UserRoles.INSURANCE_USER && (
                                        <Can
                                            actions={[PermissionKey.ALL, PermissionKey.VIEW_MESSAGES]}
                                            yes={() => (
                                                <Fragment>
                                                    <div className="menu-div">
                                                        <NavLink
                                                            to={MESSAGES_RECEIVED_LIST_ROUTE}
                                                            className={({ isActive }) => (isActive || this.belongsToRoute(MESSAGES_RECEIVED_LIST_ROUTE)
                                                                ? 'active'
                                                                : undefined)
                                                    }
                                                        >
                                                            <SvgIcon icon={ICON.MESSAGES} />
                                                            {width >= 768 && (<p>{t('topbar.menu.messages')}</p>)}
                                                        </NavLink>
                                                    </div>
                                                    {unseenMessages > 0 && (
                                                        <div className="number-div">
                                                            {unseenMessages}
                                                        </div>
                                                    )}
                                                </Fragment>
                                            )}
                                        />
                                    )}
                                </div>
                                <div className="app-screen__topbar__container__menu__menu-item message">
                                    <Can
                                        actions={[PermissionKey.ALL, PermissionKey.VIEW_DISPUTES]}
                                        yes={() => (
                                            <Fragment>
                                                <div className="menu-div">
                                                    <NavLink
                                                        to={DISPUTES_LIST_ROUTE}
                                                        className={({ isActive }) => (isActive || this.belongsToRoute(DISPUTES_LIST_ROUTE)
                                                            ? 'active'
                                                            : undefined)
                                                }
                                                    >
                                                        <SvgIcon icon={ICON.DISPUTE} />
                                                        {width >= 768 && (<p>{t('topbar.menu.disputes')}</p>)}
                                                    </NavLink>
                                                </div>
                                                {unseenDisputes > 0 && (
                                                    <div className="number-div">
                                                        {unseenDisputes}
                                                    </div>
                                                )}
                                            </Fragment>
                                        )}
                                    />
                                </div>
                                <div className="app-screen__topbar__container__menu__menu-item">
                                    <Can
                                        actions={[PermissionKey.ALL, PermissionKey.VIEW_USERS]}
                                        yes={() => (
                                            <NavLink
                                                to={USERS_LIST_ROUTE}
                                                className={({ isActive }) => (isActive || this.belongsToRoute(USERS_LIST_ROUTE)
                                                    ? 'active'
                                                    : undefined)
                                        }
                                            >
                                                <SvgIcon icon={ICON.USERS} />
                                                {width >= 768 && (<p>{t('topbar.menu.users')}</p>)}
                                            </NavLink>
                                        )}
                                    />
                                </div>
                                <div className="app-screen__topbar__container__menu__menu-item">
                                    <Can
                                        actions={[PermissionKey.ALL, PermissionKey.VIEW_STATISTICS]}
                                        yes={() => (
                                            <NavLink
                                                to={STATISTICS_ROUTE}
                                                className={({ isActive }) => (isActive || this.belongsToRoute(STATISTICS_ROUTE)
                                                    ? 'active'
                                                    : undefined)
                                        }
                                            >
                                                <SvgIcon icon={ICON.TRENDING_UP} />
                                                {width >= 768 && (<p>{t('topbar.menu.statistics')}</p>)}
                                            </NavLink>
                                        )}
                                    />
                                </div>
                                <div className="app-screen__topbar__container__menu__menu-item">
                                    <Can
                                        actions={[PermissionKey.ALL]}
                                        yes={() => (
                                            <NavLink
                                                to={DAMAGE_TOOL_ROUTE}
                                                className={({ isActive }) => (isActive || this.belongsToRoute(DAMAGE_TOOL_ROUTE)
                                                    ? 'active'
                                                    : undefined)
                                        }
                                            >
                                                <IconPolyline />
                                                {width >= 768 && (<p>{t('topbar.menu.damageTool')}</p>)}
                                            </NavLink>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="app-screen__topbar__container__right-container">
                                <div className="app-screen__topbar__container__right-container__logout">
                                    <p
                                        data-testid="user-profile"
                                        className="user-name"
                                        onClick={this.onProfileClick}
                                    >{user && user.name}
                                    </p>
                                    <ButtonDeprecated
                                        text={t('topbar.logout')}
                                        callback={this.onLogoutClick}
                                        isLink
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
}

export default withMessageContext(withRouter(withAuthenticationContext(withTranslationContext(TopBar))));
