/* eslint-disable @typescript-eslint/ban-types */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICON, SvgIcon } from './SvgIcon';
import ButtonDeprecated from './ButtonDeprecated';
import { ListOrder, ListQueryParamKey } from '../../constants/misc';

interface OwnProps {
    text: string;
    sort?: string;
    order?: string;
    field?: string;
    sortable: boolean;
    headerClass?: string;
    onSortChange?: Function;
    onFilterClick?: Function;
    filterable?: boolean;
}

const TableHeader: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        headerClass,
        sort,
        field,
        order,
        filterable,
        onFilterClick,
        sortable,
        onSortChange,
        text,
    } = props;

    const classHeader = headerClass || '';
    let sortIcon;
    let filterIcon;

    const location = useLocation();
    const navigate = useNavigate();

    if (sort && field && sort === field) {
        if (order === 'desc') {
            sortIcon = <SvgIcon icon={ICON.CHEVRON_DOWN} />;
        } else {
            sortIcon = <SvgIcon icon={ICON.CHEVRON_UP} />;
        }
    }

    if (filterable && onFilterClick) {
        filterIcon = (
            <ButtonDeprecated
                icon={ICON.FILTER}
                iconPosition="right"
                text=""
                callback={(): void => onFilterClick(field)}
            />
        );
    }

    const onClick = (): void => {
        if (!sortable) return;

        if (onSortChange) {
            onSortChange(field);
            return;
        }

        const query = new URLSearchParams(location.search);

        query.set(ListQueryParamKey.Sort, field || '');
        query.set(ListQueryParamKey.Order, ListOrder.Asc);

        if (sort === field && order === ListOrder.Asc) {
            query.set(ListQueryParamKey.Order, ListOrder.Desc);
        } else if (sort === field && order === ListOrder.Desc) {
            query.delete(ListQueryParamKey.Order);
            query.delete(ListQueryParamKey.Sort);
        }

        if (query.toString()) {
            navigate(`${location.pathname}?${query.toString()}`);
        } else {
            navigate(location.pathname);
        }
    };

    return (
        <div data-testid={`table-header-${field}`}>
            {filterIcon}
            <div
                className={sortable ? 'sortable' : classHeader}
                onClick={onClick}
            >
                {text}&nbsp;{sortIcon}
            </div>
        </div>

    );
};

export default TableHeader;
