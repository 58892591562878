/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, FormEvent } from 'react';
import { AxiosError } from 'axios';
import { get } from 'lodash';
import FormTextField from './FormTextField';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import FormSelectField from './FormSelectField';
import ButtonDeprecated from './ButtonDeprecated';
import { ICON, SvgIcon } from './SvgIcon';
import { UserRoles } from '../../constants/authorization';
import { getFormErrors, IFormError, VALIDATIONS } from '../../utils/validation';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';
import logo from '../../assets/images/logo_bgwhite.svg';
import { SelectOption } from '../../constants/misc';
import { ERROR_CODE } from '../../utils/errors';
import { UsersContext, withUsersContext } from '../controllers/users/UsersContext';
import { CreateUserFields } from '../../constants/authentication';

interface OwnProps extends TranslationContext, UsersContext {
    onModalClose: () => void;
    onCreateSuccess: () => void;
}

interface OwnState {
    name: string;
    email: string;
    permissions: string;
    formErrors: any;
}

const initialState: OwnState = {
    name: '',
    email: '',
    permissions: '',
    formErrors: {},
};

const modalOutsideId = 'modal-outside';

class NewUserModal extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    onInputChange = ({ currentTarget: { name, value } }: FormEvent<HTMLInputElement>): void => {
        this.setState((prevState: OwnState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    onClose = (): void => {
        const { onModalClose } = this.props;

        this.setState({ ...initialState });

        onModalClose();
    };

    onFailure = (e: AxiosError): void => {
        const { t } = this.props;

        if (e?.response?.data.errors) {
            const { errorCode } = e.response.data.errors[0];
            return displayNotification(NOTIFICATION_TYPE.ERROR, t(`errors.${ERROR_CODE[errorCode]}`));
        }
        displayNotification(NOTIFICATION_TYPE.ERROR, t('createUser.errorNotif'));
    };

    onSendInvitationClick = (): void => {
        const { email, name, permissions } = this.state;

        if (this.validateFields()) {
            const fields: CreateUserFields = {
                email: String(email).trim(),
                name: String(name).trim(),
                avatar: null,
            };

            const formData = new FormData();
            formData.append('userRequest', new Blob([JSON.stringify(fields)], { type: 'application/json' }));

            this.createUserRequest(permissions, formData);
        }
    };

    handleOutsideClick = (e: any): void => {
        const { target: { id } } = e;

        if (id === modalOutsideId) this.onClose();
    };

    createUserRequest = (userRole: string, fields: FormData): void => {
        const { onCreateSuccess, createUser } = this.props;

        createUser(userRole, fields, () => onCreateSuccess(), this.onFailure);
    };

    validateFields = (): boolean => {
        const { state } = this;

        const errors: IFormError | null = getFormErrors(state, VALIDATIONS.CREATE_USER_FORM);

        this.setState({ formErrors: errors ? { fields: errors } : errors });

        return errors === null;
    };

    render() {
        const { t } = this.props;
        const {
            name,
            email,
            permissions,
            formErrors,
        } = this.state;

        const options: Array<SelectOption> = [];

        options.push({
            value: '',
            label: t('createUser.labels.defaultSelectOption'),
        });

        Object.values(UserRoles).forEach((role) => {
            options.push({
                value: role,
                label: t(`enums.userRoles.${role}`),
            });
        });

        return (
            <div id={modalOutsideId} className="app-screen__modal">
                <div className="app-screen__modal__container">
                    <div className="app-screen__modal__container__box">
                        <SvgIcon callback={this.onClose} icon={ICON.CROSS} />
                        <div className="app-screen__modal__container__box__content">
                            <div className="app-screen__modal__container__box__content__header">
                                <img src={logo} alt="logo" />
                                <p>{t('createUser.title')}</p>
                            </div>
                            <FormTextField
                                containerStyles="field-container"
                                label={t('createUser.labels.name')}
                                name="name"
                                value={name}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.name', null)}
                            />
                            <FormTextField
                                containerStyles="field-container"
                                label={t('createUser.labels.email')}
                                name="email"
                                value={email}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.email', null)}
                            />
                            <FormSelectField
                                containerStyles="field-container"
                                options={options}
                                name="permissions"
                                value={permissions}
                                onChange={this.onInputChange}
                                label={t('createUser.labels.permissions')}
                                errors={get(formErrors, 'fields.permissions', null)}
                            />
                            <ButtonDeprecated
                                text={t('createUser.sendInvitationBtn')}
                                styles="btn--green"
                                callback={this.onSendInvitationClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(withUsersContext(NewUserModal));
