/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { GeneralState } from './types';

export const generalInitialState: GeneralState = {

};

// eslint-disable-next-line func-names
export default function (state = generalInitialState, action: any): GeneralState {
    switch (action.type) {
        default:
            return state;
    }
}
