/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';
import { Store } from 'redux';
import { createMemoryHistory } from 'history';

import { resetAuthenticationActionCreator } from './actions/authentication';
import { LOGIN_ROUTE } from './constants/routes';

const setupRequestInterceptor = (store: Store) => {
    axios.interceptors.request.use(
        (reqConfig) => {
            const { authentication } = store.getState();
            const { token } = authentication;

            const newConfig = reqConfig;

            if (token) {
                newConfig.headers.Authorization = `Bearer ${token}`;
            }

            return newConfig;
        },
        (err) => Promise.reject(err),
    );
};

const setupResponseInterceptor = (store: Store) => {
    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        if (error.response) {
            if (error.response.status === 401) {
                store.dispatch(resetAuthenticationActionCreator());

                const history = createMemoryHistory();
                history.push(LOGIN_ROUTE);
            }
        }
        return Promise.reject(error);
    });
};

export const setupAPI = async (store: Store) => {
    axios.create({ withCredentials: true });
    setupRequestInterceptor(store);
    setupResponseInterceptor(store);
};
