/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export enum AuthenticationAction {
    LOGIN_REQUEST = 'LOGIN_REQUEST',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILURE = 'LOGIN_FAILURE',
    LOGOUT_REQUEST = 'LOGOUT_REQUEST',
    SET_AUTHENTICATION = 'SET_AUTHENTICATION',
    RESET_AUTHENTICATION = 'RESET_AUTHENTICATION',
    SET_TURN_CREDENTIALS = 'SET_TURN_CREDENTIALS',
    SET_TOKEN = 'SET_TOKEN',
    SET_USER = 'SET_USER',
}

export interface LoginRequestAction {
    type: typeof AuthenticationAction.LOGIN_REQUEST;
}

export interface LoginSuccessAction {
    type: typeof AuthenticationAction.LOGIN_SUCCESS;
    payload: object;
}

export interface LoginFailureAction {
    type: typeof AuthenticationAction.LOGIN_FAILURE;
    payload: object;
}

export interface LogoutFailureAction {
    type: typeof AuthenticationAction.LOGOUT_REQUEST;
}

export interface SetAuthenticationAction {
    type: typeof AuthenticationAction.SET_AUTHENTICATION;
    payload: object;
}

export interface ResetAuthenticationAction {
    type: typeof AuthenticationAction.RESET_AUTHENTICATION;
}

export interface SetTurnCredentials {
    type: typeof AuthenticationAction.SET_TURN_CREDENTIALS;
    payload: object;
}

export interface SetToken{
    type: typeof AuthenticationAction.SET_TOKEN;
    payload: object;
}

export interface SetUser{
    type: typeof AuthenticationAction.SET_USER;
    payload: object;
}

export type AuthenticationActionTypes = SetUser | SetToken | SetTurnCredentials | LoginRequestAction | LoginSuccessAction | LoginFailureAction | LogoutFailureAction | SetAuthenticationAction | ResetAuthenticationAction;
