/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';

import { KeyedObject } from '../constants/misc';
import { Language } from '../constants/preferences';

const getString = (data: KeyedObject, key: string, defaultValue: any): any => {
    const keyParts = key.split('.');
    let currentPosition = data;

    for (let i = 0; i < keyParts.length; i++) {
        if (currentPosition) {
            currentPosition = currentPosition[keyParts[i]];
        }
    }

    return currentPosition || defaultValue;
};

const interpolateString = (text: string, params?: KeyedObject): any => {
    if (!params) {
        return text;
    }

    const children = text.split(/({[^}]+})/g)
        .map((child) => {
            const match = /{(.+)}/g.exec(child);
            if (match) {
                const param = params[match[1]];
                return param || String(param);
            }
            return child;
        });

    return children.some((child) => child && typeof child === 'object')
        ? React.createElement('span', null, ...children)
        : children.join('');
};

export const getTranslation = (translations: KeyedObject, language: Language, key: string, params?: KeyedObject): string => {
    let out = key;

    if (translations) {
        const languageStrings = translations[language];

        if (languageStrings) {
            const keyString = getString(languageStrings, key, null);

            if (keyString) {
                out = interpolateString(keyString, params);
            }
        }
    }

    return out;
};
