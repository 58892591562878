/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, Fragment } from 'react';
import { AxiosError } from 'axios';
import { get, debounce } from 'lodash';
import { DEBOUNCED_REQUEST_DELAY_MS, SelectOption } from '../../../constants/misc';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import FormSelectField from '../../elements/FormSelectField';
import FormDatePicker from '../../elements/FormDatePicker';
import ButtonDeprecated from '../../elements/ButtonDeprecated';
import FormTextField from '../../elements/FormTextField';
import FormTextAreaField from '../../elements/FormTextAreaField';
import { getFormErrors, IFormError, VALIDATIONS } from '../../../utils/validation';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import { validateString, setDateTime } from '../../../utils/misc';
import { preInspectionCompleteURL, preInspectionGlassURL } from '../../../services/preInspections';
import { PRE_INSPECTIONS_LIST_ROUTE, PRE_INSPECTIONS_COMPLETE_LIST_ROUTE } from '../../../constants/routes';
import { ICON } from '../../elements/SvgIcon';
import FormSearchableSelectField from '../../elements/FormSearchableSelectField';
import { UsersContext, withUsersContext } from '../../controllers/users/UsersContext';
import { WithRouterProps, withRouter } from '../../containers/withRouter';
import { PreInspectionsContext, withPreInspectionsContext } from '../../controllers/preInspections/PreInspectionsContext';
import { AuthenticationContext, withAuthenticationContext } from '../../controllers/authentication/AuthenticationContext';
import { UserRoles } from '../../../constants/authorization';
import {
    InsuranceCompany, User, UserOptionsResponse, UserRoleParam,
} from '../../../constants/types';

interface OwnProps extends WithRouterProps, TranslationContext, UsersContext, PreInspectionsContext, AuthenticationContext {}

interface OwnState {
    isFetching: boolean;
    insuranceCompaniesOptions: Array<SelectOption>;
    insuranceCompanyId: number | null;
    simulationNumber: number | null;
    simulationDate: string | null;
    insured: string | null;
    insuredContact: string | null;
    insuredEmail: string | null;
    vehicleLicensePlate: string | null;
    vehicleModel: string | null;
    vehicleBrand: string | null;
    insuranceStartDate: string | null;
    insuranceTylaCode: string | null;
    insuranceYearlyAmount: string | null;
    insuranceExtrasAmount: string | null;
    agentAddress: string | null;
    agentName: string | null;
    agentContact: string | null;
    agentEmail: string | null;
    mediatorNumber: string | null;
    observations: string | null;
    isGlassCreate: boolean;
    formErrors: any;
    vehicleColor: string;
    vehicleImported: boolean | null;
    vehicleManufactureDate: string;
    vehicleLicenseDate: string;
    vehicleMileage: number;
    mileageReadout: string;
    inspected: boolean | null;
    inspectionDate: string;
    designatedValue: string;
    glassDamageEstimate: string;
    marketValue: string;
    importedOptions: Array<SelectOption>;
    readOptions: Array<SelectOption>;
    insuranceEurotaxValue: string;
    apiKey: string;
    insurances: Array<User>;
    insuranceSearchValue: string;
}

const initialState: OwnState = {
    isFetching: false,
    insuranceCompaniesOptions: [],
    insuranceCompanyId: null,
    simulationNumber: null,
    simulationDate: '',
    insured: '',
    insuredContact: '',
    insuredEmail: '',
    vehicleLicensePlate: '',
    vehicleModel: '',
    vehicleBrand: '',
    insuranceStartDate: '',
    insuranceTylaCode: '',
    insuranceYearlyAmount: '',
    insuranceExtrasAmount: '',
    agentAddress: '',
    agentName: '',
    agentContact: '',
    agentEmail: '',
    mediatorNumber: '',
    observations: '',
    isGlassCreate: false,
    formErrors: null,
    vehicleColor: '',
    vehicleImported: null,
    vehicleManufactureDate: '',
    vehicleLicenseDate: '',
    vehicleMileage: 0,
    mileageReadout: 'REAL',
    inspected: null,
    inspectionDate: '',
    designatedValue: '',
    glassDamageEstimate: '',
    marketValue: '',
    importedOptions: [],
    readOptions: [],
    insuranceEurotaxValue: '',
    apiKey: '',
    insurances: [],
    insuranceSearchValue: '',
};

class PreInspectionsCreateFormScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    private readonly debouncedRequestInsuranceCompanies: Function;

    private readonly preInspectionNameLabel: string = 'preInspections.form.labels.name';

    constructor(props: OwnProps) {
        super(props);
        this.debouncedRequestInsuranceCompanies = debounce(this.requestInsuranceUsers, DEBOUNCED_REQUEST_DELAY_MS);
    }

    componentDidMount(): void {
        this.prepare();
        const { params, user } = this.props;
        const { glass } = params;

        if (glass) {
            const isPilotTester: boolean = user?.role === UserRoles.PILOT_TESTER;

            this.setState({
                isGlassCreate: !isPilotTester && (glass === 'glass'),
                insuredEmail: isPilotTester ? user?.email || '' : '',
            });
        }
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { state } = this;
        const { name, value } = e.currentTarget;

        if (name === 'vehicleImported' || name === 'inspected') {
            let valueSelected: boolean | null = null;

            if (value === 'no') {
                valueSelected = false;
            }

            if (value === 'yes') {
                valueSelected = true;
            }

            this.setState((prevState) => ({
                ...prevState,
                [name]: valueSelected,
            }));

            if (name === 'inspected' && !valueSelected) {
                this.setState({
                    inspectionDate: '',
                });
            }
            return;
        }

        this.setState({
            ...state,
            [name]: value,
        });
    };

    onInsuranceSearchChange = (name: string, newValue: string): void => {
        this.debouncedRequestInsuranceCompanies(newValue);

        this.setState((prevState) => ({
            ...prevState,
            [name]: newValue,
        }));
    };

    onInsuranceCompanySelect = (name: string, optValue: string): void => {
        const { insurances } = this.state;

        const insurance = insurances.find((k) => k.id === Number(optValue));

        if (insurance && insurance.apiKey) {
            this.setState({
                insuranceCompanyId: insurance?.id || null,
                apiKey: insurance?.apiKey || '',
            });
        }
    };

    onDateChange = (name: string, date: any) => {
        const { state } = this;

        this.setState({
            ...state,
            [name]: date,
        });
    };

    onCancelClick = () => {
        const { navigate } = this.props;
        navigate(-1);
    };

    onCreatePreInspectionSuccess = () => {
        const { t, navigate, user } = this.props;
        displayNotification(NOTIFICATION_TYPE.SUCCESS, t('preInspections.form.saveSuccess'));

        let url: string = PRE_INSPECTIONS_LIST_ROUTE;

        if (user?.role === UserRoles.PILOT_TESTER) url = PRE_INSPECTIONS_COMPLETE_LIST_ROUTE;

        navigate(url);
    };

    onCreatePreInspectionFailure = (error: AxiosError) => {
        const { t } = this.props;

        displayNotification(NOTIFICATION_TYPE.ERROR, t('preInspections.form.saveError'));
        if (error.response) {
            this.handleResponse(error.response.data);
        }
    };

    onSaveClick = () => {
        const { createPreInspection, user } = this.props;
        const { isGlassCreate } = this.state;
        let url = '';
        let fields = null;

        if ((!isGlassCreate && this.validateForm()) || (isGlassCreate && this.validateGlassForm())) {
            const {
                apiKey,
                simulationNumber,
                simulationDate,
                insured,
                insuredContact,
                insuredEmail,
                vehicleLicensePlate,
                vehicleModel,
                vehicleBrand,
                insuranceStartDate,
                insuranceTylaCode,
                insuranceYearlyAmount,
                insuranceExtrasAmount,
                agentAddress,
                agentName,
                agentContact,
                agentEmail,
                mediatorNumber,
                observations,
                vehicleColor,
                vehicleImported,
                vehicleLicenseDate,
                vehicleMileage,
                mileageReadout,
                inspected,
                inspectionDate,
                designatedValue,
                glassDamageEstimate,
                marketValue,
                insuranceEurotaxValue,
                vehicleManufactureDate,
            } = this.state;

            const glassFields = {
                apiKey,
                simulationNumber,
                simulationDate: simulationDate ? setDateTime(simulationDate) : null,
                insured: insured || null,
                insuredContact,
                insuredEmail,
                vehicleLicensePlate: vehicleLicensePlate || null,
                vehicleModel: vehicleModel || null,
                vehicleBrand: vehicleBrand || null,
                insuranceStartDate: setDateTime(insuranceStartDate),
                vehicleManufactureDate: setDateTime(vehicleManufactureDate),
                insuranceTylaCode,
                insuranceYearlyAmount,
                insuranceExtrasAmount,
                agentAddress,
                agentName,
                agentContact: agentContact || null,
                agentEmail: validateString(agentEmail),
                mediatorNumber,
            };

            const preInspectionFields = {
                ...glassFields,
                designatedValue: validateString(designatedValue),
                glassDamageEstimate: validateString(glassDamageEstimate),
                inspected,
                inspectionDate: setDateTime(inspectionDate),
                marketValue: validateString(marketValue),
                mileageReadout,
                observations: validateString(observations),
                vehicleColor: validateString(vehicleColor),
                vehicleImported,
                vehicleLicenseDate: setDateTime(vehicleLicenseDate),
                vehicleMileage: validateString(vehicleMileage),
                insuranceEurotaxValue: validateString(insuranceEurotaxValue),
            };

            this.setFetching(true);

            if (isGlassCreate && user?.role !== UserRoles.PILOT_TESTER) {
                url = preInspectionGlassURL();
                fields = glassFields;
            } else {
                url = preInspectionCompleteURL();
                fields = preInspectionFields;
            }

            createPreInspection(url, fields, this.onCreatePreInspectionSuccess, this.onCreatePreInspectionFailure);

            this.setFetching(false);
        }
    };

    requestInsuranceUsers = async (searchValue: string): Promise<void> => {
        const { getUsersOptions, user } = this.props;
        this.setFetching(true);

        const isPilotTester: boolean = user?.role === UserRoles.PILOT_TESTER;

        const usersOptions: UserOptionsResponse = await getUsersOptions({
            userRole: UserRoleParam.InsuranceUser, _limit: 10, _q: searchValue, active: true,
        });

        let users: InsuranceCompany[] = usersOptions.users as InsuranceCompany[];

        if (!isPilotTester) users = users.filter(({ pilotInsuranceCompany }) => !pilotInsuranceCompany);

        const options: Array<SelectOption> = users.map(({ id, name }) => ({ value: id, label: name }));

        this.setState(({ insuranceCompanyId: prevInsuranceCompanyId, apiKey: prevApiKey }: OwnState) => ({
            isFetching: false,
            insuranceCompaniesOptions: options,
            insurances: users,
            insuranceCompanyId: isPilotTester && users.length > 0 ? users[0].id : prevInsuranceCompanyId,
            apiKey: isPilotTester && users.length > 0 ? (users[0].apiKey || '') : prevApiKey,
        }));
    };

    prepare = () => {
        const { t } = this.props;

        this.setState({
            importedOptions: [
                {
                    value: null,
                    label: t('keywords.none'),
                },
                {
                    value: 'yes',
                    label: t('keywords.yes'),
                },
                {
                    value: 'no',
                    label: t('keywords.no'),
                },
            ],
            readOptions: [
                {
                    value: 'REAL',
                    label: t('keywords.REAL'),
                },
                {
                    value: 'ESTIMATED',
                    label: t('keywords.ESTIMATED'),
                },
            ],
        });

        this.requestInsuranceUsers('');
    };

    validateGlassForm = () => {
        const { state } = this;

        const errors: IFormError | null = getFormErrors(state, VALIDATIONS.PRE_INSPECTION_GLASS_CREATE_FORM);
        this.setState({ formErrors: errors ? { fields: errors } : errors });

        return errors === null;
    };

    validateForm = () => {
        const { state } = this;

        const errors: IFormError | null = getFormErrors(state, VALIDATIONS.PRE_INSPECTION_CREATE_FORM);
        this.setState({ formErrors: errors ? { fields: errors } : errors });

        return errors === null;
    };

    handleResponse = (formErrors: any = null) => {
        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors,
                isFetching: false,
            });
        } else {
            this.setFetching(false);
        }
    };

    setFetching = (isFetching: boolean): void => {
        this.setState({
            isFetching,
        });
    };

    render() {
        const { t, user } = this.props;

        const {
            isFetching,
            insuranceCompaniesOptions,
            insuranceCompanyId,
            simulationNumber,
            simulationDate,
            insured,
            insuredContact,
            insuredEmail,
            vehicleLicensePlate,
            vehicleModel,
            vehicleBrand,
            insuranceStartDate,
            insuranceTylaCode,
            insuranceYearlyAmount,
            insuranceExtrasAmount,
            agentAddress,
            agentName,
            agentContact,
            agentEmail,
            mediatorNumber,
            observations,
            formErrors,
            isGlassCreate,
            vehicleColor,
            vehicleImported,
            vehicleManufactureDate,
            vehicleLicenseDate,
            vehicleMileage,
            mileageReadout,
            inspected,
            inspectionDate,
            designatedValue,
            glassDamageEstimate,
            marketValue,
            importedOptions,
            readOptions,
            insuranceEurotaxValue,
            apiKey,
            insuranceSearchValue,
        } = this.state;

        const isPilotTester: boolean = user?.role === UserRoles.PILOT_TESTER;

        return (
            <div>
                <div className="app-screen__form__form-container form-content create-edit-form">
                    <div className="button-container">
                        <ButtonDeprecated
                            text={t('global.buttons.savePreInspections')}
                            styles="btn--purple"
                            callback={this.onSaveClick}
                            disabled={isFetching}
                            icon={isFetching ? ICON.LOADER : ''}
                            iconPosition="left"
                        />
                        <ButtonDeprecated
                            text={t('global.buttons.reject')}
                            styles="btn--disabled"
                            callback={this.onCancelClick}
                            disabled={isFetching}
                        />
                    </div>
                    {/* Insurance */}
                    <h3>{t('preInspections.form.labels.client')}</h3>
                    <div className="row">
                        <div className="col-sm-4">
                            <FormSearchableSelectField
                                name="insuranceCompanyId"
                                inputName="insuranceSearchValue"
                                value={insuranceCompanyId || ''}
                                searchValue={insuranceSearchValue}
                                onChange={this.onInsuranceCompanySelect}
                                onSearchInputChange={this.onInsuranceSearchChange}
                                options={insuranceCompaniesOptions}
                                isDisabled={isPilotTester}
                                label={t(this.preInspectionNameLabel)}
                                errors={get(formErrors, 'fields.insuranceCompanyId', null)}
                            />
                        </div>
                        <div className="col-sm-4">
                            <FormTextField
                                label={t('preInspections.form.labels.apiKey')}
                                name="apiKey"
                                value={apiKey}
                                disabled
                            />
                        </div>
                        <div className="col-sm-4">
                            <FormTextField
                                label={t('preInspections.form.labels.simulationNumber')}
                                name="simulationNumber"
                                value={simulationNumber}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.simulationNumber', null)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <FormDatePicker
                                name="simulationDate"
                                label={t('preInspections.form.labels.simulationDate')}
                                value={simulationDate ? new Date(simulationDate) : null}
                                onChange={this.onDateChange}
                                errors={get(formErrors, 'fields.simulationDate', null)}
                            />
                        </div>
                    </div>
                    {/* Insured */}
                    <h3>{t('preInspections.form.labels.insured')}</h3>
                    <div className="row">
                        <div className="col-sm-4">
                            <FormTextField
                                label={t(this.preInspectionNameLabel)}
                                name="insured"
                                value={insured}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.insured', null)}
                            />
                        </div>
                        <div className="col-sm-4">
                            <FormTextField
                                label={t('preInspections.form.labels.mobilePhone')}
                                name="insuredContact"
                                value={insuredContact}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.insuredContact', null)}
                            />
                        </div>
                        <div className="col-sm-4">
                            <FormTextField
                                label={t('preInspections.form.labels.email')}
                                name="insuredEmail"
                                value={insuredEmail}
                                disabled={isPilotTester}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.insuredEmail', null)}
                            />
                        </div>
                    </div>
                    {/* Vehicles */}
                    <h3>{t('preInspections.form.labels.vehicle')}</h3>
                    <div className="row">
                        <div className={`${isGlassCreate ? 'col-sm-4' : 'col-sm-3 p-l-0'}`}>
                            <FormTextField
                                label={t('preInspections.form.labels.vehicleLicensePlate')}
                                name="vehicleLicensePlate"
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.vehicleLicensePlate', null)}
                                value={vehicleLicensePlate}
                            />
                        </div>
                        <div className={`${isGlassCreate ? 'col-sm-4' : 'col-sm-3 p-l-0 p-r-0'}`}>
                            <FormTextField
                                label={t('preInspections.form.labels.vehicleBrand')}
                                name="vehicleBrand"
                                value={vehicleBrand}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.vehicleBrand', null)}
                            />
                        </div>
                        <div className={`${isGlassCreate ? 'col-sm-4' : 'col-sm-3 p-r-0'}`}>
                            <FormTextField
                                label={t('preInspections.form.labels.vehicleModel')}
                                name="vehicleModel"
                                value={vehicleModel}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.vehicleModel', null)}
                            />
                        </div>
                        {!isGlassCreate && (
                            <div className="col-sm-3 p-r-0">
                                <FormTextField
                                    label={t('preInspections.form.labels.eurotaxValue')}
                                    name="insuranceEurotaxValue"
                                    value={insuranceEurotaxValue}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.insuranceEurotaxValue', null)}
                                />
                            </div>
                        )}
                    </div>
                    {!isGlassCreate && (
                        <Fragment>
                            <div className="row">
                                <div className="col-sm-3 p-l-0">
                                    <FormTextField
                                        label={t('preInspections.form.labels.vehicleColor')}
                                        name="vehicleColor"
                                        onChange={this.onInputChange}
                                        value={vehicleColor}
                                    />
                                </div>
                                <div className="col-sm-3 p-l-0 p-r-0">
                                    <FormSelectField
                                        options={importedOptions}
                                        name="vehicleImported"
                                        value={vehicleImported === null ? 'none' : (vehicleImported ? 'yes' : 'no')}
                                        onChange={this.onInputChange}
                                        label={t('preInspections.form.labels.vehicleImported')}
                                    />
                                </div>
                                <div className="col-sm-3 p-r-0">
                                    <FormDatePicker
                                        name="vehicleManufactureDate"
                                        label={t('preInspections.form.labels.vehicleManufactureDate')}
                                        value={vehicleManufactureDate ? new Date(vehicleManufactureDate) : null}
                                        onChange={this.onDateChange}
                                        maxDate={new Date()}
                                    />
                                </div>
                                <div className="col-sm-3 p-r-0">
                                    <FormDatePicker
                                        name="vehicleLicenseDate"
                                        label={t('preInspections.form.labels.vehicleRegistrationDate')}
                                        value={vehicleLicenseDate ? new Date(vehicleLicenseDate) : null}
                                        onChange={this.onDateChange}
                                        maxDate={new Date()}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3 p-l-0">
                                    <FormTextField
                                        name="vehicleMileage"
                                        label={t('preInspections.form.labels.km')}
                                        value={vehicleMileage}
                                        errors={get(formErrors, 'fields.vehicleMileage', null)}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                                <div className="col-sm-3 p-l-0 p-r-0">
                                    <FormSelectField
                                        options={readOptions}
                                        name="mileageReadout"
                                        value={mileageReadout}
                                        onChange={this.onInputChange}
                                        label={t('preInspections.form.labels.readOptions')}
                                    />
                                </div>
                                <div className="col-sm-3 p-r-0">
                                    <FormSelectField
                                        options={importedOptions}
                                        name="inspected"
                                        value={inspected === null ? 'none' : (inspected ? 'yes' : 'no')}
                                        onChange={this.onInputChange}
                                        label={t('preInspections.form.labels.vehicleInspection')}
                                    />
                                </div>
                                <div className="col-sm-3 p-r-0">
                                    <FormDatePicker
                                        name="inspectionDate"
                                        label={t('preInspections.form.labels.vehicleInpectionDate')}
                                        value={inspectionDate ? new Date(inspectionDate) : null}
                                        onChange={this.onDateChange}
                                        disabled={!inspected}
                                    />
                                </div>
                            </div>
                            {/* Prices */}
                            <h3>{t('preInspections.form.labels.marketValueTitle')}</h3>
                            <div className="row">
                                <div className="col-sm-4">
                                    <FormTextField
                                        name="marketValue"
                                        label={t('preInspections.form.labels.marketValue')}
                                        value={marketValue}
                                        errors={get(formErrors, 'fields.marketValue', null)}
                                        onChange={this.onInputChange}

                                    />
                                </div>
                                <div className="col-sm-4">
                                    <FormTextField
                                        name="designatedValue"
                                        label={t('preInspections.form.labels.atributedValue')}
                                        value={designatedValue}
                                        errors={get(formErrors, 'fields.designatedValue', null)}
                                        onChange={this.onInputChange}

                                    />
                                </div>
                                <div className="col-sm-4">
                                    <FormTextField
                                        name="glassDamageEstimate"
                                        label={t('preInspections.form.labels.glassDamageCost')}
                                        value={glassDamageEstimate}
                                        errors={get(formErrors, 'fields.glassDamageEstimate', null)}
                                        onChange={this.onInputChange}

                                    />
                                </div>
                            </div>
                        </Fragment>
                    )}
                    {/* Client Insurance */}
                    <h3>{t('preInspections.form.labels.insurance')}</h3>
                    <div className="row">
                        <div className="col-sm-4">
                            <FormDatePicker
                                name="insuranceStartDate"
                                label={t('preInspections.form.labels.initialInsuranceDate')}
                                value={insuranceStartDate ? new Date(insuranceStartDate) : null}
                                onChange={this.onDateChange}
                                disabled={false}
                                errors={get(formErrors, 'fields.insuranceStartDate', null)}
                            />
                        </div>
                        <div className="col-sm-4">
                            <FormTextField
                                label={t('preInspections.form.labels.tylaCode')}
                                name="insuranceTylaCode"
                                value={insuranceTylaCode}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.insuranceTylaCode', null)}
                            />
                        </div>
                        <div className="col-sm-2 p-l-0">
                            <FormTextField
                                label={t('preInspections.form.labels.annualValue')}
                                name="insuranceYearlyAmount"
                                value={insuranceYearlyAmount}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.insuranceYearlyAmount', null)}
                            />
                        </div>
                        <div className="col-sm-2 p-l-0 p-r-0">
                            <FormTextField
                                label={t('preInspections.form.labels.extrasValue')}
                                name="insuranceExtrasAmount"
                                value={insuranceExtrasAmount}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.insuranceExtrasAmount', null)}
                            />
                        </div>
                    </div>
                    {/* AGENT */}
                    <h3>{t('preInspections.form.labels.agent')}</h3>
                    <div className="row">
                        <div className="col-sm-4">
                            <FormTextField
                                label={t(this.preInspectionNameLabel)}
                                name="agentName"
                                value={agentName}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.agentName', null)}
                            />
                        </div>
                        <div className="col-sm-4">
                            <FormTextField
                                label={t('preInspections.form.labels.email')}
                                name="agentEmail"
                                value={agentEmail}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.agentEmail', null)}
                            />
                        </div>
                        <div className="col-sm-2 p-l-0">
                            <FormTextField
                                label={t('preInspections.form.labels.mediatorNumber')}
                                name="mediatorNumber"
                                value={mediatorNumber}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.mediatorNumber', null)}
                            />
                        </div>
                        <div className="col-sm-2 p-l-0 p-r-0">
                            <FormTextField
                                label={t('preInspections.form.labels.mobilePhone')}
                                name="agentContact"
                                value={agentContact}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.agentContact', null)}
                            />
                        </div>
                        <div className={`${isGlassCreate ? 'col-sm-8' : 'col-sm-4'}`}>
                            <FormTextAreaField
                                label={t('preInspections.form.labels.address')}
                                name="agentAddress"
                                value={agentAddress}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.agentAddress', null)}
                            />
                        </div>
                        {!isGlassCreate && (
                            <div className="col-sm-8">
                                <FormTextAreaField
                                    name="observations"
                                    label={t('preInspections.form.labels.observations')}
                                    value={observations}
                                    errors={get(formErrors, 'fields.observations', null)}
                                    onChange={this.onInputChange}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withTranslationContext(withUsersContext(withPreInspectionsContext(withAuthenticationContext(PreInspectionsCreateFormScreen)))));
