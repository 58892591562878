/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { Navigate } from 'react-router-dom';
import { NOT_FOUND_ROUTE } from '../../constants/routes';
import {
    AuthorizationContext,
    withAuthorizationContext,
} from '../controllers/authorization/AuthorizationContext';
import { checkPermission } from '../../utils/authorization';

interface OwnProps extends AuthorizationContext {
    actions: Array<string>;
    children: React.ReactNode;
}

type Props = OwnProps;

const AuthenticatedRoute: React.FC<Props> = (props) => {
    const {
        children,
        actions,
        permissions,
    } = props;

    if (!checkPermission(permissions, actions)) {
        return <Navigate to={NOT_FOUND_ROUTE} />;
    }

    return <>{children}</>;
};

export default withAuthorizationContext(AuthenticatedRoute);
