/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { ICON, SvgIcon } from './SvgIcon';
import { ListQueryParamKey } from '../../constants/misc';
import { WithRouterProps, withRouter } from '../containers/withRouter';

interface OwnProps extends WithRouterProps {
    currentPage: number;
    limit: number;
    totalResults: number;
    onStartChange?: (newPage: number) => void;
}

class TablePaging extends Component<OwnProps> {
    onPreviousClick = (): void => {
        const { currentPage } = this.props;

        this.onPageSelect(currentPage - 1);
    };

    onNextClick = (): void => {
        const { currentPage } = this.props;
        this.onPageSelect(currentPage + 1);
    };

    onPageSelect = (page: number): void => {
        const {
            onStartChange, navigate, location,
        } = this.props;

        if (onStartChange) {
            onStartChange(page);
        } else {
            const query = new URLSearchParams(location.search);
            query.set(ListQueryParamKey.Page, String(page));
            navigate(`${location.pathname}?${query.toString()}`);
        }
    };

    getTotalPages = () => {
        const { limit, totalResults } = this.props;

        return Math.ceil(totalResults / limit);
    };

    renderPrevious = () => {
        const { currentPage } = this.props;

        if (currentPage > 1) {
            return (
                <button type="button" onClick={this.onPreviousClick} data-testid="previous-page-btn">
                    <SvgIcon icon={ICON.LEFT_ARROW} />
                </button>
            );
        }
        return <React.Fragment />;
    };

    renderNext = () => {
        const { currentPage } = this.props;
        const totalPages = this.getTotalPages();

        if (currentPage < totalPages) {
            return (
                <button type="button" onClick={this.onNextClick} data-testid="next-page-btn">
                    <SvgIcon icon={ICON.RIGHT_ARROW} />
                </button>
            );
        }
        return <React.Fragment />;
    };

    renderPages = () => {
        const { currentPage } = this.props;
        const totalPages = this.getTotalPages();

        const showFirst: boolean = (currentPage - 3 >= 1);
        const hasPrevPrevPrev: boolean = (currentPage - 3 > 1);
        const hasPrevPrev: boolean = (currentPage - 2 >= 1);
        const hasPrev: boolean = (currentPage - 1 >= 1);
        const hasNext: boolean = (currentPage + 1 <= totalPages);
        const hasNextNext: boolean = (currentPage + 2 <= totalPages);
        const hasNextNextNext: boolean = (currentPage + 3 < totalPages);
        const showLast: boolean = (totalPages >= currentPage + 3);

        return (
            <div className="pages">
                {showFirst && this.renderButton(1)}
                {hasPrevPrevPrev && <span className="more">...</span>}
                {hasPrevPrev && this.renderButton(currentPage - 2)}
                {hasPrev && this.renderButton(currentPage - 1)}
                <span className="page">{currentPage}</span>
                {hasNext && this.renderButton(currentPage + 1)}
                {hasNextNext && this.renderButton(currentPage + 2)}
                {hasNextNextNext && <span className="more">...</span>}
                {showLast && this.renderButton(totalPages)}
            </div>
        );
    };

    renderButton = (page: number) => {
        return <button type="button" onClick={() => this.onPageSelect(page)}>{page}</button>;
    };

    render() {
        return (
            <div className="paging" data-testid="paging-wrapper">
                {this.renderPrevious()}
                {this.renderPages()}
                {this.renderNext()}
            </div>
        );
    }
}

export default withRouter(TablePaging);
