/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

export const usersURL = (params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/users${queryString}`;
};

export const userCreateURL = (userRole: string): string => {
    return `${API_URL}/users/${userRole}/create`;
};

export const adminUserURL = (id: string | number = ''): string => {
    return `${API_URL}/admins/${id}`;
};

export const externalAdminUserURL = (id: string | number = ''): string => {
    return `${API_URL}/external-admins/${id}`;
};

export const collaboratorsUserURL = (id: string | number = ''): string => {
    return `${API_URL}/collaborators/${id}`;
};

export const usersToggleURL = (id: string | number): string => {
    return `${API_URL}/users/${id}/toggle`;
};

export const insuranceUserURL = (id = ''): string => {
    return `${API_URL}/insurance-companies/${id}`;
};

export const mechanicUserURL = (id = ''): string => {
    return `${API_URL}/mechanics/${id}`;
};

export const insuranceUserResetKeyURL = (id = ''): string => {
    return `${API_URL}/insurance-companies/${id}/reset-key`;
};

export const glassProviderUserURL = (id: string | number = ''): string => {
    return `${API_URL}/glass-provider/${id}`;
};

export const resendInvitesURL = (): string => {
    return `${API_URL}/users/resend-invites`;
};
