/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { PreferencesState } from './types';
import {
    PreferencesAction,
} from '../actions/preferences_types';
import {
    Language,
    languageNameToEnum,
} from '../constants/preferences';

export const preferencesInitialState: PreferencesState = {
    language: Language.PT,
    isOpen: false,
    isFetching: false,
    formErrors: null,
};

// eslint-disable-next-line func-names
export default function (state = preferencesInitialState, action: any): PreferencesState {
    switch (action.type) {
        case PreferencesAction.SET_LANGUAGE:
            return {
                ...state,
                language: action.payload,
            };
        case PreferencesAction.PREFERENCES_REQUEST:
        case PreferencesAction.PREFERENCES_CHANGE_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case PreferencesAction.PREFERENCES_SUCCESS:
        case PreferencesAction.PREFERENCES_CHANGE_SUCCESS:
            return {
                ...state,
                language: languageNameToEnum(action.payload.language),
                isFetching: false,
                formErrors: null,
            };
        case PreferencesAction.PREFERENCES_FAILURE:
        case PreferencesAction.PREFERENCES_CHANGE_FAILURE:
        default:
            return state;
    }
}
