/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';
import { CasualtyController, ConnectedCasualtyController } from './CasualtyController';

export interface CasualtyContext extends Omit<InstanceType<typeof CasualtyController>, keyof React.Component> {}

const CasualtyContextInstance = createContext<CasualtyContext | null>(null);

export const CasualtyContextProvider = CasualtyContextInstance.Provider;
export const CasualtyContextConsumer = CasualtyContextInstance.Consumer;

export const withCasualtyContext = <P, >(
    Component: ComponentType<P>,
): FC<Omit<P, keyof CasualtyContext>> => (props) => (
    <ConnectedCasualtyController>
        <CasualtyContextConsumer>
            { (ctx) => <Component {...props as P} {...ctx} />}
        </CasualtyContextConsumer>
    </ConnectedCasualtyController>
    );
