/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { TabItem } from '../../../constants/misc';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { CasualtiesTabsEnum, CASUALTIES_TABS_ITEM } from '../../../constants/tabs';
import Tabs from '../../elements/Tabs';
import CasualtiesList from './CasualtiesListScreen';
import {
    AuthenticationContext,
    withAuthenticationContext,
} from '../../controllers/authentication/AuthenticationContext';
import {
    CASUALTIES_CANCELED_LIST_ROUTE,
    CASUALTIES_CREATE_ROUTE,
    CASUALTIES_DONE_LIST_ROUTE,
    CASUALTIES_LIST_ROUTE,
    CASUALTIES_ONGOING_LIST_ROUTE,
} from '../../../constants/routes';
import { UserRoles } from '../../../constants/authorization';
import { withRouter, WithRouterProps } from '../../containers/withRouter';

interface OwnProps extends WithRouterProps, TranslationContext, AuthenticationContext {}

interface OwnState {
    tabItemSelected: string;
    tabItems: TabItem[];
}

const initialState: OwnState = {
    tabItemSelected: CasualtiesTabsEnum.ALL,
    tabItems: [],
};

class CasualtiesScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { location, user } = this.props;
        const { pathname } = location;
        const tabItems: TabItem[] = [];
        let role = '';

        if (user) ({ role } = user);

        let tabItemSelected = CasualtiesTabsEnum.ALL;
        switch (pathname) {
            case CASUALTIES_CANCELED_LIST_ROUTE:
                tabItemSelected = CasualtiesTabsEnum.CANCELED;
                break;
            case CASUALTIES_DONE_LIST_ROUTE:
                tabItemSelected = CasualtiesTabsEnum.DONE;
                break;
            case CASUALTIES_ONGOING_LIST_ROUTE:
                tabItemSelected = CasualtiesTabsEnum.ONGOING;
                break;
            default:
        }

        Object.keys(CASUALTIES_TABS_ITEM).forEach((k) => {
            const i = CASUALTIES_TABS_ITEM[Number(k)];

            if (role === UserRoles.INSURANCE_USER) {
                if (Number(k) < 3) {
                    this.pushTabItems(i, tabItems);
                }
            } else {
                this.pushTabItems(i, tabItems);
            }
        });

        this.setState({
            tabItems,
            tabItemSelected,
        }, () => {
            if (role === UserRoles.INSURANCE_USER) {
                this.onTabChange('DONE');
            }
        });
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>): void {
        const { location: oldLocation } = prevProps;
        const { location } = this.props;
        const { pathname: oldPathname } = oldLocation;
        const { pathname } = location;

        if (pathname !== oldPathname) {
            let tabItemSelected = CasualtiesTabsEnum.ALL;
            switch (pathname) {
                case CASUALTIES_CANCELED_LIST_ROUTE:
                    tabItemSelected = CasualtiesTabsEnum.CANCELED;
                    break;
                case CASUALTIES_DONE_LIST_ROUTE:
                    tabItemSelected = CasualtiesTabsEnum.DONE;
                    break;
                case CASUALTIES_ONGOING_LIST_ROUTE:
                    tabItemSelected = CasualtiesTabsEnum.ONGOING;
                    break;
                default:
            }

            this.setState({ tabItemSelected });
        }
    }

    onTabChange = (newTab: string) => {
        const { navigate } = this.props;
        switch (newTab) {
            case CasualtiesTabsEnum.ALL:
                navigate(CASUALTIES_LIST_ROUTE);
                break;
            case CasualtiesTabsEnum.CANCELED:
                navigate(CASUALTIES_CANCELED_LIST_ROUTE);
                break;
            case CasualtiesTabsEnum.DONE:
                navigate(CASUALTIES_DONE_LIST_ROUTE);
                break;
            case CasualtiesTabsEnum.ONGOING:
                navigate(CASUALTIES_ONGOING_LIST_ROUTE);
                break;
            default:
        }

        this.setState({
            tabItemSelected: newTab,
        });
    };

    addCasualtyClick = () => {
        const { navigate } = this.props;
        navigate(CASUALTIES_CREATE_ROUTE);
    };

    pushTabItems = (i: string, tabItems: TabItem[]) => {
        const { t } = this.props;
        const item: TabItem = {
            value: i,
            text: t(`casualties.list.tabItems.${i}`),
        };

        tabItems.push(item);
    };

    render() {
        let userRole = '';
        const {
            t, user,
        } = this.props;
        const { tabItems, tabItemSelected } = this.state;
        if (user) userRole = user.role;

        return (
            <div>
                <Tabs
                    tabsItems={tabItems}
                    onTabChange={this.onTabChange}
                    withSearch
                    addButtonText={t('casualties.addNewCasualtyBtn')}
                    addButtonClickHandler={this.addCasualtyClick}
                    tabSelected={tabItemSelected}
                />
                <CasualtiesList
                    tabItem={tabItemSelected}
                    role={userRole}
                />
            </div>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(withRouter(CasualtiesScreen)));
