/* eslint-disable react/button-has-type */

/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

interface OwnProps {
    children: React.ReactNode;
    isSubmit?: boolean;
    extraClasses?: string;
    isDisabled?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        children,
        isSubmit,
        extraClasses,
        isDisabled,
        onClick,
    } = props;

    let classes = extraClasses;
    if (isDisabled) {
        classes += ' btn--disabled';
    }

    return (
        <button
            type={isSubmit ? 'submit' : 'button'}
            className={`btn ${classes}`}
            disabled={isDisabled}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default Button;
