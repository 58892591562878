/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedMessageController, MessageController } from './MessageController';

export interface MessageContext extends Omit<InstanceType<typeof MessageController>, keyof React.Component> {
    unseenDisputes: number;
    unseenMessages: number;
    isFetching: boolean;
}

const MessageContextInstance = createContext<MessageContext | null>(null);

// Export Provider and Consumer

export const MessageContextProvider = MessageContextInstance.Provider;
export const MessageContextConsumer = MessageContextInstance.Consumer;

export const withMessageContext = <P, >(
    Component: ComponentType<P>,
): FC<Omit<P, keyof MessageContext>> => (props) => (
    <ConnectedMessageController>
        <MessageContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </MessageContextConsumer>
    </ConnectedMessageController>
    );
