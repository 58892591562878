/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { VideoContextProvider } from './VideoContext';
import { AppState } from '../../../reducers/types';
import {
    notifyAnswerCall,
    notifyCallDeclined,
    notifyCallTerminated,
    callWasAnswered,
    notifyDeclineCall,
    hasIncomingCall,
    iceCandidateFound,
    resetVideoFlags,
    resetVideoState,
    sendMessage,
    setOnlineFluxeUsers,
    setOnlineMechanicUsers,
    setOnlineInsuranceUsers,
    resetPendingCandidates,
    resetHasCandidates,
    setOnlineGlassProviderUsers,
} from '../../../actions/video';
import { SocketReceivedMessage, SocketReceivedMessageType } from '../../../constants/socket_types';
import { UserRoles } from '../../../constants/authorization';
import { User } from '../../../constants/types';
import { withAuthenticationContext } from '../authentication/AuthenticationContext';

interface StateProps {
    onlineFluxeUsers: Array<number>;
    onlineMechanicUsers: Array<number>;
    onlineInsuranceUsers: Array<number>;
    onlineGlassProviderUsers: Array<number>;
    incomingCall: boolean;
    signalMessage: SocketReceivedMessage | null;
    incomingCallMessage: SocketReceivedMessage | null;
    wasIceCandidateFound: boolean;
    callAnswered: boolean;
    answerCall: boolean;
    messageToSend: string | null;
    declineCall: boolean;
    callDeclined: boolean;
    hasCandidates: boolean;
    candidates: Array<SocketReceivedMessage>;
}

interface DispatchProps {
    dispatchSetOnlineFluxeUsers: Function;
    dispatchSetOnlineMechanicUsers: Function;
    dispatchSetOnlineInsuranceUsers: Function;
    dispatchSetOnlineGlassProviderUsers: Function;
    notifyHasIncomingCall: Function;
    notifyIceCandidateFound: Function;
    notifyCallWasAnswered: Function;
    notifyWantsToAnswerCall: Function;
    sendSocketMessage: Function;
    dispatchResetPendingCandidates: Function;
    dispatchResetHasCandidates: Function;
    dispatchNotifyDeclineCall: Function;
    notifyCallWasDeclined: Function;
    notifyCallWasTerminated: Function;
    dispatchResetVideoState: Function;
    dispatchResetVideoFlags: Function;
}

interface OwnProps {
    children: React.ReactNode;
}

type Props = OwnProps & StateProps & DispatchProps;

export class VideoController extends Component<Props> {
    onSocketMessage = (signal: SocketReceivedMessage) => {
        const { messageType, users } = signal;

        switch (messageType) {
            case SocketReceivedMessageType.INVALID_CALLEE:
            case SocketReceivedMessageType.INVALID_SDP:
            case SocketReceivedMessageType.INVALID_MESSAGE:
            case SocketReceivedMessageType.CALL_TERMINATED:
                this.onCallTerminated();
                break;
            case SocketReceivedMessageType.ICE_CANDIDATE_FOUND:
                this.onIceCandidateFound(signal);
                break;
            case SocketReceivedMessageType.INCOMING_CALL:
                this.onHasIncomingCall(signal);
                break;
            case SocketReceivedMessageType.INCOMING_CALL_CANCELED:
                this.onCallDeclined();
                break;
            case SocketReceivedMessageType.USER_BUSY:
            case SocketReceivedMessageType.USER_OFFLINE:
                this.onCallDeclined();
                break;
            case SocketReceivedMessageType.CALL_ANSWERED:
                this.onCallWasAnswered(signal);
                break;
            case SocketReceivedMessageType.CALL_DECLINED:
                this.onCallDeclined();
                break;
            case SocketReceivedMessageType.USER_FULL_LIST:
            case SocketReceivedMessageType.USER_LIST:
            case SocketReceivedMessageType.JOINED:
                this.putUsers(users);
                break;
            case SocketReceivedMessageType.LEFT:
                this.removeUsers(users);
                break;
            case SocketReceivedMessageType.INVALID_SESSION:
            case SocketReceivedMessageType.INVALID_TOKEN:
            case SocketReceivedMessageType.UNAUTHORIZED:
                break;
            default:
        }
    };

    onCallWasAnswered = (message: SocketReceivedMessage) => {
        const { notifyCallWasAnswered } = this.props;
        notifyCallWasAnswered(message);
    };

    onHasIncomingCall = (message: SocketReceivedMessage) => {
        const { notifyHasIncomingCall } = this.props;
        notifyHasIncomingCall(message);
    };

    onIceCandidateFound = (message: SocketReceivedMessage) => {
        const { notifyIceCandidateFound } = this.props;
        notifyIceCandidateFound(message);
    };

    onDeclineCall = () => {
        const { dispatchNotifyDeclineCall } = this.props;
        dispatchNotifyDeclineCall();
    };

    onCallDeclined = () => {
        const { notifyCallWasDeclined } = this.props;
        notifyCallWasDeclined();
    };

    onCallTerminated = () => {
        const { notifyCallWasTerminated } = this.props;
        notifyCallWasTerminated();
    };

    removeUsers = (users: Array<User> | null) => {
        if (users) {
            Object.keys(users).forEach((k) => {
                const user = users[Number(k)];

                if (user.role === UserRoles.ADMIN || user.role === UserRoles.EXTERNAL_ADMIN || user.role === UserRoles.COLLABORATOR) {
                    this.removeOnlineFluxeUser(user.id);
                } else if (user.role === UserRoles.MECHANIC) {
                    this.removeOnlineMechanicUser(user.id);
                } else if (user.role === UserRoles.INSURANCE_USER) {
                    this.removeOnlineInsuranceUser(user.id);
                } else if (user.role === UserRoles.GLASS_PROVIDER) {
                    this.removeOnlineGlassProviderUser(user.id);
                }
            });
        }
    };

    putUsers = (users: Array<User> | null) => {
        if (users) {
            Object.keys(users).forEach((k) => {
                const user = users[Number(k)];

                if (user.role === UserRoles.ADMIN || user.role === UserRoles.EXTERNAL_ADMIN || user.role === UserRoles.COLLABORATOR) {
                    this.addOnlineFluxeUser(user.id);
                } else if (user.role === UserRoles.MECHANIC) {
                    this.addOnlineMechanicUser(user.id);
                } else if (user.role === UserRoles.INSURANCE_USER) {
                    this.addOnlineInsuranceUser(user.id);
                } else if (user.role === UserRoles.GLASS_PROVIDER) {
                    this.addOnlineGlassProviderUser(user.id);
                }
            });
        }
    };

    addOnlineFluxeUser = (user: number) => {
        const { onlineFluxeUsers, dispatchSetOnlineFluxeUsers } = this.props;

        if (user && !onlineFluxeUsers.includes(user)) {
            onlineFluxeUsers.push(user);
            dispatchSetOnlineFluxeUsers(onlineFluxeUsers);
        }
    };

    addOnlineMechanicUser = (user: number) => {
        const { onlineMechanicUsers, dispatchSetOnlineMechanicUsers } = this.props;

        if (user && !onlineMechanicUsers.includes(user)) {
            onlineMechanicUsers.push(user);

            dispatchSetOnlineMechanicUsers(onlineMechanicUsers);
        }
    };

    addOnlineInsuranceUser = (user: number) => {
        const { onlineInsuranceUsers, dispatchSetOnlineInsuranceUsers } = this.props;

        if (user && !onlineInsuranceUsers.includes(user)) {
            onlineInsuranceUsers.push(user);

            dispatchSetOnlineInsuranceUsers(onlineInsuranceUsers);
        }
    };

    addOnlineGlassProviderUser = (user: number) => {
        const { onlineGlassProviderUsers, dispatchSetOnlineGlassProviderUsers } = this.props;

        if (user && !onlineGlassProviderUsers.includes(user)) {
            onlineGlassProviderUsers.push(user);

            dispatchSetOnlineGlassProviderUsers(onlineGlassProviderUsers);
        }
    };

    removeOnlineFluxeUser = (user: number) => {
        const { onlineFluxeUsers, dispatchSetOnlineFluxeUsers } = this.props;
        if (user) {
            const i = onlineFluxeUsers.indexOf(user);
            onlineFluxeUsers.splice(i, 1);

            dispatchSetOnlineFluxeUsers(onlineFluxeUsers);
        }
    };

    removeOnlineMechanicUser = (user: number) => {
        const { onlineMechanicUsers, dispatchSetOnlineMechanicUsers } = this.props;
        if (user) {
            const i = onlineMechanicUsers.indexOf(user);
            onlineMechanicUsers.splice(i, 1);
            dispatchSetOnlineMechanicUsers(onlineMechanicUsers);
        }
    };

    removeOnlineInsuranceUser = (user: number) => {
        const { onlineInsuranceUsers } = this.props;
        if (user) {
            const i = onlineInsuranceUsers.indexOf(user);
            onlineInsuranceUsers.splice(i, 1);
            setOnlineInsuranceUsers(onlineInsuranceUsers);
        }
    };

    removeOnlineGlassProviderUser = (user: number): void => {
        const { onlineGlassProviderUsers, dispatchSetOnlineGlassProviderUsers } = this.props;
        if (user) {
            const i = onlineGlassProviderUsers.indexOf(user);
            onlineGlassProviderUsers.splice(i, 1);
            dispatchSetOnlineGlassProviderUsers(onlineGlassProviderUsers);
        }
    };

    notifyAnswerCall = () => {
        const { notifyWantsToAnswerCall } = this.props;

        notifyWantsToAnswerCall();
    };

    updatePendingCandidates = (hasCandidates: boolean, candidates: Array<SocketReceivedMessage>) => {
        const { dispatchResetPendingCandidates, dispatchResetHasCandidates } = this.props;
        dispatchResetPendingCandidates(candidates);
        dispatchResetHasCandidates(hasCandidates);
    };

    sendSocketMessage = (message: string | null) => {
        const { sendSocketMessage } = this.props;
        sendSocketMessage(message);
    };

    render() {
        const {
            children,
            onlineFluxeUsers,
            onlineMechanicUsers,
            onlineInsuranceUsers,
            onlineGlassProviderUsers,
            incomingCall,
            signalMessage,
            wasIceCandidateFound,
            callAnswered,
            answerCall,
            incomingCallMessage,
            messageToSend,
            declineCall,
            callDeclined,
            candidates,
            hasCandidates,
            dispatchResetVideoState,
            dispatchResetVideoFlags,
        } = this.props;

        return (
            <VideoContextProvider value={{
                onlineFluxeUsers,
                onlineMechanicUsers,
                onlineInsuranceUsers,
                onlineGlassProviderUsers,
                incomingCall,
                signalMessage,
                wasIceCandidateFound,
                callAnswered,
                answerCall,
                incomingCallMessage,
                messageToSend,
                declineCall,
                callDeclined,
                hasCandidates,
                candidates,
                updatePendingCandidates: this.updatePendingCandidates,
                sendSocketMessage: this.sendSocketMessage,
                notifyAnswerCall: this.notifyAnswerCall,
                notifyDeclineCall: this.onDeclineCall,
                handleSocketMessage: (signal: SocketReceivedMessage) => this.onSocketMessage(signal),
                resetVideoState: () => dispatchResetVideoState(),
                resetVideoFlags: () => dispatchResetVideoFlags(),
            }}
            >
                {children}
            </VideoContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        onlineFluxeUsers: state.video.onlineFluxeUsers,
        onlineMechanicUsers: state.video.onlineMechanicUsers,
        onlineInsuranceUsers: state.video.onlineInsuranceUsers,
        onlineGlassProviderUsers: state.video.onlineGlassProviderUsers,
        incomingCall: state.video.incomingCall,
        signalMessage: state.video.signalMessage,
        wasIceCandidateFound: state.video.wasIceCandidateFound,
        callAnswered: state.video.callAnswered,
        answerCall: state.video.answerCall,
        incomingCallMessage: state.video.incomingCallMessage,
        messageToSend: state.video.messageToSend,
        declineCall: state.video.declineCall,
        callDeclined: state.video.callDeclined,
        hasCandidates: state.video.hasCandidates,
        candidates: state.video.candidates,
    };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
    dispatchSetOnlineFluxeUsers: (users: Array<number>) => dispatch(setOnlineFluxeUsers(users)),
    dispatchSetOnlineMechanicUsers: (users: Array<number>) => dispatch(setOnlineMechanicUsers(users)),
    dispatchSetOnlineInsuranceUsers: (users: Array<number>) => dispatch(setOnlineInsuranceUsers(users)),
    dispatchSetOnlineGlassProviderUsers: (users: Array<number>) => dispatch(setOnlineGlassProviderUsers(users)),
    notifyHasIncomingCall: (signal: SocketReceivedMessage) => dispatch(hasIncomingCall(signal)),
    notifyIceCandidateFound: (signal: SocketReceivedMessage) => dispatch(iceCandidateFound(signal)),
    notifyCallWasAnswered: (signal: SocketReceivedMessage) => dispatch(callWasAnswered(signal)),
    notifyWantsToAnswerCall: () => dispatch(notifyAnswerCall()),
    sendSocketMessage: (message: string | null) => dispatch(sendMessage(message)),
    dispatchNotifyDeclineCall: () => dispatch(notifyDeclineCall()),
    notifyCallWasDeclined: () => dispatch(notifyCallDeclined()),
    notifyCallWasTerminated: () => dispatch(notifyCallTerminated()),
    dispatchResetVideoState: () => dispatch(resetVideoState()),
    dispatchResetVideoFlags: () => dispatch(resetVideoFlags()),
    dispatchResetPendingCandidates: (candidates: Array<SocketReceivedMessage>) => dispatch(resetPendingCandidates(candidates)),
    dispatchResetHasCandidates: (hasCandidates: boolean) => dispatch(resetHasCandidates(hasCandidates)),
});

export const ConnectedVideoController = withAuthenticationContext(connect(mapStateToProps, mapDispatchToProps)(VideoController));
