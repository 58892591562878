/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

export const LAST_POINT_THRESHOLD = 10;

export const DAMAGE_SELECTED_FILL_COLOR = 'rgba(210, 105, 255, 0.40)';

export const DAMAGE_SELECTED_STROKE_COLOR = '#ffffff';

export const CAR_PART_OUTLINE_STROKE_COLOR = '#00f242';
