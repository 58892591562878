/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import {
    PolygonPoint,
    FileDamage,
    FileDamageSeverityType,
    FileDamageType,
    CarPartOutline,
} from '../constants/types';
import { CAR_PART_OUTLINE_STROKE_COLOR, DAMAGE_SELECTED_FILL_COLOR, DAMAGE_SELECTED_STROKE_COLOR } from '../constants/damages';

export interface DrawDamagePolygonProps {
    canvasCtx: CanvasRenderingContext2D;
    damagesList: FileDamage[];
    outlineList?: CarPartOutline[];
    widthRatio: number;
    heightRatio: number;
    damageSelectedId: number | null;
    opacity: number;
    isDamageTool?: boolean;
}

const getDamageSeverityColor = (damageType: FileDamageType, damageSeverity: FileDamageSeverityType, alpha = 1) => {
    const severityTypeColor = {
        [FileDamageSeverityType.LOW]: `rgba(0, 128, 0, ${alpha})`, // green
        [FileDamageSeverityType.MEDIUM]: `rgba(245, 230, 35, ${alpha})`, // yellow
        [FileDamageSeverityType.HIGH]: `rgba(181, 55, 55, ${alpha})`, // red
    };

    if (damageType === FileDamageType.BODYWORK) {
        return severityTypeColor[damageSeverity];
    }

    return `rgba(27, 154, 245, ${alpha})`;
};

const fillDamageColor = (damage: FileDamage, opacityLevel: number, isDamageTool: boolean): string => {
    const isNew = damage.id < 0;
    const alpha = opacityLevel / 100;

    if (isNew && !isDamageTool) {
        if (damage.damageType === FileDamageType.GLASS) {
            return `rgba(106, 50, 159, ${alpha})`;
        }
        return `rgba(251, 140, 0, ${alpha})`;
    }

    return getDamageSeverityColor(damage.damageType, damage.damageSeverityType, alpha);
};

const strokeDamageColor = (damage: FileDamage, opacityLevel: number, isDamageTool: boolean) => {
    const isNew = damage.id < 0;

    const alpha = opacityLevel / 100;

    const color = damage.damageType === FileDamageType.GLASS ? `rgba(88, 213, 255, ${alpha})` : `rgba(255, 139, 15, ${alpha})`;

    return isNew && !isDamageTool ? color : getDamageSeverityColor(damage.damageType, damage.damageSeverityType, alpha);
};

export const drawNewLineInCanvas = (ctx: CanvasRenderingContext2D, fromX: number, fromY: number, toX: number, toY: number) => {
    ctx.beginPath();
    ctx.moveTo(fromX, fromY);
    ctx.lineTo(toX, toY);
    ctx.strokeStyle = 'rgb(247, 205, 52)';
    ctx.lineWidth = 3;
    ctx.stroke();
    ctx.closePath();
};

export const getDamageIdIfClicked = (damagesList: FileDamage[], imgClickedX: number, imgClickedY: number) => {
    let clickedDamageId: number | null = null;

    for (let i = 0; i < damagesList.length; i++) {
        let counter = 0;
        const damagePolygonPoints = damagesList[i].polygonPoints;

        for (let polygonIdx = 0; polygonIdx < damagePolygonPoints.length; polygonIdx++) {
            const pointA = damagePolygonPoints[polygonIdx];
            const pointB = damagePolygonPoints[(polygonIdx + 1) % damagePolygonPoints.length];

            if (((pointA.y > imgClickedY) !== (pointB.y > imgClickedY)) && (imgClickedX < (pointB.x - pointA.x) * (imgClickedY - pointA.y) / (pointB.y - pointA.y) + pointA.x)) {
                counter++;
            }
        }

        if (counter % 2 === 1) {
            clickedDamageId = damagesList[i].id;
            break;
        }
    }

    return clickedDamageId;
};

export const getTwoPointsDistance = (firstPoint: PolygonPoint, secondPoint: PolygonPoint) => {
    return Math.sqrt(Math.pow(firstPoint.x - secondPoint.x, 2) + Math.pow(firstPoint.y - secondPoint.y, 2));
};

export const drawDamagesPolygonsInCanvas = (props: DrawDamagePolygonProps) => {
    const {
        damagesList,
        outlineList,
        damageSelectedId,
        canvasCtx,
        widthRatio,
        heightRatio,
        opacity,
        isDamageTool = false,
    } = props;

    damagesList.forEach((_, i) => {
        const {
            id: damageId,
            polygonPoints,
        } = damagesList[i];

        const isDamageSelected = damageId === damageSelectedId;

        canvasCtx.beginPath();
        canvasCtx.moveTo(polygonPoints[0].x * widthRatio, polygonPoints[0].y * heightRatio);

        for (let polygonPointIdx = 1; polygonPointIdx < polygonPoints.length; polygonPointIdx++) {
            canvasCtx.lineTo(polygonPoints[polygonPointIdx].x * widthRatio, polygonPoints[polygonPointIdx].y * heightRatio);
        }

        canvasCtx.closePath();

        canvasCtx.fillStyle = isDamageSelected ? DAMAGE_SELECTED_FILL_COLOR : fillDamageColor(damagesList[i], opacity, isDamageTool);
        canvasCtx.fill();

        canvasCtx.strokeStyle = isDamageSelected ? DAMAGE_SELECTED_STROKE_COLOR : strokeDamageColor(damagesList[i], opacity, isDamageTool);

        canvasCtx.lineWidth = isDamageSelected ? 4 : 2;
        canvasCtx.stroke();

        canvasCtx.save();
    });

    outlineList?.forEach((_, i) => {
        const { outline } = outlineList[i];

        if (!outline || !outline.length) return;

        canvasCtx.beginPath();
        canvasCtx.moveTo(outline[0].x * widthRatio, outline[0].y * heightRatio);

        for (let polygonPointIdx = 1; polygonPointIdx < outline.length; polygonPointIdx++) {
            canvasCtx.lineTo(outline[polygonPointIdx].x * widthRatio, outline[polygonPointIdx].y * heightRatio);
        }

        canvasCtx.closePath();

        canvasCtx.strokeStyle = CAR_PART_OUTLINE_STROKE_COLOR;

        canvasCtx.lineWidth = 4;
        canvasCtx.stroke();

        canvasCtx.save();
    });
};
