/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { VideoAction, VideoActionType } from './video_types';
import { SocketReceivedMessage } from '../constants/socket_types';

export const resetVideoState = (): VideoActionType => {
    return {
        type: VideoAction.VIDEO_RESET,
    };
};

export const resetVideoFlags = (): VideoActionType => {
    return {
        type: VideoAction.RESET_FLAGS,
    };
};

export const setOnlineFluxeUsers = (users: Array<number>): VideoActionType => {
    return {
        type: VideoAction.SET_ONLINE_FLUXE_USERS,
        payload: users,
    };
};

export const setOnlineMechanicUsers = (users: Array<number>): VideoActionType => {
    return {
        type: VideoAction.SET_ONLINE_MECHANIC_USERS,
        payload: users,
    };
};

export const setOnlineInsuranceUsers = (users: Array<number>): VideoActionType => {
    return {
        type: VideoAction.SET_ONLINE_INSURANCE_USERS,
        payload: users,
    };
};

export const setOnlineGlassProviderUsers = (users: Array<number>): VideoActionType => {
    return {
        type: VideoAction.SET_ONLINE_GLASS_PROVIDER_USERS,
        payload: users,
    };
};

export const hasIncomingCall = (signal: SocketReceivedMessage): VideoActionType => {
    return {
        type: VideoAction.INCOMING_CALL,
        payload: signal,
    };
};

export const iceCandidateFound = (signal: SocketReceivedMessage): VideoActionType => {
    return {
        type: VideoAction.ICE_CANDIDATE_FOUND,
        payload: signal,
    };
};

export const callWasAnswered = (signal: SocketReceivedMessage): VideoActionType => {
    return {
        type: VideoAction.CALL_ANSWERED,
        payload: signal,
    };
};

export const notifyAnswerCall = (): VideoActionType => {
    // is gonna notify that user wants to answer call
    return {
        type: VideoAction.ANSWER_CALL,
    };
};

export const sendMessage = (message: string | null): VideoActionType => {
    return {
        type: VideoAction.SEND_MESSAGE,
        payload: message,
    };
};

export const notifyDeclineCall = (): VideoActionType => {
    // is gonna notify that call was declined
    return {
        type: VideoAction.DECLINE_CALL,
    };
};

export const notifyCallDeclined = (): VideoActionType => {
    // is gonna notify that call was declined
    return {
        type: VideoAction.CALL_DECLINED,
    };
};

export const notifyCallTerminated = (): VideoActionType => {
    // is gonna notify that call was terminated
    return {
        type: VideoAction.CALL_TERMINATED,
    };
};

export const resetPendingCandidates = (candidates: Array<SocketReceivedMessage>) => {
    return {
        type: VideoAction.RESET_PENDING_CANDIDATES,
        payload: candidates,
    };
};

export const resetHasCandidates = (hasCandidates: boolean) => {
    return {
        type: VideoAction.RESET_HAS_CANDIDATES,
        payload: hasCandidates,
    };
};
