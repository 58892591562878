/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { TabItem } from '../../../constants/misc';
import { MESSAGES_TABS_ITEM, MessagesTabsEnum } from '../../../constants/tabs';
import Tabs from '../../elements/Tabs';
import MessagesListScreen from './MessagesListScreen';
import {
    MESSAGES_RECEIVED_LIST_ROUTE,
    MESSAGES_SENT_LIST_ROUTE,
    NOT_FOUND_ROUTE,
} from '../../../constants/routes';
import {
    AuthenticationContext,
    withAuthenticationContext,
} from '../../controllers/authentication/AuthenticationContext';
import { UserRoles } from '../../../constants/authorization';
import withQueryParams, { WithQueryParamsProps } from '../../hocs/withQueryParams';
import { withRouter, WithRouterProps } from '../../containers/withRouter';

interface OwnProps extends WithRouterProps, TranslationContext,
    AuthenticationContext, WithQueryParamsProps {}

interface OwnState {
    tabItemSelected?: string;
    tabItems: TabItem[];
}

const initialState: OwnState = {
    tabItemSelected: MessagesTabsEnum.RECEIVED,
    tabItems: [],
};

class MessagesScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const {
            user, navigate,
        } = this.props;
        const role = user ? user.role : '';

        if (role === UserRoles.INSURANCE_USER) {
            navigate(NOT_FOUND_ROUTE);
            return;
        }
        this.prepare();
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>): void {
        const { location: oldLocation } = prevProps;
        const { location } = this.props;
        const { pathname: oldPathname } = oldLocation;
        const { pathname } = location;

        if (pathname !== oldPathname) {
            let tabItemSelected = MessagesTabsEnum.RECEIVED;
            if (pathname === MESSAGES_SENT_LIST_ROUTE) {
                tabItemSelected = MessagesTabsEnum.SENT;
            }

            this.setState({ tabItemSelected });
        }
    }

    onTabChange = (newTab: string) => {
        const { navigate } = this.props;
        switch (newTab) {
            case MessagesTabsEnum.RECEIVED:
                navigate(MESSAGES_RECEIVED_LIST_ROUTE);
                break;
            case MessagesTabsEnum.SENT:
                navigate(MESSAGES_SENT_LIST_ROUTE);
                break;
            default:
        }

        this.setState({
            tabItemSelected: newTab,
        });
    };

    prepare = async () => {
        const { t, location } = this.props;
        const { pathname } = location;
        const tabItems: TabItem[] = [];

        let tabItemSelected = MessagesTabsEnum.RECEIVED;
        if (pathname === MESSAGES_SENT_LIST_ROUTE) {
            tabItemSelected = MessagesTabsEnum.SENT;
        }

        Object.keys(MESSAGES_TABS_ITEM).forEach((k) => {
            const i = MESSAGES_TABS_ITEM[Number(k)];

            const item: TabItem = {
                value: i,
                text: t(`messages.list.tabItems.${i}`),
            };

            tabItems.push(item);
        });

        this.setState({
            tabItems,
            tabItemSelected,
        });
    };

    render() {
        const { tabItems, tabItemSelected } = this.state;

        return (
            <div>
                <Tabs
                    tabsItems={tabItems}
                    onTabChange={this.onTabChange}
                    withSearch
                    tabSelected={tabItemSelected}
                />
                <MessagesListScreen
                    tabItem={tabItemSelected}
                />
            </div>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(withQueryParams(withRouter(MessagesScreen))));
