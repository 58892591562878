/* eslint-disable @typescript-eslint/ban-types */

/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import { get, debounce } from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { PermissionKey } from '../../../constants/authorization';
import { DEBOUNCED_REQUEST_DELAY_MS, SelectOption } from '../../../constants/misc';
import { USER_GLASS_PROVIDER_EDIT_ROUTE, BROKEN_GLASS_CONTROL_LIST_ROUTE } from '../../../constants/routes';
import {
    BrokenGlassControlRequest,
    GlassProvider,
    User,
    UserRoleParam,
} from '../../../constants/types';
import { checkPermission } from '../../../utils/authorization';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import { getFormErrors, IFormError, VALIDATIONS } from '../../../utils/validation';
import { AuthorizationContext, withAuthorizationContext } from '../../controllers/authorization/AuthorizationContext';
import { BrokenGlassControlContext, withBrokenGlassControlContext } from '../../controllers/brokenGlass/BrokenGlassControlContext';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import ButtonDeprecated from '../../elements/ButtonDeprecated';
import FormDatePicker from '../../elements/FormDatePicker';
import FormSelectField from '../../elements/FormSelectField';
import FormTextField from '../../elements/FormTextField';
import { ICON } from '../../elements/SvgIcon';
import FormSearchableSelectField from '../../elements/FormSearchableSelectField';
import { UsersContext, withUsersContext } from '../../controllers/users/UsersContext';
import { WithRouterProps, withRouter } from '../../containers/withRouter';

interface OwnProps extends WithRouterProps, TranslationContext, AuthorizationContext, BrokenGlassControlContext, UsersContext {}

interface OwnState {
    insuranceCompanyId: number | null;
    apiKey: string;
    insurancePolicyNumber: string;
    glassProviderId: string | number;
    insured: string;
    insuredContact: string;
    insuredEmail: string;
    vehicleLicensePlate: string;
    vehicleBrand: string;
    vehicleModel: string;
    insuranceSearchValue: string;
    glassProviderSearchValue: string;
    controlDate: Date;
    advancedDriverAssistanceSystem: boolean | null;
    costAmount: string;
    isFetching: boolean;
    formErrors: {} | null;
    insurances: Array<User>;
    glassProviders: Array<GlassProvider>;
    insuranceCompaniesOptions: Array<SelectOption>;
    glassProvidersOptions: Array<SelectOption>;
    adasOptions: Array<SelectOption>;
}

const initialState: OwnState = {
    insuranceCompanyId: null,
    apiKey: '',
    insurancePolicyNumber: '',
    glassProviderId: '',
    insured: '',
    insuredContact: '',
    insuredEmail: '',
    vehicleLicensePlate: '',
    vehicleBrand: '',
    vehicleModel: '',
    controlDate: new Date(),
    advancedDriverAssistanceSystem: null,
    costAmount: '',
    isFetching: false,
    formErrors: null,
    insurances: [],
    glassProviders: [],
    insuranceCompaniesOptions: [],
    glassProvidersOptions: [],
    adasOptions: [],
    insuranceSearchValue: '',
    glassProviderSearchValue: '',
};

class BrokenGlassControlCreateFormScreen extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    private readonly debouncedRequestInsuranceCompanies: Function;

    private readonly debouncedRequestGlassProviders: Function;

    constructor(props: OwnProps) {
        super(props);

        this.debouncedRequestInsuranceCompanies = debounce(this.requestInsuranceCompanies, DEBOUNCED_REQUEST_DELAY_MS);
        this.debouncedRequestGlassProviders = debounce(this.requestGlassProviders, DEBOUNCED_REQUEST_DELAY_MS);
    }

    componentDidMount(): void {
        this.prepare();
    }

    onSuccess = (): void => {
        const { t, navigate } = this.props;

        displayNotification(NOTIFICATION_TYPE.SUCCESS, t('brokenGlassControl.form.createSuccessControlNotif'));
        navigate(BROKEN_GLASS_CONTROL_LIST_ROUTE);
    };

    onFailure = (): void => {
        const { t } = this.props;

        displayNotification(NOTIFICATION_TYPE.ERROR, t('brokenGlassControl.form.createErrorControlNotif'));
        this.setState({
            isFetching: false,
        });
    };

    onSaveClick = (): void => {
        if (this.validateForm()) {
            const { createBrokenGlassControls } = this.props;
            const {
                apiKey, insurancePolicyNumber, controlDate, insured, insuredContact, insuredEmail, vehicleLicensePlate, vehicleBrand, vehicleModel, glassProviderId, costAmount, advancedDriverAssistanceSystem,
            } = this.state;

            const requestBody: BrokenGlassControlRequest = {
                apiKey,
                insurancePolicyNumber: insurancePolicyNumber || null,
                controlDate: moment(controlDate || 0).format('YYYY-MM-DD'),
                insured: insured || null,
                insuredContact: insuredContact || null,
                insuredEmail: insuredEmail || null,
                vehicleLicensePlate: vehicleLicensePlate || null,
                vehicleBrand: vehicleBrand || null,
                vehicleModel: vehicleModel || null,
                advancedDriverAssistanceSystem: !!advancedDriverAssistanceSystem,
                glassProviderId: Number(glassProviderId),
                costAmount: costAmount ? Number(costAmount) : null,
            };

            this.setState({
                isFetching: true,
            });

            createBrokenGlassControls(this.onSuccess, this.onFailure, requestBody);
        }
    };

    onCancelClick = (): void => {
        const { navigate } = this.props;

        navigate(BROKEN_GLASS_CONTROL_LIST_ROUTE);
    };

    onInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
        const { name: inputName, value: inputValue } = e.currentTarget;

        this.setState((prevState) => ({
            ...prevState,
            [inputName]: inputValue,
        }));
    };

    onDateChange = (name: string, date: string): void => {
        const { state } = this;

        this.setState({
            ...state,
            [name]: date,
        });
    };

    onSearchInputChange = (name: string, newValue: string): void => {
        if (name === 'insuranceSearchValue') {
            this.debouncedRequestInsuranceCompanies(newValue);
        }

        if (name === 'glassProviderSearchValue') {
            this.debouncedRequestGlassProviders(newValue);
        }

        this.setState((prevState) => ({
            ...prevState,
            [name]: newValue,
        }));
    };

    onInsuranceCompanySelect = (name: string, optValue: string): void => {
        const { insurances } = this.state;

        const insuranceCompany = insurances.find((k) => k.id === Number(optValue));

        this.setState({
            insuranceCompanyId: insuranceCompany ? insuranceCompany.id : null,
            apiKey: insuranceCompany && insuranceCompany.apiKey ? insuranceCompany.apiKey : '',
        });
    };

    onGlassProviderChange = (name: string, optValue: string) => {
        const { glassProviders } = this.state;

        const glassProvider = glassProviders.find((k) => k.id === Number(optValue));

        this.setState({
            glassProviderId: glassProvider ? glassProvider.id : '',
        });
    };

    onGlassProviderSearchSuccess = (data: GlassProvider[]) => {
        const glassProvidersOptions: Array<SelectOption> = [];

        if (data.length) {
            glassProvidersOptions.push({
                value: '',
                label: '',
            });

            Object.keys(data).forEach((key) => {
                const provider = data[Number(key)];

                glassProvidersOptions.push({
                    value: provider.id,
                    label: provider.name,
                });
            });
        }

        this.setState({
            glassProviders: data,
            glassProvidersOptions,
        });
    };

    onGlassProviderSearchFailure = () => {
        this.setState({
            glassProviders: [],
            glassProvidersOptions: [],
        });
    };

    validateForm = (): boolean => {
        const { state } = this;

        let errors: IFormError | null = getFormErrors(state, VALIDATIONS.BROKEN_GLASS_CONTROL_CREATE_FORM);

        if (errors && Object.keys(errors).length === 0) {
            errors = null;
        }

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    requestInsuranceCompanies = async (insuranceSearch: string): Promise<void> => {
        const { getUsersOptions } = this.props;

        try {
            const { options, users } = await getUsersOptions({
                userRole: UserRoleParam.InsuranceUser, _limit: 10, _q: insuranceSearch, active: true,
            });

            this.setState({
                insurances: users,
                insuranceCompaniesOptions: options,
            });
        } catch {
            this.setState({
                insurances: [],
                insuranceCompaniesOptions: [],
            });
        }
    };

    requestGlassProviders = (glassProviderSearch: string) => {
        const { glassProvidersRequest } = this.props;

        glassProvidersRequest(glassProviderSearch, this.onGlassProviderSearchSuccess, this.onGlassProviderSearchFailure);
    };

    async prepare(): Promise<void> {
        const { t } = this.props;
        const { isFetching } = this.state;

        if (isFetching) return;

        const adasOptions: Array<SelectOption> = [
            { value: '', label: '' },
            { value: 'true', label: t('keywords.yes') },
            { value: 'false', label: t('keywords.no') },
        ];

        this.setState({
            isFetching: true,
            adasOptions,
        });
        await this.requestInsuranceCompanies('');
        this.requestGlassProviders('');

        this.setState({
            isFetching: false,
        });
    }

    render(): React.ReactNode {
        const { t, permissions } = this.props;
        const {
            isFetching,
            apiKey,
            formErrors,
            insurancePolicyNumber,
            controlDate,
            insured,
            insuredContact,
            insuredEmail,
            vehicleLicensePlate,
            vehicleBrand,
            vehicleModel,
            costAmount,
            insuranceCompaniesOptions,
            glassProvidersOptions,
            insuranceCompanyId,
            adasOptions,
            advancedDriverAssistanceSystem,
            glassProviderId,
            insuranceSearchValue,
            glassProviderSearchValue,
        } = this.state;

        let glassProviderToRoute: string | null = null;
        if (glassProviderId && checkPermission(permissions, [PermissionKey.ALL])) {
            glassProviderToRoute = `${USER_GLASS_PROVIDER_EDIT_ROUTE}/${glassProviderId}`;
        }

        return (
            <div>
                <div className="app-screen__form__form-container form-content create-edit-form" data-testid="broken-glass-control-create-form">
                    <div className="button-container" data-testid="button-container">
                        <ButtonDeprecated
                            text={t('global.buttons.saveBrokenGlassControl')}
                            styles="btn--purple"
                            callback={this.onSaveClick}
                            disabled={isFetching}
                            icon={isFetching ? ICON.LOADER : ''}
                            iconPosition="left"
                        />
                        <ButtonDeprecated
                            text={t('global.buttons.reject')}
                            styles="btn--disabled"
                            callback={this.onCancelClick}
                            disabled={isFetching}
                        />
                    </div>
                    {/* Insurance Company */}
                    <h3>{t('brokenGlassControl.form.labels.client')}</h3>
                    <div className="row">
                        <div className="col-sm-4">
                            <FormSearchableSelectField
                                name="insuranceCompany"
                                inputName="insuranceSearchValue"
                                value={insuranceCompanyId || ''}
                                searchValue={insuranceSearchValue}
                                onChange={this.onInsuranceCompanySelect}
                                onSearchInputChange={this.onSearchInputChange}
                                options={insuranceCompaniesOptions}
                                label={t('brokenGlassControl.form.labels.insurance')}
                                errors={get(formErrors, 'fields.insuranceCompanyId', null)}
                            />
                        </div>
                        <div className="col-sm-4">
                            <FormTextField
                                label={t('brokenGlassControl.form.labels.apiKey')}
                                name="apiKey"
                                value={apiKey}
                                disabled
                            />
                        </div>
                        <div className="col-sm-4">
                            <FormTextField
                                label={t('brokenGlassControl.form.labels.insurancePolicyNumber')}
                                name="insurancePolicyNumber"
                                value={insurancePolicyNumber}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.insurancePolicyNumber', null)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4" data-testid="controlDate">
                            <FormDatePicker
                                name="controlDate"
                                label={t('brokenGlassControl.form.labels.controlDate')}
                                value={controlDate}
                                onChange={this.onDateChange}
                                errors={get(formErrors, 'fields.controlDate', null)}
                            />
                        </div>
                    </div>
                    {/* Insured */}
                    <h3>{t('brokenGlassControl.form.labels.insured')}</h3>
                    <div className="row">
                        <div className="col-sm-4">
                            <FormTextField
                                label={t('brokenGlassControl.form.labels.insuredName')}
                                name="insured"
                                value={insured}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.insured', null)}
                            />
                        </div>
                        <div className="col-sm-4">
                            <FormTextField
                                label={t('brokenGlassControl.form.labels.insuredContact')}
                                name="insuredContact"
                                value={insuredContact}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.insuredContact', null)}
                            />
                        </div>
                        <div className="col-sm-4">
                            <FormTextField
                                label={t('brokenGlassControl.form.labels.insuredEmail')}
                                name="insuredEmail"
                                value={insuredEmail}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.insuredEmail', null)}
                            />
                        </div>
                    </div>
                    {/* Vehicle */}
                    <h3>{t('brokenGlassControl.form.labels.vehicle')}</h3>
                    <div className="row">
                        <div className="col-sm-3 p-l-0">
                            <FormTextField
                                label={t('brokenGlassControl.form.labels.vehicleLicensePlate')}
                                name="vehicleLicensePlate"
                                value={vehicleLicensePlate.toString()}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.vehicleLicensePlate', null)}
                            />
                        </div>
                        <div className="col-sm-3 col-sm-3 p-l-0">
                            <FormTextField
                                label={t('brokenGlassControl.form.labels.vehicleBrand')}
                                name="vehicleBrand"
                                value={vehicleBrand}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.vehicleBrand', null)}
                            />
                        </div>
                        <div className="col-sm-3 col-sm-3 p-l-0">
                            <FormTextField
                                label={t('brokenGlassControl.form.labels.vehicleModel')}
                                name="vehicleModel"
                                value={vehicleModel}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.vehicleModel', null)}
                            />
                        </div>
                        <div className="col-sm-3 col-sm-3 p-l-0 p-r-0">
                            <FormSelectField
                                options={adasOptions}
                                name="advancedDriverAssistanceSystem"
                                value={advancedDriverAssistanceSystem ? advancedDriverAssistanceSystem.toString() : ''}
                                onChange={this.onInputChange}
                                label={t('brokenGlassControl.form.labels.ADAS')}
                                labelTitle={t('brokenGlassControl.form.labels.advancedDriverAssistanceSystem')}
                                errors={get(formErrors, 'fields.advancedDriverAssistanceSystem', null)}
                            />
                        </div>
                    </div>
                    {/* Glass Provider */}
                    <h3>{t('brokenGlassControl.form.labels.glassProvider')}</h3>
                    <div className="row">
                        <div className="col-sm-4">
                            <FormSearchableSelectField
                                name="glassProvider"
                                inputName="glassProviderSearchValue"
                                value={glassProviderId || ''}
                                searchValue={glassProviderSearchValue}
                                onChange={this.onGlassProviderChange}
                                onSearchInputChange={this.onSearchInputChange}
                                options={glassProvidersOptions}
                                label={t('brokenGlassControl.form.labels.glassProviderName')}
                                errors={get(formErrors, 'fields.glassProviderId', null)}
                            />
                        </div>
                        {
                            glassProviderToRoute && glassProviderId && (
                                <div className="col-sm-2">
                                    <div className="link-container" data-testid="glassProviderDetails">
                                        <Link to={glassProviderToRoute}>
                                            <p>{t('brokenGlassControl.form.labels.glassProviderDetails')}</p>
                                        </Link>
                                    </div>
                                </div>
                            )
                        }
                        <div className="col-sm-3 p-l-0">
                            <FormTextField
                                label={t('brokenGlassControl.form.labels.costAmount')}
                                name="costAmount"
                                value={costAmount}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.costAmount', null)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withBrokenGlassControlContext(withTranslationContext(withAuthorizationContext(withRouter(withUsersContext(BrokenGlassControlCreateFormScreen)))));
