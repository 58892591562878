/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { Navigate } from 'react-router-dom';
import {
    AuthenticationContext,
    withAuthenticationContext,
} from '../controllers/authentication/AuthenticationContext';
import { LOGIN_ROUTE } from '../../constants/routes';

interface OwnProps extends AuthenticationContext {
    children: React.ReactNode;
}

type Props = OwnProps;

const AuthenticatedRoute: React.FC<Props> = (props) => {
    const {
        children,
        isAuthenticated,
    } = props;

    if (!isAuthenticated) {
        return <Navigate to={LOGIN_ROUTE} />;
    }

    return <>{children}</>;
};

export default withAuthenticationContext(AuthenticatedRoute);
