/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { AxiosError } from 'axios';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import Loader from '../../elements/Loader';
import { KeyedObject, TabItem } from '../../../constants/misc';
import { CasualtyFormTabsEnum, CASUALTY_FORM_TABS_ITEM, CASUALTY_FORM_TABS_ITEM_INSURANCE } from '../../../constants/tabs';
import Tabs from '../../elements/Tabs';
import CasualtyRequestTab from '../../elements/CasualtyRequestTab';
import {
    casualtyFinishURL,
    casualtyReopenURL,
    casualtyCancelURL,
    casualtyRestartURL,
    casualtyDocumentReportURL,
} from '../../../services/casualties';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import {
    CASUALTIES_DETAILS_ROUTE,
    CASUALTIES_EDIT_ROUTE,
    USER_INSURANCE_DETAILS_ROUTE,
    USER_INSURANCE_EDIT_ROUTE,
    USER_MECHANIC_DETAILS_ROUTE,
    USER_MECHANIC_EDIT_ROUTE,
} from '../../../constants/routes';
import ButtonDeprecated from '../../elements/ButtonDeprecated';
import { CasualtyRequest, CasualtyResponse, CasualtyStatus } from '../../../constants/types';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import { getFormErrors, IFormError, VALIDATIONS } from '../../../utils/validation';
import CasualtyBudgetTab from '../../elements/CasualtyBudgetTab';
import CasualtyDocumentsTab from '../../elements/CasualtyDocumentsTab';
import CasualtyMessagesTab from '../../elements/CasualtyMessagesTab';
import { AuthenticationContext, withAuthenticationContext } from '../../controllers/authentication/AuthenticationContext';
import { PermissionKey, UserRoles } from '../../../constants/authorization';
import MessageModal from '../../elements/MessageModal';
import { AuthorizationContext, withAuthorizationContext } from '../../controllers/authorization/AuthorizationContext';
import { checkPermission } from '../../../utils/authorization';
import { VideoContextInterface, withVideoContext } from '../../controllers/video/VideoContext';
import VideoComponent from '../../elements/VideoComponent';
import { SocketReceivedMessage, SocketReceivedMessageType } from '../../../constants/socket_types';
import InformativeNoteModal from '../../elements/InformativeNoteModal';
import displayConfirm from '../../elements/displayConfirm';
import { withRouter, WithRouterProps } from '../../containers/withRouter';
import { CasualtyContext, withCasualtyContext } from '../../controllers/casualites/CasualtyContext';

interface OwnProps extends WithRouterProps, TranslationContext, AuthenticationContext, AuthorizationContext, VideoContextInterface, CasualtyContext {}

interface OwnState {
    preparing: boolean;
    tabItemSelected?: string;
    tabItems: TabItem[];
    formErrors: any;
    id: string;
    isEdit: boolean;
    casualtyRequest: CasualtyRequest;
    isFetching: boolean;
    documentCount: number;
    creatingNewMessage: boolean;
    messagesCount: number;
    viewingDetails: boolean;
    isOnVideoCall: boolean;
    isMakingCall: boolean;
    isUserOnline: boolean;
    hasModifications: boolean;
    pendingCandidates: Array<SocketReceivedMessage>;
    isVideoBig: boolean;
    creatingInformativeNote: boolean;
    hasCreateResume: boolean;
    hasNewInformativeNote: boolean;
    totalDocs: number;
}

let AUX_COUNT = 0;

class CasualtyFormScreen extends Component<OwnProps, OwnState> {
    private readonly acceptButton: string = 'global.buttons.accept';

    private readonly hasChangesError: string = 'errors.modificationsWasNotSaved';

    private readonly rejectButton: string = 'global.buttons.reject';

    constructor(props: OwnProps) {
        super(props);

        const casualtyRequest: CasualtyRequest = {
            id: null,
            status: '',
            uid: '',
            casualtyDate: '',
            compensationType: '',
            deductibleValue: null,
            expertiseDate: '',
            expertiseNumber: '',
            expertiseObservations: '',
            expertiseSituation: '',
            insuranceCompanyId: null,
            insurancePolicyNumber: '',
            insuredValue: null,
            mechanicName: '',
            vehicleBrand: '',
            vehicleLicensePlate: '',
            vehicleModel: '',
            vehicleVersion: '',
            vehicleOwner: '',
            vehicleOwnerContact: '',
            insuranceCompanyName: '',
            insuranceProcessNumber: '',
            mechanicId: null,
            creatorId: null,
            vin: '',
            vehicleInfo: null,
            apiKey: '',
        };

        this.state = {
            id: '',
            preparing: false,
            formErrors: {},
            tabItemSelected: CasualtyFormTabsEnum.REQUEST,
            tabItems: [],
            isEdit: false,
            isFetching: false,
            documentCount: 0,
            messagesCount: 0,
            creatingNewMessage: false,
            casualtyRequest,
            viewingDetails: false,
            isOnVideoCall: false,
            isMakingCall: false,
            isUserOnline: false,
            hasModifications: false,
            isVideoBig: false,
            pendingCandidates: [],
            creatingInformativeNote: false,
            hasCreateResume: false,
            hasNewInformativeNote: false,
            totalDocs: 0,
        };
    }

    componentDidMount() {
        const {
            params, user, hasCandidates, candidates, updatePendingCandidates, location,
        } = this.props;
        const { pathname } = location;
        const { id } = params;
        let viewingDetails = false;
        let role = '';

        if (user) ({ role } = user);

        if (role === UserRoles.INSURANCE_USER || pathname.startsWith(CASUALTIES_DETAILS_ROUTE)) {
            viewingDetails = true;
        }

        let casualtyId = '';
        let isEdit = false;

        if (id) {
            isEdit = true;
            casualtyId = id;
        }

        const tabItems: TabItem[] = [];
        let TAB_TYPE = CASUALTY_FORM_TABS_ITEM;

        if (role === UserRoles.INSURANCE_USER) TAB_TYPE = CASUALTY_FORM_TABS_ITEM_INSURANCE;

        Object.keys(TAB_TYPE).forEach((k) => {
            const i = TAB_TYPE[Number(k)];
            this.pushTabItems(i, tabItems);
        });

        let isOnVideoCall = false;
        let pendingCandidates: Array<SocketReceivedMessage> = [];
        if (hasCandidates) {
            isOnVideoCall = true;

            if (candidates) {
                pendingCandidates = candidates;
            }
        }

        updatePendingCandidates(false, []);

        this.setState({
            tabItems,
            id: casualtyId,
            isEdit,
            viewingDetails,
            isOnVideoCall,
            pendingCandidates,
        }, () => this.prepare());
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>): void {
        const {
            answerCall: oldAnswerCall,
            callDeclined: oldCallDeclined,
            onlineMechanicUsers: oldMechanicUsers,
            onlineFluxeUsers: oldFluxeUsers,
            params: oldParams,
            signalMessage: oldSignalMessage,
        } = prevProps;
        const {
            answerCall,
            callDeclined,
            onlineMechanicUsers,
            onlineFluxeUsers,
            user,
            wasIceCandidateFound,
            signalMessage,
            params,
        } = this.props;
        const {
            casualtyRequest, isUserOnline, isOnVideoCall, pendingCandidates,
        } = this.state;
        const { id: oldId } = oldParams;
        const { id } = params;

        const hasMechanicUsersChanged = oldMechanicUsers !== onlineMechanicUsers;
        const hasFluxeUsersChanged = oldFluxeUsers !== onlineFluxeUsers;

        if (wasIceCandidateFound && !isOnVideoCall && signalMessage && signalMessage !== oldSignalMessage && signalMessage.messageType === SocketReceivedMessageType.ICE_CANDIDATE_FOUND) {
            pendingCandidates.push(signalMessage);
            this.setState({ pendingCandidates: [...pendingCandidates] });
        }

        if (id !== oldId) {
            this.setState({
                id: id || '',
            }, () => { this.prepare(); });
        }

        if (hasMechanicUsersChanged || hasFluxeUsersChanged) {
            let newIsUserOnline = isUserOnline;

            if (user && casualtyRequest && (user.role === UserRoles.ADMIN || user.role === UserRoles.COLLABORATOR)) {
                newIsUserOnline = Boolean(casualtyRequest.mechanicId && onlineMechanicUsers.includes(casualtyRequest.mechanicId));
            } else if (user && casualtyRequest && user.role === UserRoles.MECHANIC) {
                newIsUserOnline = Boolean(casualtyRequest.mechanicId && onlineFluxeUsers.length > 0);
            }

            this.setState({
                isUserOnline: newIsUserOnline,
            });
        }

        if (answerCall !== oldAnswerCall && answerCall) {
            this.setState({
                isOnVideoCall: true,
                isMakingCall: false,
            });
            return;
        }

        if (callDeclined && (callDeclined !== oldCallDeclined)) {
            this.setState({
                isOnVideoCall: false,
                isMakingCall: false,
            });
        }
    }

    onTabChange = (newTab: string) => {
        this.setState({
            tabItemSelected: newTab,
        });
    };

    onVideoConfigError = () => {
        const { t } = this.props;
        displayNotification(NOTIFICATION_TYPE.ERROR, t('errors.errorVideo'));
        this.setState({
            isOnVideoCall: false,
            isMakingCall: false,
        });
    };

    onGeneratePdfSuccess = (file: File, fileName: string, mimeType: string) => {
        this.setState({
            isFetching: false,
        });
        
        const url = URL.createObjectURL(new Blob([file], { type: mimeType }));
        const link = document.createElement('a');
        if (!link) return;
        
        link.href = url;
        link.setAttribute(
            'download',
            `${fileName}`,
        );
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) {
            link.parentNode.removeChild(link);
        }
        URL.revokeObjectURL(url);
    };

    onGeneratePdfFailure = () => {
        this.setState({
            isFetching: false,
        });
    };

    onGeneratePdfClick = async () => {
        const { generatePDF } = this.props;
        const { casualtyRequest, isFetching, preparing } = this.state;
        if (preparing || isFetching) return;

        this.setState({ isFetching: true });

        await generatePDF(casualtyDocumentReportURL(String(casualtyRequest.id)), this.onGeneratePdfSuccess, this.onGeneratePdfFailure);
    };

    onSaveClick = () => {
        const { isEdit, casualtyRequest } = this.state;

        const {
            expertiseObservations,
            insurancePolicyNumber,
            vehicleBrand,
            vehicleLicensePlate,
            vehicleModel,
            vehicleOwner,
            insuranceProcessNumber,
            vehicleOwnerContact,
            expertiseDate,
            casualtyDate,
            expertiseSituation,
            compensationType,
        } = casualtyRequest;

        if (this.validateFields()) {
            const fields: CasualtyRequest = {
                ...casualtyRequest,
                expertiseObservations: expertiseObservations.trim() || '',
                insurancePolicyNumber: insurancePolicyNumber.trim() || '',
                vehicleBrand: vehicleBrand.trim() || '',
                vehicleLicensePlate: vehicleLicensePlate.trim() || '',
                vehicleModel: vehicleModel.trim() || '',
                vehicleOwner: vehicleOwner.trim() || '',
                insuranceProcessNumber,
                vehicleOwnerContact: vehicleOwnerContact ? String(vehicleOwnerContact).trim() : null,
                expertiseDate: moment(expertiseDate).add(2, 'hours').unix() * 1000,
                casualtyDate: moment(casualtyDate).add(2, 'hours').unix() * 1000,
                expertiseSituation: expertiseSituation === '' ? null : expertiseSituation,
                compensationType: compensationType === '' ? null : compensationType,
            };

            if (isEdit) this.editCasualtyRequest(fields);
            else this.createCasualtyRequest(fields);
        }
    };

    onConcludeReopenClick = (isConclude: boolean) => {
        const { t } = this.props;
        const { hasModifications, id } = this.state;
        let title = t('casualties.form.closeConfirmationMsg');
        let onAccept = () => this.makeRequest(casualtyFinishURL(String(id)));

        if (hasModifications) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t(this.hasChangesError));
            return;
        }

        if (!isConclude) {
            title = t('casualties.form.reopenConfirmationMsg');
            onAccept = () => this.makeRequest(casualtyReopenURL(String(id)));
        }

        displayConfirm({
            acceptButtonText: t(this.acceptButton),
            onAccept,
            onReject: () => {},
            rejectButtonText: t(this.rejectButton),
            title,
        });
    };

    onVideoSizeChanged = () => {
        const { isVideoBig } = this.state;
        this.setState({ isVideoBig: !isVideoBig });
    };

    onAddClickHandler = () => {
        const { tabItemSelected } = this.state;

        if (tabItemSelected) {
            switch (tabItemSelected) {
                case CasualtyFormTabsEnum.BUDGET:
                    this.setState({
                        hasCreateResume: true,
                    });
                    break;
                case CasualtyFormTabsEnum.DOCUMENTS:
                    this.setState({ creatingInformativeNote: true });
                    break;
                case CasualtyFormTabsEnum.MESSAGES:
                    this.setState({ creatingNewMessage: true });
                    break;
                default:
            }
        }
    };

    onCancelClick = () => {
        const { t } = this.props;
        const { hasModifications, id } = this.state;

        if (hasModifications) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t(this.hasChangesError));
            return;
        }

        displayConfirm({
            acceptButtonText: t(this.acceptButton),
            onAccept: () => this.makeRequest(casualtyCancelURL(String(id))),
            onReject: () => {},
            rejectButtonText: t(this.rejectButton),
            title: t('casualties.form.cancelConfirmationMsg'),
        });
    };

    onRestartClick = () => {
        const { t } = this.props;
        const { hasModifications, id } = this.state;

        if (hasModifications) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t(this.hasChangesError));
            return;
        }

        displayConfirm({
            acceptButtonText: t(this.acceptButton),
            onAccept: () => this.makeRequest(casualtyRestartURL(String(id))),
            onReject: () => {},
            rejectButtonText: t(this.rejectButton),
            title: t('casualties.form.reopenConfirmationMsg'),
        });
    };

    onBackClick = () => {
        const { navigate } = this.props;
        navigate(-1);
    };

    onCasualtyChange: Function = (casualty: any) => {
        const { casualtyRequest } = this.state;
        this.setState({
            hasModifications: true,
            casualtyRequest: {
                ...casualty,
                status: casualtyRequest.status,
            },
        });
    };

    onNoteModalClose = () => {
        this.setState({
            creatingInformativeNote: false,
        });
    };

    onNewInformativeNoteCreated = () => {
        const { hasNewInformativeNote } = this.state;

        this.setState({
            creatingInformativeNote: false,
            hasNewInformativeNote: !hasNewInformativeNote,
        });
    };

    onFileSelected = (files: File []) => {
        const { tabItemSelected } = this.state;
        const totalDocs = files.length;
        AUX_COUNT = 0;

        if (tabItemSelected === CasualtyFormTabsEnum.DOCUMENTS) {
            Object.keys(files).forEach((key) => {
                const file = files[Number(key)];
                const formData = new FormData();
                formData.append('file', file, file.name);
                formData.append('fileName', file.name);
                this.uploadDocument(formData, totalDocs);
            });
        }
    };

    onMessageModalClose = () => {
        this.setState({ creatingNewMessage: false });
    };

    onMessageCreated = () => {
        const { messagesCount } = this.state;

        this.setState({ messagesCount: messagesCount + 1 });
    };

    onVideoCallClick = async () => {
        this.setState({ isOnVideoCall: true, isMakingCall: true });
    };

    onRejectCallClick = () => {
        const { notifyDeclineCall } = this.props;

        notifyDeclineCall();
    };

    onCallTerminate = () => {
        this.setState({
            isMakingCall: false,
            isOnVideoCall: false,
        });
    };

    onAcceptCallClick = () => {
        const { notifyAnswerCall } = this.props;

        notifyAnswerCall();
    };

    onRequestSuccess = (data: KeyedObject) => {
        const { casualtyRequest } = this.state;
        
        this.setState({
            casualtyRequest: {
                ...casualtyRequest,
                ...data,
            },
            isFetching: false,
        });
    };

    onRequestFailure = () => {
        this.setState({
            isFetching: false,
        });
    };

    onEditCasualtySuccess = () => {
        const { t } = this.props;

        displayNotification(NOTIFICATION_TYPE.SUCCESS, t('casualties.form.editSuccessNotif'));
        this.setState({
            isFetching: false,
            hasModifications: false,
        });
    };

    onEditCasualtyFailure = (error: AxiosError) => {
        const { t } = this.props;

        displayNotification(NOTIFICATION_TYPE.ERROR, t('casualties.form.editErrorNotif'));
        if (error.response) {
            this.handleResponse(error.response.data);
        }
    };

    onGetCasualtySuccess = (data: CasualtyResponse) => {
        const { onlineMechanicUsers, user, onlineFluxeUsers } = this.props;

        const casualtyResponse: CasualtyResponse = {
            ...data,
        };

        const casualtyRequest: any = {
            ...casualtyResponse,
            insuranceCompanyId: casualtyResponse.insuranceCompany ? casualtyResponse.insuranceCompany.id : null,
            insuranceCompanyName: casualtyResponse.insuranceCompany ? casualtyResponse.insuranceCompany.name : null,
            mechanicName: casualtyResponse.mechanic ? casualtyResponse.mechanic.name : null,
            mechanicId: casualtyResponse.mechanic ? casualtyResponse.mechanic.id : null,
        };

        let isUserOnline = Boolean(casualtyRequest.mechanicId && onlineMechanicUsers.includes(casualtyRequest.mechanicId));
        if (user && user.role === UserRoles.MECHANIC) {
            isUserOnline = Boolean(casualtyRequest.mechanicId && onlineFluxeUsers.length > 0);
        }

        this.setState({
            preparing: false,
            casualtyRequest: { ...casualtyRequest },
            isUserOnline,
        });
    };

    onGetCasualtyFailure = () => {
        this.setState({ preparing: false });
    };

    onCreateCasualtySuccess = (data: KeyedObject) => {
        const { t, permissions, navigate } = this.props;

        displayNotification(NOTIFICATION_TYPE.SUCCESS, t('casualties.form.createSuccessNotif'));

        const tabItems: TabItem[] = [];

        Object.keys(CASUALTY_FORM_TABS_ITEM).forEach((k) => {
            const i = CASUALTY_FORM_TABS_ITEM[Number(k)];

            const item: TabItem = {
                value: i,
                text: t(`casualties.form.tabItems.${i}`),
            };
            tabItems.push(item);
        });

        this.setState({
            tabItems,
            id: data.id,
            isFetching: false,
            hasModifications: false,
        }, () => {
            if (checkPermission(permissions, [PermissionKey.ALL, PermissionKey.EDIT_CASUALTIES])) {
                navigate(`${CASUALTIES_EDIT_ROUTE}/${data.id}`);
            } else {
                navigate(`${CASUALTIES_DETAILS_ROUTE}/${data.id}`);
            }
        });
    };

    onCreateCasualtyFailure = (error: AxiosError) => {
        const { t } = this.props;

        displayNotification(NOTIFICATION_TYPE.ERROR, t('casualties.form.createErrorNotif'));
        if (error.response) {
            this.handleResponse(error.response.data);
        }
    };

    onUploadDocumentSuccess = (data: number) => {
        const { t } = this.props;
        const { totalDocs, documentCount } = this.state;

        this.getDocument(String(data));
        this.setState({ isFetching: false });

        if (AUX_COUNT === totalDocs) {
            this.setState({ documentCount: documentCount + 1 });
            displayNotification(NOTIFICATION_TYPE.SUCCESS, t('casualties.form.documents.uploadSuccessNotif'));
        }
    };

    onUploadDocumentFailure = () => {
        const { t } = this.props;

        this.setState({ isFetching: false });
        displayNotification(NOTIFICATION_TYPE.ERROR, t('casualties.form.documents.uploadErrorNotif'));
    };

    pushTabItems = (i: string, tabItems: TabItem[]) => {
        const { t } = this.props;
        const item: TabItem = {
            value: i,
            text: t(`casualties.form.tabItems.${i}`),
        };

        tabItems.push(item);
    };

    makeRequest = (url: string) => {
        const { requestHelper } = this.props;
        const { isFetching } = this.state;

        if (isFetching) return;

        this.setState({
            isFetching: true,
        });

        requestHelper(url, this.onRequestSuccess, this.onRequestFailure);
    };

    validateFields = (): boolean => {
        const { casualtyRequest } = this.state;

        let errors: IFormError | null = getFormErrors(casualtyRequest, VALIDATIONS.CASUALTY_FORM);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    editCasualtyRequest = async (fields: CasualtyRequest) => {
        const { editCasualty } = this.props;
        const { id } = this.state;

        this.setState({ isFetching: true });

        editCasualty(id, fields, this.onEditCasualtySuccess, this.onEditCasualtyFailure);
    };

    createCasualtyRequest = (fields: CasualtyRequest) => {
        const { createCasualty } = this.props;

        this.setState({ isFetching: true });

        createCasualty(fields, this.onCreateCasualtySuccess, this.onCreateCasualtyFailure);
    };

    handleResponse = (formErrors: any = null) => {
        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors,
                isFetching: false,
            });
        } else {
            this.setState({ isFetching: false });
        }
    };

    uploadDocument = async (file: FormData, totalDocs: number) => {
        const { uploadCasualtyDocument } = this.props;
        const { id } = this.state;
        if (!id) return;

        this.setState({ isFetching: true, totalDocs });

        uploadCasualtyDocument(id, file, this.onUploadDocumentSuccess, this.onUploadDocumentFailure);

        AUX_COUNT++;
    };

    getDocument = async (imageId: string) => {
        const { getCasualtyDocument } = this.props;
        const { id } = this.state;
        getCasualtyDocument(id, imageId);
    };

    getUsersStatus = (): string => {
        const { t, user } = this.props;
        const { isUserOnline } = this.state;

        if (isUserOnline && user && (user.role === UserRoles.ADMIN || user.role === UserRoles.EXTERNAL_ADMIN)) {
            return t('casualties.form.labels.online');
        }
        if (!isUserOnline && user && (user.role === UserRoles.ADMIN || user.role === UserRoles.EXTERNAL_ADMIN)) {
            return t('casualties.form.labels.offline');
        }

        if (isUserOnline && user && user.role === UserRoles.MECHANIC) {
            return t('casualties.form.labels.fluxeUserOnline');
        }
        if (!isUserOnline && user && user.role === UserRoles.MECHANIC) {
            return t('casualties.form.labels.fluxeUserOffline');
        }

        return '';
    };

    prepare = async () => {
        const { getCasualty } = this.props;
        const { id, preparing } = this.state;
        if (!id || preparing) return;

        this.setState({ preparing: true });

        getCasualty(id, this.onGetCasualtySuccess, this.onGetCasualtyFailure);
    };

    renderItemComponent = () => {
        const { permissions, user } = this.props;
        const {
            tabItemSelected,
            casualtyRequest,
            formErrors,
            documentCount,
            messagesCount,
            hasCreateResume,
            hasNewInformativeNote,
        } = this.state;

        let { viewingDetails } = this.state;
        let role = '';

        if (casualtyRequest.status === CasualtyStatus.DONE || casualtyRequest.status === CasualtyStatus.CANCELED) {
            viewingDetails = true;
        }

        if (user) ({ role } = user);

        switch (tabItemSelected) {
            case CasualtyFormTabsEnum.REQUEST:
                return <CasualtyRequestTab role={role} readOnly={viewingDetails} formErrors={formErrors} casualty={casualtyRequest} onCasualtyChange={this.onCasualtyChange} />;
            case CasualtyFormTabsEnum.BUDGET:
                return (
                    <CasualtyBudgetTab
                        casualty={casualtyRequest}
                        readOnly={viewingDetails && !checkPermission(permissions, [PermissionKey.CREATE_CASUALTY_BUDGET])}
                        hasCreateResume={hasCreateResume}
                        role={role}
                    />
                );
            case CasualtyFormTabsEnum.DOCUMENTS:
                return (
                    <CasualtyDocumentsTab
                        casualty={casualtyRequest}
                        count={documentCount}
                        hasNewInformativeNotes={hasNewInformativeNote}
                        readOnly={viewingDetails && !checkPermission(permissions, [PermissionKey.CREATE_CASUALTY_DOCUMENT])}
                    />
                );
            case CasualtyFormTabsEnum.MESSAGES:
                return <CasualtyMessagesTab casualty={casualtyRequest} count={messagesCount} />;
            default:
                return <React.Fragment />;
        }
    };

    renderVideo = () => {
        const {
            t, incomingCall, incomingCallMessage, user,
        } = this.props;
        const {
            isUserOnline, isOnVideoCall, isMakingCall, casualtyRequest, pendingCandidates,
        } = this.state;

        let showIncomingCall: boolean = incomingCall;

        if (user && user.role === UserRoles.INSURANCE_USER) return <React.Fragment />;

        if (incomingCallMessage) {
            const { messageMap } = incomingCallMessage;

            if (messageMap.casualtyId !== casualtyRequest.id) {
                showIncomingCall = false;
            }
        }

        if (showIncomingCall) {
            return (
                <div className="app-screen__form__left-container__call-container incoming-call">
                    <div className="call-info">
                        <h3>{t('global.calling')}</h3>
                        <p>{casualtyRequest.mechanicName}</p>
                        <p className="small">{t('global.referring')}<b>{t('global.process', { processNumber: casualtyRequest.uid })}</b></p>
                    </div>
                    <div className="buttons">
                        <ButtonDeprecated
                            styles="btn--light-purple btn--no-opacity "
                            text={t('global.buttons.rejectCall')}
                            callback={this.onRejectCallClick}
                        />
                        <ButtonDeprecated
                            styles="btn--green"
                            text={t('global.buttons.answerCall')}
                            callback={this.onAcceptCallClick}
                        />
                    </div>
                </div>
            );
        }

        if (isOnVideoCall && casualtyRequest.mechanicId && casualtyRequest.id) {
            return (
                <VideoComponent
                    userId={casualtyRequest.mechanicId || null}
                    isMakingCall={isMakingCall}
                    casualtyId={casualtyRequest.id}
                    casualtyUid={casualtyRequest.uid}
                    mechanicName={casualtyRequest.mechanicName}
                    onCallTerminate={this.onCallTerminate}
                    uploadFile={(formData: FormData, docsCount: number) => this.uploadDocument(formData, docsCount)}
                    onConfigError={this.onVideoConfigError}
                    onVideoSizeChange={this.onVideoSizeChanged}
                    pendingCandidates={pendingCandidates}
                />
            );
        }

        return (
            <div className="app-screen__form__left-container__call-container">
                <SvgIcon icon={ICON.VIDEO_CAM} />
                {isUserOnline && (
                    <ButtonDeprecated
                        text={t('casualties.form.labels.videoCallBtn')}
                        styles="btn--green"
                        callback={this.onVideoCallClick}
                    />
                )}
            </div>
        );
    };

    render() {
        const { t, permissions, user } = this.props;
        const {
            preparing,
            casualtyRequest,
            tabItems,
            tabItemSelected,
            creatingNewMessage,
            viewingDetails,
            isUserOnline,
            isFetching,
            hasModifications,
            isVideoBig,
            creatingInformativeNote,
        } = this.state;

        let dotClassName = '';
        let role = '';
        switch (casualtyRequest.status) {
            case CasualtyStatus.ONGOING:
                dotClassName = 'green-circle';
                break;
            case CasualtyStatus.DONE:
                dotClassName = 'red-circle';
                break;
            case CasualtyStatus.CANCELED:
                dotClassName = 'black-circle';
                break;
            default:
        }

        if (user) ({ role } = user);

        let mechanicToRoute: any = null;
        if (casualtyRequest.mechanicId && checkPermission(permissions, [PermissionKey.ALL, PermissionKey.EDIT_USER])) {
            mechanicToRoute = `${USER_MECHANIC_EDIT_ROUTE}/${casualtyRequest.mechanicId}`;
        } else if (casualtyRequest.mechanicId && checkPermission(permissions, [PermissionKey.VIEW_MECHANIC_DETAILS])) {
            mechanicToRoute = `${USER_MECHANIC_DETAILS_ROUTE}/${casualtyRequest.mechanicId}`;
        }

        let insuranceToRoute: any = null;
        if (casualtyRequest.insuranceCompanyId && checkPermission(permissions, [PermissionKey.ALL, PermissionKey.EDIT_USER])) {
            insuranceToRoute = `${USER_INSURANCE_EDIT_ROUTE}/${casualtyRequest.insuranceCompanyId}`;
        } else if (casualtyRequest.insuranceCompanyId && checkPermission(permissions, [PermissionKey.VIEW_INSURANCE_DETAILS])) {
            insuranceToRoute = `${USER_INSURANCE_DETAILS_ROUTE}/${casualtyRequest.insuranceCompanyId}`;
        }

        let addButtonText = '';
        let showAddButton = true;
        let showFromMessages = false;
        let showSaveButton = false;
        let showUploadButton = false;
        if (tabItemSelected) {
            switch (tabItemSelected) {
                case CasualtyFormTabsEnum.REQUEST:
                    showSaveButton = !viewingDetails;
                    break;
                case CasualtyFormTabsEnum.BUDGET:
                    showAddButton = !viewingDetails || checkPermission(permissions, [PermissionKey.CREATE_CASUALTY_BUDGET]);
                    addButtonText = t('casualties.form.newBudgetBtn');
                    break;
                case CasualtyFormTabsEnum.DOCUMENTS:
                    showAddButton = !viewingDetails || checkPermission(permissions, [PermissionKey.CREATE_CASUALTY_DOCUMENT]);
                    showUploadButton = !viewingDetails || checkPermission(permissions, [PermissionKey.CREATE_CASUALTY_DOCUMENT]);
                    addButtonText = t('casualties.form.createInformativeNote');
                    break;
                case CasualtyFormTabsEnum.MESSAGES:
                    showAddButton = !viewingDetails || checkPermission(permissions, [PermissionKey.CREATE_CASUALTY_MESSAGE]);
                    showFromMessages = true;
                    addButtonText = t('casualties.form.newMessageBtn');
                    break;
                default:
            }
        }
        const showCarItem = casualtyRequest.vehicleBrand && casualtyRequest.vehicleLicensePlate && casualtyRequest.vehicleModel;
        const userStatusClass = isUserOnline ? 'status online' : 'status offline';

        if (casualtyRequest.status === CasualtyStatus.CANCELED) {
            showAddButton = false;
            showSaveButton = false;
        }

        return (
            <div>
                {(preparing || isFetching) && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className={isVideoBig ? 'app-screen__form hidden-overflow' : 'app-screen__form'}>
                    <div className="app-screen__form__left-container">
                        <div className="app-screen__form__left-container__info-container">
                            <div className="info-content">
                                <SvgIcon icon={ICON.LEFT_ARROW} callback={this.onBackClick} />
                                <div className="info">
                                    {casualtyRequest.uid && <p>{t('casualties.form.labels.process')}</p>}
                                    <h4>{casualtyRequest.uid || t('casualties.form.newProcess')}</h4>
                                </div>
                            </div>
                            <div className={`status-dot ${dotClassName}`} />
                        </div>
                        {role !== UserRoles.INSURANCE_USER && role !== UserRoles.MECHANIC
                        && casualtyRequest.status === CasualtyStatus.ONGOING && (
                            <div className="app-screen__form__left-container__buttons-container">
                                <ButtonDeprecated
                                    text={t('global.buttons.conclude')}
                                    callback={() => this.onConcludeReopenClick(true)}
                                    styles="btn--dark-purple"
                                />
                                <ButtonDeprecated
                                    text={t('global.buttons.cancel')}
                                    callback={this.onCancelClick}
                                    styles="btn--purple"
                                />
                            </div>
                        )}
                        {role !== UserRoles.INSURANCE_USER && role !== UserRoles.MECHANIC
                        && casualtyRequest.status === CasualtyStatus.CANCELED && (
                            <div className="app-screen__form__left-container__buttons-container one-button">
                                <ButtonDeprecated
                                    text={t('global.buttons.reopen')}
                                    callback={this.onRestartClick}
                                    styles="btn--dark-purple"
                                />
                            </div>
                        )}
                        {role !== UserRoles.INSURANCE_USER
                         && role !== UserRoles.MECHANIC
                         && casualtyRequest.status === CasualtyStatus.DONE && (
                             <div className="app-screen__form__left-container__buttons-container one-button">
                                 <ButtonDeprecated
                                     text={t('global.buttons.reopen')}
                                     callback={() => this.onConcludeReopenClick(false)}
                                     styles="btn--dark-purple"
                                 />
                             </div>
                        )}
                        {showSaveButton && (
                            <div className="button-container">
                                <ButtonDeprecated
                                    text={t('global.buttons.saveModifications')}
                                    styles="btn--purple"
                                    callback={this.onSaveClick}
                                    disabled={!hasModifications}
                                />
                            </div>
                        )}
                        {role !== UserRoles.MECHANIC && casualtyRequest.status !== CasualtyStatus.CANCELED && (
                            <div className="button-container">
                                <ButtonDeprecated
                                    text={t('global.buttons.reportCasualty')}
                                    styles="btn--green btn--icon-center btn--bigger"
                                    icon={ICON.PDF}
                                    callback={() => this.onGeneratePdfClick()}
                                />
                            </div>
                        )}
                        <div className="app-screen__form__left-container__centered-info">
                            {(casualtyRequest.insuranceCompanyId && casualtyRequest.insuranceCompanyName) && (
                                <div className="info-item">
                                    <SvgIcon icon={ICON.ACCOUNT_BOX} />
                                    <p className="label">{t('casualties.form.labels.client')}</p>
                                    <Link to={insuranceToRoute}>
                                        <p>{casualtyRequest.insuranceCompanyName}</p>
                                    </Link>
                                </div>
                            )}
                            {showCarItem && (
                                <React.Fragment>
                                    <div className="info-item">
                                        <SvgIcon icon={ICON.CAR} />
                                        <p className="label">{t('casualties.form.labels.vehicleBrand')}</p>
                                        <p>{casualtyRequest.vehicleBrand}</p>
                                    </div>
                                    <div className="info-item smaller-margin">
                                        <p className="label">{t('casualties.form.labels.vehicleModel')}</p>
                                        <p>{casualtyRequest.vehicleModel}</p>
                                    </div>
                                    <div className="info-item smaller-margin">
                                        <p className="label">{t('casualties.form.labels.vehicleLicensePlate')}</p>
                                        <p>{casualtyRequest.vehicleLicensePlate}</p>
                                    </div>
                                </React.Fragment>
                            )}
                            {(casualtyRequest.insurancePolicyNumber && casualtyRequest.insurancePolicyNumber !== '') && (
                                <div className="info-item smaller-margin">
                                    <p className="label">{t('casualties.form.labels.policy')}</p>
                                    <p>{casualtyRequest.insurancePolicyNumber}</p>
                                </div>
                            )}
                            {casualtyRequest.mechanicName && (
                                <div className="info-item">
                                    <SvgIcon icon={ICON.BUILD} />
                                    <p className="label">{t('casualties.form.labels.mechanic')}</p>
                                    <Link to={mechanicToRoute}>
                                        <p>{casualtyRequest.mechanicName}</p>
                                    </Link>
                                    <p className={userStatusClass}>
                                        {this.getUsersStatus()}
                                    </p>
                                </div>
                            )}
                        </div>
                        {this.renderVideo()}
                    </div>
                    <div className="app-screen__form__form-container">
                        <Tabs
                            tabsItems={tabItems}
                            onTabChange={this.onTabChange}
                            fromMessages={showFromMessages}
                            addButtonText={showAddButton ? addButtonText : undefined}
                            addButtonClickHandler={showAddButton ? this.onAddClickHandler : undefined}
                            addButtonStyles={tabItemSelected === CasualtyFormTabsEnum.REQUEST ? 'btn--purple' : 'btn--green'}
                            documentUploadText={t('casualties.form.attachDocuments')}
                            documentUploadFileSelected={showUploadButton ? this.onFileSelected : undefined}
                            isAddButtonDisabled={!hasModifications && tabItemSelected === CasualtyFormTabsEnum.REQUEST}
                        />
                        <div className="app-screen__form__form-container form-content">
                            {!preparing && this.renderItemComponent()}
                        </div>
                    </div>
                </div>
                {creatingNewMessage && <MessageModal casualty={casualtyRequest} onNewMessageCreated={this.onMessageCreated} onModalClose={this.onMessageModalClose} />}
                {creatingInformativeNote && <InformativeNoteModal onModalClose={this.onNoteModalClose} casualty={casualtyRequest} onNewInformativeNoteCreated={this.onNewInformativeNoteCreated} />}
            </div>
        );
    }
}

export default withRouter(withVideoContext(withAuthorizationContext(withAuthenticationContext(withTranslationContext(withCasualtyContext(CasualtyFormScreen))))));
