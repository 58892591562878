/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import ErrorMessage from './ErrorMessage';

interface OwnProps {
    name: string;
    value: string | number | null;
    onChange?: Function;
    label?: string;
    containerStyles?: string;
    labelStyles?: string;
    fieldStyles?: string;
    errors?: any;
    disabled?: boolean;
    id?: string;
    rightAdornment?: string;
    max?: string;
    min?: string;
}

class FormNumberField extends Component<OwnProps> {
    renderErrors = () => {
        const { name, errors } = this.props;

        return (
            <ErrorMessage errors={errors} field={name} />
        );
    };

    render() {
        const {
            name, value, onChange, label, containerStyles, fieldStyles, disabled, id, labelStyles, rightAdornment, errors, max, min,
        } = this.props;
        const containerClass = containerStyles || '';
        const labelClass = labelStyles || '';
        let fieldClass = fieldStyles || '';

        if (disabled) {
            fieldClass += ' disabled';
        }

        return (
            <div className={`field-container ${containerClass}`}>
                {label && (
                    <div className={`field-container__label ${labelClass}`}>
                        {label}
                    </div>
                )}
                <div className={`field-container__text-field ${fieldClass}`}>
                    <input
                        name={name}
                        type="number"
                        value={value || '0'}
                        onChange={onChange ? (e) => onChange(e) : () => {}}
                        max={max || undefined}
                        min={min || undefined}
                        disabled={disabled || false}
                        id={id || undefined}
                        data-testid={`input-${name}`}
                    />
                    {rightAdornment}
                </div>
                <p>{errors && this.renderErrors()}</p>
            </div>
        );
    }
}

export default FormNumberField;
