/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { IS_TESTING_ENVIRONMENT } from '../../settings';
import { INDEX_ROUTE } from '../../constants/routes';
import TopBar from '../elements/TopBar';

interface OwnProps {
    error: Error;
}

type Props = OwnProps & TranslationContext;

const ErrorScreen: FunctionComponent<Props> = (props: Props) => {
    const { error, t } = props;

    const { resetBoundary } = useErrorBoundary();
    const navigate = useNavigate();

    const onGoBackClick = () => {
        resetBoundary();
        navigate(-1);
    };

    const onReturnHomeClick = () => {
        resetBoundary();
        navigate(INDEX_ROUTE);
    };

    return (
        <div className="error-screen">
            <TopBar showTabs={false} />
            <div className="error-screen__content">
                <h1>{t('somethingWentWrong.title')}</h1>
                {IS_TESTING_ENVIRONMENT && (
                    <p>{error.message}</p>
                )}
                <div className="error-screen__buttons-group">
                    <button
                        type="button"
                        onClick={onGoBackClick}
                    >
                        {t('somethingWentWrong.goBack')}
                    </button>
                    <button
                        type="button"
                        onClick={onReturnHomeClick}
                    >
                        {t('somethingWentWrong.returnToHome')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default withTranslationContext(ErrorScreen);
