/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { get } from 'lodash';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import {
    NOT_FOUND_ROUTE,
    USER_MECHANIC_DETAILS_ROUTE,
} from '../../../constants/routes';
import Loader from '../../elements/Loader';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import ButtonDeprecated from '../../elements/ButtonDeprecated';
import FormTextField from '../../elements/FormTextField';
import { numberToPostalCode, postalCodeToNumber } from '../../../utils/format';
import { getFormErrors, IFormError, VALIDATIONS } from '../../../utils/validation';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import displayConfirm from '../../elements/displayConfirm';
import { AuthenticationContext, withAuthenticationContext } from '../../controllers/authentication/AuthenticationContext';
import { VideoContextInterface, withVideoContext } from '../../controllers/video/VideoContext';
import { UserRoles } from '../../../constants/authorization';
import { WithRouterProps, withRouter } from '../../containers/withRouter';
import { UsersContext, withUsersContext } from '../../controllers/users/UsersContext';
import { UserResponse } from '../../../constants/types';
import { KeyedObject } from '../../../constants/misc';
import { mechanicUserURL } from '../../../services/users';

interface OwnProps extends WithRouterProps, TranslationContext, VideoContextInterface, AuthenticationContext, UsersContext {}

interface OwnState {
    id: string;
    name: string;
    email: string;
    nif: string;
    street: string;
    parish: string;
    district: string;
    contactName: string;
    county: string;
    contact: string;
    contactTitle: string;
    role: string;
    locality: string;
    isFetching: boolean;
    preparing: boolean;
    inactive: boolean;
    postalCode: string;
    formErrors: any;
    hasModifications: boolean;
    viewingDetails: boolean;
    registered: boolean;
    labourRateMechanic: string;
    labourRatePaint: string;
    isEditable: boolean;
}

const initialState: OwnState = {
    id: '',
    name: '',
    email: '',
    nif: '',
    street: '',
    parish: '',
    district: '',
    county: '',
    contact: '',
    locality: '',
    contactTitle: '',
    contactName: '',
    role: '',
    postalCode: '',
    isFetching: false,
    preparing: false,
    inactive: false,
    formErrors: {},
    hasModifications: false,
    viewingDetails: false,
    registered: false,
    labourRateMechanic: '',
    labourRatePaint: '',
    isEditable: false,
};

class MechanicUserEditScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { params, user, location } = this.props;
        const { pathname } = location;
        const { id } = params;

        let isEditable = false;

        if (user && (user.role === UserRoles.ADMIN || user.role === UserRoles.COLLABORATOR)) {
            isEditable = true;
        }

        if (id) {
            let viewingDetails = false;
            if (pathname.startsWith(USER_MECHANIC_DETAILS_ROUTE)) {
                viewingDetails = true;
            }

            this.setState({
                id,
                viewingDetails,
                isEditable,
            }, () => { this.prepare(); });
        }
    }

    onBackClick = () => {
        const { navigate } = this.props;
        navigate(-1);
    };

    onSave = () => {
        const {
            name,
            email,
            contact,
            nif,
            street,
            postalCode,
            parish,
            district,
            county,
            locality,
            contactTitle,
            contactName,
            labourRatePaint,
            labourRateMechanic,
        } = this.state;

        if (this.validateForm()) {
            const fields = {
                name: String(name).trim(),
                email: String(email).trim(),
                contact: String(contact).trim(),
                nif: nif !== '' ? String(nif).trim() : null,
                street: String(street).trim(),
                postalCode: postalCodeToNumber(postalCode),
                parish: String(parish).trim(),
                district: String(district).trim(),
                county: String(county).trim(),
                locality: String(locality).trim(),
                contactTitle: String(contactTitle).trim(),
                contactName: String(contactName).trim(),
                labourRateMechanic: String(labourRateMechanic).trim(),
                labourRatePaint: String(labourRatePaint).trim(),
            };

            const formData = new FormData();
            formData.append('mechanicRequest', new Blob([JSON.stringify(fields)], {
                type: 'application/json',
            }));

            this.editUserRequest(formData);
        }
    };

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { state } = this;
        this.setState({
            ...state,
            [e.currentTarget.name]: e.currentTarget.value,
            hasModifications: true,
        });
    };

    onToggleClick = () => {
        const { t } = this.props;
        const { id, inactive, name } = this.state;

        let msg: string = t('editUser.deactivateMessage', { name });
        if (inactive) msg = t('editUser.activateMessage', { name });

        displayConfirm({
            acceptButtonText: t('global.buttons.accept'),
            onAccept: () => this.toggleRequest(id),
            onReject: () => {},
            rejectButtonText: t('global.buttons.reject'),
            containerStyle: 'remove-document',
            title: msg,
        });
    };

    onToggleUsersSuccess = (data: UserResponse) => {
        const { t } = this.props;

        const { inactive } = data;
        
        if (inactive) displayNotification(NOTIFICATION_TYPE.SUCCESS, t('editUser.deactivateSuccess'));
        else displayNotification(NOTIFICATION_TYPE.SUCCESS, t('editUser.activateSuccess'));

        this.setState({
            isFetching: false,
            inactive,
        });
    };

    onToggleUsersFailure = () => {
        const { t } = this.props;
        const { inactive: oldInactive } = this.state;

        if (oldInactive) displayNotification(NOTIFICATION_TYPE.ERROR, t('editUser.deactivateError'));
        else displayNotification(NOTIFICATION_TYPE.ERROR, t('editUser.activateError'));

        this.setState({ isFetching: false });
    };

    onEditUserSuccess = () => {
        const { t } = this.props;

        displayNotification(NOTIFICATION_TYPE.SUCCESS, t('editUser.successNotif'));
        this.onBackClick();
        this.setState({
            isFetching: false,
        });
    };

    onEditUserFailure = (error: KeyedObject) => {
        const { t } = this.props;

        displayNotification(NOTIFICATION_TYPE.ERROR, t('editUser.errorNotif'));
        if (error.response) {
            this.handleResponse(error.response.data);
        }
        this.setState({
            isFetching: false,
        });
    };

    onGetMechanicUserSuccess = (data: UserResponse) => {
        this.setState({
            preparing: false,
            name: data.name,
            email: data.email,
            contact: data.contact || '',
            inactive: data.inactive,
            nif: data.nif || '',
            role: data.role,
            street: data.street || '',
            postalCode: data.postalCode ? numberToPostalCode(Number(data.postalCode)) : '',
            locality: data.locality || '',
            district: data.district || '',
            parish: data.parish || '',
            county: data.county || '',
            contactName: data.contactName || '',
            contactTitle: data.contactTitle || '',
            registered: data.registered,
            labourRateMechanic: String(data.labourRateMechanic) || '0',
            labourRatePaint: String(data.labourRatePaint) || '0',
        });
    };

    onGetMechanicUserFailure = (status: number) => {
        const { navigate } = this.props;

        if (status === 403) {
            navigate(NOT_FOUND_ROUTE);
        }
        this.setState({ preparing: false });
    };

    toggleRequest = async (id: number | string) => {
        const { toggleUsers } = this.props;

        this.setState({ isFetching: true });

        toggleUsers(Number(id), this.onToggleUsersSuccess, this.onToggleUsersFailure);
    };

    editUserRequest = async (fields: FormData) => {
        const { editUser } = this.props;
        const { id } = this.state;

        this.setState({ isFetching: true });

        editUser(mechanicUserURL(id), fields, this.onEditUserSuccess, this.onEditUserFailure);
    };

    handleResponse = (formErrors: any = null) => {
        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors,
                isFetching: false,
            });
        } else {
            this.setState({ isFetching: false });
        }
    };

    validateForm = () => {
        const { state } = this;
        let errors: IFormError | null = getFormErrors(state, VALIDATIONS.USER_EDIT_FORM);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    prepare = async () => {
        const { getMechanicUser } = this.props;
        const { id, preparing } = this.state;

        if (preparing) return;

        this.setState({ preparing: true });

        getMechanicUser(id, this.onGetMechanicUserSuccess, this.onGetMechanicUserFailure);
    };

    render() {
        const { t, onlineMechanicUsers, user } = this.props;
        const {
            isFetching,
            preparing,
            inactive,
            id,
            name,
            street,
            parish,
            contact,
            county,
            nif,
            district,
            contactTitle,
            role,
            email,
            locality,
            formErrors,
            postalCode,
            contactName,
            hasModifications,
            registered,
            labourRateMechanic,
            labourRatePaint,
            isEditable,
        } = this.state;

        const showLoader: boolean = isFetching || preparing;
        const isOnline = onlineMechanicUsers.includes(Number(id));
        let fromInsurance = false;
        if (user) {
            fromInsurance = (user && user.role === UserRoles.INSURANCE_USER);
        }

        return (
            <div>
                {showLoader && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="app-screen__form-edit-user">
                    <div className="app-screen__form-edit-user__top-container">
                        <div className="info-container">
                            <SvgIcon icon={ICON.LEFT_ARROW} callback={this.onBackClick} />
                            <div className="info">
                                <p className="title">{t('editUser.smallTitle')}</p>
                                <p className="user-name">{name}</p>
                                {registered === false ? (
                                    <div className="status-dot orange-circle" />
                                ) : (
                                    <div className={inactive ? 'status-dot red-circle' : `${isOnline ? ' status-dot green-circle' : 'status-dot yellow-circle'}`} />
                                )}
                            </div>
                        </div>
                        {!fromInsurance && isEditable && (
                            <div className="buttons-container">
                                <ButtonDeprecated
                                    text={t('global.buttons.saveModifications')}
                                    type="submit"
                                    styles="btn--purple"
                                    callback={this.onSave}
                                    disabled={!hasModifications}
                                />
                                <ButtonDeprecated
                                    text={inactive ? t('global.buttons.activate') : t('global.buttons.deactivate')}
                                    callback={this.onToggleClick}
                                    styles="btn--green"
                                    iconPosition="right"
                                />
                            </div>
                        )}
                    </div>
                    <div className="app-screen__form-edit-user__container">
                        <div className="app-screen__form-edit-user__container__content">
                            {/* PERSONAL DATA */}
                            <h3>{t('editUser.subtitles.personalData')}</h3>
                            <div className="row">
                                <div className="col-sm-8">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.name')}
                                        name="name"
                                        value={name}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.name', null)}
                                        disabled={fromInsurance || !isEditable}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.type')}
                                        name="role"
                                        value={t(`enums.userRoles.${role}`)}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-8">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.address')}
                                        name="street"
                                        value={street}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.street', null)}
                                        disabled={fromInsurance || !isEditable}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.postalCode')}
                                        name="postalCode"
                                        value={postalCode}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.postalCode', null)}
                                        disabled={fromInsurance || !isEditable}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.locality')}
                                        name="locality"
                                        value={locality}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.locality', null)}
                                        disabled={fromInsurance || !isEditable}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.parish')}
                                        name="parish"
                                        value={parish}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.parish', null)}
                                        disabled={fromInsurance || !isEditable}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.county')}
                                        name="county"
                                        value={county}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.county', null)}
                                        disabled={fromInsurance || !isEditable}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.district')}
                                        name="district"
                                        value={district}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.district', null)}
                                        disabled={fromInsurance || !isEditable}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.nif')}
                                        name="nif"
                                        value={nif}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.nif', null)}
                                        disabled={fromInsurance || !isEditable}
                                    />
                                </div>
                            </div>
                            {/* CONTACT */}
                            <h3 className="subtitle-with-margin">{t('editUser.subtitles.contactData')}</h3>
                            <div className="row">
                                <div className="col-sm-2">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.title')}
                                        name="contactTitle"
                                        value={contactTitle}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.title', null)}
                                        disabled={fromInsurance || !isEditable}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.name')}
                                        name="contactName"
                                        value={contactName}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.contactName', null)}
                                        disabled={fromInsurance || !isEditable}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.contact')}
                                        name="contact"
                                        value={contact}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.contact', null)}
                                        disabled={fromInsurance || !isEditable}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.email')}
                                        name="email"
                                        value={email}
                                        disabled
                                    />
                                </div>
                            </div>
                            {/* PRICES */}
                            <h3 className="subtitle-with-margin">{t('editUser.subtitles.workPrices')}</h3>
                            <div className="row">
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.mechanicWork')}
                                        name="labourRateMechanic"
                                        value={labourRateMechanic}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.labourRateMechanic', null)}
                                        disabled={fromInsurance || !isEditable}
                                        rightAdornment="€"
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <FormTextField
                                        fieldStyles="with-roboto"
                                        label={t('editUser.labels.paintWork')}
                                        name="labourRatePaint"
                                        value={labourRatePaint}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.labourRatePaint', null)}
                                        disabled={fromInsurance || !isEditable}
                                        rightAdornment="€"
                                    />
                                </div>
                                <div className="col-sm-4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withVideoContext(withTranslationContext(withUsersContext(withRouter(MechanicUserEditScreen)))));
