/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StatisticsContextProvider } from './StatisticsContext';

import { StatisticQueryParams } from '../../../constants/types';
import { statisticsURL, statisticsYearsURL } from '../../../services/statistics';
import { KeyedObject } from '../../../constants/misc';

interface OwnProps {
    children: React.ReactNode;
}

export class StatisticsController extends Component<OwnProps> {
    getStatisticsList = async (params: StatisticQueryParams, onSuccess: (data: KeyedObject) => void, onFailure: () => void) => {
        try {
            const { data } = await axios.get(statisticsURL(params));
            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    getStatisticsYears = async (onSuccess: (data: KeyedObject) => void, onFailure: () => void) => {
        try {
            const { data } = await axios.get(statisticsYearsURL());
            onSuccess(data);
        } catch {
            onFailure();
        }
    };
    
    getStatisticsYear = async (year: number, onSuccess: (data: KeyedObject) => void, onFailure: () => void) => {
        try {
            const { data } = await axios.get(statisticsURL({ year }));
            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    render() {
        const {
            children,
        } = this.props;

        return (
            <StatisticsContextProvider
                value={{
                    getStatisticsList: this.getStatisticsList,
                    getStatisticsYears: this.getStatisticsYears,
                    getStatisticsYear: this.getStatisticsYear,
                }}
            >
                {children}
            </StatisticsContextProvider>
        );
    }
}

export const ConnectedStatisticsController = connect()(StatisticsController);
