/* eslint-disable @typescript-eslint/ban-types */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import axios from 'axios';

import {
    AuthorizationAction,
    AuthorizationActionTypes,
} from './authorization_types';
import { RolePermission } from '../constants/authorization';
import { permissionsURL } from '../services/permissions';

export const permissionsRequestActionCreator = (): AuthorizationActionTypes => {
    return {
        type: AuthorizationAction.PERMISSIONS_REQUEST,
    };
};

export const permissionsSuccessActionCreator = (permissions: RolePermission[]): AuthorizationActionTypes => {
    return {
        type: AuthorizationAction.PERMISSIONS_SUCCESS,
        payload: permissions,
    };
};

export const permissionsFailureActionCreator = (formErrors: object): AuthorizationActionTypes => {
    return {
        type: AuthorizationAction.PERMISSIONS_FAILURE,
        payload: formErrors,
    };
};

export const permissionsSetActionCreator = (perms: RolePermission[]): AuthorizationActionTypes => {
    return {
        type: AuthorizationAction.PERMISSIONS_SET,
        payload: perms,
    };
};

export const permissionsResetActionCreator = (): AuthorizationActionTypes => {
    return {
        type: AuthorizationAction.PERMISSIONS_RESET,
    };
};

export const requestPermissions = (onSuccess: Function) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(permissionsRequestActionCreator());
        try {
            const { status, data } = await axios.get(permissionsURL());

            if (status === 200) {
                dispatch(permissionsSuccessActionCreator(data.permissions));
                onSuccess(data);
            }
        } catch (error) {
            dispatch(permissionsFailureActionCreator({}));
        }
    };
};
