/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios, { AxiosError } from 'axios';
import { connect } from 'react-redux';

import {
    GlassProvider,
    ListResponse, Mechanic, User, UserOptionsResponse, UserResponse,
    UserRoleParam,
} from '../../../constants/types';
import { KeyedObject, SelectOption } from '../../../constants/misc';
import {
    usersURL, resendInvitesURL, usersToggleURL, mechanicUserURL,
    userCreateURL,
    insuranceUserResetKeyURL,
} from '../../../services/users';
import { UsersContextProvider } from './UsersContext';

interface OwnProps {
    children: React.ReactNode;
}

export class UsersController extends Component<OwnProps> {
    getUsers = async (parameters?: KeyedObject): Promise<ListResponse<User>> => {
        try {
            const { data, headers } = await axios.get(usersURL(parameters));

            const total: number = parseInt(headers['x-total-count']);

            return {
                results: data,
                total,
            };
        } catch {
            return {
                results: [],
                total: 0,
            };
        }
    };

    getUsersOptions = async (parameters?: KeyedObject): Promise<UserOptionsResponse> => {
        const { results: users } = await this.getUsers(parameters);

        const options: Array<SelectOption> = users.map(({ id, name }) => ({ value: id, label: name }));

        return { options, users };
    };

    getMechanicUser = async (id: string, onSuccess: (data: UserResponse) => void, onFailure: (status: number) => void) => {
        try {
            const { data } = await axios.get(mechanicUserURL(id));
            onSuccess(data);
        } catch (err) {
            const error = err as AxiosError;
            onFailure(error.response?.status || 0);
        }
    };

    glassProvidersRequest = async (glassProviderSearch: string, onSuccess: (data: GlassProvider[]) => void, onFailure: () => void): Promise<void> => {
        try {
            const { data } = await axios.get(usersURL({
                userRole: UserRoleParam.GlassProvider, _limit: 10, _q: glassProviderSearch, active: true,
            }));

            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    mechanicOptionsRequest = async (params: KeyedObject): Promise<Mechanic[]> => {
        try {
            const { data } = await axios.get(usersURL(params));
            return data;
        } catch {
            return [];
        }
    };

    editUser = async (url: string, fields: FormData, onSuccess: () => void, onFailure: (error: AxiosError) => void) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        try {
            await axios.put(url, fields, config);
            onSuccess();
        } catch (error) {
            onFailure(error as AxiosError);
        }
    };

    resendInvitesEmails = async (onSuccess: () => void, onFailure: (errorCode: number) => void): Promise<void> => {
        try {
            await axios.get(resendInvitesURL());

            onSuccess();
        } catch (err) {
            const error = err as AxiosError;
            if (error.response?.data) {
                onFailure(error.response.data.errors[0].errorCode);
            } else {
                onFailure(error.response?.status || 0);
            }
        }
    };

    toggleUsers = async (id: number, onSuccess: (data: UserResponse) => void, onFailure: () => void) => {
        try {
            const { data } = await axios.post(usersToggleURL(id));
            onSuccess(data);
        } catch {
            onFailure();
        }
    };

    editAdminUser = async (url: string, fields: FormData, config: KeyedObject, onSuccess: (data: KeyedObject) => void, onFailure: (error: AxiosError) => void) => {
        try {
            const { data, headers } = await axios.put(url, fields, config);
            onSuccess({ data, headers });
        } catch (error) {
            onFailure(error as AxiosError);
        }
    };

    getUser = async (url: string, onSuccess: (data: UserResponse) => void, onFailure: (error: AxiosError) => void) => {
        try {
            const { data } = await axios.get(url);
            onSuccess(data);
        } catch (error) {
            onFailure(error as AxiosError);
        }
    };

    createUser = async (userRole: string, fields: FormData, onSuccess: () => void, onFailure: (error: AxiosError) => void) => {
        try {
            await axios.post(userCreateURL(userRole), fields, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            });
            onSuccess();
        } catch (error) {
            onFailure(error as AxiosError);
        }
    };

    resetApiKey = async (id: string, onSuccess: () => void, onFailure: () => void) => {
        try {
            await axios.put(insuranceUserResetKeyURL(id));
            onSuccess();
        } catch {
            onFailure();
        }
    };

    render(): React.ReactNode {
        const { children } = this.props;

        return (
            <UsersContextProvider
                value={{
                    getUsers: this.getUsers,
                    getUsersOptions: this.getUsersOptions,
                    getMechanicUser: this.getMechanicUser,
                    editUser: this.editUser,
                    resendInvitesEmails: this.resendInvitesEmails,
                    toggleUsers: this.toggleUsers,
                    glassProvidersRequest: this.glassProvidersRequest,
                    editAdminUser: this.editAdminUser,
                    getUser: this.getUser,
                    createUser: this.createUser,
                    resetApiKey: this.resetApiKey,
                    mechanicOptionsRequest: this.mechanicOptionsRequest,
                }}
            >
                {children}
            </UsersContextProvider>
        );
    }
}

export const ConnectedUsersController = connect()(UsersController);
