/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';
import { ConnectedStatisticsController, StatisticsController } from './StatisticsController';

export interface StatisticsContext extends Omit<InstanceType<typeof StatisticsController>, keyof React.Component> {}

const statisticsContextInstance = createContext<StatisticsContext | null>(null);

export const StatisticsContextProvider = statisticsContextInstance.Provider;
export const StatisticsContextConsumer = statisticsContextInstance.Consumer;

export const withStatisticsContext = <P, >(Component: ComponentType<P>): FC<Omit<P, keyof StatisticsContext>> => (props) => (
    <ConnectedStatisticsController>
        <StatisticsContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </StatisticsContextConsumer>
    </ConnectedStatisticsController>
);
