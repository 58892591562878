/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { get } from 'lodash';
import logo from '../../assets/images/entry_logo.svg';
import EntryPageFormTextfield from '../elements/EntryPageFormTextfield';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import ButtonDeprecated from '../elements/ButtonDeprecated';
import { getFormErrors, IFormError, VALIDATIONS } from '../../utils/validation';
import { INDEX_ROUTE, REGISTER_ROUTE } from '../../constants/routes';
import { ICON } from '../elements/SvgIcon';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';
import { withRouter, WithRouterProps } from '../containers/withRouter';

interface OwnProps extends WithRouterProps, AuthenticationContext, TranslationContext {}

interface OwnState {
    password: string;
    passwordConfirmation: string;
    formErrors: any;
    token?: string;
    isRegister: boolean;
}

const initialState = {
    password: '',
    passwordConfirmation: '',
    formErrors: {},
    token: '',
    isRegister: false,
};

class ResetPasswordScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const {
            isAuthenticated, logout, location, params,
        } = this.props;
        const { token } = params;
        const { pathname } = location;

        if (isAuthenticated) {
            logout();
        }

        let isRegister = false;
        if (pathname === REGISTER_ROUTE) {
            isRegister = true;
        }

        this.setState({ token, isRegister });
    }

    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (this.validateFields()) {
            this.sendPasswordResetRequest();
        }
    };

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { state } = this;

        this.setState({
            ...state,
            [e.currentTarget.name]: e.currentTarget.value,
        });
    };

    onResetPasswordSuccess = () => {
        const { t, navigate } = this.props;

        navigate(INDEX_ROUTE);
        displayNotification(NOTIFICATION_TYPE.SUCCESS, t('resetPassword.successNotif'));
    };

    onResetPasswordFailure = () => {
        const { t } = this.props;
        displayNotification(NOTIFICATION_TYPE.ERROR, t('resetPassword.errorNotif'));
    };

    validateFields = () => {
        const { state } = this;
        let errors: IFormError | null = getFormErrors(state, VALIDATIONS.RESET_PASSWORD_FORM);

        if (!errors) errors = {};
        const { password, passwordConfirmation } = this.state;
        if (password !== passwordConfirmation) {
            errors.passwordConfirmation = [{ typeOfViolation: 'passwordsDontMatch' }];
        }

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    sendPasswordResetRequest = () => {
        const { resetPasswordRequest } = this.props;
        const { password, token } = this.state;

        const payload = {
            password: password.trim(),
            token,
        };

        resetPasswordRequest(payload, this.onResetPasswordSuccess, this.onResetPasswordFailure);
    };

    render() {
        const { t } = this.props;
        const { password, passwordConfirmation, formErrors } = this.state;

        return (
            <div className="entry-page">
                <div className="entry-page__centered recover-password">
                    <img src={logo} alt="logo" />
                    <form onSubmit={this.onSubmit}>
                        <div className="entry-page__centered__fields">
                            <h2>{t('resetPassword.helpText')}</h2>
                            <EntryPageFormTextfield
                                name="password"
                                type="password"
                                value={password}
                                placeholder={t('resetPassword.password')}
                                onChange={this.onInputChange}
                                endIcon={ICON.PASSWORD_FILLED}
                                errors={get(formErrors, 'fields.password', null)}
                            />
                            <EntryPageFormTextfield
                                name="passwordConfirmation"
                                type="password"
                                value={passwordConfirmation}
                                placeholder={t('resetPassword.passwordConfirmation')}
                                onChange={this.onInputChange}
                                endIcon={ICON.PASSWORD_FILLED}
                                errors={get(formErrors, 'fields.passwordConfirmation', null)}
                            />
                        </div>
                        <ButtonDeprecated
                            type="submit"
                            text={t('recoverPassword.sendBtn')}
                            styles="btn--purple"
                        />
                    </form>
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(withRouter(ResetPasswordScreen)));
