/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { get } from 'lodash';
import EntryPageFormTextfield from '../elements/EntryPageFormTextfield';
import logo from '../../assets/images/entry_logo.svg';
import { ICON, SvgIcon } from '../elements/SvgIcon';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { REMEMBERED_FLUXE_USER_KEY } from '../../constants/misc';
import ButtonDeprecated from '../elements/ButtonDeprecated';
import { LoginFormFields } from '../../constants/authentication';
import { INDEX_ROUTE, RECOVER_PASSWORD_ROUTE, CASUALTIES_EDIT_ROUTE } from '../../constants/routes';
import { getFormErrors, VALIDATIONS, IFormError } from '../../utils/validation';
import ErrorMessage, { ERROR_MESSAGE_TYPE } from '../elements/ErrorMessage';
import { withRouter, WithRouterProps } from '../containers/withRouter';

interface OwnProps extends WithRouterProps, AuthenticationContext, TranslationContext {}

interface OwnState {
    fields: LoginFormFields;
    rememberMe: boolean;
    formErrors: any;
}

const initialState: OwnState = {
    fields: {
        email: '',
        password: '',
    },
    formErrors: null,
    rememberMe: false,
};

class LoginScreen extends Component<OwnProps, OwnState> {
    state = {
        ...initialState,
    };

    componentDidMount(): void {
        this.getUserEmail();
        const {
            isAuthenticated, logout, params, navigate,
        } = this.props;

        if (params && params.budgetId && params.casualtyId) {
            this.createBudget(params.casualtyId, params.budgetId);

            if (isAuthenticated) {
                navigate(`${CASUALTIES_EDIT_ROUTE}/${params.casualtyId}`);
                return;
            }
        }

        if (isAuthenticated) {
            logout();
        }
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>): void {
        const { formErrors: prevFormErrors } = prevProps;
        const { formErrors } = this.props;

        if (formErrors !== prevFormErrors) {
            this.setState({ formErrors: { ...formErrors } });
        }
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { fields } = this.state;
        this.setState({
            fields: {
                ...fields,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { login } = this.props;

        if (this.validateLogin()) {
            const { fields } = this.state;

            login({
                email: fields.email,
                password: fields.password,
            }, this.onLoginSuccess);
        }
    };

    onRecoverPasswordClick = () => {
        const { navigate } = this.props;
        navigate(RECOVER_PASSWORD_ROUTE);
    };

    onLoginSuccess = () => {
        const { navigate } = this.props;
        navigate(INDEX_ROUTE);
    };

    onRememberMeClick = () => {
        const { rememberMe } = this.state;

        if (rememberMe) {
            this.forgetUser();
        } else {
            this.rememberUser();
        }
        this.setState({ rememberMe: !rememberMe });
    };

    createBudget = (casualtyId: string, budgetId: string) => {
        const { createBudgetRequest } = this.props;

        createBudgetRequest(casualtyId, budgetId);
    };

    forgetUser = () => {
        try {
            localStorage.removeItem(REMEMBERED_FLUXE_USER_KEY);
        } catch { /* empty */ }
    };

    validateLogin = (): boolean => {
        const { fields } = this.state;

        let errors: IFormError | null = getFormErrors(fields, VALIDATIONS.LOGIN_FORM);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    getUserEmail = () => {
        const { fields } = this.state;

        const emailRemembered: string = localStorage.getItem(REMEMBERED_FLUXE_USER_KEY) || '';

        this.setState({
            fields: {
                ...fields,
                email: emailRemembered,
            },
            rememberMe: emailRemembered !== '',
        });
    };

    rememberUser = () => {
        const { fields } = this.state;
        try {
            localStorage.setItem(REMEMBERED_FLUXE_USER_KEY, fields.email);
        } catch { /* empty */ }
    };

    render() {
        const {
            t,
        } = this.props;

        const { rememberMe, fields, formErrors } = this.state;

        const rememberMeClass: string = rememberMe ? 'selected' : '';

        return (
            <div className="entry-page">
                <div className="entry-page__centered">
                    <img src={logo} alt="logo" />
                    <form onSubmit={this.onSubmit}>
                        <div className="entry-page__centered__fields">
                            <EntryPageFormTextfield
                                name="email"
                                value={fields.email}
                                placeholder={t('login.labels.user')}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.email', null)}
                            />
                            <EntryPageFormTextfield
                                name="password"
                                value={fields.password}
                                type="password"
                                placeholder={t('login.labels.password')}
                                onChange={this.onInputChange}
                                endIcon={ICON.PASSWORD_FILLED}
                                errors={get(formErrors, 'fields.password', null)}
                            />
                        </div>
                        <div className="form-error-container">
                            <ErrorMessage
                                type={ERROR_MESSAGE_TYPE.LOGIC}
                                errors={get(formErrors, 'errors', null)}
                            />
                        </div>
                        <div className={`entry-page__centered__fields__remember-me ${rememberMeClass}`}>
                            <SvgIcon icon={ICON.CIRCLE} callback={this.onRememberMeClick} />
                            <p>{t('login.labels.rememberMe')}</p>
                        </div>
                        <ButtonDeprecated
                            data-test-id="login-submit-button"
                            type="submit"
                            text={t('login.labels.enterBtn')}
                            styles="btn--purple"
                        />
                    </form>
                    <ButtonDeprecated
                        styles="btn--forgot-password"
                        text={t('login.labels.forgotCredentials')}
                        callback={this.onRecoverPasswordClick}
                        isLink
                    />
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(withRouter(LoginScreen)));
