/**
 *
 * @Copyright 2022 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';
import { ConnectedBrokenGlassControlController, BrokenGlassControlController } from './BrokenGlassControlController';

export interface BrokenGlassControlContext extends Omit<InstanceType<typeof BrokenGlassControlController>, keyof React.Component> {}

const BrokenGlassControlContextInstance = createContext<BrokenGlassControlContext | null>(null);

export const BrokenGlassControlContextProvider = BrokenGlassControlContextInstance.Provider;
export const BrokenGlassControlContextConsumer = BrokenGlassControlContextInstance.Consumer;

export const withBrokenGlassControlContext = <P, >(
    Component: ComponentType<P>,
): FC<Omit<P, keyof BrokenGlassControlContext>> => (props) => (
    <ConnectedBrokenGlassControlController>
        <BrokenGlassControlContextConsumer>
            { (ctx) => <Component {...props as P} {...ctx} />}
        </BrokenGlassControlContextConsumer>
    </ConnectedBrokenGlassControlController>
    );
