/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { isEqual, omit } from 'lodash';
import { ListOrder, RESULTS_PER_PAGE } from '../../../constants/misc';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import {
    MessageContext,
    withMessageContext,
} from '../../controllers/messages/MessageContext';
import TableHeader from '../../elements/TableHeader';
import TableCell, { TableCellType } from '../../elements/TableCell';
import TablePaging from '../../elements/TablePaging';
import { Dispute } from '../../../constants/types';
import { PRE_INSPECTIONS_EDIT_ROUTE } from '../../../constants/routes';
import withQueryParams, { WithQueryParamsProps } from '../../hocs/withQueryParams';
import { WithRouterProps, withRouter } from '../../containers/withRouter';
import { PreInspectionsContext, withPreInspectionsContext } from '../../controllers/preInspections/PreInspectionsContext';

interface OwnProps extends WithRouterProps, TranslationContext, MessageContext, PreInspectionsContext, WithQueryParamsProps {}

interface OwnState {
    isFetching: boolean;
    data: Dispute[];
    totalResults: number;
    dispute: Dispute | null;
}

const initialState: OwnState = {
    isFetching: false,
    data: [],
    totalResults: 0,
    dispute: null,
};

class DisputesListScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { location, onQueryParamsChange } = this.props;

        onQueryParamsChange(location.search);
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>) {
        const { listParams, location, onQueryParamsChange } = this.props;
        const { listParams: oldListParams, location: oldLocation } = prevProps;

        if (location.search !== oldLocation.search) {
            onQueryParamsChange(location.search);
        }

        if (!isEqual(omit(listParams, ['search']), omit(oldListParams, ['search']))) {
            this.loadList();
        }
    }

    onGetDisputeSuccess = () => {
        const { navigate, unreadMessageAndDisputes } = this.props;
        const { dispute } = this.state;

        if (!dispute) return;
        
        this.setState({
            isFetching: false,
        });
        navigate(`${PRE_INSPECTIONS_EDIT_ROUTE}/${dispute.preInspectionId}`);
        unreadMessageAndDisputes();
    };

    onGetDisputeFailure = () => {
        const { navigate } = this.props;
        const { dispute } = this.state;

        if (!dispute) return;

        navigate(`${PRE_INSPECTIONS_EDIT_ROUTE}/${dispute.preInspectionId}`);
        this.setState({
            isFetching: false,
        });
    };

    onDisputeClick = (dispute: Dispute) => {
        const { getDispute } = this.props;
        const { isFetching } = this.state;

        if (isFetching) return;

        this.setState({
            isFetching: true,
            dispute,
        });

        getDispute(String(dispute.id), this.onGetDisputeSuccess, this.onGetDisputeFailure);
    };

    loadList = async () => {
        const {
            listParams,
            getDisputes,
        } = this.props;
        const { isFetching } = this.state;

        if (isFetching) return;

        try {
            const sent = false;
            this.setState({
                isFetching: true,
            });

            const params = {
                _page: listParams ? listParams.page - 1 : 0,
                _limit: RESULTS_PER_PAGE,
                _sort: listParams?.sort || 'createdDate',
                _order: listParams?.order || ListOrder.Desc,
                sent,
            };

            const result = await getDisputes(params);

            this.setState({
                data: result.data,
                totalResults: result.total,
                isFetching: false,
            });
        } catch (error) {
            this.setState({
                isFetching: false,
            });
        }
    };

    render() {
        const {
            t, listParams,
        } = this.props;
        const { data, totalResults } = this.state;
        const hasData: boolean = data && data.length > 0;

        return (
            <div>
                <div className="app-screen__table">
                    <div className="app-screen__table__content">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <TableHeader
                                            text={t('disputes.list.headers.preInspectionUID')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="preInspectionUID"
                                            sortable
                                        />
                                    </th>
                                    <th>
                                        <TableHeader
                                            text={t('disputes.list.headers.createdDate')}
                                            sort={listParams?.sort}
                                            order={listParams?.order}
                                            field="createdDate"
                                            sortable
                                        />
                                    </th>
                                </tr>
                            </thead>
                            {hasData ? (
                                <tbody>
                                    { data.map((dispute) => {
                                        const {
                                            preInspectionUID, createdDate, seen,
                                        } = dispute;

                                        return (
                                            <tr key={preInspectionUID} className={seen ? '' : 'not-seen'} onClick={() => this.onDisputeClick(dispute)}>
                                                <TableCell
                                                    value={preInspectionUID}
                                                />
                                                <TableCell
                                                    type={TableCellType.DATE}
                                                    value={createdDate}
                                                />
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            ) : (
                                <tbody className="no-data">
                                    <tr>
                                        <td colSpan={5}>{t('global.noData')}</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                        {hasData && (
                            <TablePaging
                                currentPage={listParams?.page || 0}
                                limit={RESULTS_PER_PAGE}
                                totalResults={totalResults}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withMessageContext(withRouter(withTranslationContext(withPreInspectionsContext(withQueryParams(DisputesListScreen)))));
