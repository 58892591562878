/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';
import { ConnectedPreInspectionsController, PreInspectionsController } from './PreInspectionsController';

export interface PreInspectionsContext extends Omit<InstanceType<typeof PreInspectionsController>, keyof React.Component> {}

const preInspectionsContextInstance = createContext<PreInspectionsContext | null>(null);

export const PreInspectionsContextProvider = preInspectionsContextInstance.Provider;
export const PreInspectionsContextConsumer = preInspectionsContextInstance.Consumer;

export const withPreInspectionsContext = <P, >(Component: ComponentType<P>): FC<Omit<P, keyof PreInspectionsContext>> => (props) => (
    <ConnectedPreInspectionsController>
        <PreInspectionsContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </PreInspectionsContextConsumer>
    </ConnectedPreInspectionsController>
);
