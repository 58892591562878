/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */
import { UserRoles } from './authorization';
import { SelectOption } from './misc';

export interface CasualtyRequest {
    id: number | null;
    casualtyDate: string | number;
    uid: string;
    compensationType: string | null;
    deductibleValue: number | null;
    expertiseDate: string | number;
    expertiseNumber: string;
    expertiseObservations: string;
    expertiseSituation: string | null;
    insuranceCompany?: InsuranceCompany;
    insuranceCompanyId: number | null;
    insurancePolicyNumber: string;
    insuranceCompanyName: string | null;
    insuredValue: number | null;
    mechanicName: string | null;
    mechanicId: number | null;
    vehicleBrand: string;
    vehicleInfo: VehicleInfo | null;
    vehicleLicensePlate: string;
    vehicleModel: string;
    vehicleVersion: string;
    vehicleOwner: string;
    vehicleOwnerContact: string | null;
    insuranceProcessNumber: string;
    status: string;
    creatorId: number | null;
    vin: string | null;
    apiKey: string;
}

export interface VehicleInfo {
    colourCode: string;
    colourDescription: string;
    createdDate: string;
    deliveryDate: string;
    id: string;
    lastModifiedDate: string | null;
    ldtDeliveryDate: string | null;
    ldtProductionDate: string | null;
    ldtRegistrationDate: string | null;
    productionDate: string | null;
    registrationDate: string | null;
    statusCode: string | null;
    statusMessage: string | null;
    topCode: string | null;
    topDescription: string | null;
    upholsteryCode: string | null;
    upholsteryDescription: string | null;
    vehicles: VehicleFromVehicleInfo | null;
    voc: string | null;
    voc2: string | null;
}

export interface VehicleFromVehicleInfo {
    vehicle: Vehicle[] | null;
}

export interface CasualtyResponse {
    createdDate: string;
    casualtyDate: string;
    compensationType: string;
    deductibleValue: number | null;
    expertiseDate: string;
    expertiseNumber: string;
    expertiseObservations: string;
    expertiseSituation: string;
    insuranceCompanyId: number | null;
    insurancePolicyNumber: string;
    insuredValue: number | null;
    mechanicCounty: string;
    mechanicDistrict: string;
    mechanicId: number | null;
    mechanicLocality: string;
    mechanicParish: string;
    mechanicPhoneNumber: number | null;
    mechanicPostalCode: number | null;
    mechanicStreet: string;
    vehicleBrand: string;
    vehicleLicensePlate: string;
    vehicleModel: string;
    vehicleOwner: string;
    vehicleOwnerContact: string;
    insuranceCompany: InsuranceCompany;
    mechanic: Mechanic;
    status: string;
    uid: string;
}

export interface InsuranceCompany extends User {
    nif: string;
    apiKey: string;
    pilotInsuranceCompany: boolean;
}

export interface Mechanic {
    createdDate: string;
    id: number;
    contact: number;
    email: string;
    inactive: boolean;
    name: string;
    role: string;
    contactName: string;
    contactTitle: string;
    county: string;
    district: string;
    locality: string;
    nif: string;
    parish: string;
    postalCode: number;
    street: number;
}

export interface User {
    id: number;
    createdDate: number;
    name: string;
    role: string;
    email: string;
    contact: number;
    inactive: boolean;
    apiKey?: string;
}

export interface GlassProvider extends User {
    registered: boolean;
    nif: string;
    district: string;
}

export interface Message {
    id: number;
    from: User;
    to: User;
    subject: string;
    createdDate: number;
    casualtyId: string;
    seen: boolean;
    seenAt: string | null;
    text: string;
    casualtyUID: string;
}

export interface Dispute {
    createdDate: number;
    id: number;
    preInspectionId: number;
    preInspectionUID: string;
    seen: boolean;
    seenAt: number | null;
    seenBy: number;
}

export interface MessageRequest {
    casualtyId: number | null;
    subject: string;
    text: string;
}

export interface InformativeNoteRequest {
    mileage: number | null;
    message: string;
}

export interface DamageInfo {
    carPartType: CarPartType;
    damageArea?: number;
    damagePercentage?: number;
  }

export interface FileResponse {
    id: number;
    originalFileName: string;
    mimeType: string;
    url: string;
    createdDate: number;
    fileType: string;
    glassDamage: boolean;
    bodyworkDamage: boolean;
    damageInfo: DamageInfo[];
}

export interface PreInspection {
    createdDate: string;
    doneDate: string | null;
    id: number | null;
    agentAddress: string;
    agentContact: string;
    agentEmail: string;
    agentName: string;
    insuranceCompany: InsuranceCompany | null;
    insuranceExtrasAmount: number | null;
    insuranceStartDate: string;
    insuranceYearlyAmount: number | null;
    insured: string;
    insuredContact: string;
    insuredEmail: string;
    marketValue: number | null;
    mediatorNumber: string;
    observations: string;
    simulationDate: string;
    simulationNumber: string;
    sendPhotosDate: number;
    status: PreInspectionsStatus | null;
    insuranceTylaCode: string;
    uid: string;
    userToken: string;
    type: string;
    vehicleBrand: string;
    vehicleLicensePlate: string;
    vehicleModel: string;
    eurotaxTylaCode: string | null;
    images: Array<FileResponse>;
    repairCost: number | null;
    result: PreInspectionsStatus | null;
    vehicleColor: string;
    vehicleImported: boolean | null;
    vehicleManufactureDate: string;
    vehicleLicenseDate: string;
    vehicleMileage: number;
    mileageReadout: string | null;
    inspected: boolean | null;
    inspectionDate: string;
    designatedValue: string;
    eurotaxPurchaseValue: string;
    eurotaxSaleValue: string;
    glassDamageEstimate: string;
    insuranceEurotaxValue: string;
    vin: string;
    vehicleInfo?: VehicleInfo|null;
    vehiclePower: string | null;
    vehicleVersion: string | null;
    pciVehicleBrand: string | null;
    pciVehicleModel: string | null;
    vehicleCylinderVolume: string | null;
    vehicleFuelType: string | null;
}

export interface RequestClose {
    sendReport: boolean;
    insuranceCompany: string;
    email: string;
}

export interface RequestShare {
    deliveryType: DeliveryType;
    email: string | null;
    phone: string | null;
}

export enum DeliveryType {
    BOTH = 'BOTH',
    SMS = 'SMS',
    EMAIL = 'EMAIL',
}

export interface BudgetValueItem {
   description: string;
   labourHours: string;
   rate: string;
   labourRate?: string;
   value: string;
}

export interface Vehicle {
    bodyType: string | null;
    engineKW: string | null;
    enginePS: string | null;
    engineSize: number;
    fuelType: string | null;
    gearType: string | null;
    makeDescription: string | null;
    modelDescription: string | null;
    numberDoors: string | null;
    numberGears: string | null;
    periodOfBuild: string | null;
    priceBrutto: string | null;
    priceNetto: string | null;
    typeDescription: string | null;
    typeETGCode: string | null;
    vehType: string | null;
}

export interface TimePosition {
    id: string;
    time: number;
    etgTime: number;
    overlapTime: number;
    etgPrice: number;
    price: number;
    posType: string;
}

export interface Calculation {
    createdDate: string;
    id: string;
    rate: {
        createdDate: string;
        discount: {
            discMatPaint: number;
            discMatPaintMaxAmount: number;
            discMatPaintSpecifier: string;
            discMatPart: number;
            discMatPartMaxAmount: number;
            discMatPartSpecifier: string;
            discOverall: number | null;
            discWorkPaint: number;
            discWorkPaintMaxAmount: number;
            discWorkPaintSpecifier: string;
            discWorkPart: number;
            discWorkPartMaxAmount: number;
            discWorkPartSpecifier: string;
        };
        id: string;
        labourRate: {
            painter: number;
        };
        vat: {
            vatmaterial: number;
            vatlabour: number;
        };
    };
    calcSetting: {
        currency: string;
    };
    position: TimePosition[];
    result: {
        createdDate: string;
        exclVatResults: {
            discMatPaint: number;
            discMatParts: number;
            discOverall: number;
            discWorkPaint: number;
            discWorkParts: number;
            prevDamDeduct: number;
            surChargeMatPaint: number;
            surChargeMatParts: number;
            surChargeWorkPaint: number;
            surChargeWorkParts: number;
            totRepCostExclVat: number;
            total1: number;
            total2: number;
            total3: number;
        };
        exclVatStatisticResults: {
            totalAmountAdditionalCosts: number;
            totalAmountLabourCosts: number;
            totalAmountPaint: number;
            totalAmountParts: number;
        };
        id: string;
        inclVatResults: {
            grandTotal: number;
            selfKeptDeduct: number;
            totRepCostInclVat: number;
            vatMat: number;
            vatWork: number;
        };
    };
}

export interface Estimation {
    attachment: any;
    calculation: Calculation;
    contactInfo: any;
    createdDate: string;
    fileDamage: any;
    globalSetting: object;
    id: string;
    lastModifiedDate: string;
    uid: string | null;
    valuation: any | null;
    vehConfig: any | null;
    vehTyres: any | null;
    vininformation: any | null;
}

export interface EuroTaxBudget {
    createdDate: string;
    estimation: Estimation | null;
    estimationId: string;
    expiryDate: string | null;
    id: string;
    lastModifiedDate: string | null;
    redirectUrl: string | null;
    sessionId: string | null;
    generalState: string | null;
    mechanicEntryDate: string;
    mileageReadout: string | null;
    vehicleColor: string | null;
    vehicleImported: boolean | null;
    vehicleInspectionDate: string;
    vehicleLicenseDate: string;
    vehicleManufactureDate: string;
    vehicleOperative: boolean;
    vehicleMileage: string | null;
    vehicleInspected: boolean | null;
    mechanicRepairDays: number;
}

export enum PreInspectionsStatus {
    CANCELED = 'CANCELED',
    DONE = 'DONE',
    ONGOING = 'ONGOING',
    PENDING = 'PENDING',
    REOPENED = 'REOPENED',
}

export interface FileType {
    id: number;
    createdDate: number;
    fileType: string | null;
    mimeType: string;
    originalFileName: string;
    bodyworkDamage: boolean;
    glassDamage: boolean;
}

export interface FileDamage {
    id: number;
    damageType: FileDamageType;
    damageSeverityType: FileDamageSeverityType;
    polygonPoints: PolygonPoint[];
}

export enum FileDamageType {
    BODYWORK = 'BODYWORK',
    GLASS = 'GLASS',
}

export enum FileDamageSeverityType {
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
}

export enum CasualtyStatus {
    CANCELED = 'CANCELED',
    DONE = 'DONE',
    ONGOING = 'ONGOING',
}

export interface StatisticQueryParams {
   year?: string;
   mechanicId?: string;
   insuranceCompanyId?: string;
}

export interface StatisticItem {
    avgDuration?: string|null;
    avgPrice?: string|null;
    avgPriceNormalized: number;
    avgDurationNormalized: number;
    quantityNormalized: number;
    periodType?: string;
    periodValue?: number;
    quantity?: number;
}

export interface ChartObjectInfo {
    labels: Array<string>;
    dataset1: Array<ChartDataset>;
    labelsDataset1: Array<string>;
    dataset2?: Array<ChartDataset>;
    labelsDataset2?: Array<string>;
    dataset3?: Array<ChartDataset>;
    labelsDataset3?: Array<string>;
}

export interface ChartDataset {
    label: string;
    value: number;
}

export enum PeriodType {
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

export interface Box {
    id: number;
    comment: string;
    isEditing: boolean;
    isSelected: boolean;
    horizontalPositionTopLeft: number;
    verticalPositionTopLeft: number;
    horizontalPositionBottomRight: number;
    verticalPositionBottomRight: number;
    isGlassDamage?: boolean;
}

export interface BrokenGlassControlRequest {
    apiKey: string;
    insurancePolicyNumber: string | null;
    controlDate: string;
    insured: string | null;
    insuredContact: string | null;
    insuredEmail: string | null;
    vehicleLicensePlate: string | null;
    vehicleBrand: string | null;
    vehicleModel: string | null;
    advancedDriverAssistanceSystem: boolean;
    glassProviderId: number;
    costAmount: number | null;
}

export interface BrokenGlassControlResponse {
    id: number;
    uid: string;
    creatorId: number;
    insured: string | null;
    insuredContact: string | null;
    insuredEmail: string | null;
    vehicleLicensePlate: string | null;
    vehicleBrand: string | null;
    vehicleModel: string | null;
    createdDate: string;
    doneDate: string | null;
    advancedDriverAssistanceSystem: boolean;
    costAmount: number | null;
    insuranceCompany: InsuranceCompany;
    insurancePolicyNumber: string | null;
    controlDate: string;
    status: BrokenGlassControlStatus;
    glassProvider: GlassProvider;
}

export interface BrokenGlassControlSearchResponse {
    id: number;
    uid: string;
    insured: string | null;
    vehicleLicensePlate: string | null;
    vehicleBrand: string | null;
    vehicleModel: string | null;
    doneDate: string | null;
    insuranceCompanyId: number;
    insuranceCompanyName: string | null;
    insurancePolicyNumber: string | null;
    controlDate: string;
    status: BrokenGlassControlStatus;
    glassProviderId: number;
    glassProviderDistrict: string | null;
    glassProviderEmail: string;
}

export interface BrokenGlassControlDocuments {
    id: number;
    checkInFrontWindshieldPhotoId: number | null;
    checkInFrontRightWindshieldPhotoId: number | null;
    checkInFrontLeftWindshieldPhotoId: number | null;
    checkInSerigraphyPhotoId: number | null;
    checkInFrontBodyPhotoId: number | null;
    checkInAdditionalPhotosIds: number[];
    checkOutFrontWindshieldPhotoId: number | null;
    checkOutFrontRightWindshieldPhotoId: number | null;
    checkOutFrontLeftWindshieldPhotoId: number | null;
    checkOutSerigraphyPhotoId: number | null;
    checkOutFrontBodyPhotoId: number | null;
    checkOutAdditionalPhotosIds: number[];
}

export enum BrokenGlassControlStatus {
    DONE = 'DONE',
    ONGOING = 'ONGOING',
    PENDING = 'PENDING',
}

export interface ApiFile {
    id: number;
    fileString: string;
    index?: number;
}

export interface PolygonPoint {
    x: number;
    y: number;
}

export interface BrokenGlassControlImages {
    [key: string]: ApiFile | null;
    frontBody: ApiFile | null;
    frontRightWindshield: ApiFile | null;
    frontLeftWindshield: ApiFile | null;
    frontWindshield: ApiFile | null;
    serigraphy: ApiFile | null;
}

export interface ListResponse<T> {
    results: Array<T>;
    total: number;
}

export interface UserOptionsResponse {
    options: Array<SelectOption>;
    users: Array<User>;
}

export interface ListData<T> {
    failed: boolean;
    list: T[];
}

export enum UserRoleParam {
    Collaborator = 'COLLABORATOR',
    Mechanic = 'MECHANIC',
    Admin = 'ADMIN',
    InsuranceUser = 'INSURANCE_USER',
    ExternalAdmin = 'ADMIN_EXTERNAL',
    GlassProvider = 'GLASS_PROVIDER',
}

export interface ControlInSeries {
    providerType: string;
    entriesNumberToProcess: number;
}

export interface UserResponse {
    id: number;
    email: string;
    name: string;
    role: UserRoles;
    contact: string;
    inactive: boolean;
    registered: boolean;
    avatarUrl: string;
    collaboratorId: number;
    nif: string | null;
    district: string;
    street: string;
    postalCode: number | null;
    locality: string;
    parish: string;
    county: string;
    contactName: string;
    contactTitle: string;
    labourRatePaint: number;
    labourRateMechanic: number;
}

export enum CarPartType {
    UNDEFINED = 'UNDEFINED',
    FENDER = 'FENDER',
    FRONT_BUMPER = 'FRONT_BUMPER',
    FRONT_DOOR = 'FRONT_DOOR',
    FRONT_WINDOW = 'FRONT_WINDOW',
    FRONT_WINDSHIELD = 'FRONT_WINDSHIELD',
    GRILLE = 'GRILLE',
    HEADLIGHT = 'HEADLIGHT',
    HOOD = 'HOOD',
    LEFT_FENDER = 'LEFT_FENDER',
    LEFT_FRONT_DOOR = 'LEFT_FRONT_DOOR',
    LEFT_QUARTER_PANEL = 'LEFT_QUARTER_PANEL',
    LEFT_REAR_DOOR = 'LEFT_REAR_DOOR',
    LEFT_ROCKER_PANEL = 'LEFT_ROCKER_PANEL',
    LEFT_WHEEL_RIM_FRONT = 'LEFT_WHEEL_RIM_FRONT',
    LEFT_WHEEL_RIM_REAR = 'LEFT_WHEEL_RIM_REAR',
    LICENSE_PLATE = 'LICENSE_PLATE',
    MIRROR = 'MIRROR',
    QUARTER_PANEL = 'QUARTER_PANEL',
    REAR = 'REAR',
    REAR_BUMPER = 'REAR_BUMPER',
    REAR_DOOR = 'REAR_DOOR',
    REAR_WINDOW = 'REAR_WINDOW',
    REAR_WINDSHIELD = 'REAR_WINDSHIELD',
    RIGHT_FENDER = 'RIGHT_FENDER',
    RIGHT_FRONT_DOOR = 'RIGHT_FRONT_DOOR',
    RIGHT_QUARTER_PANEL = 'RIGHT_QUARTER_PANEL',
    RIGHT_REAR_DOOR = 'RIGHT_REAR_DOOR',
    RIGHT_ROCKER_PANEL = 'RIGHT_ROCKER_PANEL',
    RIGHT_WHEEL_RIM_FRONT = 'RIGHT_WHEEL_RIM_FRONT',
    RIGHT_WHEEL_RIM_REAR = 'RIGHT_WHEEL_RIM_REAR',
    ROCKER_PANEL = 'ROCKER_PANEL',
    ROOF = 'ROOF',
    TAIL_LIGHT = 'TAIL_LIGHT',
    TRUNK = 'TRUNK',
    WHEEL_RIM_FRONT = 'WHEEL_RIM_FRONT',
    WHEEL_RIM_REAR = 'WHEEL_RIM_REAR',
    WINDSHIELD = 'WINDSHIELD'
}

export interface CarPartOutline {
    type: CarPartType;
    outline?: PolygonPoint[];
}
