/* eslint-disable no-nested-ternary, @typescript-eslint/no-explicit-any */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, Fragment } from 'react';
import { get } from 'lodash';
import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import {
    ImageFileType,
    PreInspectionResult,
    PreInspectionType,
    SelectOption,
} from '../../../constants/misc';
import {
    TranslationContext,
    withTranslationContext,
} from '../../controllers/translation/TranslationContext';
import {
    AuthenticationContext,
    withAuthenticationContext,
} from '../../controllers/authentication/AuthenticationContext';
import {
    AuthorizationContext,
    withAuthorizationContext,
} from '../../controllers/authorization/AuthorizationContext';
import Loader from '../../elements/Loader';
import {
    preInspectionCompleteCancelURL,
    preInspectionCompleteReopenURL,
    preInspectionGlassCancelURL,
    preInspectionGlassReopenURL,
    preInspectionResetTokenURL,
    preInspectionVideoTokenURL,
    preInspectionGlassResumeURL,
    preInspectionCompleteResumeURL,
} from '../../../services/preInspections';
import {
    DeliveryType, FileResponse, PreInspection, PreInspectionsStatus,
} from '../../../constants/types';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import { PRE_INSPECTIONS_DETAILS_ROUTE } from '../../../constants/routes';
import ButtonDeprecated from '../../elements/ButtonDeprecated';
import CheckBox from '../../elements/CheckBox';
import FormTextField from '../../elements/FormTextField';
import FormDatePicker from '../../elements/FormDatePicker';
import {
    numberToCurrency,
    validateString,
    setDateTime,
} from '../../../utils/misc';
import FormTextAreaField from '../../elements/FormTextAreaField';
import displayConfirm from '../../elements/displayConfirm';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import {
    getFormErrors, IFormError, VALIDATIONS,
} from '../../../utils/validation';
import GeneralModal from '../../elements/GeneralModal';
import FormSelectField from '../../elements/FormSelectField';
import { VideoContextInterface, withVideoContext } from '../../controllers/video/VideoContext';
import VideoComponent from '../../elements/VideoComponent';
import { SocketReceivedMessage, SocketReceivedMessageType } from '../../../constants/socket_types';
import SendMailModal from '../../elements/SendMailModal';
import logoFluxe from '../../../assets/images/logo_fluxe_white.svg';
import iconCopy from '../../../assets/images/copy.svg';
import { UserRoles } from '../../../constants/authorization';
import { API_URL } from '../../../settings';
import ReassessModal from '../../elements/ReassessModal';
import { withRouter, WithRouterProps } from '../../containers/withRouter';
import DrawImagesPolygonModal from '../../elements/DrawImagesPolygonModal';
import { PreInspectionsContext, withPreInspectionsContext } from '../../controllers/preInspections/PreInspectionsContext';
import ImageCard from '../../elements/ImageCard';

interface OwnProps extends WithRouterProps, TranslationContext, AuthenticationContext, AuthorizationContext, VideoContextInterface, PreInspectionsContext {}

interface OwnState {
    id: string | null;
    preparing: boolean;
    preInspection: PreInspection;
    formErrors: any;
    showingModal: boolean;
    imgToShow: string;
    imgId: number;
    isFetching: boolean;
    isComplete: boolean;
    newToken: string;
    showingNewTokenModal: boolean;
    resultOptions: Array<SelectOption>;
    imgLabelToShow: string | null;
    isOnVideoCall: boolean;
    isVideoBig: boolean;
    viewingDetails: boolean;
    pendingCandidates: Array<SocketReceivedMessage>;
    hasModifications: boolean;
    modificationsSaved: boolean;
    shareEmail: boolean;
    emailToShare: string;
    sharePhone: boolean;
    phoneToShare: string;
    importedOptions: Array<SelectOption>;
    readOptions: Array<SelectOption>;
    isVideoShareToken: boolean;
    showConcludeModal: boolean;
    previousGlassResult: string;
    showReassessModal: boolean;
    preInspectionThumbnailURL: Record<number, string>;
}

const initialState: OwnState = {
    id: '',
    preparing: false,
    formErrors: null,
    showingModal: false,
    isFetching: false,
    imgToShow: '',
    imgId: 0,
    isComplete: true,
    showingNewTokenModal: false,
    newToken: '',
    resultOptions: [],
    imgLabelToShow: null,
    isOnVideoCall: false,
    pendingCandidates: [],
    isVideoBig: false,
    viewingDetails: false,
    hasModifications: false,
    modificationsSaved: false,
    shareEmail: false,
    emailToShare: '',
    sharePhone: false,
    phoneToShare: '',
    preInspection: {
        createdDate: '',
        doneDate: null,
        id: null,
        agentAddress: '',
        agentContact: '',
        agentEmail: '',
        agentName: '',
        insuranceCompany: null,
        insuranceExtrasAmount: null,
        insuranceStartDate: '',
        insuranceYearlyAmount: null,
        insured: '',
        insuredContact: '',
        insuredEmail: '',
        marketValue: null,
        mediatorNumber: '',
        observations: '',
        simulationDate: '',
        sendPhotosDate: 0,
        simulationNumber: '',
        status: null,
        insuranceTylaCode: '',
        uid: '',
        userToken: '',
        vehicleBrand: '',
        vehicleLicensePlate: '',
        vehicleModel: '',
        type: '',
        images: [],
        eurotaxTylaCode: null,
        repairCost: null,
        result: null,
        vehicleColor: '',
        vehicleImported: null,
        vehicleManufactureDate: '',
        vehicleLicenseDate: '',
        vehicleMileage: 0,
        mileageReadout: null,
        inspected: null,
        inspectionDate: '',
        designatedValue: '',
        eurotaxPurchaseValue: '',
        eurotaxSaleValue: '',
        insuranceEurotaxValue: '',
        glassDamageEstimate: '',
        vin: '',
        vehicleInfo: null,
        pciVehicleBrand: null,
        pciVehicleModel: null,
        vehicleCylinderVolume: null,
        vehicleFuelType: null,
        vehiclePower: null,
        vehicleVersion: null,
    },
    importedOptions: [],
    readOptions: [],
    isVideoShareToken: false,
    showConcludeModal: false,
    previousGlassResult: '',
    showReassessModal: false,
    preInspectionThumbnailURL: {},
};

class PreInspectionsFormScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    private readonly yesKeyword: string = 'keywords.yes';

    private readonly noKeyword: string = 'keywords.no';

    private readonly noneKeyword: string = 'keywords.none';

    private readonly cancelButton: string = 'global.buttons.cancel';

    private readonly acceptButton: string = 'global.buttons.accept';

    private readonly rejectButton: string = 'global.buttons.reject';

    private readonly preInspectionFormVehicleModel: string = 'preInspections.form.labels.vehicleModel';

    private readonly preInspectionFormVehicleBrand: string = 'preInspections.form.labels.vehicleBrand';

    private readonly preInspectionFormName: string = 'preInspections.form.labels.name';

    private readonly dateFormat: string = 'DD/MM/YYYY';

    private readonly actualGlassResult: string = 'preInspections.form.labels.actualResult';

    componentDidMount(): void {
        const {
            t, params, candidates, hasCandidates, updatePendingCandidates, location,
        } = this.props;
        const { pathname } = location;
        const { id } = params;

        let viewingDetails = false;
        if (pathname.startsWith(PRE_INSPECTIONS_DETAILS_ROUTE)) {
            viewingDetails = true;
        }

        const resultOptions: Array<SelectOption> = [];
        resultOptions.push({
            value: PreInspectionResult.DAMAGED,
            label: t(`enums.preInspectionResult.${PreInspectionResult.DAMAGED}`),
        }, {
            value: PreInspectionResult.OK,
            label: t(`enums.preInspectionResult.${PreInspectionResult.OK}`),
        });

        let isOnVideoCall = false;
        let pendingCandidates: Array<SocketReceivedMessage> = [];
        if (hasCandidates) {
            isOnVideoCall = true;

            if (candidates) {
                pendingCandidates = candidates;
            }

            updatePendingCandidates(false, []);
        }

        this.setState({
            id: id || null,
            resultOptions,
            isOnVideoCall,
            pendingCandidates,
            viewingDetails,
            importedOptions: [
                {
                    value: null,
                    label: t(this.noneKeyword),
                },
                {
                    value: 'yes',
                    label: t(this.yesKeyword),
                },
                {
                    value: 'no',
                    label: t(this.noKeyword),
                },
            ],
            readOptions: [
                {
                    value: 'REAL',
                    label: t('keywords.REAL'),
                },
                {
                    value: 'ESTIMATE',
                    label: t('keywords.ESTIMATED'),
                },
            ],
        }, () => this.prepare());
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>): void {
        const {
            answerCall: oldAnswerCall,
            callDeclined: oldCallDeclined,
            signalMessage: oldSignalMessage,
        } = prevProps;
        const {
            answerCall,
            callDeclined,
            signalMessage,
            wasIceCandidateFound,
        } = this.props;
        const { isOnVideoCall, pendingCandidates } = this.state;

        if (wasIceCandidateFound && !isOnVideoCall && signalMessage && signalMessage !== oldSignalMessage && signalMessage.messageType === SocketReceivedMessageType.ICE_CANDIDATE_FOUND) {
            pendingCandidates.push(signalMessage);
            this.setState({ pendingCandidates: [...pendingCandidates] });
        }

        if (answerCall !== oldAnswerCall && answerCall) {
            this.setState({
                isOnVideoCall: true,
            });
            return;
        }

        if (callDeclined && (callDeclined !== oldCallDeclined)) {
            this.setState({
                isOnVideoCall: false,
            });
        }
    }

    onBackClick = () => {
        const { navigate } = this.props;
        navigate(-1);
    };

    onConcludeClick = () => {
        const { t } = this.props;
        const { modificationsSaved, hasModifications } = this.state;

        if (hasModifications && !modificationsSaved) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('errors.modificationsWasNotSaved'));
            return;
        }

        this.setState({
            showConcludeModal: true,
        });
    };

    onCancelClick = () => {
        const { t } = this.props;
        const { preInspection } = this.state;
        const { id } = preInspection;

        displayConfirm({
            acceptButtonText: t(this.acceptButton),
            onAccept: () => this.cancelRequest(String(id)),
            onReject: () => {},
            rejectButtonText: t(this.rejectButton),
            title: t('preInspections.form.cancelConfirmationMsg'),
        });
    };

    onConcludeGlassClick = () => {
        const { t } = this.props;
        const { preInspection } = this.state;
        const { id } = preInspection;

        displayConfirm({
            acceptButtonText: t(this.acceptButton),
            onAccept: () => this.concludeRequest(String(id)),
            onReject: () => {},
            rejectButtonText: t(this.rejectButton),
            title: t('preInspections.form.closeConfirmationMsg'),
        });
    };

    onRestartClick = () => {
        const { t } = this.props;
        const { preInspection } = this.state;
        const { id } = preInspection;

        displayConfirm({
            acceptButtonText: t(this.acceptButton),
            onAccept: () => this.restartRequest(String(id)),
            onReject: () => {},
            rejectButtonText: t(this.rejectButton),
            title: t('preInspections.form.restartConfirmationMsg'),
        });
    };

    onReopenClick = () => {
        const { t } = this.props;
        const { preInspection } = this.state;
        const { id } = preInspection;

        displayConfirm({
            acceptButtonText: t(this.acceptButton),
            onAccept: () => this.reopenRequest(String(id)),
            onReject: () => {},
            rejectButtonText: t(this.rejectButton),
            title: t('preInspections.form.reopenConfirmationMsg'),
        });
    };

    onNewTokenClick = () => {
        const { t } = this.props;
        const { preInspection } = this.state;
        const { id } = preInspection;

        displayConfirm({
            acceptButtonText: t(this.acceptButton),
            onAccept: () => this.newTokenRequest(String(id)),
            onReject: () => {},
            rejectButtonText: t(this.rejectButton),
            title: t('preInspections.form.newTokenConfirmationMsg'),
        });
    };

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { preInspection } = this.state;
        const { name, value } = e.currentTarget;

        if (name === 'vehicleImported' || name === 'inspected') {
            let valueSelected: boolean | null = null;

            if (value === 'no') {
                valueSelected = false;
            }

            if (value === 'yes') {
                valueSelected = true;
            }

            this.setState((prevState) => ({
                ...prevState,
                hasModifications: true,
                modificationsSaved: false,
                preInspection: {
                    ...prevState.preInspection,
                    [name]: valueSelected,
                },
            }), () => {
                if (name === 'inspected' && !valueSelected) {
                    this.setState((prevState) => ({
                        preInspection: {
                            ...prevState.preInspection,
                            inspectionDate: '',
                        },
                    }));
                }
            });

            return;
        }

        const formFieldsErrors = name === 'observations'
            ? getFormErrors({ [name]: value }, VALIDATIONS.PRE_INSPECTION_FORM) : null;

        this.setState({
            hasModifications: true,
            modificationsSaved: false,
            preInspection: {
                ...preInspection,
                [name]: value,
            },
            formErrors: { fields: formFieldsErrors },
        });
    };

    onDateChange = (name: string, date: any) => {
        const { preInspection } = this.state;

        this.setState({
            hasModifications: true,
            modificationsSaved: false,
            preInspection: {
                ...preInspection,
                [name]: date,
            },
        });
    };

    onNewTokenModalClose = () => {
        this.setState({
            newToken: '',
            showingNewTokenModal: false,
            formErrors: null,
        });
    };

    onImageClick = (imgUrl: string, label: string, imgId: number) => {
        this.setState({
            showingModal: true,
            imgToShow: imgUrl,
            imgLabelToShow: label,
            imgId,
        });
    };

    onImageModalClose = () => {
        this.setState({ showingModal: false });
    };

    onReassessSuccess = (response: PreInspection) => {
        const { preInspection } = this.state;
        this.setState({
            isFetching: false,
            showReassessModal: true,
            preInspection: {
                ...preInspection,
                ...response,
            },
        });
    };

    onReassessFailure = () => {
        const { t } = this.props;

        displayNotification(NOTIFICATION_TYPE.ERROR, t('preInspections.form.reassessError'));
        this.setState({
            isFetching: false,
        });
    };

    onReassess = () => {
        const { t, reEvaluateGlassPreInspection } = this.props;
        const { id, isFetching, preInspection } = this.state;

        if (isFetching) return;

        this.setState({
            isFetching: true,
            previousGlassResult: preInspection.result || t(this.noneKeyword),
        });

        if (id) {
            reEvaluateGlassPreInspection(id, this.onReassessSuccess, this.onReassessFailure);
        }
    };

    onRejectCallClick = () => {
        const { notifyDeclineCall } = this.props;

        notifyDeclineCall();
    };

    onAcceptCallClick = () => {
        const { notifyAnswerCall } = this.props;

        notifyAnswerCall();
    };

    onCallTerminate = () => {
        this.setState({
            isOnVideoCall: false,
        });
    };

    onVideoConfigError = () => {
        const { t } = this.props;
        displayNotification(NOTIFICATION_TYPE.ERROR, t('errors.errorVideo'));
        this.setState({
            isOnVideoCall: false,
        });
    };

    onVideoSizeChanged = () => {
        const { isVideoBig } = this.state;
        this.setState({ isVideoBig: !isVideoBig });
    };

    onCreateVideoToken = () => {
        const { t } = this.props;
        const { preInspection } = this.state;
        const { id } = preInspection;

        displayConfirm({
            acceptButtonText: t(this.acceptButton),
            onAccept: () => this.newVideoTokenRequest(String(id)),
            onReject: () => {},
            rejectButtonText: t(this.rejectButton),
            title: t('preInspections.form.newVideoTokenConfirmationMsg'),
        });
    };

    onSaveChanges = async () => {
        const { preInspection, isComplete } = this.state;

        const {
            vin,
            pciVehicleModel,
            pciVehicleBrand,
            vehicleFuelType,
            vehicleVersion,
            vehicleCylinderVolume,
            vehiclePower,
            vehicleColor,
            vehicleImported,
            vehicleManufactureDate,
            vehicleLicenseDate,
            vehicleMileage,
            mileageReadout,
            inspected,
            inspectionDate,
            designatedValue,
            eurotaxSaleValue,
            marketValue,
            eurotaxPurchaseValue,
            glassDamageEstimate,
            eurotaxTylaCode,
            observations,
            result,
        } = preInspection;

        if (isComplete && this.validateFields()) {
            const fields = {
                designatedValue: validateString(designatedValue),
                eurotaxPurchaseValue: validateString(eurotaxPurchaseValue),
                eurotaxSaleValue: validateString(eurotaxSaleValue),
                eurotaxTylaCode: validateString(eurotaxTylaCode),
                glassDamageEstimate: validateString(glassDamageEstimate),
                inspected,
                inspectionDate: setDateTime(inspectionDate),
                marketValue: validateString(marketValue),
                mileageReadout,
                observations: validateString(observations),
                vehicleColor: validateString(vehicleColor),
                vehicleImported,
                vehicleLicenseDate: setDateTime(vehicleLicenseDate),
                vehicleManufactureDate: setDateTime(vehicleManufactureDate),
                vehicleMileage: validateString(vehicleMileage),
                preInspectionResult: result,
                vehiclePower: validateString(vehiclePower),
                vehicleVersion: validateString(vehicleVersion),
                pciVehicleBrand: validateString(pciVehicleBrand),
                pciVehicleModel: validateString(pciVehicleModel),
                vin: validateString(vin),
                vehicleCylinderVolume: validateString(vehicleCylinderVolume),
                vehicleFuelType: validateString(vehicleFuelType),
            };

            this.updateRequest(fields);
        } else if (!isComplete && this.validateGlassFields()) {
            const fields = {
                preInspectionResult: result,
                repairCost: preInspection.repairCost || '0',
            };

            this.glassUpdateRequest(fields);
        }
    };

    onGeneratePdfSuccess = (data: File, fileName: string, mimeType: string) => {
        this.setState({
            isFetching: false,
        });
        const blob = new Blob([data], { type: mimeType });
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
    };

    onGeneratePdfFailure = () => {
        this.setState({
            isFetching: false,
        });
    };

    onGeneratePdfClick = async () => {
        const { generatePDF } = this.props;
        const { preInspection, isFetching, preparing } = this.state;

        if (preparing || isFetching) return;

        this.setState({ isFetching: true });

        let url = preInspectionCompleteResumeURL(String(preInspection.id));

        if (preInspection.type === PreInspectionType.GLASS) {
            url = preInspectionGlassResumeURL(String(preInspection.id));
        }

        await generatePDF(url, this.onGeneratePdfSuccess, this.onGeneratePdfFailure);
    };

    onShareEmailClick = () => {
        const { shareEmail } = this.state;
        this.setState({
            shareEmail: !shareEmail,
        });
    };

    onSharePhoneClick = () => {
        const { sharePhone } = this.state;
        this.setState({
            sharePhone: !sharePhone,
        });
    };

    onEmailToShareChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            emailToShare: e.currentTarget.value,
        });
    };

    onPhoneToShareChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            phoneToShare: e.currentTarget.value,
        });
    };

    onGlassUpdateSuccess = () => {
        const { t } = this.props;

        displayNotification(NOTIFICATION_TYPE.SUCCESS, t('preInspections.form.updateSuccess'));
        this.setState({
            isFetching: false,
            hasModifications: false,
            modificationsSaved: true,
        });
    };

    onGlassUpdateFailure = () => {
        const { t } = this.props;

        displayNotification(NOTIFICATION_TYPE.ERROR, t('preInspections.form.updateError'));
        this.setState({
            isFetching: false,
        });
    };

    onPreInspectionUpdateSuccess = () => {
        const { t } = this.props;
        displayNotification(NOTIFICATION_TYPE.SUCCESS, t('preInspections.form.updateSuccess'));

        this.setState({
            isFetching: false,
            modificationsSaved: true,
            hasModifications: false,
        });
    };

    onPreInspectionUpdateFailure = () => {
        const { t } = this.props;

        displayNotification(NOTIFICATION_TYPE.ERROR, t('preInspections.form.updateError'));
        this.setState({
            isFetching: false,
        });
    };

    onRequestSuccess = (data: PreInspection) => {
        const { preInspection } = this.state;

        this.setState({
            preInspection: {
                ...preInspection,
                status: data.status,
            },
            isFetching: false,
        });
    };

    onRequestNewTokenSuccess = (data: string) => {
        this.setState({
            isFetching: false,
            newToken: data,
            showingNewTokenModal: true,
            isVideoShareToken: false,
        });
    };

    onRequestNewVideoTokenSuccess = (data: string) => {
        this.setState({
            isFetching: false,
            newToken: data,
            showingNewTokenModal: true,
            isVideoShareToken: true,
        });
    };

    onShareTokenSuccess = () => {
        const { t } = this.props;

        displayNotification(
            NOTIFICATION_TYPE.SUCCESS,
            t('global.tokenShareSuccess'),
        );
        this.setState({
            isFetching: false,
            showingNewTokenModal: false,
        });
    };

    onShareTokenFailure = (error: AxiosError) => {
        const { t } = this.props;

        let errorMessage = t('global.tokenShareError');
        if (error && error.response) {
            const errorData = error.response.data;
            if (errorData && errorData.fields && errorData.fields.email && errorData.fields.email[0].typeOfViolation === 'EmailConstraint') {
                errorMessage = t('errors.invalidEmail');
            }
        }

        this.displayError(errorMessage);
        this.setState({
            isFetching: false,
        });
    };

    onGetPreInspectionSuccess = (data: PreInspection) => {
        const preInspection: PreInspection = {
            ...data,
        };

        const { images, mileageReadout } = preInspection;

        if (mileageReadout === null) {
            preInspection.mileageReadout = 'REAL';
        }

        if (images && preInspection.id && images.length > 0) {
            preInspection.sendPhotosDate = images[0].createdDate;
            // to render only the last 12 images from glass preinspections
            const start = images.length - 12;

            if (preInspection.type === PreInspectionType.GLASS && start > 0) {
                preInspection.images = images.slice(start, images.length);
            }

            this.getAllPhotos(preInspection.type, preInspection.id, images);
        }

        this.setState({
            preInspection,
            preparing: false,
            isComplete: preInspection.type === PreInspectionType.COMPLETE,
        });
    };

    onGetPreInspectionFailure = () => {
        this.setState({ preparing: false });
    };

    onGetImageThumbnailSuccess = (result: AxiosResponse) => {
        const mimeType = result.headers['content-type'];
        const blob = new Blob([result.data], {
            type: mimeType,
        });

        const imageId = (result.config.url || '').split('?').shift()?.split('/').pop(); // get image id from each url constructed before
        const photoURL = URL.createObjectURL(blob);

        this.setState((prevState) => ({
            preInspectionThumbnailURL: {
                ...prevState.preInspectionThumbnailURL,
                [Number(imageId)]: photoURL,
            },
        }));
    };

    onFailure = () => {
        this.setState({
            isFetching: false,
        });
    };

    validateGlassFields = (): boolean => {
        const { preInspection } = this.state;
        let errors: IFormError | null = getFormErrors(preInspection, VALIDATIONS.PRE_INSPECTION_GLASS_FORM);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    validateFields = (): boolean => {
        const { preInspection } = this.state;
        let errors: IFormError | null = getFormErrors(preInspection, VALIDATIONS.PRE_INSPECTION_FORM);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    glassUpdateRequest = async (fields: object) => {
        const { glassUpdate } = this.props;
        const { preInspection } = this.state;

        if (!preInspection.id) return;

        this.setState({ isFetching: true });

        glassUpdate(preInspection.id, fields, this.onGlassUpdateSuccess, this.onGlassUpdateFailure);
    };

    updateRequest = async (fields: object) => {
        const { preInspectionUpdate } = this.props;
        const { preInspection } = this.state;

        if (!preInspection.id) return;

        this.setState({ isFetching: true });

        preInspectionUpdate(preInspection.id, fields, this.onPreInspectionUpdateSuccess, this.onPreInspectionUpdateFailure);
    };

    concludeRequest = async (id: string) => {
        const { concludeGlassPreInspection } = this.props;
        const { isFetching } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        concludeGlassPreInspection(id, this.onRequestSuccess, this.onFailure);
    };

    cancelRequest = async (id: string) => {
        const { requestCancellation } = this.props;
        const { isComplete } = this.state;

        this.setState({ isFetching: true });

        const url: string = isComplete ? preInspectionCompleteCancelURL(id) : preInspectionGlassCancelURL(id);

        requestCancellation(url, this.onRequestSuccess, this.onFailure);
    };

    newTokenRequest = async (id: string) => {
        const { requestNewToken } = this.props;

        this.setState({ isFetching: true });

        requestNewToken(id, this.onRequestNewTokenSuccess, this.onFailure);
    };

    newVideoTokenRequest = async (id: string) => {
        const { requestNewVideoToken } = this.props;

        this.setState({ isFetching: true });

        requestNewVideoToken(id, this.onRequestNewVideoTokenSuccess, this.onFailure);
    };

    reopenRequest = async (id: string) => {
        const { requestReopen } = this.props;
        const { isFetching, isComplete } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        const url = isComplete ? preInspectionCompleteReopenURL(id) : preInspectionGlassReopenURL(id);

        requestReopen(url, this.onRequestSuccess, this.onFailure);
    };

    restartRequest = async (id: string) => {
        const { requestRestart } = this.props;
        const { isFetching } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        requestRestart(id, this.onRequestSuccess, this.onFailure);
    };

    closeRequest = (sendReport: boolean, insuranceCompany: string, email: string) => {
        const { requestClose } = this.props;
        const { preInspection } = this.state;

        const { id } = preInspection;

        this.setState({ isFetching: true });

        requestClose(String(id), {
            sendReport,
            insuranceCompany: validateString(insuranceCompany),
            email: validateString(email),
        }, this.onRequestSuccess, this.onFailure);
    };

    shareTokenRequest = async () => {
        const { shareToken } = this.props;

        const {
            preInspection,
            isVideoShareToken,
            emailToShare,
            shareEmail,
            sharePhone,
            phoneToShare,
            isFetching,
        } = this.state;

        if (!preInspection.id || isFetching) return;

        const data: any = {};
        if (shareEmail) data.email = emailToShare;
        if (sharePhone) data.phone = phoneToShare;

        if ((emailToShare.trim().length === 0 && phoneToShare.trim().length === 0) || (
            !shareEmail && !sharePhone
        )) {
            this.setState({
                formErrors: {
                    fields: {
                        emailToShare: [{ typeOfViolation: 'NotBlank' }],
                        phoneToShare: [{ typeOfViolation: 'NotBlank' }],
                    },
                },
            });

            return;
        }

        this.setState({ isFetching: true });

        const hasEmail = shareEmail && emailToShare;
        const hasPhone = sharePhone && phoneToShare;

        if (hasEmail && hasPhone) {
            data.deliveryType = DeliveryType.BOTH;
        } else if (hasPhone) {
            data.deliveryType = DeliveryType.SMS;
        } else if (hasEmail) {
            data.deliveryType = DeliveryType.EMAIL;
        }

        const url = isVideoShareToken ? preInspectionVideoTokenURL(preInspection.id) : preInspectionResetTokenURL(preInspection.id);

        shareToken(url, data, this.onShareTokenSuccess, this.onShareTokenFailure);
    };

    displayError = (msg: string) => {
        displayNotification(
            NOTIFICATION_TYPE.ERROR,
            msg,
        );
    };

    prepare = async () => {
        const { getPreInspection } = this.props;
        const { id, preparing } = this.state;

        if (preparing || !id) return;

        this.setState({ preparing: true });

        getPreInspection(id, this.onGetPreInspectionSuccess, this.onGetPreInspectionFailure);
    };

    getAllPhotos = async (type: string, id: number, imgs: Array<FileResponse>): Promise<void> => {
        const { getImageThumbnail } = this.props;
        
        const isComplete = type === PreInspectionType.COMPLETE;
        let imagePartUrl = `${API_URL}/pre-inspections/glass/${id}/documents/`;
        if (isComplete) imagePartUrl = `${API_URL}/pre-inspections/complete/${id}/documents/`;

        imgs.forEach((k) => {
            const url = `${imagePartUrl}${k.id}?thumbnail=true`;
            getImageThumbnail(url, this.onGetImageThumbnailSuccess, this.onFailure);
        });
    };

    copyLink = () => {
        const { t } = this.props;
        const { newToken } = this.state;
        navigator.clipboard.writeText(newToken);
        displayNotification(
            NOTIFICATION_TYPE.SUCCESS,
            t('global.urlCopySuccess'),
        );
    };

    orderImages = (images: FileResponse[]): FileResponse[] => {
        const imageOrder = [
            ImageFileType.FRONT_BODY,
            ImageFileType.LEFT_BODY,
            ImageFileType.RIGHT_BODY,
            ImageFileType.BACK_BODY,
            ImageFileType.CEILING,
            ImageFileType.FRONT_WINDSHIELD,
            ImageFileType.FRONT_RIGHT_WINDSHIELD,
            ImageFileType.FRONT_LEFT_WINDSHIELD,
            ImageFileType.INSIDE,
            ImageFileType.IDENTIFICATION,
            ImageFileType.DOCUMENT,
            ImageFileType.INSPECTION,
            ImageFileType.LICENSE_PLATE_WINDSHIELD,
            ImageFileType.ADDITIONAL_PHOTOS,
        ];

        images.sort((a, b) => {
            const A = a.fileType;
            const B = b.fileType;

            if (imageOrder.indexOf(A as ImageFileType) > imageOrder.indexOf(B as ImageFileType)) {
                return 1;
            }
            return -1;
        });

        return images;
    };

    renderGlassButtons = () => {
        const { t } = this.props;
        const { preInspection } = this.state;

        switch (preInspection.status) {
            case PreInspectionsStatus.PENDING:
                return (
                    <div className="app-screen__form__left-container__buttons-container">
                        <ButtonDeprecated
                            text={t('global.buttons.newToken')}
                            callback={this.onNewTokenClick}
                            styles="btn--dark-purple"
                        />
                        <ButtonDeprecated
                            text={t(this.cancelButton)}
                            callback={this.onCancelClick}
                            styles="btn--purple"
                        />
                    </div>
                );
            case PreInspectionsStatus.DONE:
                return (
                    <div className="app-screen__form__left-container__buttons-container one-button">
                        <ButtonDeprecated
                            text={t('global.buttons.reopen')}
                            callback={this.onReopenClick}
                            styles="btn--dark-purple"
                        />
                    </div>
                );
            case PreInspectionsStatus.ONGOING:
                return (
                    <div className="app-screen__form__left-container__buttons-container">
                        <ButtonDeprecated
                            text={t('global.buttons.conclude')}
                            callback={this.onConcludeGlassClick}
                            styles="btn--dark-purple"
                        />
                        <ButtonDeprecated
                            text={t(this.cancelButton)}
                            callback={this.onCancelClick}
                            styles="btn--purple"
                        />
                    </div>
                );
            case PreInspectionsStatus.CANCELED:
                return (
                    <div className="app-screen__form__left-container__buttons-container one-button">
                        <ButtonDeprecated
                            text={t('global.buttons.restart')}
                            callback={this.onRestartClick}
                            styles="btn--dark-purple"
                        />
                    </div>
                );
            default:
                return <React.Fragment />;
        }
    };

    renderCompleteButtons = () => {
        const { t, user } = this.props;
        const { preInspection } = this.state;

        if (user && user.role === UserRoles.INSURANCE_USER) {
            return <React.Fragment />;
        }

        switch (preInspection.status) {
            case PreInspectionsStatus.PENDING:
                return (
                    <div className="app-screen__form__left-container__buttons-container">
                        <ButtonDeprecated
                            text={t('global.buttons.newToken')}
                            callback={this.onNewTokenClick}
                            styles="btn--dark-purple"
                        />
                        <ButtonDeprecated
                            text={t(this.cancelButton)}
                            callback={this.onCancelClick}
                            styles="btn--purple"
                        />
                    </div>
                );
            case PreInspectionsStatus.CANCELED:
            case PreInspectionsStatus.DONE:
                return (
                    <div className="app-screen__form__left-container__buttons-container one-button">
                        <ButtonDeprecated
                            text={t('global.buttons.reopen')}
                            callback={this.onReopenClick}
                            styles="btn--dark-purple"
                        />
                    </div>
                );
            case PreInspectionsStatus.ONGOING:
                return (
                    <div className="app-screen__form__left-container__buttons-container">
                        <ButtonDeprecated
                            text={t('global.buttons.conclude')}
                            callback={this.onConcludeClick}
                            styles="btn--dark-purple"
                        />
                        <ButtonDeprecated
                            text={t(this.cancelButton)}
                            callback={this.onCancelClick}
                            styles="btn--purple"
                        />
                    </div>
                );
            default:
                return <React.Fragment />;
        }
    };

    renderVideo = () => {
        const {
            t, incomingCall, incomingCallMessage, user,
        } = this.props;
        const { preInspection, isOnVideoCall, pendingCandidates } = this.state;

        let showIncomingCall: boolean = incomingCall;

        if (user && user.role === UserRoles.INSURANCE_USER) return <React.Fragment />;

        if (incomingCallMessage) {
            const { messageMap } = incomingCallMessage;

            if (messageMap.preInspectionId !== preInspection.id) {
                showIncomingCall = false;
            }
        }

        if (showIncomingCall) {
            return (
                <div className="app-screen__form__left-container__call-container incoming-call">
                    <div className="call-info">
                        <h3>{t('global.calling')}</h3>
                        <p className="small">{t('global.referring')}<b>{t('global.process', { processNumber: preInspection.uid })}</b></p>
                    </div>
                    <div className="buttons">
                        <ButtonDeprecated
                            styles="btn--light-purple btn--no-opacity "
                            text={t('global.buttons.rejectCall')}
                            callback={this.onRejectCallClick}
                        />
                        <ButtonDeprecated
                            styles="btn--green"
                            text={t('global.buttons.answerCall')}
                            callback={this.onAcceptCallClick}
                        />
                    </div>
                </div>
            );
        }

        if (isOnVideoCall) {
            return (
                <VideoComponent
                    isMakingCall={false}
                    onCallTerminate={this.onCallTerminate}
                    onConfigError={this.onVideoConfigError}
                    pendingCandidates={pendingCandidates}
                    onVideoSizeChange={this.onVideoSizeChanged}
                    isPreInspection
                />
            );
        }

        return (
            <div className="app-screen__form__left-container__call-container">
                <SvgIcon icon={ICON.VIDEO_CAM} />
                <ButtonDeprecated
                    text={t('global.buttons.generateNewToken')}
                    styles="btn--purple"
                    callback={this.onCreateVideoToken}
                />
            </div>
        );
    };

    renderCompleteForm = (showSaveChanges: boolean) => {
        const { t, user } = this.props;
        const {
            preInspection, formErrors, importedOptions, readOptions, resultOptions,
        } = this.state;
        let damageEstimate = '';

        const {
            vehicleColor,
            vehicleImported,
            vehicleManufactureDate,
            vehicleLicenseDate,
            vehicleMileage,
            mileageReadout,
            inspected,
            inspectionDate,
            designatedValue,
            eurotaxSaleValue,
            marketValue,
            eurotaxPurchaseValue,
            glassDamageEstimate,
            eurotaxTylaCode,
            observations,
            result,
        } = preInspection;

        if (glassDamageEstimate !== null) {
            damageEstimate = glassDamageEstimate.toString();
        }

        return (
            <Fragment>
                <div className="form-content">
                    <div className="row">
                        <div className="col-sm-3">
                            <FormTextField
                                name="vehicleColor"
                                label={t('preInspections.form.labels.vehicleColor')}
                                value={vehicleColor}
                                errors={get(formErrors, 'fields.vehicleColor', null)}
                                onChange={this.onInputChange}
                                disabled={!showSaveChanges}
                            />
                        </div>
                        <div className="col-sm-3">
                            {showSaveChanges ? (
                                <FormSelectField
                                    options={importedOptions}
                                    name="vehicleImported"
                                    value={vehicleImported === null ? 'none' : (vehicleImported ? 'yes' : 'no')}
                                    onChange={this.onInputChange}
                                    label={t('preInspections.form.labels.vehicleImported')}
                                />
                            ) : (
                                <FormTextField
                                    name="vehicleImported"
                                    label={t('preInspections.form.labels.vehicleImported')}
                                    value={vehicleImported === null ? t(this.noneKeyword) : (vehicleImported ? t(this.yesKeyword) : t(this.noKeyword))}
                                    onChange={this.onInputChange}
                                    disabled
                                />
                            )}
                        </div>
                        <div className="col-sm-3">
                            <FormDatePicker
                                name="vehicleManufactureDate"
                                label={t('preInspections.form.labels.vehicleManufactureDate')}
                                value={vehicleManufactureDate ? new Date(vehicleManufactureDate) : null}
                                onChange={this.onDateChange}
                                disabled={!showSaveChanges}
                                errors={get(formErrors, 'fields.vehicleManufactureDate', null)}
                            />
                        </div>
                        <div className="col-sm-3">
                            <FormDatePicker
                                name="vehicleLicenseDate"
                                label={t('preInspections.form.labels.vehicleRegistrationDate')}
                                value={vehicleLicenseDate ? new Date(vehicleLicenseDate) : null}
                                onChange={this.onDateChange}
                                disabled={!showSaveChanges}
                                errors={get(formErrors, 'fields.vehicleLicenseDate', null)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3">
                            <FormTextField
                                name="vehicleMileage"
                                label={t('preInspections.form.labels.km')}
                                value={vehicleMileage}
                                errors={get(formErrors, 'fields.vehicleMileage', null)}
                                onChange={this.onInputChange}
                                disabled={!showSaveChanges}
                            />
                        </div>
                        <div className="col-sm-3">
                            {showSaveChanges ? (
                                <FormSelectField
                                    options={readOptions}
                                    name="mileageReadout"
                                    value={mileageReadout}
                                    onChange={this.onInputChange}
                                    label={t('preInspections.form.labels.readOptions')}
                                />
                            ) : (
                                <FormTextField
                                    name="mileageReadout"
                                    label={t('preInspections.form.labels.readOptions')}
                                    value={mileageReadout === null ? t(this.noneKeyword) : t(`keywords.${mileageReadout}`)}
                                    onChange={this.onInputChange}
                                    disabled
                                />
                            )}
                        </div>
                        <div className="col-sm-3">
                            {showSaveChanges ? (
                                <FormSelectField
                                    options={importedOptions}
                                    name="inspected"
                                    value={inspected === null ? 'none' : (inspected ? 'yes' : 'no')}
                                    onChange={this.onInputChange}
                                    label={t('preInspections.form.labels.vehicleInspection')}
                                />
                            ) : (
                                <FormTextField
                                    name="inspected"
                                    label={t('preInspections.form.labels.vehicleInspection')}
                                    value={inspected === null ? t(this.noneKeyword) : (inspected ? t(this.yesKeyword) : t(this.noKeyword))}
                                    onChange={this.onInputChange}
                                    disabled
                                />
                            )}
                        </div>
                        <div className="col-sm-3">
                            <FormDatePicker
                                name="inspectionDate"
                                label={t('preInspections.form.labels.vehicleInpectionDate')}
                                value={inspectionDate ? new Date(inspectionDate) : null}
                                onChange={this.onDateChange}
                                disabled={!showSaveChanges || !inspected}
                                errors={get(formErrors, 'fields.inspectionDate', null)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-sm-6 p-r-10">
                                    <FormTextField
                                        name="marketValue"
                                        label={t('preInspections.form.labels.marketValue')}
                                        value={marketValue}
                                        errors={get(formErrors, 'fields.marketValue', null)}
                                        onChange={this.onInputChange}
                                        disabled={!showSaveChanges}
                                        containerStyles="m-l-10-m-r-5"
                                    />
                                </div>
                                <div className="col-sm-6 p-r-10 p-l-10">
                                    <FormTextField
                                        name="eurotaxSaleValue"
                                        label={t('preInspections.form.labels.eurotaxSaleValue')}
                                        value={eurotaxSaleValue}
                                        errors={get(formErrors, 'fields.eurotaxSaleValue', null)}
                                        onChange={this.onInputChange}
                                        disabled={!showSaveChanges}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 p-r-10">
                                    <FormTextField
                                        name="designatedValue"
                                        label={t('preInspections.form.labels.atributedValue')}
                                        value={designatedValue}
                                        errors={get(formErrors, 'fields.designatedValue', null)}
                                        onChange={this.onInputChange}
                                        disabled={!showSaveChanges}
                                    />
                                </div>
                                <div className="col-sm-6 p-r-10 p-l-10">
                                    <FormTextField
                                        name="eurotaxPurchaseValue"
                                        label={t('preInspections.form.labels.eurotaxBuyValue')}
                                        value={eurotaxPurchaseValue}
                                        errors={get(formErrors, 'fields.eurotaxPurchaseValue', null)}
                                        onChange={this.onInputChange}
                                        disabled={!showSaveChanges}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                {user?.role !== UserRoles.INSURANCE_USER && showSaveChanges ? (
                                    <div className="col-sm-4 p-r-10">
                                        <FormSelectField
                                            name="result"
                                            value={result}
                                            onChange={this.onInputChange}
                                            options={resultOptions}
                                            label={t(this.actualGlassResult)}
                                        />
                                    </div>
                                ) : (
                                    <div className="col-sm-4 p-r-10">
                                        <FormTextField
                                            name="result"
                                            label={t(this.actualGlassResult)}
                                            value={!result ? t(this.noneKeyword) : t(`enums.preInspectionResult.${result}`)}
                                            onChange={this.onInputChange}
                                            disabled
                                        />
                                    </div>
                                )}
                                <div className="col-sm-4 p-r-10 p-l-10">
                                    <FormTextField
                                        name="glassDamageEstimate"
                                        label={t('preInspections.form.labels.glassDamageCost')}
                                        value={damageEstimate}
                                        errors={get(formErrors, 'fields.glassDamageEstimate', null)}
                                        onChange={this.onInputChange}
                                        disabled={!showSaveChanges}
                                    />
                                </div>
                                <div className="col-sm-4 p-r-10 p-l-10">
                                    <FormTextField
                                        name="eurotaxTylaCode"
                                        label={t('preInspections.form.labels.tylaCode')}
                                        value={eurotaxTylaCode}
                                        errors={get(formErrors, 'fields.eurotaxTylaCode', null)}
                                        onChange={this.onInputChange}
                                        disabled={!showSaveChanges}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 p-l-10">
                            <FormTextAreaField
                                name="observations"
                                label={t('preInspections.form.labels.observations')}
                                value={observations}
                                errors={get(formErrors, 'fields.observations', null)}
                                onChange={this.onInputChange}
                                disabled={!showSaveChanges}
                                fieldStyles="height-bigger"
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };

    renderHeaderInfo = (showSaveChanges: boolean): React.ReactNode => {
        const { t } = this.props;
        const {
            preInspection,
        } = this.state;

        return (
            <div className="info-area">
                <div className="row info-container">
                    <div className="col-sm-4 first-item">
                        <p className="subtitle">{t('preInspections.form.labels.vin')}</p>
                        <input
                            onChange={this.onInputChange}
                            className="first-item__inline-input"
                            type="text"
                            name="vin"
                            value={preInspection.vin || '--'}
                            disabled={!showSaveChanges}
                        />
                    </div>
                    <div className="col-sm-4 first-item">
                        <p className="subtitle">{t(this.preInspectionFormVehicleBrand)}</p>
                        <input
                            onChange={this.onInputChange}
                            className="first-item__inline-input"
                            type="text"
                            name="pciVehicleBrand"
                            value={preInspection.pciVehicleBrand || '--'}
                            disabled={!showSaveChanges}
                        />
                    </div>
                    <div className="col-sm-4 first-item">
                        <p className="subtitle">{t(this.preInspectionFormVehicleModel)}</p>
                        <input
                            onChange={this.onInputChange}
                            className="first-item__inline-input"
                            type="text"
                            name="pciVehicleModel"
                            value={preInspection.pciVehicleModel || '--'}
                            disabled={!showSaveChanges}
                        />
                    </div>
                    <div className="col-sm-4 first-item">
                        <p className="subtitle">{t('preInspections.form.labels.vehicleVersion')}</p>
                        <input
                            onChange={this.onInputChange}
                            className="first-item__inline-input"
                            type="text"
                            name="vehicleVersion"
                            value={preInspection.vehicleVersion || '--'}
                            disabled={!showSaveChanges}
                        />
                    </div>
                </div>
                <div className="row info-container">
                    <div className="col-sm-4 first-item">
                        <p className="subtitle">{t('preInspections.form.labels.vehicleDisplacement')}</p>
                        <input
                            onChange={this.onInputChange}
                            className="first-item__inline-input"
                            type="text"
                            name="vehicleCylinderVolume"
                            value={preInspection.vehicleCylinderVolume || '--'}
                            disabled={!showSaveChanges}
                        />
                    </div>
                    <div className="col-sm-4 first-item">
                        <p className="subtitle">{t('preInspections.form.labels.vehicleFuel')}</p>
                        <input
                            onChange={this.onInputChange}
                            className="first-item__inline-input"
                            type="text"
                            name="vehicleFuelType"
                            value={preInspection.vehicleFuelType || '--'}
                            disabled={!showSaveChanges}
                        />
                    </div>
                    <div className="col-sm-4 first-item">
                        <p className="subtitle">{t('preInspections.form.labels.vehiclePower')}</p>
                        <input
                            onChange={this.onInputChange}
                            className="first-item__inline-input"
                            type="text"
                            name="vehiclePower"
                            value={preInspection.vehiclePower || '--'}
                            disabled={!showSaveChanges}
                        />
                    </div>
                    <div className="col-sm-4 first-item" />
                </div>
            </div>
        );
    };

    renderPhotos = (photos: FileResponse[]) => {
        const { preInspection, preInspectionThumbnailURL } = this.state;

        return (
            Object.keys(photos).map((k) => {
                const image = photos[Number(k)];
                const {
                    id,
                    originalFileName,
                    createdDate,
                } = image;

                const imageUrl = `${API_URL}/pre-inspections/complete/${preInspection.id}/documents/${id}`;
                const label = `${originalFileName} - ${moment(createdDate).format('DD/MM/YYYY')}`;
                
                return (
                    <div data-testid={id} key={id} className="image-container image-container--open">
                        <ImageCard
                            key={id}
                            image={image}
                            preInspectionThumbnailURL={preInspectionThumbnailURL}
                            onImageClick={() => this.onImageClick(imageUrl, label, id)}
                        />
                    </div>
                );
            })
        );
    };

    render() {
        const { t, user } = this.props;
        const {
            preparing,
            preInspection,
            formErrors,
            showingModal,
            imgToShow,
            isFetching,
            isComplete,
            showingNewTokenModal,
            newToken,
            resultOptions,
            imgLabelToShow,
            isVideoBig,
            viewingDetails,
            hasModifications,
            emailToShare,
            phoneToShare,
            showConcludeModal,
            showReassessModal,
            preInspectionThumbnailURL,
            previousGlassResult,
            sharePhone,
            shareEmail,
            imgId,
        } = this.state;

        let userRole = '';

        if (user) {
            userRole = user.role;
        }

        const allImages = this.orderImages([...preInspection.images]);

        const firstImages = allImages.splice(0, 12);
        const lastImages = allImages;
        let showSaveChanges = false;
        const showGlassTopContainer = !isComplete && preInspection.status !== PreInspectionsStatus.PENDING && preInspection.status !== PreInspectionsStatus.CANCELED;

        let dotClassName = '';
        switch (preInspection.status) {
            case PreInspectionsStatus.CANCELED:
                dotClassName = 'black-circle';
                break;
            case PreInspectionsStatus.DONE:
                dotClassName = 'red-circle';
                break;
            case PreInspectionsStatus.ONGOING:
                showSaveChanges = !viewingDetails;
                dotClassName = 'green-circle';
                break;
            case PreInspectionsStatus.PENDING:
                dotClassName = 'yellow-circle';
                break;
            case PreInspectionsStatus.REOPENED:
                dotClassName = 'green-circle';
                break;
            default:
        }

        return (
            <div data-testid="PreInspectionFormScreen">
                {(preparing || isFetching) && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className={isVideoBig ? 'app-screen__form pre-inspection-form hidden-overflow' : 'app-screen__form pre-inspection-form'}>
                    <div className="app-screen__form__left-container pre-inspection-form">
                        <div className="app-screen__form__left-container__info-container">
                            <div className="info-content">
                                <SvgIcon icon={ICON.LEFT_ARROW} callback={this.onBackClick} />
                                <div className="info">
                                    {preInspection.uid && <p>{t('preInspections.form.labels.process')}</p>}
                                    <h4>{preInspection.uid}</h4>
                                </div>
                            </div>
                            <div className={`status-dot ${dotClassName}`} />
                        </div>
                        {isComplete ? this.renderCompleteButtons() : this.renderGlassButtons()}
                        {showSaveChanges && (
                            <div className="button-container">
                                <ButtonDeprecated
                                    text={t('global.buttons.saveModifications')}
                                    styles="btn--purple"
                                    callback={this.onSaveChanges}
                                    disabled={!hasModifications}
                                />
                            </div>
                        )}
                        <div className="button-container">
                            <ButtonDeprecated
                                text={t('global.buttons.reportPreInspection')}
                                styles="btn--green btn--icon-center btn--bigger"
                                icon={ICON.PDF}
                                callback={() => this.onGeneratePdfClick()}
                            />
                        </div>
                        <div className="app-screen__form__left-container__centered-info pre-inspection-screen">
                            {(preInspection.insuranceCompany && preInspection.insuranceCompany.name) && (
                                <div className="info-item">
                                    <SvgIcon icon={ICON.ACCOUNT_BOX} />
                                    <p className="label">{t('preInspections.form.labels.client')}</p>
                                    <p>{preInspection.insuranceCompany.name}</p>
                                </div>
                            )}
                            <div className="info-item smaller-margin">
                                <p className="label">{t('preInspections.form.labels.insured')}</p>
                                <p>{preInspection.insured}</p>
                            </div>
                            <div className="info-item with-top-margin">
                                <SvgIcon icon={ICON.CAR} />
                                <p className="label">{t(this.preInspectionFormVehicleBrand)}</p>
                                <p>{preInspection.vehicleBrand}</p>
                            </div>
                            <div className="info-item smaller-margin">
                                <p className="label">{t(this.preInspectionFormVehicleModel)}</p>
                                <p>{preInspection.vehicleModel}</p>
                            </div>
                            <div className="info-item smaller-margin">
                                <p className="label">{t('preInspections.form.labels.vehicleLicensePlate')}</p>
                                <p>{preInspection.vehicleLicensePlate}</p>
                            </div>
                        </div>
                        {this.renderVideo()}
                    </div>
                    <div className="app-screen__form__form-container without-tabs pre-inspection-form">
                        {(isComplete) && (
                            <React.Fragment>
                                {this.renderHeaderInfo(showSaveChanges)}
                                {this.renderCompleteForm(showSaveChanges)}
                                <div className="info-area">
                                    <div className="title">
                                        {t('preInspections.form.labels.photosAttached')}
                                    </div>
                                    <div className="info-area info-area--scrollable">
                                        <div className="row info-container image-row">
                                            {this.renderPhotos(firstImages)}
                                        </div>
                                        {lastImages.length > 0 && (
                                            <div className="row info-container image-row">
                                                {this.renderPhotos(lastImages)}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                        {showGlassTopContainer && (
                            <div className="glass-top-container">
                                <SvgIcon icon={ICON.ROBOT} />
                                <div className="logo-container">
                                    <div className="logo">
                                        <img src={logoFluxe} alt="Fluxe logo" />
                                        <p>glassBOT</p>
                                    </div>
                                    <p>{t('preInspections.form.botHelp')}</p>
                                    {userRole !== UserRoles.INSURANCE_USER && (
                                        <ButtonDeprecated
                                            text={t('preInspections.form.reassessButton')}
                                            callback={this.onReassess}
                                            styles="btn--green"
                                        />
                                    )}
                                </div>
                                <div className="state-container">
                                    <div className="top">
                                        <div className="column">
                                            <p className="title">{t('preInspections.form.labels.state')}</p>
                                            <p>{t(`preInspections.form.states.${preInspection.status}`)}</p>
                                        </div>
                                        <div className="column">
                                            <p className="title">{t('preInspections.form.labels.pictures')}</p>
                                            <p className="small">{t('preInspections.form.labels.receivedDate', { date: moment(preInspection.sendPhotosDate).format(this.dateFormat) })}</p>
                                        </div>
                                        <div className="column">
                                            <p className="title">{t(this.actualGlassResult)}</p>
                                            {userRole !== UserRoles.INSURANCE_USER && showSaveChanges ? (
                                                <FormSelectField
                                                    name="result"
                                                    value={preInspection.result}
                                                    onChange={this.onInputChange}
                                                    options={resultOptions}
                                                    containerStyles="select-container"
                                                    fieldStyles="select-field"
                                                />
                                            ) : (
                                                <div>
                                                    {preInspection.result ? t(`enums.preInspectionResult.${preInspection.result}`) : t(this.noneKeyword)}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="middle">
                                        <div className="pictures-container">
                                            <SvgIcon icon={ICON.CROSS_RED_CIRCLE} />
                                            <div className="images">
                                                {Object.keys(firstImages).map((k) => {
                                                    const image = firstImages[Number(k)];
                                                    const { id, originalFileName } = image;

                                                    const label = `${originalFileName} - ${moment(preInspection.sendPhotosDate).format(this.dateFormat)}`;
                                                    const imageUrl = `${API_URL}/pre-inspections/glass/${preInspection.id}/documents/${id}`;

                                                    return (
                                                        <img
                                                            key={id}
                                                            src={preInspectionThumbnailURL[id]}
                                                            alt={originalFileName}
                                                            onClick={() => this.onImageClick(imageUrl, label, id)}
                                                            title={originalFileName}
                                                        />
                                                    );
                                                })}
                                            </div>
                                            <SvgIcon icon={ICON.CHECK_GREEN_CIRCLE} />
                                        </div>
                                        <div className="dashed" />
                                    </div>
                                    <div className="bottom">
                                        <div className="text">
                                            <p className="bold">{t('preInspections.form.labels.fixValue')}</p>
                                            <p>{t('preInspections.form.labels.estimatedValue')}</p>
                                        </div>
                                        <FormTextField
                                            name="repairCost"
                                            value={preInspection.repairCost}
                                            fieldStyles="text-field"
                                            containerStyles="text-field-container"
                                            onChange={this.onInputChange}
                                            errors={get(formErrors, 'fields.repairCost', null)}
                                            disabled={!showSaveChanges}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="info-area">
                            <div className="title">
                                {t('preInspections.form.labels.client')}
                            </div>
                            <div className="row info-container">
                                <div className="col-sm-8 first-item">
                                    <p className="subtitle">{t(this.preInspectionFormName)}</p>
                                    <p>{preInspection.insuranceCompany && preInspection.insuranceCompany.name}</p>
                                </div>
                                <div className="col-sm-2">
                                    <p className="subtitle">{t('preInspections.form.labels.simulationNumber')}</p>
                                    <p>{preInspection.simulationNumber}</p>
                                </div>
                                <div className="col-sm-2">
                                    <p className="subtitle">{t('preInspections.form.labels.simulationDate')}</p>
                                    <p>{preInspection.simulationDate && moment(preInspection.simulationDate).format(this.dateFormat)}</p>
                                </div>
                            </div>
                        </div>
                        {/* INSURED */}
                        <div className="info-area">
                            <div className="title">
                                {t('preInspections.form.labels.insured')}
                            </div>
                            <div className="row info-container">
                                <div className="col-sm-8 first-item">
                                    <p className="subtitle">{t(this.preInspectionFormName)}</p>
                                    <p>{preInspection.insured}</p>
                                </div>
                                <div className="col-sm-2">
                                    <p className="subtitle">{t('preInspections.form.labels.mobilePhone')}</p>
                                    <p>{preInspection.insuredContact}</p>
                                </div>
                                <div className="col-sm-2">
                                    <p className="subtitle">{t('preInspections.form.labels.email')}</p>
                                    <p>{preInspection.insuredEmail}</p>
                                </div>
                            </div>
                        </div>
                        {/* VEHICLE */}
                        <div className="info-area">
                            <div className="title">
                                {t('preInspections.form.labels.vehicle')}
                            </div>
                            <div className="row info-container">
                                <div className="col-sm-6 first-item">
                                    <p className="subtitle">{t('preInspections.form.labels.vehicleLicensePlate')}</p>
                                    <p>{preInspection.vehicleLicensePlate}</p>
                                </div>
                                {isComplete && (
                                    <div className="col-sm-2">
                                        <p className="subtitle">{t('preInspections.form.labels.eurotaxValue')}</p>
                                        <p>{preInspection.insuranceEurotaxValue ? numberToCurrency(Number(preInspection.insuranceEurotaxValue)) : '--'}</p>
                                    </div>
                                )}
                                <div className="col-sm-2">
                                    <p className="subtitle">{t(this.preInspectionFormVehicleBrand)}</p>
                                    <p>{preInspection.vehicleBrand}</p>
                                </div>
                                <div className="col-sm-2">
                                    <p className="subtitle">{t(this.preInspectionFormVehicleModel)}</p>
                                    <p>{preInspection.vehicleModel}</p>
                                </div>
                            </div>
                        </div>
                        {/* INSURANCE */}
                        <div className="info-area">
                            <div className="title">
                                {t('preInspections.form.labels.insurance')}
                            </div>
                            <div className="row info-container">
                                <div className="col-sm-6 first-item">
                                    <p className="subtitle">{t('preInspections.form.labels.initialInsuranceDate')}</p>
                                    <p>{preInspection.insuranceStartDate && moment(preInspection.insuranceStartDate).format(this.dateFormat)}</p>
                                </div>
                                <div className="col-sm-2">
                                    <p className="subtitle">{t('preInspections.form.labels.annualValue')}</p>
                                    <p>{preInspection.insuranceYearlyAmount && numberToCurrency(preInspection.insuranceYearlyAmount)}</p>
                                </div>
                                <div className="col-sm-2">
                                    <p className="subtitle">{t('preInspections.form.labels.tylaCode')}</p>
                                    <p>{preInspection.insuranceTylaCode}</p>
                                </div>
                                <div className="col-sm-2">
                                    <p className="subtitle">{t('preInspections.form.labels.extrasValue')}</p>
                                    <p>{preInspection.insuranceExtrasAmount && numberToCurrency(preInspection.insuranceExtrasAmount)}</p>
                                </div>
                            </div>
                        </div>
                        {/* AGENT */}
                        <div className="info-area">
                            <div className="title">
                                {t('preInspections.form.labels.agent')}
                            </div>
                            <div className="row info-container">
                                <div className="col-sm-8 first-item">
                                    <p className="subtitle">{t(this.preInspectionFormName)}</p>
                                    <p>{preInspection.agentName}</p>
                                </div>
                                <div className="col-sm-2">
                                    <p className="subtitle">{t('preInspections.form.labels.mediatorNumber')}</p>
                                    <p>{preInspection.mediatorNumber}</p>
                                </div>
                                <div className="col-sm-2">
                                    <p className="subtitle">{t('preInspections.form.labels.mobilePhone')}</p>
                                    <p>{preInspection.agentContact}</p>
                                </div>
                            </div>
                            <div className="row info-container">
                                <div className="col-sm-8 first-item">
                                    <p className="subtitle">{t('preInspections.form.labels.email')}</p>
                                    <p>{preInspection.agentEmail}</p>
                                </div>
                                <div className="col-sm-4">
                                    <p className="subtitle">{t('preInspections.form.labels.address')}</p>
                                    <p>{preInspection.agentAddress}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showingModal && <DrawImagesPolygonModal onModalClose={this.onImageModalClose} imgId={imgId} imgUrl={imgToShow} imgLabel={imgLabelToShow} isEditable={!showGlassTopContainer} prepare={this.prepare} />}
                {showingNewTokenModal && (
                    <GeneralModal
                        onModalClose={this.onNewTokenModalClose}
                        containerStyle="new-token-modal"
                        boxStyle="new-token-modal"
                        boxContentStyle="new-token"
                    >
                        <div>
                            <p>{t('preInspections.form.tokenModal.newTokenCreated')}</p>
                            <p className="regular">{t('preInspections.form.tokenModal.process')}</p>
                            <p className="big">{preInspection.uid}</p>
                        </div>
                        <div className="body">
                            <FormTextField
                                name="token"
                                value={newToken}
                                labelStyles="new-token-label"
                                containerStyles="new-token-input"
                                id="urlToCopy"
                                label="Link"
                            />
                            <img src={iconCopy} alt="Copy" title="Copy" onClick={this.copyLink} />
                        </div>
                        <div className="body">
                            <FormTextField
                                name="token"
                                value={emailToShare}
                                onChange={this.onEmailToShareChange}
                                containerStyles="new-token-input"
                                labelStyles="new-token-label"
                                id="emailToShare"
                                errors={get(formErrors, 'fields.emailToShare', null)}
                                label="E-Mail"
                            />
                            <CheckBox checked={shareEmail} callback={this.onShareEmailClick} />
                        </div>
                        <div className="body">
                            <FormTextField
                                name="token"
                                value={phoneToShare}
                                onChange={this.onPhoneToShareChange}
                                containerStyles="new-token-input"
                                labelStyles="new-token-label"
                                id="phoneToShare"
                                errors={get(formErrors, 'fields.phoneToShare', null)}
                                label={t('preInspections.form.tokenModal.phone')}
                            />
                            <CheckBox checked={sharePhone} callback={this.onSharePhoneClick} />
                        </div>
                        <div className="action">
                            <ButtonDeprecated
                                text={t('preInspections.form.shareToken')}
                                callback={this.shareTokenRequest}
                                styles="share-button"
                            />
                        </div>
                    </GeneralModal>
                )}
                {showConcludeModal && (
                    <SendMailModal
                        onModalClose={() => { this.setState({ showConcludeModal: false }); }}
                        onClickSendReport={(sendReport: boolean, insuranceCompany: string, email: string) => this.closeRequest(sendReport, insuranceCompany, email)}
                        agentEmail={preInspection.agentEmail}
                        agentName={preInspection.agentName}
                    />
                )}
                {showReassessModal && (
                    <ReassessModal
                        onModalClose={() => { this.setState({ showReassessModal: false }); }}
                        previousGlassResult={previousGlassResult || t(this.noneKeyword)}
                        currentGlassResult={preInspection.result ? t(`enums.preInspectionResult.${preInspection.result}`) : t(this.noneKeyword)}
                    />
                )}
            </div>
        );
    }
}

export default withPreInspectionsContext(withVideoContext(withAuthorizationContext(withAuthenticationContext(withTranslationContext(withRouter(PreInspectionsFormScreen))))));
