/* eslint-disable @typescript-eslint/no-shadow */
/**
 *
 * @Copyright 2024 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, ReactNode } from 'react';
import { cloneDeep } from 'lodash';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import {
    ApiFile,
    Box,
    PolygonPoint,
    FileDamage,
    FileDamageSeverityType,
    FileDamageType,
    CarPartOutline,
} from '../../constants/types';
import Button from './Button';
import iconCross from '../../assets/images/icon-cross.svg';
import { PreInspectionsContext, withPreInspectionsContext } from '../controllers/preInspections/PreInspectionsContext';
import Loader from './Loader';
import { AnnotationContext, withAnnotationContext } from '../controllers/annotation/AnnotationContext';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';
import IconClose from '../assets/IconClose';
import { generateInt } from '../../utils/misc';
import displaySelectOptionsDialog from './displaySelectOptionsDialog';
import { SelectOption } from '../../constants/misc';
import {
    drawDamagesPolygonsInCanvas,
    drawNewLineInCanvas,
    getDamageIdIfClicked,
    getTwoPointsDistance,
} from '../../utils/damagesHelper';
import { LAST_POINT_THRESHOLD } from '../../constants/damages';
import { FileDamageContext, withFileDamageContext } from '../controllers/fileDamage/FileDamageContext';
import DamageOpacityInput from './DamageOpacityInput';

interface OwnProps extends TranslationContext, PreInspectionsContext, AnnotationContext, FileDamageContext {
    onModalClose: () => void;
    imgUrl?: string | null;
    imgId: number;
    imgLabel?: string | null;
    isEditable: boolean;
    imageFile?: ApiFile;
    prepare?: () => void;
}

interface OwnState {
    imgSrc: string;
    isFetching: boolean;
    damageData: FileDamage[];
    outlineData: CarPartOutline[];
    annotationBoxes: Box[];
    isDrawing: boolean;
    start: {x: number; y: number};
    widthRatio: number;
    heightRatio: number;
    imgRealWidth: number;
    imgRealHeight: number;
    backgroundImage: ImageBitmap | undefined;
    opacityLevel: number;
    damageSelectedId: number | null;
    isEditingDamages: boolean;
    currentDrawingPolygonPoints: Array<PolygonPoint>;
 }

const initialState: OwnState = {
    imgSrc: '',
    isFetching: false,
    damageData: [],
    outlineData: [],
    annotationBoxes: [],
    isDrawing: false,
    start: { x: 0, y: 0 },
    widthRatio: 0,
    heightRatio: 0,
    imgRealWidth: 0,
    imgRealHeight: 0,
    backgroundImage: undefined,
    opacityLevel: 40,
    damageSelectedId: null,
    isEditingDamages: false,
    currentDrawingPolygonPoints: [],
};

let ctx: CanvasRenderingContext2D;
let canvas: HTMLCanvasElement;

class DrawImagesPolygonModal extends Component<OwnProps, OwnState> {
    state = initialState;

    currentBox: CanvasRenderingContext2D[] = [];

    newBox: React.MutableRefObject<Box | null> = React.createRef<Box | null>();

    componentDidMount = async () => {
        const {
            imgUrl,
            imageFile,
            getImage,
            getGlassDamages,
            getFilePartOutlines,
            imgId,
            t,
        } = this.props;

        const { isFetching } = this.state;

        this.newBox.current = null;

        if (!canvas) {
            // this is needed for react to not re-render this component
            canvas = document.createElement('canvas');

            // render canvas
            canvas.id = 'canvas';
            canvas.dataset.testid = 'polygon-canvas';
            canvas.onmousedown = this.handleMouseDown;
            canvas.onmousemove = this.handleMouseMove;
            canvas.onmouseup = this.handleMouseUp;
            canvas.onmouseleave = this.handleMouseUp;
            canvas.onclick = (e) => this.onCanvasClick(e);
            canvas.oncontextmenu = (e) => {
                e.preventDefault();
                this.cleanCurrentPolygonPoints();
            };
            window.addEventListener('resize', this.drawCanvas);
            window.addEventListener('keydown', this.handleCanvasKeydown);
            document.querySelector('#damage-canvas')?.appendChild(canvas);

            const context = canvas.getContext('2d');

            if (!context) {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('preInspections.errors.canvasRender'));
            } else {
                ctx = context;
            }
        }

        this.getAnnotationBoxes();

        const damageData = await getGlassDamages(imgId);

        const outlineData = await getFilePartOutlines(imgId);

        if (imgUrl) {
            if (isFetching) return;

            this.setState({
                isFetching: true,
            });

            try {
                const res = await getImage(imgUrl);

                if (!res) return;

                this.setState({
                    imgSrc: res,
                    isFetching: false,
                    damageData,
                    outlineData,
                }, this.drawCanvas);
            } catch {
                this.setState({
                    isFetching: false,
                });
            }
        } else if (imageFile) {
            this.setState({
                imgSrc: imageFile.fileString,
                isFetching: false,
                damageData,
                outlineData,
            }, this.drawCanvas);
        }
    };

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.drawCanvas);
        window.removeEventListener('keydown', this.handleCanvasKeydown);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        canvas = undefined as any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ctx = undefined as any;
    }

    onClose = (): void => {
        const { onModalClose } = this.props;
        onModalClose();
    };

    onRemoveDamageClick = () => {
        const { currentDrawingPolygonPoints } = this.state;

        if (currentDrawingPolygonPoints.length > 0) {
            this.setState({
                currentDrawingPolygonPoints: [],
            }, this.redraw);
            return;
        }

        this.removeSelectedDamageFromList();
    };

    onCancelDamagesEdition = async () => {
        const { getGlassDamages, imgId } = this.props;

        const damageData = await getGlassDamages(imgId);

        this.setState({
            isEditingDamages: false,
            damageData,
            currentDrawingPolygonPoints: [],
        }, this.redraw);
    };

    onCanvasClick = (e: MouseEvent) => {
        const {
            widthRatio,
            heightRatio,
            currentDrawingPolygonPoints,
            isEditingDamages,
            damageData,
        } = this.state;

        this.unselectNotes();

        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        const imgClickedX = Math.round(x / widthRatio);
        const imgClickedY = Math.round(y / heightRatio);

        const clickedDamageId = getDamageIdIfClicked(damageData, imgClickedX, imgClickedY);

        if (isEditingDamages && (!clickedDamageId || currentDrawingPolygonPoints.length > 0)) {
            // is drawing a new polygon
            this.setState({ damageSelectedId: null });
            this.handleClickWhenDrawingPolygon(x, y);
            return;
        }

        if (clickedDamageId) {
            this.setState({
                isEditingDamages: true,
                damageSelectedId: clickedDamageId,
            }, this.redraw);
        }
    };

    onDamageUpdateSuccess = async (updatedDamageData: FileDamage[]) => {
        const { t, prepare } = this.props;

        displayNotification(NOTIFICATION_TYPE.SUCCESS, t('preInspections.form.savedDamagesSuccessMsg'));

        if (prepare) prepare();

        this.setState({
            damageData: updatedDamageData,
            damageSelectedId: null,
            isEditingDamages: false,
        }, this.redraw);
    };

    onDamageUpdateFailure = () => {
        const { t } = this.props;
        displayNotification(NOTIFICATION_TYPE.ERROR, t('preInspections.form.updateDamagesFailureMsg'));
    };

    onSaveDamagesEditionClick = () => {
        const { imgId, updateFileDamages } = this.props;
        const { damageData } = this.state;

        const damageDataSend = damageData.map((damage) => ({
            ...damage,
            id: damage.id < 0 ? 0 : damage.id,
        }));

        updateFileDamages(imgId, damageDataSend, this.onDamageUpdateSuccess, this.onDamageUpdateFailure);
    };

    onEditDamages = () => {
        const { annotationBoxes } = this.state;

        this.setState({
            annotationBoxes: annotationBoxes.map((box) => ({ ...box, isSelected: false })),
            isEditingDamages: true,
        });
    };

    onDamageTypeOptSelected = (opt: SelectOption) => {
        const {
            damageData,
            currentDrawingPolygonPoints,
            widthRatio,
            heightRatio,
        } = this.state;

        this.setState({
            currentDrawingPolygonPoints: [],
            damageData: [
                ...damageData, {
                    id: generateInt(-50000, -1),
                    damageSeverityType: FileDamageSeverityType.LOW,
                    damageType: opt.value,
                    polygonPoints: currentDrawingPolygonPoints.map((point) => ({
                        x: Math.round(point.x / widthRatio),
                        y: Math.round(point.y / heightRatio),
                    })),
                },
            ],
        }, this.redraw);
    };

    getAnnotationBoxes = async (): Promise<void> => {
        const {
            getAnnotations,
            imgId,
            isEditable,
            t,
        } = this.props;

        const annotations = await getAnnotations(imgId, isEditable);

        if (annotations.failed) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('modalImage.commentsFailed'));
        }

        this.setState({ annotationBoxes: [...annotations.list] });
    };

    handleClickWhenDrawingPolygon = (clickedX: number, clickedY: number) => {
        const {
            currentDrawingPolygonPoints,
        } = this.state;

        if (currentDrawingPolygonPoints.length === 0) {
            this.setState({ currentDrawingPolygonPoints: [{ x: clickedX, y: clickedY }] });
            return;
        }

        const distance = getTwoPointsDistance(currentDrawingPolygonPoints[0], { x: clickedX, y: clickedY });

        if (distance < LAST_POINT_THRESHOLD) {
            this.handleFinishDrawingCurrentPolygon();
            return;
        }

        this.setState({ currentDrawingPolygonPoints: [...currentDrawingPolygonPoints, { x: clickedX, y: clickedY }] }, this.redraw);
    };

    handleFinishDrawingCurrentPolygon = () => {
        const { t } = this.props;

        displaySelectOptionsDialog({
            message: t('preInspections.form.selectDamageTypeMsg'),
            options: [{
                value: FileDamageType.GLASS,
                label: t('preInspections.form.glassDamageBtn'),
            }, {
                value: FileDamageType.BODYWORK,
                label: t('preInspections.form.bodyworkDamageBtn'),
            }],
            onOptionSelected: this.onDamageTypeOptSelected,
        });
    };

    drawCanvas = async () => {
        const { imgLabel } = this.props;
        const { imgSrc } = this.state;

        const canvasWidth = canvas.clientWidth;
        const canvasHeight = canvas.clientHeight;

        const baseCanvasImage = new Image();
        baseCanvasImage.src = imgSrc;
        baseCanvasImage.alt = imgLabel || '';

        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        baseCanvasImage.onload = async () => {
            const widthRatio = canvasWidth / baseCanvasImage.naturalWidth;
            const heightRatio = canvasHeight / baseCanvasImage.naturalHeight;

            this.setState({
                imgRealWidth: baseCanvasImage.naturalWidth,
                imgRealHeight: baseCanvasImage.naturalHeight,
                backgroundImage: await createImageBitmap(baseCanvasImage),
                widthRatio,
                heightRatio,
            }, this.redraw);
        };
    };

    drawCurrentDrawingPolygon = () => {
        const { currentDrawingPolygonPoints } = this.state;

        for (let i = 0; i < currentDrawingPolygonPoints.length - 1; i++) {
            const currentPoint = currentDrawingPolygonPoints[i];
            const nextPoint = currentDrawingPolygonPoints[i + 1];

            drawNewLineInCanvas(ctx, currentPoint.x, currentPoint.y, nextPoint.x, nextPoint.y);
        }
    };

    redraw = () => {
        const {
            backgroundImage,
            widthRatio,
            heightRatio,
            damageData,
            outlineData,
            damageSelectedId,
            opacityLevel,
            imgRealWidth,
            imgRealHeight,
        } = this.state;

        if (!backgroundImage || !canvas) return;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ctx.drawImage(backgroundImage, 0, 0, imgRealWidth * widthRatio, imgRealHeight * heightRatio);

        drawDamagesPolygonsInCanvas({
            canvasCtx: ctx,
            damagesList: damageData,
            outlineList: outlineData,
            opacity: opacityLevel,
            widthRatio,
            heightRatio,
            damageSelectedId,
        });

        this.drawCurrentBox();
        this.drawCurrentDrawingPolygon();
    };

    handleMouseDown = (e: MouseEvent): void => {
        const { isEditingDamages } = this.state;

        if (isEditingDamages) return;

        const { left, top } = canvas.getBoundingClientRect();
        const horizontalPositionTopLeft = e.clientX - left;
        const verticalPositionTopLeft = e.clientY - top;

        this.currentBox[0] = canvas.getContext('2d')!;

        this.newBox.current = {
            id: -1,
            horizontalPositionTopLeft,
            verticalPositionTopLeft,
            horizontalPositionBottomRight: horizontalPositionTopLeft,
            verticalPositionBottomRight: verticalPositionTopLeft,
            comment: '',
            isEditing: false,
            isSelected: false,
        };
    };

    handleMouseUp = (): void => {
        const {
            isDrawing,
            imgRealHeight,
            imgRealWidth,
        } = this.state;

        this.setState({
            isDrawing: false,
        });

        if (!this.newBox.current) return;

        const isBoxValid = Math.abs(this.newBox.current.horizontalPositionTopLeft - this.newBox.current.horizontalPositionBottomRight) * Math.abs(this.newBox.current.verticalPositionTopLeft - this.newBox.current.verticalPositionBottomRight) > 10;

        const xStart = Math.min(this.newBox.current.horizontalPositionTopLeft, this.newBox.current.horizontalPositionBottomRight);
        const xEnd = Math.max(this.newBox.current.horizontalPositionTopLeft, this.newBox.current.horizontalPositionBottomRight);
        const yStart = Math.min(this.newBox.current.verticalPositionTopLeft, this.newBox.current.verticalPositionBottomRight);
        const yEnd = Math.max(this.newBox.current.verticalPositionTopLeft, this.newBox.current.verticalPositionBottomRight);

        if (isDrawing && isBoxValid) {
            const ratioWidth = imgRealWidth / canvas.clientWidth;
            const ratioHeight = imgRealHeight / canvas.clientHeight;

            this.createNote({
                ...this.newBox.current,
                horizontalPositionTopLeft: xStart * ratioWidth,
                horizontalPositionBottomRight: xEnd * ratioWidth,
                verticalPositionTopLeft: yStart * ratioHeight,
                verticalPositionBottomRight: yEnd * ratioHeight,
            });
        }

        this.newBox.current = null;
        this.redraw();
    };

    handleMouseMove = (e: MouseEvent): void => {
        const {
            isEditingDamages,
            currentDrawingPolygonPoints,
        } = this.state;

        e.preventDefault();
        e.stopPropagation();

        const { left, top } = canvas.getBoundingClientRect();

        const x = e.clientX - left;
        const y = e.clientY - top;

        if (isEditingDamages && currentDrawingPolygonPoints.length > 0) {
            const lastPoint = currentDrawingPolygonPoints[currentDrawingPolygonPoints.length - 1];
            this.redraw();
            drawNewLineInCanvas(ctx, lastPoint.x, lastPoint.y, x, y);
            return;
        }

        if (!this.newBox.current) {
            return;
        }

        this.newBox.current = {
            ...this.newBox.current,
            id: -1,
            horizontalPositionBottomRight: x,
            verticalPositionBottomRight: y,
            comment: '',
            isEditing: true,
            isSelected: true,
        };
        this.setState({ isDrawing: true });
        this.redraw();
    };

    handleCanvasKeydown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            this.cleanCurrentPolygonPoints();
        } else if (e.key === 'Backspace') {
            this.onRemoveDamageClick();
        }
    };

    handleEditNote = async (comment: string, box: Box) => {
        const { annotationBoxes } = this.state;
        const { editAnnotation, imgId } = this.props;

        const idx = annotationBoxes.findIndex((b) => b.id === box.id);
        const newBoxes = [...annotationBoxes];
        newBoxes[idx] = {
            ...newBoxes[idx],
            comment,
            isEditing: false,
        };
        this.setState({
            annotationBoxes: [...newBoxes],
        });

        await editAnnotation({ ...box, comment }, imgId);
    };

    handleOpacityChange = (newOpacityValue: number) => {
        this.setState({ opacityLevel: newOpacityValue }, this.redraw);
    };

    isPositionEqual = (b1: Box, b2: Box): boolean => {
        return b1.horizontalPositionTopLeft === b2.horizontalPositionTopLeft
        && b1.horizontalPositionBottomRight === b2.horizontalPositionBottomRight
        && b1.verticalPositionTopLeft === b2.verticalPositionTopLeft
        && b1.verticalPositionBottomRight === b2.verticalPositionBottomRight;
    };

    removeSelectedDamageFromList = () => {
        const { damageSelectedId, damageData } = this.state;

        if (!damageSelectedId) return;

        this.setState({
            damageData: [...damageData].filter((damage) => damage.id !== damageSelectedId),
            damageSelectedId: null,
        }, this.redraw);
    };

    cleanCurrentPolygonPoints = () => {
        this.setState({
            currentDrawingPolygonPoints: [],
        }, this.redraw);
    };

    createNote = async (box: Box) => {
        const { annotationBoxes } = this.state;
        const { addAnnotation, imgId } = this.props;

        const addedBox = await addAnnotation(box, imgId);

        if (!addedBox) return;

        this.setState({
            isDrawing: false,
            annotationBoxes: [...annotationBoxes, { ...addedBox, isEditing: true, isSelected: true }],
        }, this.redraw);
    };

    drawCurrentBox = () => {
        const { isEditable } = this.props;

        if (!this.newBox.current) return;

        ctx.strokeStyle = isEditable ? 'green' : 'red';
        ctx.lineWidth = 2;

        ctx.strokeRect(this.newBox.current.horizontalPositionTopLeft, this.newBox.current.verticalPositionTopLeft, this.newBox.current.horizontalPositionBottomRight - this.newBox.current.horizontalPositionTopLeft, this.newBox.current.verticalPositionBottomRight - this.newBox.current.verticalPositionTopLeft);

        ctx.save();
    };

    editNote = (box: Box): void => {
        const { annotationBoxes } = this.state;

        this.setState({
            annotationBoxes: annotationBoxes.map(((b) => {
                if (b.id === box.id || this.isPositionEqual(box, b)) {
                    return { ...b, isEditing: true, isSelected: true };
                }
                return { ...b, isEditing: false, isSelected: false };
            })),
        });
    };

    removeNote = async (box: Box): Promise<void> => {
        const {
            deleteAnnotation, deleteGlassAIAnnotation, imgId, t,
        } = this.props;
        const { annotationBoxes } = this.state;

        if (box.isGlassDamage) {
            if (await deleteGlassAIAnnotation(imgId, box.id)) {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('modalImage.removeAnotationError'));
                return;
            }
        } else {
            deleteAnnotation(box, imgId);

            this.currentBox[0] = ctx;
            this.currentBox[0]?.clearRect(0, 0, canvas?.width || 0, canvas?.height || 0);
        }

        this.setState({
            annotationBoxes: annotationBoxes.filter((b) => !this.isPositionEqual(box, b)),
        }, this.redraw);
    };

    removeBoxFocus = (event: React.FocusEvent<HTMLInputElement>, box: Box): void => {
        const { t } = this.props;

        const comment = event.currentTarget.value;

        if (comment.length > 20) {
            displayNotification(
                NOTIFICATION_TYPE.ERROR,
                t('modalImage.messageTooLong'),
            );
            return;
        }

        this.handleEditNote(comment, box);
    };

    selectNote = (box: Box): void => {
        const { annotationBoxes } = this.state;

        const newBoxes = cloneDeep(annotationBoxes).map((b) => ({ ...b, isSelected: false }));
        const index = annotationBoxes.findIndex((b) => b.id === box.id || this.isPositionEqual(box, b));

        if (index < 0) return;
        newBoxes[index].isSelected = !annotationBoxes[index].isSelected;

        this.setState({ annotationBoxes: newBoxes });
    };

    unselectNotes = (): void => {
        const { annotationBoxes } = this.state;

        this.setState({
            annotationBoxes: annotationBoxes.map((b) => ({ ...b, isSelected: false })),
        });
    };

    renderBoxes = (boxes: Array<Box>): ReactNode => {
        const { isEditable } = this.props;
        const { imgRealWidth, imgRealHeight } = this.state;

        if (boxes.length === 0) return;

        const imgWidth = canvas.clientWidth;
        const imgHeight = canvas.clientHeight;

        const ratioWidth = imgWidth / imgRealWidth;
        const ratioHeight = imgHeight / imgRealHeight;

        return (
            <div
                className="comments-boxes"
                style={{
                    width: imgWidth,
                    height: imgHeight,
                }}
                data-testid="boxes-wrapper"
            >
                {boxes.map((box) => {
                    const xStart = Math.min(box.horizontalPositionTopLeft, box.horizontalPositionBottomRight); // did this so things in production dont get broken
                    const xEnd = Math.max(box.horizontalPositionTopLeft, box.horizontalPositionBottomRight);
                    const yStart = Math.min(box.verticalPositionTopLeft, box.verticalPositionBottomRight);
                    const yEnd = Math.max(box.verticalPositionTopLeft, box.verticalPositionBottomRight);

                    let topPx = Math.round(yStart * ratioHeight);
                    let leftPx = Math.round(xStart * ratioWidth);
                    let rightPx = Math.round(Math.abs(imgRealWidth - xEnd) * ratioWidth);
                    let bottomPx = Math.round(Math.abs(imgRealHeight - yEnd) * ratioHeight);

                    const increasedValue = 5;
                    if ((imgHeight - topPx - bottomPx) < 30) {
                        topPx -= increasedValue;
                        bottomPx -= increasedValue;
                    }

                    if ((imgWidth - leftPx - rightPx) < 30) {
                        leftPx -= increasedValue;
                        rightPx -= increasedValue;
                    }

                    return (
                        <div
                            key={box.id}
                            className="box-wrapper"
                            data-testid="box-wrapper"
                            style={{
                                top: `${topPx}px`,
                                bottom: `${bottomPx}px`,
                                left: `${leftPx}px`,
                                right: `${rightPx}px`,
                            }}
                            onMouseUp={isEditable ? this.handleMouseUp : undefined}
                            onMouseLeave={isEditable ? this.handleMouseUp : undefined}
                        >
                            {box.isSelected && (
                                <button className="box-wrapper__close-btn zoomed" type="button" data-testid="close-button" onClick={() => this.removeNote(box)}>
                                    <IconClose />
                                </button>
                            )}
                            <div
                                className="box-wrapper__box"
                                data-testid="img-box-element"
                                style={{
                                    borderWidth: '3px',
                                    borderColor: !box.isGlassDamage ? 'green' : 'red',
                                }}
                                onClick={isEditable || box.isGlassDamage ? () => this.selectNote(box) : () => {}}
                            />
                            {(isEditable && !box.isGlassDamage) && (
                                <div className="box-wrapper__comment">
                                    <div>
                                        {box.isEditing ? (
                                            <input autoFocus type="text" onBlur={(e): void => this.removeBoxFocus(e, box)} placeholder={box.comment} data-testid="comment-input" />
                                        ) : (
                                            <p data-testid="commentLabel" onClick={(): void => this.editNote(box)}>{box.comment}</p>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    renderLoader = () => {
        const { isFetching } = this.state;

        if (!isFetching) return;

        return (
            <div className="image-loader">
                <Loader />
            </div>
        );
    };

    renderAction = () => {
        const { t } = this.props;
        const {
            isDrawing,
            isEditingDamages,
            damageSelectedId,
        } = this.state;

        if (isDrawing) {
            return (
                <div className="draw-image__details__inner__actions">
                    <p>{t('preInspections.form.addingNewAnnotationMsg')}</p>
                </div>
            );
        }

        if (isEditingDamages) {
            return (
                <div className="draw-image__details__inner__actions">
                    <Button
                        extraClasses="btn--outlined"
                        onClick={this.onCancelDamagesEdition}
                    >
                        {t('global.buttons.reject')}
                    </Button>
                    <Button
                        extraClasses="btn--danger"
                        onClick={() => this.onRemoveDamageClick()}
                        isDisabled={!damageSelectedId}
                    >
                        {t('preInspections.form.removeDamageBtn')}
                    </Button>
                    <Button
                        extraClasses="btn--purple draw-image__details__inner__actions__bigger-btn"
                        onClick={this.onSaveDamagesEditionClick}
                    >
                        {t('preInspections.form.saveNewDamagesBtn')}
                    </Button>
                </div>
            );
        }

        return (
            <div className="draw-image__details__inner__actions">
                <Button
                    extraClasses="btn--purple btn-edit-damages"
                    onClick={() => this.onEditDamages()}
                >
                    {t('preInspections.form.editDamagesBtn')}
                </Button>
            </div>
        );
    };

    render = (): ReactNode => {
        const { t } = this.props;
        const {
            annotationBoxes,
            opacityLevel,
        } = this.state;

        return (
            <div className="draw-image" data-testid="image-draw-modal">
                <div className="draw-image__inner">
                    <Button
                        extraClasses="btn-close"
                        onClick={this.onClose}
                    >
                        <img src={iconCross} alt={t('preInspections.form.labels.close')} />
                    </Button>
                    <div id="damage-canvas" />
                    {this.renderBoxes(annotationBoxes)}
                </div>
                <div className="draw-image__details">
                    <div className="draw-image__details__inner">
                        <DamageOpacityInput
                            opacityLevel={opacityLevel}
                            onOpacityValueChange={this.handleOpacityChange}
                        />
                        {this.renderAction()}
                    </div>
                </div>
                {this.renderLoader()}
            </div>
        );
    };
}

export default withPreInspectionsContext(withAnnotationContext(withTranslationContext(withFileDamageContext(DrawImagesPolygonModal))));
