/* eslint-disable react/button-has-type */

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { SvgIcon } from './SvgIcon';

interface OwnProps {
    isLink?: boolean;
    type?: 'button' | 'submit';
    text?: string;
    styles?: string;
    callback?: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    icon?: string;
    name?: string;
    iconPosition?: string;
    disabled?: boolean;
    withoutSpan?: boolean;
    toolTipText?: string;
}

class ButtonDeprecated extends Component<OwnProps> {
    getClassNames = (): string => {
        const {
            styles, isLink, icon, text, iconPosition, disabled,
        } = this.props;

        let baseStyle = 'btn';

        if (styles) {
            baseStyle = `btn ${styles}`;
        }

        let disabledStyle = '';
        if (disabled) {
            disabledStyle = 'btn--disabled';
        }

        const linkStyle: string = isLink ? 'btn--link' : '';

        let iconStyle = '';
        let iconPosStyle = '';
        if (icon) {
            if (text && text.length > 0) {
                iconStyle = 'btn--with-icon';
                iconPosStyle = `btn--with-icon--${iconPosition}`;
            } else {
                iconStyle = 'btn--just-icon';
            }
        }

        return `${baseStyle} ${linkStyle} ${iconStyle} ${iconPosStyle} ${disabledStyle}`;
    };

    renderDisabledButton = () => {
        const {
            text,
            callback,
            type,
            icon,
            name,
        } = this.props;

        return (
            <button
                type={type || 'button'}
                className={this.getClassNames()}
                onClick={callback}
                name={name || ''}
                disabled
            >
                {text && <span>{text}</span>}
                {icon && <SvgIcon icon={icon} />}
            </button>
        );
    };

    render() {
        const {
            text, callback, type, icon, name, disabled, withoutSpan, toolTipText,
        } = this.props;

        if (disabled) {
            return this.renderDisabledButton();
        }

        return (
            <button
                type={type || 'button'}
                className={this.getClassNames()}
                onClick={callback}
                name={name || ''}
                title={toolTipText || undefined}
            >
                {(text && !withoutSpan) && <span>{text}</span>}
                {(text && withoutSpan) && text}
                {icon && <SvgIcon icon={icon} />}
            </button>
        );
    }
}

export default ButtonDeprecated;
