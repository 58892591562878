/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { TabItem } from '../../../constants/misc';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { PreInspectionsTabsEnum } from '../../../constants/tabs';
import Tabs from '../../elements/Tabs';
import PreInspectionsListScreen from './PreInspectionsListScreen';
import {
    PRE_INSPECTIONS_COMPLETE_LIST_ROUTE,
    PRE_INSPECTIONS_GLASS_LIST_ROUTE,
    PRE_INSPECTIONS_LIST_ROUTE,
    PRE_INSPECTIONS_CREATE_ROUTE,
} from '../../../constants/routes';
import { ICON } from '../../elements/SvgIcon';
import { withRouter, WithRouterProps } from '../../containers/withRouter';
import { AuthenticationContext, withAuthenticationContext } from '../../controllers/authentication/AuthenticationContext';
import { UserRoles } from '../../../constants/authorization';

interface OwnProps extends WithRouterProps, TranslationContext, AuthenticationContext {}

interface OwnState {
    tabItemSelected: string;
    tabItems: TabItem[];
    addButtonText?: string;
    secondAddButtonText?: string;
    searchValue: string;
}

const initialState: OwnState = {
    tabItemSelected: PreInspectionsTabsEnum.ALL,
    tabItems: [],
    addButtonText: undefined,
    secondAddButtonText: undefined,
    searchValue: '',
};

class PreInspectionsScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { t, location, user } = this.props;

        const { pathname } = location;

        const role = user?.role || '';

        let addButtonText: string | undefined = t('preInspections.list.addGlassInspectionBtn');
        let secondAddButtonText: string | undefined = t('preInspections.list.addPreInspectionBtn');

        let tabItemSelected = PreInspectionsTabsEnum.ALL;

        if (role === UserRoles.PILOT_TESTER) {
            tabItemSelected = PreInspectionsTabsEnum.COMPLETE;
            addButtonText = undefined;
        } else if (pathname === PRE_INSPECTIONS_GLASS_LIST_ROUTE) {
            tabItemSelected = PreInspectionsTabsEnum.GLASS;
            secondAddButtonText = undefined;
        } else if (pathname === PRE_INSPECTIONS_COMPLETE_LIST_ROUTE) {
            tabItemSelected = PreInspectionsTabsEnum.COMPLETE;
            addButtonText = undefined;
        }

        const tabItems: TabItem[] = [];

        Object.values(PreInspectionsTabsEnum).forEach((preInspectionsTab) => {
            if (role === UserRoles.PILOT_TESTER && preInspectionsTab !== PreInspectionsTabsEnum.COMPLETE) return;

            const item: TabItem = {
                value: preInspectionsTab,
                text: t(`preInspections.list.tabItems.${preInspectionsTab}`),
            };

            tabItems.push(item);
        });

        this.setState({
            tabItems,
            tabItemSelected,
            addButtonText,
            secondAddButtonText,
        });
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>): void {
        const { location: { pathname: oldPathname }, user } = prevProps;

        const { location: { pathname }, t, navigate } = this.props;

        let addButtonText: string | undefined = t('preInspections.list.addGlassInspectionBtn');
        let secondAddButtonText: string | undefined = t('preInspections.list.addPreInspectionBtn');

        if (pathname !== oldPathname) {
            let tabItemSelected = PreInspectionsTabsEnum.ALL;

            if (user?.role === UserRoles.PILOT_TESTER) {
                if (pathname !== PRE_INSPECTIONS_COMPLETE_LIST_ROUTE) navigate(PRE_INSPECTIONS_COMPLETE_LIST_ROUTE);

                tabItemSelected = PreInspectionsTabsEnum.COMPLETE;
                addButtonText = undefined;
            } else if (pathname === PRE_INSPECTIONS_GLASS_LIST_ROUTE) {
                tabItemSelected = PreInspectionsTabsEnum.GLASS;
                secondAddButtonText = undefined;
            } else if (pathname === PRE_INSPECTIONS_COMPLETE_LIST_ROUTE) {
                tabItemSelected = PreInspectionsTabsEnum.COMPLETE;
                addButtonText = undefined;
            }

            this.setState({
                tabItemSelected,
                addButtonText,
                secondAddButtonText,
            });
        }
    }

    onTabChange = (newTab: string) => {
        const { navigate } = this.props;
        switch (newTab) {
            case PreInspectionsTabsEnum.COMPLETE:
                navigate(PRE_INSPECTIONS_COMPLETE_LIST_ROUTE);
                break;
            case PreInspectionsTabsEnum.GLASS:
                navigate(PRE_INSPECTIONS_GLASS_LIST_ROUTE);
                break;
            case PreInspectionsTabsEnum.ALL:
                navigate(PRE_INSPECTIONS_LIST_ROUTE);
                break;
            default:
        }

        this.setState({
            tabItemSelected: newTab,
        });
    };

    addPreInspectionClick = () => {
        const { navigate } = this.props;
        navigate(`${PRE_INSPECTIONS_CREATE_ROUTE}/pre-inspection`);
    };

    addGlassInpectionClick = () => {
        const { navigate } = this.props;
        navigate(`${PRE_INSPECTIONS_CREATE_ROUTE}/glass`);
    };

    render() {
        const {
            tabItems, tabItemSelected, addButtonText, secondAddButtonText,
        } = this.state;

        return (
            <div>
                <Tabs
                    tabsItems={tabItems}
                    onTabChange={this.onTabChange}
                    withSearch
                    tabSelected={tabItemSelected}
                    addButtonText={addButtonText}
                    addButtonClickHandler={this.addGlassInpectionClick}
                    secondAddButtonText={secondAddButtonText}
                    secondButtonIcon={ICON.PLUS}
                    onSecondAddButtonClickHandler={this.addPreInspectionClick}
                />
                <PreInspectionsListScreen
                    tabItem={tabItemSelected}
                />
            </div>
        );
    }
}

export default withTranslationContext(withRouter(withAuthenticationContext(PreInspectionsScreen)));
