/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export const VERSION = process.env.REACT_APP_COMMIT;
export const API_URL = process.env.REACT_APP_API_URL;
export const WEB_URL = process.env.REACT_APP_WEB;
export const MOBILE_URL = process.env.REACT_APP_MOBILE_URL;
export const WEB_SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL;
export const STUN_SERVER_URL = process.env.REACT_APP_ICE_STUN_SERVER;
export const TURN_SERVER_URL = process.env.REACT_APP_ICE_TURN_SERVER;
export const IS_TESTING_ENVIRONMENT = process.env.REACT_APP_IS_TESTING_ENVIRONMENT === 'true';
